import styled from 'styled-components';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { Service, useHomePage } from '@home-diy-toolbox/web/contentful';
import { useFlow } from '../../providers';
import { Section } from './components/section2';
import StoreFixReplace from './assets/mini-store-fix-replace-smartphone.svg';
import { breakpoints, PageLoader, Text } from '@soluto-private/mx-asurion-ui-react';
import WorkFromHome from './assets/mini-work-from-home.svg';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 343px;

	${breakpoints.lg} {
		max-width: calc(100vw - 3em);
	}
`;

const Header = styled.div`
	display: flex;
	margin: 0.5rem 0px 1.5rem 0px;
	align-self: baseline;
`;

export const HomePage = (): JSX.Element => {
	const page = useHomePage();

	const {
		flow: {
			sessionData: { eligibility },
		},
	} = useFlow();

	if (!page) return <PageLoader />;
	if (page.designType === true) {
		return;
	}

	const inStoreServices: Service[] = (page.inStoreServices ?? []).filter((service) => {
		const isEligibilityRequired = Object.keys(eligibility).includes(service.checkupType);
		const isEligible = !!eligibility[service.checkupType];
		return isEligibilityRequired ? isEligible : true;
	});

	return (
		<Container className="widecontainer">
			<Header>
				<Text weight="feather" size={5} data-cy="header" as="h1">
					{page.heroTitle}
				</Text>
			</Header>
			<Section
				title={page.servicesTitle}
				description={page.servicesSubtitle}
				visual={WorkFromHome}
				services={page.services}
			/>
			<Section
				title={page.inStoreServicesTitle}
				description={page.inStoreServicesSubtitle}
				visual={StoreFixReplace}
				services={inStoreServices}
			/>
		</Container>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: GenericPageIds.IN_STORE_HOME,
	CheckupType: undefined,
})(HomePage);
