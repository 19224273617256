import { Partners } from '@home-diy-toolbox/web/common-types';
import { ChatSender } from './ChatSender';
import { getChatClient } from '../components';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { ChatButton } from './ChatButton';
import { colors } from '@soluto-private/mx-asurion-ui-react';

const Container = styled.div`
	margin: 0.75rem 0;
`;

const Message = styled.div`
	position: relative;
	padding: 8px 16px;
	display: inline-block;
	min-width: 0.625em;
	max-width: 17.5em;
	word-break: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	line-height: 1.5;
	background-color: ${colors.white};
	color: ${colors.neutralDeepest};
	border: 1px solid ${colors.neutralBright};
	border-radius: 20px;
	margin: 0.125em 1em;
`;

const MessagesContainer = styled.div`
	margin-bottom: 1rem;
`;

const ChatButtonsContainer = styled.div`
	margin: 0.75rem 0;
`;

export class AEChatSender extends ChatSender {
	isInitialLoad(node: Node) {
		return node?.textContent.includes("Let's get going");
	}

	async sendVZTroubleshootMessages(partner: string) {
		const observer = new MutationObserver(async (mutationList, observer) => {
			for (const mutation of mutationList) {
				const newNode = mutation?.addedNodes?.item(0);
				const initialLoad = this.isInitialLoad(newNode);
				if (!initialLoad) continue;

				this.clearNodeChildren(newNode);

				if (partner === Partners.VERIZON) {
					const vzTroubleshoot = await this.getVzTroubleshoot();
					if (!vzTroubleshoot) return;

					const container = document.createElement('div');
					const messagesContainer = document.getElementsByClassName(
						'timeline-messages-section'
					)[0];
					ReactDOM.render(
						<Container>
							<MessagesContainer>
								{vzTroubleshoot.messages.map((message) => (
									<Message key={message}>{message}</Message>
								))}
							</MessagesContainer>
							<ChatButtonsContainer>
								{vzTroubleshoot.chatButtons.map((chatButton) => (
									<ChatButton
										key={chatButton.buttonText}
										onClick={() =>
											getChatClient().sendMessage('CustomerMessage', chatButton.chatText)
										}
										message={chatButton.buttonText}
									/>
								))}
							</ChatButtonsContainer>
						</Container>,
						container
					);

					messagesContainer.appendChild(container);
				}

				observer.disconnect();
				break;
			}
		});

		this.observeChatContainer(observer);
	}

	private clearNodeChildren(node: Node) {
		while (node.firstChild) {
			node.removeChild(node.lastChild);
		}
	}
}
