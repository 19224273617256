import { Checkups, Partners } from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	NewBatteryCheckupFlow,
	BatteryCheckupFlow,
	BatteryFlowPageNextParams,
} from './batteryCheckupFlow';

/* This contains the rollups of all the individual configs in a way that enables the strong typing to work for each checkup */

export type CheckupFlowConfigs = {
	[Checkups.BATTERY]: typeof BatteryCheckupFlow;
};

export type CheckupFlowParams = {
	[Checkups.BATTERY]: BatteryFlowPageNextParams;
};

export const CHECKUP_FLOW_CONFIGS = {
	[Checkups.BATTERY]: {
		[Partners.DEFAULT]: BatteryCheckupFlow,
		[Partners.ATT]: NewBatteryCheckupFlow,
		[Partners.VERIZON]: NewBatteryCheckupFlow,
		[Partners.COX]: NewBatteryCheckupFlow,
		[Partners.USCELLULAR]: NewBatteryCheckupFlow,
		[Partners.CRICKET]: NewBatteryCheckupFlow,
	},
} as const;

/** The flow types that are supported for these dynamic checkups */
export type SupportedCheckups = keyof typeof CHECKUP_FLOW_CONFIGS;
