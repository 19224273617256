import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { invert } from 'lodash';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import {
	SimpleArticleGridSection,
	useServicePage,
} from '@home-diy-toolbox/web/contentful';
import { GradientPage } from '@home-diy-toolbox/web/theme';
import {
	BatteryFilterPathNames,
	BatteryPageIds,
	CheckupQuestionPathnames,
	Checkups,
	GenericPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { Step, useBreadcrumb } from '../../../providers';
import { ArticleGridSection } from '../../../components/ArticleGridSection';
import { ExpertContactCard, OtherServices2 } from '../../../components';
import { BatteryReplacementEligibilityCard } from './BatteryReplacementEligibilityCard';
import { LoadingPage } from '../../LoadingPage';

const Container = styled(GradientPage)`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 0 1rem;
	gap: 2.25rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		padding: 0 1.5rem;
	}

	${breakpoints.xxl} {
		padding: 0 1rem;
		max-width: 90rem;
	}
`;

const BatteryLandingPage = (): JSX.Element => {
	const batteryPage = useServicePage('batteryPage');

	const { setBreadcrumbSteps, resetBreadcrumbSteps } = useBreadcrumb();
	useEffect(() => {
		if (batteryPage?.breadcrumbTitle) {
			const breadcrumbSteps: Step[] = [
				{
					path: '/',
					label: 'Device Care',
				},
				{
					path: CheckupQuestionPathnames.BATTERY,
					label: 'batteryHeader',
				},
				{
					label: batteryPage.breadcrumbTitle,
				},
			];
			setBreadcrumbSteps(breadcrumbSteps);
		}
		return () => {
			resetBreadcrumbSteps();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [batteryPage?.breadcrumbTitle]);

	const gridSection = useMemo(() => {
		if (batteryPage) {
			const data: SimpleArticleGridSection = {
				id: batteryPage.id,
				title: batteryPage.pageTitle,
				commonTag: batteryPage.commonTag,
				filters: batteryPage.filters.map((filter) => ({
					label: filter.label,
					value: filter.values,
					url: filter.url,
				})),
			};
			return data;
		}
		return null;
	}, [batteryPage]);

	if (!batteryPage) {
		return <LoadingPage />;
	}

	return (
		<Container className="widecontainer">
			<ArticleGridSection articleGridSection={gridSection} />
			<BatteryReplacementEligibilityCard
				batteryReplacementEligibilityCard={batteryPage.replacementEligibility}
			/>
			<ExpertContactCard from={GenericPageIds.BATTERY_PAGE} />
			<OtherServices2
				title={batteryPage.serviceListTitle}
				services={batteryPage.serviceList}
			/>
		</Container>
	);
};

const pathname = window.location.pathname;
const subpath = pathname.match(/\/device-care(\/.+)/);
const filter = subpath && subpath.length > 1 ? subpath[1] : '/battery-popular';
const signalPathMap = invert(BatteryFilterPathNames);
const page = signalPathMap[filter];

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: BatteryPageIds[page],
	CheckupType: Checkups.BATTERY,
})(BatteryLandingPage);
