import {
	HeaderState,
	updateHeader as mxUpdateHeader,
} from '@soluto-private/mx-app-header';
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

const HeaderContext = createContext<{
	currentState: () => HeaderState;
	initHeader: () => void;
	updateHeader: (
		state: Partial<HeaderState>,
		onceBackUrl?: string,
		customOnBackClicked?: () => void
	) => void;
}>(null);

export const HeaderProvider = ({ children }) => {
	const history = useHistory();
	const headerState = useRef<HeaderState>({
		view: 'service-view',
		title: '',
		backTitle: ' ',
		homePath: '/device-care',
		onBack() {
			handleBack();
		},
		onLogoClick() {
			history.push('/');
		},
		subNavConfig: {
			showPartnerLogo: true,
		},
	});
	const onceBackUrlRef = useRef<string>(null);
	const customOnBackClickedRef = useRef<() => void>(null);

	const currentState = useCallback(() => {
		return headerState.current;
	}, []);

	useEffect(() => {
		mxUpdateHeader(headerState.current);
	}, []);

	const initHeader = useCallback(() => {
		mxUpdateHeader(headerState.current);
	}, []);

	const updateHeader = useCallback(
		(
			state: Partial<HeaderState>,
			onceBackUrl?: string,
			customOnBackClicked?: () => void
		) => {
			onceBackUrlRef.current = onceBackUrl;
			customOnBackClickedRef.current = customOnBackClicked;

			const mergedState = mergeState(state);
			mxUpdateHeader(mergedState);
		},
		[]
	);

	const mergeState = (state: Partial<HeaderState>) => {
		headerState.current = {
			...headerState.current,
			...state,
		};
		return headerState.current;
	};

	const handleBack = () => {
		customOnBackClickedRef.current?.();

		if (onceBackUrlRef.current) {
			history.push(onceBackUrlRef.current);
			onceBackUrlRef.current = null;
			return;
		}

		if (history.length <= 1) {
			history.push('/');
			return;
		}

		history.goBack();
	};

	const value = useMemo(
		() => ({ currentState, initHeader, updateHeader }),
		[currentState, initHeader, updateHeader]
	);

	return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

export const useHeader = () => useContext(HeaderContext);
