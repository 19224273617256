import { useAnalytics } from 'react-shisell';
import { DataMap } from 'shisell';
import { withExtras } from 'shisell/extenders';
import { AnalyticEventType } from '../types';

export const useDispatchEvent = () => {
	const { dispatcher } = useAnalytics();

	return {
		dispatchEvent: (eventType: AnalyticEventType, extras?: DataMap) => {
			dispatcher.extend(withExtras(extras)).dispatch(eventType);
		},
	};
};
