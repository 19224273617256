import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	colors,
	getFontSize,
	getFontWeight,
	Checkbox,
	Text,
} from '@soluto-private/mx-asurion-ui-react';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { RichText, Answer } from '@home-diy-toolbox/web/contentful';
import { DurationPicker } from '@home-diy-toolbox/web/duration-picker';
import { PercentageInput } from '@home-diy-toolbox/web/percentage-input';
import { type BatteryChargeAnswer } from '../answers/types';
import { type Props } from './types';
import { InputContainer } from './styles';

const Instruction = styled(Text)`
	&& {
		display: block;
		margin-bottom: 0.5rem;
		font-size: ${getFontSize(6)};
		font-weight: ${getFontWeight('feather')};
		max-width: 50rem;
	}
`;

const Guide = styled.button`
	&& {
		display: block;
		text-decoration: underline;
		margin-bottom: 2.25rem;
		font-size: ${getFontSize(3)};
		cursor: pointer;
		background: transparent;
		border: 0;
	}
`;

const Question = styled(Text)`
	&& {
		display: block;
		margin-bottom: 1rem;
		font-size: ${getFontSize(4)};
	}
`;

const CurrentBatteryChargeDescription = styled(Text)`
	&& {
		display: block;
		margin: 0.25rem auto 2.25rem 0.75rem;
		font-size: ${getFontSize(2)};
		color: ${colors.neutralDeep};
	}
`;

const DurationContainer = styled(InputContainer)`
	&& {
		display: flex;
		gap: 0.75rem;
	}
`;

const DontHaveOption = styled(Checkbox)`
	&& {
		margin-top: 1rem;
	}
`;

interface BatteryChargeQuestion {
	instruction: Document;
	guide: Document;
	currentBatteryChargeQuestion: string;
	currentBatteryChargePlaceholder: string;
	currentBatteryChargeLabel: string;
	currentBatteryChargeDescription: string;
	remainingTimeQuestion: string;
	dontHaveOption: Answer;
}

interface ThisProps extends Props<BatteryChargeAnswer, BatteryChargeQuestion> {
	onGuideClick: () => void;
}

export const BatteryCharge = ({
	initialValue,
	onSetValue,
	content,
	onGuideClick,
}: ThisProps) => {
	const {
		instruction,
		guide,
		currentBatteryChargeQuestion,
		currentBatteryChargePlaceholder,
		currentBatteryChargeLabel,
		currentBatteryChargeDescription,
		remainingTimeQuestion,
		dontHaveOption,
	} = content;
	const [answers, setAnswers] = useState(initialValue);

	useEffect(() => {
		onSetValue({ value: initialValue, valid: isValid(answers) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isValid = (answers: BatteryChargeAnswer) => {
		const { currentCharge, duration, dontHaveOption } = answers;
		const hasRemainingTime = duration.days || duration.hours || duration.minutes;
		return (!!currentCharge && !!hasRemainingTime) || dontHaveOption;
	};

	return (
		<div>
			<RichText
				document={instruction}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => <Instruction>{children}</Instruction>,
					},
				}}
			/>
			<RichText
				document={guide}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => (
							<Guide onClick={() => onGuideClick()}>{children}</Guide>
						),
					},
				}}
			/>
			<Question>{currentBatteryChargeQuestion}</Question>
			<InputContainer>
				<PercentageInput
					value={answers.currentCharge}
					setValue={(percentage) => {
						setAnswers((prevVal) => {
							const newVal = { ...prevVal, currentCharge: percentage };
							onSetValue({ value: newVal, valid: isValid(newVal) });
							return newVal;
						});
					}}
					label={currentBatteryChargeLabel}
					placeholder={currentBatteryChargePlaceholder}
				/>
			</InputContainer>
			<CurrentBatteryChargeDescription>
				{currentBatteryChargeDescription}
			</CurrentBatteryChargeDescription>
			<Question>{remainingTimeQuestion}</Question>
			<DurationContainer>
				<DurationPicker
					initialDuration={{
						days: answers.duration.days,
						hours: answers.duration.hours,
						minutes: answers.duration.minutes,
					}}
					onDurationChanged={(e) => {
						setAnswers((prevVal) => {
							const newVal = {
								...prevVal,
								duration: e.value,
								remainingHours: e.totalDurationInHours,
							};
							onSetValue({ value: newVal, valid: isValid(newVal) });
							return newVal;
						});
					}}
					disabled={answers.dontHaveOption}
				/>
			</DurationContainer>
			<DontHaveOption
				label={dontHaveOption.text}
				name={dontHaveOption.entryName}
				onChange={() =>
					setAnswers((prevVal) => {
						const newVal = {
							...prevVal,
							dontHaveOption: !prevVal.dontHaveOption,
						};
						onSetValue({ value: newVal, valid: isValid(newVal) });
						return newVal;
					})
				}
				checked={answers.dontHaveOption}
			/>
		</div>
	);
};
