import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { union } from 'lodash';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import {
	CheckupCategoryType,
	CheckupResultPathnames,
	ComponentIds,
	CheckupPageIds,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow, useResults } from '../../../../providers';
import { removeArrayFromArray } from '@home-diy-toolbox/web/utils';
import {
	AnswerSelectionInstructionsWrapper,
	CheckupContainer,
	CheckboxWithLabel,
	CheckupQuestionsProgress,
	NextButton,
	QuestionHeader,
} from '../../../../components';
import {
	QuestionBlock,
	RichText,
	useCheckupPage,
} from '@home-diy-toolbox/web/contentful';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

const NextButtonWrapper = styled(NextButton)`
	margin-top: 48px;
`;

const SignalIssuePlacesPage: FunctionComponent = () => {
	const pageId = CheckupPageIds.SIGNAL_ISSUES;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;

	const history = useHistory();

	const { updateCheckupPage, getCheckupForPageId } = useFlow();
	const { calculateResults } = useResults();

	const pageContent = useCheckupPage(pageId);
	const questionBlock = pageContent?.blocks?.find((item) => {
		return item.entryName === 'signalIssuesPlaces';
	}) as QuestionBlock;

	const nextButtonCopy = pageContent?.ctas.next.copy;

	const { answers: latestAnswers, tags: latestTags } = getCheckupForPageId(
		CheckupCategoryType.SIGNAL_CHECKUP,
		pageId
	) || {
		answers: [],
		tags: [],
	};

	const [selectedSignalIssuePlacesAnswer, setSelectedSignalIssuePlacesAnswer] =
		useState<string[]>(latestAnswers);
	const [selectedSignalIssuePlacesTags, setSelectedSignalIssuePlacesTags] =
		useState<string[]>(latestTags);

	const onAnswerChange = (answerSelected: string, tags: string[] = []) => {
		const answerAlreadyExistsIndex =
			selectedSignalIssuePlacesAnswer.indexOf(answerSelected);
		const indexOfNone = selectedSignalIssuePlacesAnswer.indexOf('none');

		if (answerAlreadyExistsIndex > -1) {
			selectedSignalIssuePlacesAnswer.splice(answerAlreadyExistsIndex, 1);
			setSelectedSignalIssuePlacesAnswer([...selectedSignalIssuePlacesAnswer]);

			setSelectedSignalIssuePlacesTags(
				removeArrayFromArray(selectedSignalIssuePlacesTags, tags)
			);
		} else {
			if (answerSelected === 'none') {
				setSelectedSignalIssuePlacesAnswer([answerSelected]);
				setSelectedSignalIssuePlacesTags(tags);
			} else {
				const updatedState =
					indexOfNone > -1
						? [answerSelected]
						: [...selectedSignalIssuePlacesAnswer, answerSelected];
				setSelectedSignalIssuePlacesAnswer(updatedState);

				const updatedTags =
					indexOfNone > -1 ? tags : union(selectedSignalIssuePlacesTags, tags);
				setSelectedSignalIssuePlacesTags(updatedTags);
			}
		}
	};

	const onNextButtonClick = () => {
		updateCheckupPage(
			CheckupCategoryType.SIGNAL_CHECKUP,
			pageId,
			selectedSignalIssuePlacesAnswer,
			selectedSignalIssuePlacesTags
		);
		calculateResults(Checkups.SIGNAL);
		history.push(CheckupResultPathnames.SIGNAL_RESULT_LOADING, {
			from: history.location.pathname,
		});
	};

	const renderAnswerOptions = () =>
		questionBlock.answers.map(({ entryName: answerId, text: copy, tags }) => {
			const buttonId = `${pageId}_${answerId}`;
			return (
				<CheckboxWithLabel
					value={answerId}
					label={copy}
					name={`${pageId}_answerOptions`}
					key={buttonId}
					data-cy={buttonId}
					checked={selectedSignalIssuePlacesAnswer.includes(answerId)}
					onChange={() => onAnswerChange(answerId, tags)}
				/>
			);
		});

	const isNextButtonDisabled = () => selectedSignalIssuePlacesAnswer.length === 0;

	if (!pageContent) return <PageLoader />;

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress currQuestion={2} checkupType={Checkups.SIGNAL} />
			<QuestionHeader>{questionBlock.question}</QuestionHeader>
			<AnswerSelectionInstructionsWrapper>
				<RichText document={questionBlock.questionDescription} />
			</AnswerSelectionInstructionsWrapper>
			{renderAnswerOptions()}
			<NextButtonWrapper
				actionId={nextButtonId}
				data-cy="next-button"
				disabled={isNextButtonDisabled()}
				onClick={onNextButtonClick}
				analyticExtras={{
					SelectedAnswers: selectedSignalIssuePlacesAnswer,
					CheckupType: Checkups.SIGNAL,
				}}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.SIGNAL_ISSUE_PLACES,
	CheckupType: Checkups.SIGNAL,
})(SignalIssuePlacesPage);
