import { HomePage } from './models/HomePage';
import { useQuery } from 'urql';
import { homePageQuery } from './queries/homePage';
import { useValidateMxContext } from '../useValidateMxContext';
import { transformGraphqlEntry } from './utils';

export const useHomePage = (): HomePage => {
	const { language, client } = useValidateMxContext();

	const [response] = useQuery({
		query: homePageQuery,
		variables: {
			locale: language,
			partner: client,
		},
		pause: false,
	});

	if (!response.data) return null;

	return transformGraphqlEntry(response?.data?.homePageCollection?.items[0]);
};
