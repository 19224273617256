import {
	QuestionBlock,
	RichText,
	useCheckupPage,
} from '@home-diy-toolbox/web/contentful';
import { useCallback, FC, useMemo } from 'react';
import { FontSize, Text, PageLoader } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { UsageHabits } from '../../../../components/UsageHabits';
import { CheckboxWithLabel } from '../../../../components';
import { HABITS_INIT_VALUE, OtherIssueAnswers } from '../answers';
import { DeviceProps } from './types';
import {
	AnalyticEventType,
	AnalyticViewType,
	useDispatchEvent,
	withAnalyticOnView,
} from '@home-diy-toolbox/web/analytics';
import { Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';

const Title = styled(Text)<{ marginBottom?: string }>`
	display: block;
	margin-bottom: ${(props) => props.marginBottom ?? 0};
`;

type HeaderProps = {
	heading: Document;
	size: FontSize;
	marginBottom?: string;
};

const Header: FC<HeaderProps> = ({ heading, size, marginBottom }) => (
	<RichText
		document={heading}
		renderOptionsOverride={{
			renderNode: {
				[BLOCKS.PARAGRAPH]: (_, children) => (
					<Title weight="feather" size={size} marginBottom={marginBottom}>
						{children}
					</Title>
				),
			},
		}}
	/>
);

const AndroidOthersHabitsAndOtherIssuesContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem 0;
`;

type Props = Pick<DeviceProps, 'setAnswers' | 'setTags'> & {
	answers: OtherIssueAnswers;
};

const AndroidOthersHabitsAndOtherIssues: FC<Props> = ({
	answers,
	setAnswers,
	setTags,
}) => {
	const batteryUsageHabits = useCheckupPage('batteryUsageHabits');
	const otherIssues = useCheckupPage('otherIssues');
	const { dispatchEvent } = useDispatchEvent();

	const issues = (otherIssues?.blocks[0] as QuestionBlock) ?? null;

	const tagOptions = useMemo(() => {
		if (!issues) return {};

		return issues.answers.reduce(
			(prev, curr) => ({
				...prev,
				[curr.value]: curr?.tags || [],
			}),
			{}
		);
	}, [issues]);

	const onChangeIssues = useCallback(
		(ans: string) => {
			// create new answers and add last state
			const newAnswers = {
				...answers,
			};

			// filter `none` answer every time !none is selected and include more conditions if has
			const filterIssue = (func?: (s: string) => boolean) => {
				if (answers?.issues) {
					return answers.issues.filter(
						(issue) => issue !== 'none' && (func?.(issue) ?? true)
					);
				}
				return [];
			};

			// replace `issues` property based on input
			if (ans === 'none') {
				newAnswers['issues'] = [ans];
			} else if (answers?.issues?.includes(ans)) {
				newAnswers['issues'] = filterIssue((issue) => issue !== ans);
			} else {
				newAnswers['issues'] = [...filterIssue(), ans];
			}

			setTags((oldTags) => {
				return new Set([
					...Array.from(oldTags),
					...newAnswers.issues.reduce((prev, curr) => [...prev, ...tagOptions[curr]], []),
				]);
			});

			dispatchEvent(AnalyticEventType.CLICK, {
				issues: newAnswers.issues?.join(',') || '',
			});

			setAnswers({ otherIssuesPage: newAnswers });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[answers, setAnswers, setTags, tagOptions]
	);

	if (!batteryUsageHabits || !otherIssues) {
		return <PageLoader />;
	}

	return (
		<AndroidOthersHabitsAndOtherIssuesContainer>
			{batteryUsageHabits?.blocks.length > 0 && (
				<UsageHabits
					content={{
						question: batteryUsageHabits.heading,
						questionFontSize: 4,
						questionMarginBottom: '0.5rem',
						habits: batteryUsageHabits.blocks.map((block: QuestionBlock) => ({
							value: block.entryName,
							label: block.question,
							options: block.answers,
						})),
					}}
					initialValue={answers?.habits ?? HABITS_INIT_VALUE}
					onSetValue={(newHabits) => {
						setAnswers({
							otherIssuesPage: {
								...answers,
								habits: newHabits.value,
							},
						});

						dispatchEvent(AnalyticEventType.CLICK, {
							habits_audio: newHabits.value.audio,
							habits_call: newHabits.value.call,
							habits_gaming: newHabits.value.gaming,
							habits_socialMedia: newHabits.value.socialMedia,
							habits_video: newHabits.value.video,
						});

						if (newHabits?.tags) {
							setTags((oldTags: Set<string>) => new Set([...oldTags, ...newHabits.tags]));
						}
					}}
				/>
			)}
			{Boolean(otherIssues) && (
				<>
					<Header heading={otherIssues.heading} size={4} />
					{issues.answers.map((answer) => (
						<CheckboxWithLabel
							key={answer.value}
							label={answer.text}
							name={answer.text}
							value={answer.value}
							checked={(answers?.issues ?? []).includes(answer.value)}
							onChange={() => {
								onChangeIssues(answer.value);
							}}
						/>
					))}
				</>
			)}
		</AndroidOthersHabitsAndOtherIssuesContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: 'androidOthersHabitsAndOtherIssuesPage',
	CheckupType: Checkups.BATTERY,
})(AndroidOthersHabitsAndOtherIssues);
