import { FunctionComponent } from 'react';
import {
	ArticleContentType,
	FunFact,
	ArticleContentFragmentType,
	StoreList as StoreListType,
	StyledImage as StyledImageType,
	ArticleContentTypes,
} from '@home-diy-toolbox/web/contentful';
import styled from 'styled-components';
import { FunFactBlock } from './FunFact';
import { Entry } from 'contentful';
import RichTextOverride from './RichTextOverride';
import { StoreList } from './StoreList';
import StyledImage from './StyledImage';

const StyledDiv = styled.div`
	& > * {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	& > p {
		margin-bottom: 0;
	}

	& > p + ul {
		margin-top: 0;
	}

	& > ul > li > * {
		margin: 0;
	}

	& > h1 {
		margin: 1rem 0;
	}

	& > h2 {
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}

	& > p + p {
		margin-top: 1rem;
	}
`;

type Props = {
	articleContent: ArticleContentType;
};

export const ArticleContent: FunctionComponent<Props> = ({ articleContent }) => {
	return (
		<StyledDiv>
			{articleContent.map((fragment, index) => {
				const {
					sys: {
						contentType: {
							sys: { id: contentTypeId },
						},
					},
				} = fragment;

				switch (contentTypeId) {
					case ArticleContentTypes.FUN_FACT: {
						const {
							fields: { header, content, description },
						} = fragment as Entry<FunFact>;
						return (
							<FunFactBlock
								key={index}
								header={header}
								content={content}
								description={description}
							/>
						);
					}
					case ArticleContentTypes.STORE_LIST: {
						const {
							fields: { stores },
						} = fragment as Entry<StoreListType>;
						return <StoreList key={index} stores={stores.map(({ fields }) => fields)} />;
					}
					case ArticleContentTypes.STYLED_IMAGE: {
						const {
							fields: { image, style },
						} = fragment as Entry<StyledImageType>;
						return <StyledImage key={index} image={image} style={style} />;
					}
					default: {
						const {
							fields: { content },
						} = fragment as Entry<ArticleContentFragmentType>;
						return <RichTextOverride key={index} content={content} />;
					}
				}
			})}
		</StyledDiv>
	);
};

export * from './FunFact';
export * from './StoreList';
