import { ValueOf } from '../utility';

const FREQUENCY_OPTIONS = {
	Never: 'never',
	Sometimes: 'sometimes',
	Always: 'always',
} as const;
type FrequencyOption = ValueOf<typeof FREQUENCY_OPTIONS>;

export { FREQUENCY_OPTIONS, FrequencyOption };
