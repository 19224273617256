import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Card, Text, Button, Tag, colors } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';

interface ActionCardProps {
	image: { url: string; alt: string };
	tags: string[];
	title: string;
	body: string;
	cost?: string;
	costDescription?: string;
	button: {
		onClick: () => void;
		text: string;
	};
}

const StyledCard = styled(Card)`
	&& {
		display: flex;
		flex-direction: column;
		margin-top: 1.5rem;
		text-align: left;
		padding: 0;
		width: 100%;
		img {
			border: 1px solid ${colors.neutralBright};
			border-bottom: none;
			max-width: 100%;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			height: 10.625rem;
			object-fit: cover;
		}
	}
`;

const CardContent = styled.div`
	padding: 1rem;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
`;

const TagContainer = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	margin-bottom: 0.6875rem;
`;

const BodyText = styled(Text)`
	&& {
		margin-bottom: 1.2rem;
		display: inline-block;
	}
`;

const CostContainer = styled(Text)`
	&& {
		margin-bottom: 1rem;
		display: inline-block;
	}
`;

export const ActionCard: FunctionComponent<ActionCardProps> = ({
	image,
	tags,
	title,
	body,
	cost,
	costDescription,
	button,
}) => {
	const { t } = useTranslation();

	return (
		<StyledCard>
			{image && <img src={image.url} alt={image.alt} />}
			<CardContent>
				{tags && (
					<TagContainer>
						{tags.map((tag) => (
							<Tag type="light" text={t(tag)} key={tag} />
						))}
					</TagContainer>
				)}
				<Text size={4} weight="feather">
					{title}
				</Text>
				<BodyText>{body}</BodyText>
				{/* TODO: Implement dynamic pricing. 
				Maybe we should pass a dedicated pricing component as a prop here.
				and just treat it as an extras section*/}

				{cost && costDescription && (
					<CostContainer>
						<Text size={3} weight="heavy">
							{cost}
						</Text>
						<Text size={2}> {costDescription}</Text>
					</CostContainer>
				)}
				{button.onClick && button.text && (
					<Button onClick={button.onClick} color="secondary" size="small">
						{button.text}
					</Button>
				)}
			</CardContent>
		</StyledCard>
	);
};
