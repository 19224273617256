import {
	Answer,
	CheckupBlocks,
	QuestionBlock,
	useCheckupPage,
} from '@home-diy-toolbox/web/contentful';
import styled from 'styled-components';
import { BatteryQuestionBlock } from '../components/BatteryQuestionBlock';
import { FC, memo, useCallback, useMemo } from 'react';
import { StartClaimCard } from '../components/StartClaimCard';
import {
	InitialPageAnswers,
	UserInputsType,
	SamsungBatteryAnswer,
	BATTERY_STATUS,
	SAMSUNG_MEMBER,
} from '../answers/types';
import { DeviceProps } from './types';
import { Spinner } from '../components/Spinner';

const SamsungBatteryCheckupV3Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

// Add blocks depending on the current answers
const useBlocks = (answers: SamsungBatteryAnswer, initBlocks?: CheckupBlocks[]) => {
	const nonMemberFlow = useCheckupPage('samsungBatteryNonMemberFlow');
	const memberFlow = useCheckupPage('samsungBatteryMemberFlow');

	const blocks = useMemo(() => {
		if (!initBlocks || !initBlocks.length) {
			return [];
		}

		const [samsungMemberQ] = initBlocks;

		const getMoreBlocks = (otherBlocks: CheckupBlocks[]) => {
			// show `battery charge` and `charge last` fields
			// if `batteryStatus` answered `i dont have this`
			if (answers?.batteryStatus !== BATTERY_STATUS.DONT_HAVE_THIS_OPTION) {
				const startClaim = otherBlocks.find((item) => item.typename === 'ResultCard');
				const [battStatus] = otherBlocks;
				return [samsungMemberQ, battStatus, startClaim];
			}
			return [samsungMemberQ, ...otherBlocks];
		};

		// for samsung member
		if (
			memberFlow?.blocks.length > 0 &&
			answers?.samsungMembersApp === SAMSUNG_MEMBER.YES
		) {
			return getMoreBlocks(memberFlow.blocks);
		}

		// for non samsung member
		if (
			nonMemberFlow?.blocks.length > 0 &&
			answers?.samsungMembersApp === SAMSUNG_MEMBER.NO
		) {
			return getMoreBlocks(nonMemberFlow.blocks);
		}

		// not yet select any type of members
		return [samsungMemberQ];
	}, [initBlocks, answers, nonMemberFlow?.blocks, memberFlow?.blocks]);

	return blocks;
};

type Props = {
	answers: SamsungBatteryAnswer;
	setAnswers: (newAnswers: InitialPageAnswers) => void;
	setTags: DeviceProps['setTags'];
};

export const SamsungBatteryCheckupV3: FC<Props> = memo(
	({ answers, setAnswers, setTags }) => {
		const pageContent = useCheckupPage('samsungBatteryFlow');
		const blocks = useBlocks(answers, pageContent?.blocks);

		const onChange = useCallback(
			(entryName: string, answer: UserInputsType, tags: string[]) => {
				const resetKeys = ['samsungMembersApp', 'batteryStatus'];

				// clear answers if these 2 keys changed
				if (resetKeys.includes(entryName)) {
					setAnswers({
						samsungMembersApp: answers.samsungMembersApp,
						[entryName]: answer,
					} as InitialPageAnswers);
				} else {
					setAnswers({ ...answers, [entryName]: answer });
				}

				setTags((oldTags) => new Set([...Array.from(oldTags), ...tags]));
			},
			[answers] // eslint-disable-line react-hooks/exhaustive-deps
		);

		const blocksComponent = useMemo(() => {
			const blocksComponent = blocks.map((block, blockIndex) => {
				const blockComponents = {
					QuestionBlock: (questionBlock: QuestionBlock) => {
						// disable `input.text` if on entryName=`chargeLast` if checkbox is selected (don't have this info)
						const checkDisabled = (answerOption: Answer) => {
							if (answerOption.type === 'checkbox') return false;

							const userInput = answers?.[block.entryName] ?? null;
							if (!userInput) return false;
							// disabled the text field if checkbox=`don't have this information` is checked
							const checkbox = questionBlock.answers.find((b) => b.type === 'checkbox');
							return userInput === checkbox.value;
						};

						return (
							<BatteryQuestionBlock
								key={block.entryName}
								userInput={answers?.[block.entryName] ?? null}
								onChange={(answer, newTags) => onChange(block.entryName, answer, newTags)}
								typename={questionBlock.typename}
								entryName={questionBlock.entryName}
								question={questionBlock.question}
								questionDescription={questionBlock.questionDescription}
								answers={questionBlock.answers}
								disabledOption={
									questionBlock.entryName === 'chargeLast' ? checkDisabled : false
								}
							/>
						);
					},
					ResultCard: () => <StartClaimCard key={blockIndex} />,
				};

				if (blockComponents[block.typename]) {
					return blockComponents[block.typename](block);
				}

				return null;
			});

			return blocksComponent;
		}, [answers, blocks, onChange]);

		if (!pageContent) {
			return <Spinner />;
		}

		return (
			<SamsungBatteryCheckupV3Container>
				{blocksComponent}
			</SamsungBatteryCheckupV3Container>
		);
	}
);
