import { AnswerOption } from '@home-diy-toolbox/web/contentful';
import { Entry } from 'contentful';
import React, { FunctionComponent } from 'react';
import { RadioButtonWithLabel } from '../RadioButtonWithLabel';

export type RadioButtonWithLabelGroupProps = {
	answerOptions: Entry<AnswerOption>[];
	isAnswerChecked: (answerId: string) => boolean;
	onAnswerChange: (answerId: string, tags: string[]) => void;
	buttonIdPrefix: string;
};

export const RadioButtonWithLabelGroup: FunctionComponent<
	RadioButtonWithLabelGroupProps
> = ({ answerOptions, isAnswerChecked, onAnswerChange, buttonIdPrefix }) => (
	<>
		{answerOptions.map(({ fields: { id: answerId, copy, tags } }) => {
			const buttonId = `${buttonIdPrefix}_${answerId}`;
			return (
				<RadioButtonWithLabel
					value={answerId}
					label={copy}
					name={`${buttonIdPrefix}_answerOptions`}
					key={buttonId}
					data-cy={buttonId}
					checked={isAnswerChecked(answerId)}
					onChange={() => onAnswerChange(answerId, tags)}
				/>
			);
		})}
	</>
);
