import { Entry, Asset } from 'contentful';
import { Document } from '@contentful/rich-text-types';
import { CheckupCategoryType } from '@home-diy-toolbox/web/common-types/refresh-base';

export enum InfoTagTypes {
	STEPS_TO_COMPLETE = 'stepsToComplete',
	TIME_TO_COMPLETE = 'timeToComplete',
}

export enum EmbeddedContentTypes {
	INSTRUCTION_NOTE = 'Instruction Note',
	ACCORDION = 'Accordion',
}

export enum LinkTypes {
	URL = 'URL',
	BUTTON = 'Button',
	PHONE_NUMBER = 'Phone Number',
	OPEN_CHAT_BUTTON = 'Open Chat Button',
}

export enum ImageStyles {
	COVER_FIT = 'cover-fit',
}

export enum ArticleContentTypes {
	FUN_FACT = 'funFact',
	EMBEDDED_CONTENT = 'embeddedContent',
	LINKS = 'links',
	STYLED_IMAGE = 'styledImage',
	STORE_LIST = 'storeList',
}

const InfoTagTypesList = Object.values(InfoTagTypes);

export type InfoTag = {
	text: string;
	infoType: typeof InfoTagTypesList[number];
};

export type StyledImage = {
	id: string;
	image: Asset;
	style: ImageStyles;
};

export type ArticleCategoryType = {
	articleCategory: string;
};

export type ContentCardType = {
	id: string;
	title: string;
	subtitle?: string;
	displayCategory: string;
	coverImage?: Asset;
	backgroundColor: string;
	textColor: string;
	buttonPosition: string;
	backgroundImage: Asset;
	externalLink: string;
};

export type ArticleContentFragmentType = {
	id?: string;
	entryName?: string;
	content: Document;
};

export type ArticleContentType = Array<
	| Entry<ArticleContentFragmentType>
	| Entry<FunFact>
	| Entry<StyledImage>
	| Entry<StoreList>
>;

export type FunFact = {
	id?: string;
	header: string;
	description?: string;
	content?: Document;
};

export type EmbeddedContent = {
	entryName?: string;
	title?: string;
	contentType: EmbeddedContentTypes;
	content?: Document;
};

export type Links = {
	entryName: string;
	defaultText: string;
	linkType: LinkTypes;
	defaultLink: string;
	alternativeText?: string[];
	alternativeLink?: string[];
	alternativeNumber?: string[];
	requireAuth?: boolean;
};

export type StoreHours = {
	open: string;
	close: string;
};
export type StoreConfig = {
	name?: string;
	address?: string;
	id?: number;
	state?: string;
	hours?: StoreHours;
};

export type StoreList = {
	stores?: Entry<StoreConfig>[];
};

export type InfoTagType = {
	copy: string;
	icon?: string;
};

export type Article = {
	id: string;
	category: Entry<ArticleCategoryType>[];
	contentCard: Entry<ContentCardType>;
	title: string;
	richTextTitle: Document;
	infoTags?: Array<Entry<InfoTag>>;
	articleContent: ArticleContentType;
};

export type ArticleCard = Pick<Article, 'id' | 'title' | 'contentCard'> & {
	tags: string[];
};

export type GetArticleCards = {
	tags: string[];
	deviceOS?: string;
	excludeTags?: string[];
};

// TODO: might need to refactor these functions' argument if `deviceOS` key of those functions will be optional
export type ArticleClientContextType = {
	getSuggestedArticles: (
		tags: string[],
		deviceOS: string,
		checkup: CheckupCategoryType,
		excludeTags?: string[]
	) => Promise<Article[]>;
	getArticleContent: (
		articleId: string
	) => Promise<{ articleData: Article; tags: string[] }>;
	getArticleCards: (config: GetArticleCards) => Promise<ArticleCard[]>;
	getTopArticles: (
		requiredTags: string[],
		optionalTags: string[],
		deviceOS: string,
		excludeTags?: string[],
		limit?: number
	) => Promise<ArticleCard[]>;
	getTags: (tags: string[], checkup: CheckupCategoryType) => string[];
};

export type ArticleContentProviderProps = {
	spaceId: string;
	accessToken: string;
	environment: string;
	locale: string;
	partner: string;
	retryOnError?: boolean;
};
