import React from 'react';
import styled from 'styled-components';

type Props = {
	className?: string;
	'data-cy'?: string;
};

//width: 90%; is a placeholder. we need to update this as per the min width support for different screen sizes [mobile, tablet, desktop].
const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0px auto;
	width: 90%;
`;

export const CheckupContainer: React.FunctionComponent<Props> = ({
	className,
	children,
	...others
}) => (
	<Container className={className} data-cy={others['data-cy']}>
		{children}
	</Container>
);
