import { colors } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

export const GradientPage = styled.div`
	&::before {
		content: '';
		z-index: -1;
		position: absolute;
		top: -112px; // offsets header
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, #f7f7f7 0%, ${colors.white} 100%);
	}
`;
