import { BatteryResultIds } from '@home-diy-toolbox/web/contentful';
import type { MainIssuesAnswer } from '../../answers/types';

export const getMainIssuesResult = (
	mainIssueAnswers: MainIssuesAnswer
): BatteryResultIds | null => {
	if (mainIssueAnswers.none) {
		return null;
	}

	if (mainIssueAnswers.damaged) {
		return BatteryResultIds.BATTERY_DAMAGED;
	}

	if (mainIssueAnswers.glass_cracked_back) {
		return BatteryResultIds.BACK_GLASS_DAMAGED;
	}

	if (mainIssueAnswers.glass_cracked_front) {
		return BatteryResultIds.FRONT_GLASS_DAMAGED;
	}

	return null;
};
