import React from 'react';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled(Text)`
	&& {
		margin-bottom: 0.5rem;
		display: block;
	}
`;

export const QuestionIndex = ({ currentIndex, total }) => {
	const { t } = useTranslation();

	return (
		<Container>
			{t('question')} {currentIndex} / {total}
		</Container>
	);
};
