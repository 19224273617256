import { useCheckupPage, QuestionBlock } from '@home-diy-toolbox/web/contentful';
import { PageBanner } from '../components/PageBanner';
import { PageCard } from '../components/PageCard';
import { IphoneBatteryHealth } from '../questions/IphoneBatteryHealth';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { QuestionIndex } from '../components/QuestionIndex';
import { iPhoneBatteryHealthAnswer } from '../answers/types';
import { ButtonConfig, Index, OnSetValueFn } from './types';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

type QuestionBlocks = {
	batteryPercentage: QuestionBlock;
	batteryMessage: QuestionBlock;
};

const BATTERY_BLOCK_ENTRY_NAMES = ['batteryPercentage', 'batteryMessage'];

type Props = {
	index: Index;
	initialValue: iPhoneBatteryHealthAnswer;
	onSetValue: OnSetValueFn;
	continueButtonConfig: ButtonConfig;
	backButtonConfig: ButtonConfig;
};

export const IphoneBatteryHealthPage = ({
	index,
	initialValue,
	onSetValue,
	backButtonConfig,
	continueButtonConfig,
}: Props) => {
	const pageContent = useCheckupPage('iPhoneBatteryHealth');

	if (!pageContent) return <PageLoader />;

	const { batteryPercentage, batteryMessage } = pageContent.blocks.reduce(
		(batteryBlocks, block) => {
			if (BATTERY_BLOCK_ENTRY_NAMES.includes(block.entryName)) {
				batteryBlocks[block.entryName] = block;
			}
			return batteryBlocks;
		},
		{} as QuestionBlocks
	);

	const content = {
		intro: pageContent.heading,
		instruction: pageContent.subheading,
		maxCapacityQuestion: batteryPercentage.question,
		maxCapacityInputLabel: batteryPercentage.answers[0].fieldTitle,
		maxCapacityInputDescription: batteryPercentage.answers[0].description,
		maxCapacityPlaceholder: batteryPercentage.answers[0].placeholder,
		batteryMessageQuestion: batteryMessage.question,
		batteryMessageOptions: batteryMessage.answers,
	};

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={<PageCard banner={pageContent?.banners[0]} />}
			backButtonConfig={{
				customLabel: pageContent?.ctas.back.copy,
				...backButtonConfig,
			}}
			continueButtonConfig={{
				customLabel: pageContent?.ctas.continue.copy,
				...continueButtonConfig,
			}}
		>
			<QuestionIndex currentIndex={index.currentIndex} total={index.total} />
			<IphoneBatteryHealth
				onSetValue={onSetValue}
				initialValue={initialValue}
				content={content}
			/>
		</CheckupPageLayout>
	);
};
