import React from 'react';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { QuestionBlock, useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { PageBanner } from '../components/PageBanner';
import { PageCard } from '../components/PageCard';
import { QuestionIndex } from '../components/QuestionIndex';
import { SamsungBatteryDiagnostic } from '../questions';
import { SamsungBatteryDiagnosticAnswer } from '../answers/types';
import { ButtonConfig, Index, OnGuideClickFn, OnSetValueFn } from './types';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

type Props = {
	index: Index;
	batteryDiagnostic:
		| 'samsungSettingsBatteryDiagnostic'
		| 'samsungMembersAppBatteryDiagnostic';
	initialValue: SamsungBatteryDiagnosticAnswer;
	onGuideClick: OnGuideClickFn;
	onSetValue: OnSetValueFn;
	continueButtonConfig: ButtonConfig;
	backButtonConfig: ButtonConfig;
};

export const SamsungBatteryDiagnosticPage = ({
	index,
	batteryDiagnostic,
	initialValue,
	onGuideClick,
	onSetValue,
	continueButtonConfig,
	backButtonConfig,
}: Props) => {
	const pageContent = useCheckupPage(batteryDiagnostic);

	if (!pageContent) return <PageLoader />;

	const questionBlock = pageContent.blocks.find(
		(block) => block.entryName === batteryDiagnostic
	) as QuestionBlock;

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={<PageCard banner={pageContent?.banners[0]} />}
			backButtonConfig={{
				customLabel: pageContent?.ctas.back.copy,
				...backButtonConfig,
			}}
			continueButtonConfig={{
				customLabel: pageContent?.ctas.continue.copy,
				...continueButtonConfig,
			}}
		>
			<QuestionIndex currentIndex={index.currentIndex} total={index.total} />
			<SamsungBatteryDiagnostic
				onSetValue={onSetValue}
				initialValue={initialValue}
				onGuideClick={onGuideClick}
				content={{
					instruction: pageContent.heading,
					guide: pageContent.description,
					question: questionBlock.question,
					options: questionBlock.answers,
				}}
			/>
		</CheckupPageLayout>
	);
};
