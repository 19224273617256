import { useMemo } from 'react';
import { ServiceSection } from '..';
import { NewServiceProps } from './types';
import { useFlow } from '../../providers';

export const NewServices = ({
	title,
	services: otherOptions,
	xScrollable = false,
}: NewServiceProps) => {
	const { flow } = useFlow();
	const { eligibility } = flow.sessionData;

	const services = useMemo(
		() => otherOptions.filter((service) => eligibility[service.id] !== false),
		[otherOptions, eligibility]
	);

	return (
		<ServiceSection xScrollable={xScrollable} sectionTitle={title} services={services} />
	);
};
