import React, { FunctionComponent, useState } from 'react';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	CheckupCategoryType,
	CheckupPageIds,
	ComponentIds,
	CheckupPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import styled from 'styled-components';
import { useFlow } from '../../../../providers';
import {
	AnswerSelectionInstructionsWrapper,
	CheckupContainer,
	QuestionHeader,
	NextButton,
	CheckupQuestionsProgress,
	RadioButtonWithLabelGroup,
	PreviousButton,
} from '../../../../components';
import { useCheckupPageFlow } from '../../../../features/checkupFlows';
import { useHistory } from 'react-router-dom';

const NextButtonWrapper = styled(NextButton)`
	margin-top: 56px;
`;

const BatteryLifePage: FunctionComponent = () => {
	const pageId = CheckupPageIds.BATTERY_LIFE;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;

	const { usePageContent } = useCheckupContentContext();
	const history = useHistory();
	const [goNext] = useCheckupPageFlow(Checkups.BATTERY, CheckupPages.BATTERY_LIFE);

	const {
		updateCheckupPage,
		getCheckupForPageId,
		flow: {
			sessionData: { currentQuestionIndex, partner },
		},
		setCurrentQuestionIndex,
	} = useFlow();

	const { answers: latestAnswers, tags: latestTags } = getCheckupForPageId(
		CheckupCategoryType.BATTERY_CHECKUP,
		pageId
	) || {
		answers: [],
		tags: [],
	};

	const [selectedAnswer, setSelectedAnswer] = useState<string[]>(latestAnswers);
	const [selectedTags, setSelectedTags] = useState<string[]>(latestTags);

	const onAnswerChange = (answerSelected: string, tags: string[] = []) => {
		setSelectedAnswer([answerSelected]);
		setSelectedTags(tags);
	};
	const onNextButtonClick = () => {
		updateCheckupPage(
			CheckupCategoryType.BATTERY_CHECKUP,
			pageId,
			selectedAnswer,
			selectedTags
		);
		setCurrentQuestionIndex(currentQuestionIndex + 1);
		goNext();
	};

	const onBackButtonClick = () => {
		setCurrentQuestionIndex(currentQuestionIndex - 1);
		history.goBack();
	};

	const { questionTitleCopy, instructionsCopy, answerOptions, nextButtonCopy } =
		usePageContent(pageId, partner, Checkups.BATTERY);

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<QuestionHeader>{questionTitleCopy}</QuestionHeader>
			<AnswerSelectionInstructionsWrapper>
				<RichText document={instructionsCopy} />
			</AnswerSelectionInstructionsWrapper>
			<RadioButtonWithLabelGroup
				answerOptions={answerOptions}
				buttonIdPrefix={pageId}
				onAnswerChange={onAnswerChange}
				isAnswerChecked={(answerId) => selectedAnswer.includes(answerId)}
			/>
			<NextButtonWrapper
				onClick={onNextButtonClick}
				actionId={nextButtonId}
				data-cy="next-button"
				analyticExtras={{
					SelectedAnswers: selectedAnswer,
					CheckupType: Checkups.BATTERY,
				}}
				disabled={selectedAnswer.length < 1}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
			<PreviousButton onClick={onBackButtonClick} />
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.BATTERY_LIFE,
	CheckupType: Checkups.BATTERY,
})(BatteryLifePage);
