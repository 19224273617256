import {
	CheckupQuestionPathnames,
	SignalFilterPathnames,
	SpeedFilterPathnames,
	Checkups,
	Checkup,
	Partners,
	Partner,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { serviceArticles } from '../../utils';

export const getServiceRedirect = (partner: Partner, checkupType: Checkup): string => {
	const serviceArticlesCopy = serviceArticles(partner);
	return {
		[Checkups.BATTERY]:
			partner === Partners.ATT
				? CheckupQuestionPathnames.BATTERY_INTRO
				: CheckupQuestionPathnames.BATTERY,
		[Checkups.SIGNAL]: SignalFilterPathnames.POPULAR,
		[Checkups.SPEED]: SpeedFilterPathnames.POPULAR,
		[Checkups.DEVICE_SANITIZATION]: `/article/${serviceArticlesCopy.sanitization}`,
		[Checkups.DEVICE_PERFORMANCE]: `/article/${serviceArticlesCopy.performance}`,
		[Checkups.DEVICE_PERFORMANCE_YEAR_2]: `/article/${serviceArticlesCopy.performanceYear2}`,
		[Checkups.DATA_RECOVERY]: `/article/${serviceArticlesCopy.recovery}`,
		[Checkups.DEVICE_DIAGNOSTICS]: `/article/${serviceArticlesCopy.deviceDiagnostics}`,
	}[checkupType];
};
