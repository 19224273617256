import { Environment } from './types';

export const environment: Environment = {
	production: true,
	deviceCareApiDomain: 'https://6j530gbjgl.execute-api.us-east-1.amazonaws.com/prod',
	ubifApiDomain: 'https://api.tech-care.solutotlv.asurion53.com',
	tempChatAutoscaleApiDomain: 'https://trailboss.remoteexpert.anywhereexpert.us',
	localizationNamespace: 'device-care',
	evidentlyProject: 'device-care-api-mxDeviceCare',
	serviceArticles: {
		att: {
			sanitization: '7crbcxkazrJZxS5m6JCuyg',
		},
		default: {
			sanitization: '1uTmCPkbRjhF1bs3h0D18b',
			performance: '72cGaXAXQdTpZ9UEaVRmXK',
			performanceYear2: '5lQhP7oiwIQz9xHe8tG9Jz',
			recovery: '5e5m83PkvQvZzaoXOifvuj',
			deviceDiagnostics: '72EpBkCxBcUFcmuzD1epbL',
		},
	},
	contentful: {
		article: {
			spaceId: 'vny2voxhx17a',
			accessToken: 'ftp4mLD6W7Am4R5CZKznm2ahvM9x-hjKwMydkB5IDRQ',
			environment: 'product-diy',
		},
		checkup: {
			spaceId: 'rmlmaoupstcv',
			accessToken: 'gVYfOoIU3D0Tm3zbgqzy0DesFVKUO6xIpeqmXMN_-bk',
			environment: 'master',
		},
		page: {
			spaceId: 'vny2voxhx17a',
			accessToken: 'K-goJcLisfw5tsirXsGIjh6N4-1vI-ouoM3UKjl5NfM',
			environment: 'device-care',
		},
	},
	wixiPartner: {
		att: {
			partnerAppId: 'device-care',
			partnerAppSecret: 'tVPPjQ54HB2n2HhD',
			partnerId: 'att',
			partnerSecret: 'yjTwBzbxMpCnu8vw',
		},
		verizon: {
			partnerAppId: 'device-care',
			partnerAppSecret: '8ZH3O6PQJtuCm5VB',
			partnerId: 'verizon',
			partnerSecret: '4zjcyBVsGHw6aDir',
		},
		asurion: {
			partnerAppId: 'device-care',
			partnerAppSecret: 'xWRoZsp5cMrcQFI3',
			partnerId: 'asurion',
			partnerSecret: 'udB9mTtUTPQYhjlV',
		},
		telus: {
			partnerAppId: 'device-care',
			partnerAppSecret: 'dMd2WndAwYNG65fb',
			partnerId: 'telus',
			partnerSecret: 'KYnaUWYBmEAN65wN',
		},
		koodo: {
			partnerAppId: 'device-care',
			partnerAppSecret: '6EQaaFwQ4eQENpUZ',
			partnerId: 'koodo',
			partnerSecret: '5acuDfWRB35hz8UE',
		},
		uscellular: {
			partnerAppId: 'device-care',
			partnerAppSecret: 'BaxtBBie7MQLoepz',
			partnerId: 'uscellular',
			partnerSecret: 'KZcel8ATs03MEoSF',
		},
		cricket: {
			partnerAppId: 'device-care',
			partnerAppSecret: 'ovSRkFLhwjQNbwSn',
			partnerId: 'cricket',
			partnerSecret: 'LWcqZLG5XLRlVvHx',
		},
		cox: {
			partnerAppId: 'device-care',
			partnerAppSecret: 'hyj4HSsA8qTge5Vc',
			partnerId: 'cricket',
			partnerSecret: 'Ds543ATyyhME3Enz',
		},
	},
	asurionId: {
		apiDomain: 'https://id.asurion.com',
		clientId: '4c007156-ec0d-48d3-8bbb-34c07884e693',
	},
};
