import { FunctionComponent, ReactElement } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { lazy } from 'react';
import { getAllQueryParams } from '@home-diy-toolbox/web/utils';
import { RouteProps } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow } from '../providers';
import { LoadingPage } from '../pages';
import { useServiceArticles } from '../utils';

const LazyNewArticlePage = lazy(() => import('../pages/ArticlePage/NewArticlePage'));
const LazyArticlePage = lazy(() => import('../pages/ArticlePage/ArticlePage'));

const loadArticlePage = (isNewDesign: boolean, articleId: string) => {
	return isNewDesign ? (
		<LazyNewArticlePage articleId={articleId} />
	) : (
		<LazyArticlePage articleId={articleId} />
	);
};

export const ArticleRoutes: FunctionComponent<RouteProps> = ({
	baseRoute,
	isNewDesign,
}) => {
	const {
		flow: {
			sessionData: {
				eligibility,
				deviceSelection: { assetsFetched, selectionRequired, selectionDone },
			},
		},
	} = useFlow();
	const location = useLocation();
	const serviceArticles = useServiceArticles();

	const renderArticle = (articleId: string): ReactElement => {
		const matchingArticle = Object.entries(serviceArticles).find(
			([, value]) => value === articleId
		);

		const isEligibilityRequired = matchingArticle?.length > 0;
		const isEligible = !!eligibility[matchingArticle?.[0]];

		const isEnabled = isEligibilityRequired ? isEligible : true;

		if (!isEnabled && assetsFetched && selectionRequired && !selectionDone) {
			// Multi-asset flow: Redirect to device selection page
			const queryParams = getAllQueryParams();
			const deviceSelectionURL = new URL('/devices/select', window.location.origin);
			Object.entries(queryParams).forEach(([key, value]) => {
				if (typeof value === 'string') {
					deviceSelectionURL.searchParams.set(key, value);
				} else {
					value.forEach((v) => deviceSelectionURL.searchParams.set(key, v));
				}
			});
			deviceSelectionURL.searchParams.set('redirectpath', location.pathname);
			deviceSelectionURL.searchParams.set('devicetype', 'phone');
			deviceSelectionURL.searchParams.set('cid', 'dashboard_services_menu');
			window.location.href = deviceSelectionURL.href;
			return <LoadingPage />;
		}

		const isDevicePerformance = [
			serviceArticles.performance,
			serviceArticles.performanceYear2,
		].includes(articleId);
		if (isDevicePerformance && !isEnabled) {
			// Auto-redirect on Device Performance Articles
			let correctArticleId;
			switch (matchingArticle[0]) {
				case 'performance':
					correctArticleId =
						(!!eligibility.performance && articleId) ||
						(!!eligibility.performanceYear2 && serviceArticles.performanceYear2);
					break;
				case 'performanceYear2':
					correctArticleId =
						(!!eligibility.performanceYear2 && articleId) ||
						(!!eligibility.performance && serviceArticles.performance);
					break;
				default:
					break;
			}

			if (correctArticleId) {
				return loadArticlePage(isNewDesign, correctArticleId);
			} else {
				return <Redirect to={'/'} />;
			}
		}

		return isEnabled ? loadArticlePage(isNewDesign, articleId) : <Redirect to={'/'} />;
	};

	return (
		<Switch>
			<Route
				exact
				path={`${baseRoute}/:articleId`}
				render={({
					match: {
						params: { articleId },
					},
				}) => renderArticle(articleId)}
			/>
			<Route path="*" render={() => <Redirect to={'/'} />} />
		</Switch>
	);
};
