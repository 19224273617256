import { FC, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { GenericPathnames } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useScrollToTop } from '@home-diy-toolbox/web/utils';
import { BatteryCheckupV3Answers, DeviceType } from './answers/types';
import { BatteryResultV3Ids } from './answers/BatteryResultV3';
import { checkIsMobile } from './utils';
import {
	useGetDeviceType,
	usePageStack,
	useResetSession,
	useSessionState,
	useUpdateHeader,
} from './hooks';
import { type Pages } from './pages';
import { BatteryCheckupLayout } from './components';
import { DeviceProps } from './pages/types';
import { AndroidOthersPage, IphonePage, SamsungPage } from './flows';
import InitialPage from './pages/InitialPage';

export const BatteryCheckupV3 = () => {
	useUpdateHeader();

	const deviceType = useGetDeviceType();
	const location = useLocation();
	const isMobile = checkIsMobile();
	const resetSession = useResetSession();
	const history = useHistory();

	const stack = usePageStack<Pages>(
		'BatteryCheckupV3PageStack',
		['initialPage'],
		resetSession
	);

	const [answers, setAnswers] = useSessionState<BatteryCheckupV3Answers>(
		{},
		'BatteryCheckupV3Answers',
		resetSession
	);

	const [tags, setTags] = useSessionState<Set<string>>(
		new Set(),
		'BatteryCheckupV3Tags',
		resetSession
	);

	const [result, setResult] = useSessionState<BatteryResultV3Ids>(
		null,
		'BatteryCheckupV3Result',
		resetSession
	);

	const currPage = stack.peek();

	useScrollToTop([currPage, deviceType]);

	useEffect(() => {
		// reset the answers if `deviceType` was changed
		if (deviceType !== answers?.initialPage?.deviceType) {
			setAnswers({ initialPage: { deviceType } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceType, answers?.initialPage?.deviceType]);

	const changeAnswers = (newAnswer: Partial<BatteryCheckupV3Answers>) => {
		setAnswers((oldAnswers) => ({
			...oldAnswers,
			...newAnswer,
		}));
	};

	/**
	 * * NOTE: this condition is used as a fallback to an edge case scenario
	 * * where deviceOS and deviceMake failed to be determined when using a mobile device
	 */
	if (isMobile && !deviceType) {
		return (
			<Redirect
				to={{
					pathname: GenericPathnames.OS_SELECTION,
					state: { redirectPath: location.pathname },
				}}
			/>
		);
	}

	const deviceComponent: Record<DeviceType, FC<DeviceProps>> = {
		ios_apple: IphonePage,
		android_samsung: SamsungPage,
		android_other: AndroidOthersPage,
	};

	if (deviceComponent?.[deviceType]) {
		const Device = deviceComponent[deviceType];

		return (
			<Device
				answers={answers}
				setAnswers={changeAnswers}
				currentPage={currPage}
				result={result}
				setResult={setResult}
				stack={stack}
				tags={tags}
				setTags={setTags}
			/>
		);
	}

	// If there's no matching device, the user hasn't yet selected any.
	return (
		<BatteryCheckupLayout
			onBack={() => history.push('/device-care')}
			currPage={currPage}
			onContinue={() => ({})}
			disabledContinue={true}
		>
			<InitialPage
				answers={answers?.initialPage}
				setAnswers={setAnswers}
				setTags={setTags}
			/>
		</BatteryCheckupLayout>
	);
};
