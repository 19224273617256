import { FC, useMemo } from 'react';
import { OldServices } from './OldServices';
import { OtherServicesProps } from './types';

// TODO: Add `NewServices` here...
export const OtherServices2: FC<OtherServicesProps> = ({ title, services }) => {
	const { oldServices } = useMemo(() => {
		const oldServices = [];
		const newServices = [];

		services.forEach((service) => {
			if (service.id.startsWith('v1')) {
				oldServices.push({
					...service,
					id: service.id.replace('v1_', ''),
				});
			} else {
				newServices.push(service);
			}
		});

		return {
			oldServices,
			newServices,
		};
	}, [services]);

	return <OldServices title={title} services={oldServices} />;
};
