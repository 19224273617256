import { FunctionComponent } from 'react';
import { useAnalytics } from 'react-shisell';
import { Document } from '@contentful/rich-text-types';
import { withExtra, AnalyticEventType } from '@home-diy-toolbox/web/analytics';
import styled from 'styled-components';
import { Accordion, AccordionSection } from '@soluto-private/mx-asurion-ui-react';
import RichTextOverride from './RichTextOverride';

const ContentScroll = styled.div`
	overflow: auto;
	max-height: 100vh;
`;

export type ArticleAccordionProps = {
	id?: string;
	title?: string;
	content?: Document;
};

export const ArticleAccordion: FunctionComponent<ArticleAccordionProps> = ({
	id,
	title,
	content,
}) => {
	const analytics = useAnalytics();
	const onclick = () => {
		analytics &&
			analytics.dispatcher
				.extend(withExtra('Accordion', id || 'articleAccordion'))
				.dispatch(AnalyticEventType.CLICK);
	};

	return (
		<Accordion>
			<AccordionSection header={title} onClick={onclick}>
				<ContentScroll>
					<RichTextOverride content={content} />
				</ContentScroll>
			</AccordionSection>
		</Accordion>
	);
};
