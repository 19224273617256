import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { isSpaceOrEnterPress } from '@home-diy-toolbox/web/utils';
import { ReactComponent as ThumbsDown } from '../../../assets/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '../../../assets/thumbs-up.svg';
import { useTranslation } from 'react-i18next';

const Fieldset = styled.fieldset`
	border: none;
	padding: 0;
	margin: 30px 0 0 0;
	display: flex;
	justify-content: center;
`;

const ThumbsDownWrapper = styled(ThumbsDown)<{ rating: number; checked: boolean }>`
	cursor: pointer;
	circle {
		stroke: ${({ checked, theme }) =>
			checked
				? theme.feedback.thumbsPressedBackground
				: theme.feedback.thumbsDefaultColor};
		fill: ${({ rating, checked, theme }) =>
			checked
				? theme.feedback.thumbsPressedBackground
				: rating === 0 && theme.feedback.thumbsHoverBackground};
	}
	path {
		fill: ${({ checked, theme }) => checked && theme.feedback.thumbsPressedColor};
	}

	@media (hover: hover) {
		&:hover {
			circle {
				fill: ${({ theme, checked }) => !checked && theme.feedback.thumbsHoverBackground};
			}
		}
	}
`;

const ThumbsUpWrapper = styled(ThumbsUp)<{ rating: number; checked: boolean }>`
	margin-left: 16px;
	cursor: pointer;
	circle {
		stroke: ${({ checked, theme }) =>
			checked
				? theme.feedback.thumbsPressedBackground
				: theme.feedback.thumbsDefaultColor};
		fill: ${({ rating, checked, theme }) =>
			checked
				? theme.feedback.thumbsPressedBackground
				: rating === 1 && theme.feedback.thumbsHoverBackground};
	}
	path {
		fill: ${({ checked, theme }) => checked && theme.feedback.thumbsPressedColor};
	}

	@media (hover: hover) {
		&:hover {
			circle {
				fill: ${({ theme, checked }) => !checked && theme.feedback.thumbsHoverBackground};
			}
		}
	}
`;

interface Props {
	disabled: boolean;
	onChange: (number) => void;
}

const ThumbsSelection: FunctionComponent<Props> = ({ disabled, onChange }) => {
	const [rating, setRating] = useState<number | null>(null);

	const { t } = useTranslation();

	const OnThumbsSelect = (rating: number) => {
		setRating(rating);
		onChange(rating);
		setTimeout(() => window.document.getElementById('feedbackFormHeader').focus(), 500);
	};

	return (
		<Fieldset data-pw="thumbSelection" disabled={disabled}>
			<ThumbsDownWrapper
				aria-label={t('thumbsDownFeedbackName')}
				tabIndex={0}
				checked={disabled}
				rating={rating}
				role={'button'}
				onKeyPress={(event) =>
					isSpaceOrEnterPress(event) && !disabled && OnThumbsSelect(0)
				}
				onClick={() => !disabled && OnThumbsSelect(0)}
			/>
			<ThumbsUpWrapper
				aria-label={t('thumbsUpFeedbackName')}
				tabIndex={0}
				checked={disabled}
				rating={rating}
				role={'button'}
				onKeyPress={(event) =>
					isSpaceOrEnterPress(event) && !disabled && OnThumbsSelect(1)
				}
				onClick={() => !disabled && OnThumbsSelect(1)}
			/>
		</Fieldset>
	);
};

export default ThumbsSelection;
