export const readFromStorage = (key: string) => window.sessionStorage.getItem(key);

export const readJsonFromStorage = (key: string, fallbackValue?: unknown) => {
	try {
		const data = readFromStorage(key);
		return data ? JSON.parse(data) : fallbackValue;
	} catch (error) {
		return fallbackValue;
	}
};

export const readMapFromStorage = <TMap extends Map<unknown, unknown>>(
	key: string
): TMap => {
	try {
		const parsed = readJsonFromStorage(key);
		if (parsed) {
			return new Map(parsed) as TMap;
		}
	} catch (error) {
		return new Map() as TMap;
	}

	return new Map() as TMap;
};

export const setInStorage = (key: string, rawData: string) => {
	window.sessionStorage.setItem(key, rawData);
};

export const stringifyMap = (mapData: Map<string, unknown>) => {
	try {
		return JSON.stringify(Array.from(mapData.entries()));
	} catch (error) {
		return '[]';
	}
};
