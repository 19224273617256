import React, { useEffect, useMemo, useState } from 'react';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { Answer, RichText } from '@home-diy-toolbox/web/contentful';
import { CheckboxWithLabel } from '../../../../components';
import { IssueAnswer } from '../answers/types';
import { type Props } from './types';
import { InputContainer } from './styles';

const Question = styled(Text)`
	&& {
		margin-bottom: 2.25rem;

		display: block;
	}
`;

const Instruction = styled(Text)`
	&& {
		margin-bottom: 1rem;
		display: block;
	}
`;

export interface IssuesQuestion {
	question: Document;
	instruction: string;
	options: Answer[];
}

export const Issues = ({
	initialValue,
	onSetValue,
	content,
}: Props<IssueAnswer, IssuesQuestion>) => {
	const { question, instruction, options } = content;
	const [answers, setAnswers] = useState(initialValue);
	const resetValue = useMemo(
		() =>
			content.options.reduce((prev, option) => {
				prev[option.value] = false;
				return prev;
			}, {}),
		[content]
	);

	const optionsMap = useMemo(
		() =>
			options.reduce((map, option) => {
				map[option.value] = option;
				return map;
			}, {}),
		[options]
	);

	useEffect(() => {
		onSetValue({ value: initialValue, valid: isValid(initialValue) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (option: Answer) => {
		const noneSelected = option.value === 'none';

		setAnswers((checked) => {
			const newVal = {
				...(noneSelected ? resetValue : checked),
				none: noneSelected,
				[option.value]: !checked[option.value],
			};

			const newTags = Object.keys(newVal).reduce((tags, key) => {
				const isSelected = newVal[key];
				const answerTags = optionsMap[key].tags || [];
				if (isSelected) {
					answerTags.forEach((tag) => tags.add(tag));
				}

				return tags;
			}, new Set<string>());

			onSetValue({ value: newVal, valid: isValid(newVal), tags: newTags });

			return newVal;
		});
	};

	const isValid = (items) => {
		return Object.values(items).some((item) => item);
	};

	return (
		<div>
			<RichText
				document={question}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => (
							<Question size={6} weight="feather">
								{children}
							</Question>
						),
					},
				}}
			/>
			<Instruction size={4} weight="feather">
				{instruction}
			</Instruction>
			<InputContainer>
				{options.map((option) => (
					<CheckboxWithLabel
						key={option.value}
						name={option.value}
						value={option.value}
						label={option.text}
						description={option.description}
						checked={answers[option.value]}
						onChange={() => handleChange(option)}
					/>
				))}
			</InputContainer>
		</div>
	);
};
