import { useEffect } from 'react';
import { Text, Button } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { RichText } from '@home-diy-toolbox/web/contentful';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { BatteryResultV3Ids } from '../answers/BatteryResultV3';
import { Button as ContenfulButtonProps } from '@home-diy-toolbox/web/contentful';

const MainContainer = styled.div`
	width: 100%;
	@media (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 19.5625rem;
`;

const Card = styled.div`
	box-shadow: 0rem 0rem 0.25rem 0.125rem #0c0e110a;
	border-radius: 0.5rem;
	margin-bottom: 0.264rem;
`;

const ExpertImg = styled.img`
	width: 100%;
	border-top-right-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
`;

const TextContainer = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
`;

const TextHeading = styled(Text)`
	&& {
		font-size: 1.5rem;
		font-weight: 300;
	}
`;

const TextSubHeading = styled(Text)`
	&& {
		font-size: 0.875rem;
		font-weight: 400;
		color: #5d5e61;
		display: block;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 1rem;
`;

interface Props {
	mainHeading: string;
	cardHeading: Document;
	cardSubheading: string;
	imageUrl: string;
	imageAlt: string;
	isDesktop: boolean;
	onClickBanner: () => void;
	resultId: BatteryResultV3Ids;
	// CTAs must be in right order from left to right eg. [chatNow, callLink]
	openCardButtonCtAs: ContenfulButtonProps[];
}

export const ContactExpertsCard = ({
	cardHeading,
	cardSubheading,
	imageUrl,
	imageAlt,
	onClickBanner,
	resultId,
	openCardButtonCtAs,
}: Props) => {
	const { dispatchEvent } = useDispatchEvent();

	useEffect(() => {
		dispatchEvent(AnalyticEventType.VIEW, {
			ActionId: 'batteryResultsLiveExpertCard',
			BatteryCheckupResult: resultId,
			PageId: 'resultPage',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<MainContainer>
			<Container>
				<Card>
					<ExpertImg src={imageUrl} alt={imageAlt} />
					<TextContainer>
						<RichText
							document={cardHeading}
							renderOptionsOverride={{
								renderNode: {
									[BLOCKS.PARAGRAPH]: (_, children) => (
										<TextHeading>{children}</TextHeading>
									),
								},
							}}
						/>
						<TextSubHeading>{cardSubheading}</TextSubHeading>
						{openCardButtonCtAs.length > 0 && (
							<ButtonContainer>
								{openCardButtonCtAs.map((cardButtonCtA) => (
									<Button
										color="primary"
										key={cardButtonCtA.entryName}
										onClick={onClickBanner}
										size="small"
									>
										{cardButtonCtA.copy}
									</Button>
								))}
							</ButtonContainer>
						)}
					</TextContainer>
				</Card>
			</Container>
		</MainContainer>
	);
};
