import { useQuery } from 'urql';
import { checkupPageQuery, checkupPageQueryWithPartnerTag } from './queries';
import { CheckupPage } from './models';
import { useValidateMxContext } from '../useValidateMxContext';

const transformEntry = (entry) => {
	if (typeof entry !== 'object' || !entry) return entry;

	if (Array.isArray(entry)) {
		return entry.map((x) => transformEntry(x));
	}

	const holder = {};
	for (const key of Object.keys(entry)) {
		const value = entry[key];

		const isCollection = value?.items && key.endsWith('Collection');
		if (isCollection) {
			holder[key.replace('Collection', '')] = transformEntry(value.items);
			continue;
		}
		const isRichText = value?.json && value.json.content && value.json.nodeType;
		if (isRichText) {
			holder[key] = value.json;
			continue;
		}

		holder[key.replace('__', '')] = transformEntry(value);
	}

	return holder;
};

export const useCheckupPage = (
	checkupPage: string,
	filterByPartner = false
): CheckupPage => {
	const { language, client: partner } = useValidateMxContext();

	const [response] = useQuery({
		query: filterByPartner ? checkupPageQueryWithPartnerTag : checkupPageQuery,
		variables: {
			locale: language,
			checkupPage,
			partnerTag: `partner_${partner}`,
		},
		pause: !checkupPage,
	});

	if (!response.data) return null;

	const transformedEntry = transformEntry(response.data.checkupPageCollection.items[0]);

	transformedEntry.ctas = transformedEntry?.ctas?.reduce((acc, button) => {
		acc[button.entryName] = button;
		return acc;
	}, {});

	return transformedEntry;
};
