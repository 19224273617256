import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	CheckupDiagnosticsPathnames,
	CheckupQuestionPathnames,
	ComponentIds,
	DiagnosticsPageIds,
	DiagnosticsCategoryType,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow } from '../../../../providers';
import {
	CheckupContainer,
	QuestionHeader,
	QuestionImageContainer,
	NextButton,
} from '../../../../components';

const QuestionHeaderCenter = styled(QuestionHeader)`
	margin-top: 40px;
	text-align: center;
	font-size: 24px;
	font-weight: light;
`;
const DownloadComplete = styled.div`
	font-size: 14px;
	font-weight: bold;
	text-align: center;
`;

const DownloadSpeed = styled.div`
	font-size: 48px;
	font-weight: lighter;
	text-align: center;
`;

const MpbsLabel = styled.div`
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};
	font-size: 14px;
	line-height: 16px;
	text-align: center;
`;

const NextButtonId = styled(NextButton)`
	margin-top: 60px;
`;

const SpeedContainer = styled.div``;

const SignalDownloadCompletePage: FunctionComponent = () => {
	const pageId = DiagnosticsPageIds.SIGNAL_TEST_COMPLETE;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;

	const history = useHistory();
	const { getDiagnosticsByTestId } = useFlow();

	const [downloadSpeed, setDownloadSpeed] = useState<string>(null);

	const {
		usePageContent,
		app: {
			signalDialConfig: {
				fields: { speedUnit },
			},
		},
	} = useCheckupContentContext();

	const { questionTitleCopy, questionImage, nextButtonCopy, instructionsCopy } =
		usePageContent(pageId);

	useEffect(() => {
		const sessionDownloadSpeed = getDiagnosticsByTestId(
			DiagnosticsCategoryType.SIGNAL_DIAGNOSTICS,
			'downloadSpeed'
		) as string;

		if (sessionDownloadSpeed) {
			setDownloadSpeed(sessionDownloadSpeed);
		} else {
			history.replace(CheckupDiagnosticsPathnames.SIGNAL_TURN_OFF_WIFI, {
				from: history.location.pathname,
			});
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const formattedDownloadSpeed = downloadSpeed ? downloadSpeed : 0;

	const onNextButtonClick = () =>
		history.push(CheckupQuestionPathnames.SIGNAL_ISSUES, {
			from: history.location.pathname,
		});

	return (
		<CheckupContainer>
			<QuestionHeaderCenter>{questionTitleCopy}</QuestionHeaderCenter>
			<QuestionImageContainer>
				<img src={questionImage.fields.file.url} alt="" />
			</QuestionImageContainer>
			<SpeedContainer tabIndex={-1}>
				<DownloadComplete>
					<RichText document={instructionsCopy} />
				</DownloadComplete>
				<DownloadSpeed>{formattedDownloadSpeed}</DownloadSpeed>
				<MpbsLabel>{speedUnit}</MpbsLabel>
			</SpeedContainer>
			<NextButtonId
				onClick={onNextButtonClick}
				actionId={nextButtonId}
				data-cy="next-button"
				analyticExtras={{
					DownloadSpeed: formattedDownloadSpeed,
					CheckupType: Checkups.SIGNAL,
				}}
			>
				{nextButtonCopy}
			</NextButtonId>
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: DiagnosticsPageIds.SIGNAL_TEST_COMPLETE,
	CheckupType: Checkups.SIGNAL,
})(SignalDownloadCompletePage);
