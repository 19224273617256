import { ReactElement, ReactNode } from 'react';
import { ButtonRenderer } from './ButtonRenderer';
import { getChatClient } from '../../../features/chat/components';
import styled from 'styled-components';
import { Link, Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { ButtonOnClick } from './types';

const StyledButton = styled(Button)`
	&&& {
		width: 100%;
	}
`;

const PhoneButton = (props: {
	children: ReactNode;
	phoneNumber: string;
	onClick: ButtonOnClick;
}) => {
	const { children, phoneNumber, onClick } = props;
	return (
		<Link
			href={`tel:${phoneNumber}`}
			onClick={() => onClick()}
			btnVariantProps={{
				btnVariant: 'outline',
				btnSize: 'medium',
			}}
			color="secondary"
			iconSide="left"
			iconSrc="Phone"
			variant="button"
		>
			{children}
		</Link>
	);
};

const LinkButton = (props: {
	children: ReactNode;
	link: string;
	onClick: ButtonOnClick;
	external: boolean;
}) => {
	const { children, link, onClick, external } = props;

	return (
		<Link
			href={link}
			target={external ? '_blank' : '_self'}
			onClick={() => onClick()}
			rel={external ? 'noreferrer' : undefined}
			btnVariantProps={{
				btnVariant: 'outline',
				btnSize: 'medium',
			}}
			color="secondary"
			iconSide="left"
			iconSrc="FileEdit-2"
			variant="button"
		>
			{children}
		</Link>
	);
};

const OpenChat = (props: { children: ReactNode; onClick: ButtonOnClick }) => {
	const { children, onClick } = props;
	return (
		<StyledButton
			color="secondary"
			iconSide="left"
			iconSrc="ChatBubbleCircle"
			onClick={() => {
				const client = getChatClient();
				client.openMessagingOverlay();
				onClick();
			}}
			size="medium"
			variant="outline"
		>
			{children}
		</StyledButton>
	);
};

export class ContactExpertButtonRenderer implements ButtonRenderer {
	createPhoneButton(
		copy: string,
		phoneNumber: string,
		onClick: ButtonOnClick
	): ReactElement {
		return (
			<PhoneButton phoneNumber={phoneNumber} onClick={onClick}>
				{copy}
			</PhoneButton>
		);
	}

	createExternalLinkButton(
		copy: string,
		link: string,
		onClick: ButtonOnClick
	): ReactElement {
		return (
			<LinkButton link={link} onClick={onClick} external={true}>
				{copy}
			</LinkButton>
		);
	}

	createInternalLinkButton(
		copy: string,
		link: string,
		onClick: ButtonOnClick
	): ReactElement {
		return (
			<LinkButton link={link} onClick={onClick} external={false}>
				{copy}
			</LinkButton>
		);
	}

	createOpenChatButton(copy: string, onClick: ButtonOnClick): ReactElement {
		return <OpenChat onClick={onClick}>{copy}</OpenChat>;
	}

	createDefaultButton(copy: string, onClick: ButtonOnClick): ReactElement {
		return (
			<StyledButton onClick={onClick} color="secondary" size="medium" variant="outline">
				{copy}
			</StyledButton>
		);
	}
}
