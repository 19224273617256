import { ReactElement } from 'react';
import { ButtonRenderer } from './ButtonRenderer';
import { Button, Link } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { getChatClient } from '../../../features/chat/components';
import { ButtonOnClick } from './types';

type Props = { buttonText: string; onClick: () => void };

const ButtonLinkContainer = styled.div`
	display: flex;
`;

const ButtonLink = ({ children, ...rest }) => {
	return (
		<ButtonLinkContainer>
			<Link
				variant="button"
				btnVariantProps={{ btnSize: 'small', btnVariant: 'default' }}
				{...rest}
			>
				{children}
			</Link>
		</ButtonLinkContainer>
	);
};

const BaseButton = ({ children, ...rest }) => {
	return (
		<Button size="small" color="secondary" {...rest}>
			{children}
		</Button>
	);
};

const OpenChatButton = ({ buttonText, onClick }: Props) => {
	return (
		<BaseButton
			onClick={() => {
				getChatClient().openMessagingOverlay();
				onClick();
			}}
		>
			{buttonText}
		</BaseButton>
	);
};

const PhoneNumberButton = ({
	buttonText,
	onClick,
	phoneNumber,
}: Props & { phoneNumber: string }) => {
	return (
		<ButtonLink href={`tel:${phoneNumber}`} onClick={() => onClick()}>
			{buttonText}
		</ButtonLink>
	);
};

const ExternalLinkButton = ({ buttonText, onClick, url }: Props & { url: string }) => {
	return (
		<ButtonLink href={url} target="_blank" onClick={() => onClick()}>
			{buttonText}
		</ButtonLink>
	);
};

const InternaLinkButton = ({ buttonText, onClick, url }: Props & { url: string }) => {
	return (
		<ButtonLink href={url} target="_self" onClick={() => onClick()}>
			{buttonText}
		</ButtonLink>
	);
};

export class ResultButtonRenderer implements ButtonRenderer {
	createPhoneButton(
		copy: string,
		phoneNumber: string,
		onClick: ButtonOnClick
	): ReactElement {
		return (
			<PhoneNumberButton buttonText={copy} onClick={onClick} phoneNumber={phoneNumber} />
		);
	}
	createExternalLinkButton(
		copy: string,
		link: string,
		onClick: ButtonOnClick
	): ReactElement {
		return <ExternalLinkButton buttonText={copy} onClick={onClick} url={link} />;
	}
	createInternalLinkButton(
		copy: string,
		link: string,
		onClick: ButtonOnClick
	): ReactElement {
		return <InternaLinkButton buttonText={copy} onClick={onClick} url={link} />;
	}
	createOpenChatButton(copy: string, onClick: ButtonOnClick): ReactElement {
		return <OpenChatButton buttonText={copy} onClick={onClick} />;
	}
	createDefaultButton(copy: string, onClick: ButtonOnClick): ReactElement {
		return <BaseButton onClick={() => onClick()}>{copy}</BaseButton>;
	}
}
