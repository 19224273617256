import { createGlobalStyle } from 'styled-components';
import { Size } from '@soluto-private/mx-asurion-ui-react';

const rightSpace = 16;
const mobileButtonsHeight = 81;
const tabletButtonsHeight = 81;
const chatHeadHeight = 60;
const extraSpace = 10;

const maxWidthMd2 = `@media (max-width: ${Size.MD2 - 1}px)`;
const maxWidthMd = `@media (max-width: ${Size.MD - 1}px)`;

/**
 * This will position the chathead for the battery checkup page only.
 * We need this because the chathead is covering the bottom sticky buttons for that page.
 */
export const ChatHeadPositioner = createGlobalStyle`
	${maxWidthMd2} {
		#fabio-button{
			right: ${rightSpace}px !important;
			bottom: ${tabletButtonsHeight + rightSpace}px !important;
		}

		.timeline-overlay{
			right: ${rightSpace}px !important;
			bottom: ${tabletButtonsHeight + chatHeadHeight + extraSpace}px !important;
		}

		#twilio-webchat-widget-root > * {
			bottom: ${tabletButtonsHeight + rightSpace}px !important;
			right: ${rightSpace}px !important;
		}

		// root container for mobile devices should be brought back to original position
		#re-sdk-root-container:only-child {
			position: relative;
			bottom: -${tabletButtonsHeight + rightSpace}px !important;
			right: -${rightSpace}px !important;
		}
	}

    ${maxWidthMd} {
		#fabio-button{
			bottom: ${mobileButtonsHeight + rightSpace}px !important;
		}

		.timeline-overlay{
			bottom: ${mobileButtonsHeight + chatHeadHeight + extraSpace}px !important;
		}

		#twilio-webchat-widget-root > * {
			bottom: ${mobileButtonsHeight + rightSpace}px !important;
			right: ${rightSpace}px !important;
		}

		#re-sdk-root-container:only-child {
			position: relative;
			bottom: -${mobileButtonsHeight + rightSpace}px !important;
			right: -${rightSpace}px !important;
		}
	}
`;
