import { useMemo } from 'react';
import { useFlow } from '../../../providers';
import { ChatHeadPositioner } from './ChatHeadPositioner';
import {
	GenericAndroidBatteryFlow,
	IphoneBatteryFlow,
	SamsungAndroidBatteryFlow,
} from './flows';

export const BatteryCheckupV2 = () => {
	const { flow: appFlow } = useFlow();
	const { deviceOS, deviceMake } = appFlow.sessionData;

	const flowComponent = useMemo(() => {
		if (deviceOS === 'ios') return <IphoneBatteryFlow />;
		else if (deviceOS === 'android' && deviceMake === 'Samsung') {
			return <SamsungAndroidBatteryFlow />;
		} else return <GenericAndroidBatteryFlow />;
	}, [deviceOS, deviceMake]);

	return (
		<>
			<ChatHeadPositioner />
			{flowComponent}
		</>
	);
};
