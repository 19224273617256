import React, { useEffect, useRef } from 'react';
import type { RadioValue } from '../types';

export const radioClassName = 'ui-radio';
export const toggleClassName = 'ui-radio__toggle';

type Props = React.LiHTMLAttributes<HTMLLIElement> &
	RadioValue & {
		index: number;
		isChecked: boolean;
		shouldFocus: boolean;
	};

export const RadioToggle = ({
	index,
	value,
	label,
	isChecked,
	shouldFocus,
	...rest
}: Props): JSX.Element => {
	const ref = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (shouldFocus) ref.current && ref.current.focus();
	}, [shouldFocus]);

	return (
		<li
			className={radioClassName}
			role="radio"
			aria-checked={isChecked}
			data-value={value}
			value={index}
			tabIndex={shouldFocus ? 0 : -1}
			ref={ref}
			{...rest}
		>
			<span className={toggleClassName} />
			{label}
		</li>
	);
};
