import { FunctionComponent, useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Article, useArticleContentContext } from '@home-diy-toolbox/web/contentful';
import { ArticleRenderer } from '@home-diy-toolbox/web/article-renderer';
import {
	useAnalytics,
	AnalyticViewType,
	withExtras,
	AnalyticEventType,
} from '@home-diy-toolbox/web/analytics';
import { Checkups, ResultPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow } from '../../providers';
import { ubifApiClient } from '../../clients';
import { LoadingPage } from '../../pages/LoadingPage';
import { ErrorPage } from '../../pages/ErrorPage';
import { ResultSectionDivider, FeedbackForm, FeedbackFormProps } from '../../components';

type ArticleLoaderProps = {
	articleId: string;
	feedbackSectionProps?: FeedbackFormProps;
	onArticleLoad?: (article: { articleData: Article; tags: string[] }) => void;
};

export const ArticleLoader: FunctionComponent<ArticleLoaderProps> = ({
	articleId,
	onArticleLoad,
	feedbackSectionProps,
}) => {
	const pageId = ResultPageIds.ARTICLE_PAGE;
	const analytics = useAnalytics();
	const [checkupType, setCheckupType] = useState<string>(undefined);

	const { flow } = useFlow();
	const { deviceOS, partner, deviceMake, deviceModel, assetId, subscriberId } =
		flow.sessionData;
	const [article, setArticle] = useState<{ articleData: Article; tags: string[] }>(
		undefined
	);
	const { getArticleContent } = useArticleContentContext();

	const loadArticle = async () => {
		try {
			const articleData = await getArticleContent(articleId);
			setArticle(articleData);
			if (onArticleLoad) {
				onArticleLoad(articleData);
				sendAnalytics(articleData);
			}
		} catch {
			setArticle(undefined);
		}
	};

	const sendAnalytics = (article: { articleData: Article; tags: string[] }) => {
		const checkups: string[] = Object.values(Checkups);
		const articleTags = article?.tags || [];
		const checkupType = articleTags.find((tag) => checkups.includes(tag)) || undefined;
		setCheckupType(checkupType);
		article &&
			analytics.dispatcher
				.extend(
					withExtras({
						PageId: pageId,
						CheckupType: checkupType,
						ArticleTags: articleTags,
						ComponentType: AnalyticViewType.PAGE,
						ArticleId: articleId,
					})
				)
				.dispatch(AnalyticEventType.VIEW);
	};

	useEffect(() => {
		loadArticle();
	}, [articleId, getArticleContent]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!article) return <LoadingPage />;

	return (
		<ErrorBoundary FallbackComponent={ErrorPage}>
			<ArticleRenderer
				article={article.articleData}
				devicePartnerContext={{
					partner,
					deviceOS,
					deviceMakeAndModel: deviceMake + ' ' + deviceModel,
					assetId,
					subscriberId,
				}}
				ubifApiClient={ubifApiClient}
			/>
			<ResultSectionDivider />
			{feedbackSectionProps && (
				<FeedbackForm
					variant={feedbackSectionProps.variant}
					type={feedbackSectionProps.type}
					feedbackTitle={feedbackSectionProps.feedbackTitle}
					positiveForm={feedbackSectionProps.positiveForm}
					negativeForm={feedbackSectionProps.negativeForm}
					articleId={articleId}
					analyticsExtras={{ CheckupType: checkupType }}
				/>
			)}
		</ErrorBoundary>
	);
};
