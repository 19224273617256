import { FunctionComponent } from 'react';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { ResultPageIds, Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { CheckupContainer, MagicWandWithLabel } from '../../../components';
import { useResultPageLoading } from '../../../features/checkupResults/hooks';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

const CalculatingSignalResultPage: FunctionComponent = () => {
	const pageId = ResultPageIds.CALCULATING_SIGNAL_RESULT;
	const pageContent = useCheckupPage(pageId);
	useResultPageLoading(Checkups.SIGNAL);

	if (!pageContent) return <PageLoader />;

	return (
		<CheckupContainer>
			<MagicWandWithLabel label={pageContent.heading} />
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: ResultPageIds.CALCULATING_SIGNAL_RESULT,
	CheckupType: Checkups.SIGNAL,
})(CalculatingSignalResultPage);
