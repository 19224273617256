import { BaseHttpApiClient } from './BaseHttpApiClient';

type RedeemAuthCodeResponse = {
	access_token: string;
	expires_in: number;
	scope: string;
	token_type: string;
};

export class AsurionIdApiClient extends BaseHttpApiClient {
	public redeemAcessToken = async (
		code: string,
		clientId: string,
		redirectUri: string
	): Promise<RedeemAuthCodeResponse | undefined> => {
		try {
			const payload = {
				code,
				client_id: clientId,
				grant_type: 'authorization_code',
				redirect_uri: redirectUri,
			};

			const res = await this.post<string, Promise<RedeemAuthCodeResponse>>(
				`${this.domain}/oauth2/token`,
				new URLSearchParams(payload).toString(),
				{
					'Content-Type': 'application/x-www-form-urlencoded',
				}
			);

			return await res.body;
		} catch (error) {
			console.error(error);
		}
	};
}
