import { Partners } from '@home-diy-toolbox/web/common-types';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { ChatSender } from './ChatSender';
import { ChatButton } from './ChatButton';
import { getChatClient } from '../components';

const Container = styled.div`
	margin: 0.75rem 0;
`;

const Message = styled.div`
	padding: 0.5rem 0.75rem;
	background-color: ${colors.neutralBrightest};
	border-radius: 0.25rem;
	max-width: 90%;
	margin-bottom: 0.75rem;
	line-height: 1.2;
`;

const MessagesContainer = styled.div`
	margin-bottom: 1.5rem;
`;

const ChatButtonsContainer = styled.div`
	margin: 0.75rem 0;
`;

export class RemoteChatSender extends ChatSender {
	isInitialLoad(node: Node) {
		return node?.textContent.includes('Chat started');
	}

	async sendVZTroubleshootMessages(partner: string) {
		const observer = new MutationObserver(async (mutationList, observer) => {
			for (const mutation of mutationList) {
				const newNode = mutation?.addedNodes?.item(0);
				const initialLoad = this.isInitialLoad(newNode);
				if (!initialLoad) continue;

				if (partner === Partners.VERIZON) {
					const vzTroubleshoot = await this.getVzTroubleshoot();
					if (!vzTroubleshoot) return;

					const container = document.createElement('div');
					const messagesContainer = document.getElementById('scroll-container-inner');
					ReactDOM.render(
						<Container>
							<MessagesContainer>
								{vzTroubleshoot.messages.map((message) => (
									<Message key={message}>{message}</Message>
								))}
							</MessagesContainer>
							<ChatButtonsContainer>
								{vzTroubleshoot.chatButtons.map((chatButton) => (
									<ChatButton
										key={chatButton.buttonText}
										onClick={() =>
											getChatClient().sendMessage('CustomerMessage', chatButton.chatText)
										}
										message={chatButton.buttonText}
									/>
								))}
							</ChatButtonsContainer>
						</Container>,
						container
					);

					messagesContainer.appendChild(container);
				}

				observer.disconnect();
				break;
			}
		});

		this.observeChatContainer(observer);
	}
}
