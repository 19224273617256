export const chatOnlyPagePromptsQuery = `
query ChatOnlyPagePrompt($locale: String) {
    chatOnlyPagePromptCollection(
        limit: 100,
        locale: $locale
    ) {
        items {
            utmValues
            prompt
        }
    }
}z
`;
