import { ArticleCard } from './types';

export const getArticlePartnerEntryId = (partner: string): string => {
	switch (partner) {
		case 'att':
			return '2aFtunmvy79wEO2Lw8fJLR';
		case 'verizon':
			return '3u4xGwt3MoWQDaMshzoOKx';
		case 'telus':
			return '4hFmvClPop7JfzxrKrsnJd';
		case 'koodo':
			return '1EmfqnExPwbATm9Wg5Cjmn';
		case 'uscellular':
			return '3mMx8YM0YvtNJ1xpX1maEO';
		case 'cricket':
			return '3lkpJc3zpRABIoBaevXSs8';
		case 'cox':
			return '7buRcQWh3BaOddGvhbSBez';
		case 'liberty':
			return '488izfdvlIdtknjoj34Hul';
		default:
			// DIY Asurion
			return '7m3s1eagREnZAoqVydIVUv';
	}
};

// TODO: make this more readable
const countCommon = (a: unknown[], b: unknown[]) => {
	const freq1 = new Map();
	const freq2 = new Map();
	let result = 0;

	const n = a.length;
	const m = b.length;

	for (let i = 0; i < n; i++)
		if (freq1.has(a[i])) freq1.set(a[i], freq1.get(a[i]) + 1);
		else freq1.set(a[i], 1);

	for (let i = 0; i < m; i++)
		if (freq2.has(b[i])) freq2.set(b[i], freq2.get(b[i]) + 1);
		else freq2.set(b[i], 1);

	freq1.forEach((value, key) => {
		if (freq2.has(key)) {
			result += Math.min(value, freq2.get(key));
		} else {
			result += Math.min(value, 0);
		}
	});

	return result;
};

export const sortArticleCards = (articleCards: ArticleCard[], tags: string[]) => {
	return articleCards
		.map((articleCard) => {
			articleCard['commonTags'] = countCommon(articleCard.tags, tags);
			return articleCard;
		})
		.sort((a, b) => {
			return a['commonTags'] > b['commonTags'] ? 1 : -1;
		});
};
