import { Document } from '@contentful/rich-text-types';

export enum BatteryResultIds {
	EXTRA_INFORMATION_NEEDED = 'extra_information_needed',
	BACK_GLASS_DAMAGED = 'back_glass_damaged',
	FRONT_GLASS_DAMAGED = 'front_glass_damaged',
	BATTERY_REPLACEMENT_FREE = 'battery_replacement_free',
	BATTERY_REPLACEMENT = 'battery_replacement',
	BATTERY_DAMAGED = 'battery_damaged',
	GOOD_BATTERY = 'good_battery',
}

export interface BatteryResult {
	id: BatteryResultIds;
	result: string;
	description: Document;
}
