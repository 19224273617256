import React from 'react';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { PageBanner } from '../components/PageBanner';
import { PageCard } from '../components/PageCard';
import { MagicWandWithLabel } from '../../../../components';
import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

export const MagicWandPage = () => {
	const pageContent = useCheckupPage('batteryMagicWandPage');

	if (!pageContent) return <PageLoader />;

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={<PageCard banner={pageContent?.banners[0]} />}
			backButtonConfig={{
				hide: true,
			}}
			continueButtonConfig={{
				hide: true,
			}}
		>
			<MagicWandWithLabel label={pageContent.heading} />
		</CheckupPageLayout>
	);
};
