import styled from 'styled-components';
import {
	Button,
	Modal,
	Text,
	getFontSize,
	getFontWeight,
	Drawer,
	PageLoader,
} from '@soluto-private/mx-asurion-ui-react';
import { redirectToLogin } from '@soluto-private/mx-app-authentication';
import {
	Image as ImageType,
	RichText,
	useCheckupPage,
} from '@home-diy-toolbox/web/contentful';
import { useDisableScroll } from '../../../../utils';
import { BLOCKS } from '@contentful/rich-text-types';

const Image = styled.img`
	width: 7.2237rem;
	margin-bottom: 1.4006rem;
`;

const Title = styled(Text)`
	&& {
		display: block;
		margin-bottom: 0.75rem;
		font-size: ${getFontSize(5)};
		font-weight: ${getFontWeight('feather')};
		text-align: center;
	}
`;

const Description = styled(Text)`
	&& {
		display: block;
		margin-bottom: 2.25rem;
		font-weight: ${getFontWeight('feather')};
		line-height: 1.3;
	}
`;

const Buttons = styled.div`
	display: grid;
	grid-template-columns: minmax(0, 8.1875rem) minmax(0, 9.9375rem);
	gap: 0.5rem;
	width: 100%;
	justify-content: end;
`;

const Content = ({ onCloseClick }) => {
	const pageContent = useCheckupPage('unknownDeviceModal');

	if (!pageContent) return <PageLoader />;

	const unknownDevice = pageContent.blocks.find(
		(block) => block.entryName === 'unknownDevice'
	) as ImageType;

	return (
		<>
			<Image src={unknownDevice.asset.url} alt={unknownDevice.alternativeText} />
			<RichText
				document={pageContent.heading}
				renderOptionsOverride={{
					renderNode: { [BLOCKS.PARAGRAPH]: (_, children) => <Title>{children}</Title> },
				}}
			/>
			<RichText
				document={pageContent.description}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => <Description>{children}</Description>,
					},
				}}
			/>

			<Buttons>
				<Button color="secondary" variant="outline" onClick={() => onCloseClick()}>
					{pageContent.ctas.continue.copy}
				</Button>
				<Button
					color="secondary"
					onClick={() => {
						redirectToLogin();
					}}
				>
					{pageContent.ctas.signIn.copy}
				</Button>
			</Buttons>
		</>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ModalContainer = styled(Container)`
	padding: 0 1.25rem;
`;

const aboveChatZIndex = '2147482648';

export const NoAndroidDeviceWarningModal = ({ isOpen, onCloseClick }) => {
	useDisableScroll(isOpen);

	return (
		<Modal isOpen={isOpen} onCloseClick={() => onCloseClick()} width="30rem">
			<ModalContainer>
				<Content onCloseClick={onCloseClick} />
			</ModalContainer>
		</Modal>
	);
};

export const NoAndroidDeviceWarningDrawer = ({ isOpen, onCloseClick }) => {
	useDisableScroll(isOpen);

	return (
		<Drawer
			className="widecontainer"
			style={{ zIndex: aboveChatZIndex }}
			open={isOpen}
			onOpenUpdate={(open) => {
				if (!open) {
					onCloseClick();
				}
			}}
		>
			<Container>
				<Content onCloseClick={onCloseClick} />
			</Container>
		</Drawer>
	);
};
