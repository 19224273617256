import { useQuery } from 'urql';
import { chatOnlyPagePromptsQuery } from './queries';
import { transformGraphqlEntry } from './utils';
import { useMemo } from 'react';
import { ChatOnlyPagePrompts } from './models';
import { useValidateMxContext } from '../useValidateMxContext';

export const useChatOnlyPagePrompts = (): ChatOnlyPagePrompts[] | null => {
	const { language } = useValidateMxContext();

	const [response] = useQuery({
		query: chatOnlyPagePromptsQuery,
		variables: {
			locale: language,
		},
	});

	const data = useMemo(() => {
		if (!response.data) return null;
		return transformGraphqlEntry(response.data.chatOnlyPagePromptCollection.items);
	}, [response.data]);

	return data;
};
