import { FC, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FlexTemplate, breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { Banner as BannerContent } from './Banner';
import { NavButtons, type NavButtonsProps } from './NavButtons';
import { Pages } from '../pages';
import { ChatHeadPositioner } from '../ChatHeadPositioner';
import { MainContentStyled } from '../MainContentStyled';

const Container = styled(FlexTemplate)`
	&&& {
		width: 100%;
		box-sizing: border-box;
		max-width: unset;
	}
`;

const MainContent = styled(FlexTemplate.MainContentContainer)`
	&& {
		overflow-y: auto;

		${breakpoints.md2} {
			overscroll-behavior: contain;
		}
	}
`;

const SidePanel = styled(FlexTemplate.SidePanelContainer)`
	&& {
		width: 100%;
		margin-left: 0;
		padding: 0;
		overflow-y: auto;

		${breakpoints.md2} {
			overscroll-behavior: contain;
		}
	}
`;

const { MainPanelContainer, ButtonGroup } = FlexTemplate;

type BatteryCheckupLayoutProps = {
	currPage?: Pages;
	children: ReactNode;
} & NavButtonsProps;

export const BatteryCheckupLayout: FC<BatteryCheckupLayoutProps> = ({
	children,
	onBack,
	disabledBack,
	isBackLoading,
	onContinue,
	disabledContinue,
	isContinueLoading,
	currPage,
}) => {
	const mainContent = useRef<HTMLDivElement>();

	useEffect(() => {
		mainContent.current?.scrollTo({ top: 0, behavior: 'smooth' });
	}, [currPage]);

	return (
		<>
			<MainContentStyled />
			<ChatHeadPositioner />
			<Container className="widecontainer" upperVerticalOffset={'123px'}>
				<MainContent ref={mainContent}>
					<MainPanelContainer>{children}</MainPanelContainer>
				</MainContent>
				<SidePanel>
					<BannerContent currPage={currPage} />
				</SidePanel>
				<ButtonGroup alignment="start">
					<NavButtons
						onBack={onBack}
						disabledBack={disabledBack}
						isBackLoading={isBackLoading}
						onContinue={onContinue}
						disabledContinue={disabledContinue}
						isContinueLoading={isContinueLoading}
					/>
				</ButtonGroup>
			</Container>
		</>
	);
};
