import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Article,
	FeedbackChoicesTypes,
	useArticleContentContext,
	useArticlePage,
	useManageMxClient,
} from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	DiagnosticsCategoryType,
	DiagnosticsCheckupsMap,
	BatteryPages,
	BatteryFilterPathNames,
	SignalPages,
	SignalFilterPathnames,
	SpeedPages,
	SpeedFilterPathnames,
	GenericPageIds,
	Partners,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	FeedbackComponentTypes,
	ServiceSection,
	ExpertContactCard,
	FeedbackTypes,
} from '../../components';
import { useFlow, useBreadcrumb, Step } from '../../providers';
import { useTranslation } from 'react-i18next';
import { useHeader } from '../../features/header';
import styled from 'styled-components';
import { Text, Link, breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { ArticleList, ArticleItem } from '../../components/ArticleGridSection';
import { ArticleLoader } from '../../components/ArticleLoader';

const OtherContent = styled.div`
	&& {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		width: 100%;
		gap: 2.25rem;
		margin-top: 3.25rem;
	}
`;

const RelatedGuidesHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledRelatedGuidesTitle = styled(Text)`
	&& {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
`;

const StyledArticle = styled.article`
	&& {
		width: 100%;
		max-width: 560px;
	}
`;

const ArticleContainer = styled.div`
	padding: 0 1rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		padding: 0 1.5rem;
	}

	${breakpoints.lg} {
		padding: 0;
	}

	${breakpoints.xxl} {
		padding: 0 1rem;
	}
`;

type ArticlePageProps = {
	articleId: string;
};

const relatedGuidesLinkMap = {
	[Checkups.BATTERY as string]: BatteryFilterPathNames[BatteryPages.ALL],
	[Checkups.SIGNAL as string]: SignalFilterPathnames[SignalPages.ALL],
	[Checkups.SPEED as string]: SpeedFilterPathnames[SpeedPages.ALL],
};

const defaultBackPageLinkMap = {
	[Checkups.BATTERY as string]: BatteryFilterPathNames[BatteryPages.POPULAR],
	[Checkups.SIGNAL as string]: SignalFilterPathnames[SignalPages.POPULAR],
	[Checkups.SPEED as string]: SpeedFilterPathnames[SpeedPages.POPULAR],
};

const selectRandomArticles = (articles, randomArticleCount) => {
	const randomNumbers = [];

	while (randomNumbers.length < Math.min(articles.length, randomArticleCount)) {
		const randomNumber = Math.floor(Math.random() * articles.length);
		if (randomNumbers.includes(randomNumber)) {
			continue;
		}

		randomNumbers.push(randomNumber);
	}

	return randomNumbers.map((randomNumber) => articles[randomNumber]);
};

const ArticlePage: FunctionComponent<ArticlePageProps> = ({ articleId }) => {
	const partner = useManageMxClient();
	const page = useArticlePage(`${partner}_articlePage`);

	const { t } = useTranslation();
	const history = useHistory();
	const { flow, getActiveFlowType } = useFlow();
	const { deviceOS } = flow.sessionData;
	const { getArticleCards } = useArticleContentContext();
	const { currentState, updateHeader } = useHeader();
	const { setBreadcrumbSteps, resetBreadcrumbSteps } = useBreadcrumb();
	const [article, setArticle] = useState<{ articleData: Article; tags: string[] }>(
		undefined
	);
	const [checkupType, setCheckupType] = useState<string>(undefined);
	const [relatedGuides, setRelatedGuides] = useState(undefined);

	// TODO: remove once battery landing page for att is ready for deployment
	const attBatteryExcluded = checkupType === Checkups.BATTERY && partner === Partners.ATT;

	const updateMxHeader = () => {
		const activeFlow = getActiveFlowType();

		if (activeFlow && currentState().title) {
			return;
		}

		// Active flow, but page has been refreshed. No title. Must set and then return.
		if (activeFlow && !currentState().title) {
			let checkup = activeFlow;

			// We can be in a diagnostics flow, we want to translate that to the checkup type
			if (checkup in DiagnosticsCategoryType) {
				checkup = DiagnosticsCheckupsMap[checkup];
			}

			updateHeader({
				title: t(`${checkup}Header`),
			});

			return;
		}

		// Assumed new navigation directly to article, must set default title
		updateHeader({
			title: t('Device Care'),
		});
	};

	const updateBreadcrumb = (
		articleTitle: string,
		checkupType?: string,
		backPageTitle?: string
	) => {
		const historyState = history.location.state || {};
		const originPath = historyState['originPath'] ?? historyState['from'];

		let possibleBackpageLinks = [];
		switch (checkupType) {
			case Checkups.BATTERY:
				possibleBackpageLinks = Object.values(BatteryFilterPathNames);
				break;
			case Checkups.SIGNAL:
				possibleBackpageLinks = Object.values(SignalFilterPathnames);
				break;
			case Checkups.SPEED:
				possibleBackpageLinks = Object.values(SpeedFilterPathnames);
				break;
		}

		const backPageLink = possibleBackpageLinks.includes(originPath)
			? originPath
			: defaultBackPageLinkMap[checkupType];

		const landingPageLinks =
			backPageLink && backPageTitle && !attBatteryExcluded
				? [
						{
							path: backPageLink,
							label: backPageTitle,
						},
				  ]
				: [];

		const breadcrumbSteps: Step[] = [
			{
				path: '/',
				label: 'Device Care',
			},
			...landingPageLinks,
			{
				label: articleTitle,
			},
		];
		setBreadcrumbSteps(breadcrumbSteps);
	};

	useEffect(() => {
		let backPageTitle;
		switch (checkupType) {
			case Checkups.BATTERY:
				backPageTitle = page?.batteryPageTitle?.copy;
				break;
			case Checkups.SIGNAL:
				backPageTitle = page?.signalPageTitle?.copy;
				break;
			case Checkups.SPEED:
				backPageTitle = page?.speedPageTitle?.copy;
		}
		const articleTitle = article?.articleData?.title;
		if (articleTitle) {
			updateBreadcrumb(articleTitle, checkupType, backPageTitle);
		}
		return () => {
			resetBreadcrumbSteps();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		article,
		checkupType,
		page?.batteryPageTitle?.copy,
		page?.signalPageTitle?.copy,
		page?.speedPageTitle?.copy,
	]);

	const onArticleLoad = (articleData) => {
		try {
			updateMxHeader();

			const checkups: string[] = Object.values(Checkups);
			const articleTags = articleData?.tags || [];
			const checkupType = articleTags.find((tag) => checkups.includes(tag)) || undefined;

			const tags = [checkupType];
			const excludeTags = ['old'];
			getArticleCards({ tags, deviceOS, excludeTags }).then((articles) => {
				setRelatedGuides(
					selectRandomArticles(articles, 4)
						.filter((article) => article && article.id !== articleId)
						.slice(0, 3)
				);
			});
			setCheckupType(checkupType);
			setArticle(articleData);
		} catch {
			setArticle(undefined);
		}
	};

	const otherServices = page?.serviceList
		.filter(({ checkupType: ct }) => checkupType !== ct)
		.slice(0, 3);

	return (
		<>
			<StyledArticle>
				<ArticleContainer>
					<ArticleLoader
						articleId={articleId}
						onArticleLoad={onArticleLoad}
						feedbackSectionProps={{
							variant: FeedbackComponentTypes.STARS,
							type: FeedbackTypes.ARTICLE,
							feedbackTitle: page?.feedbackSectionTitle,
							positiveForm: {
								...page?.positiveFeedbackForm,
								formId: page?.positiveFeedbackForm?.entryName,
								feedbackChoices: page?.positiveFeedbackForm?.feedbackChoices.map(
									(choice) => ({
										id: choice.entryName,
										label: choice.copy,
									})
								),
								submitButtonCopy: page?.positiveFeedbackForm?.submitCta?.copy,
								feedbackChoicesType: FeedbackChoicesTypes.NEW,
							},
							negativeForm: {
								...page?.negativeFeedbackForm,
								formId: page?.negativeFeedbackForm?.entryName,
								feedbackChoices: page?.negativeFeedbackForm?.feedbackChoices.map(
									(choice) => ({
										id: choice.entryName,
										label: choice.copy,
									})
								),
								submitButtonCopy: page?.negativeFeedbackForm?.submitCta?.copy,
								feedbackChoicesType: FeedbackChoicesTypes.NEW,
							},
						}}
					/>
				</ArticleContainer>
			</StyledArticle>
			{article ? (
				<OtherContent className="widecontainer">
					<ExpertContactCard
						from={GenericPageIds.ARTICLE_PAGE}
						expertContactCard={page?.contactExpertCard}
					/>
					{!!relatedGuides?.length && (
						<div>
							<RelatedGuidesHeader>
								<StyledRelatedGuidesTitle forwardedAs="h1" weight="feather" size={4}>
									{page?.relatedGuidesTitle}
								</StyledRelatedGuidesTitle>
								{relatedGuidesLinkMap[checkupType] && !attBatteryExcluded && (
									<Link
										tabIndex={0}
										data-cy="allRelatedGuidesLink"
										data-pw="allRelatedGuidesLink"
										color="secondary"
										iconSide="right"
										iconSrc="ArrowRight"
										onClick={() => {
											history.push(relatedGuidesLinkMap[checkupType], {
												from: history.location.pathname,
											});
										}}
									>
										{page?.relatedGuidesLink}
									</Link>
								)}
							</RelatedGuidesHeader>
							<ArticleList xScrollable={true}>
								{relatedGuides.map(({ id, contentCard, subtext, title }) => (
									<ArticleItem
										id={id}
										key={id}
										subtitle={contentCard.fields.subtitle || subtext}
										title={title}
										imageUrl={contentCard?.fields.coverImage?.fields.file.url}
										externalLink={contentCard.fields.externalLink}
									/>
								))}
							</ArticleList>
						</div>
					)}
					{!!otherServices?.length && (
						<ServiceSection
							xScrollable={true}
							sectionTitle={page.serviceListTitle}
							services={otherServices}
						/>
					)}
				</OtherContent>
			) : null}
		</>
	);
};

export default ArticlePage;
