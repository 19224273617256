import { useEffect } from 'react';
import { Partners } from '@home-diy-toolbox/web/common-types';
import { storage, Key } from '@soluto-private/mx-context';

// dumping here all the dirty hacks needed to position the sidebar exactly below the header
export const usePositionOffsets = () => {
	const client = storage.get(Key.Client);
	const subnavHasNoLogo = !client || client === Partners.ASURION;
	const subnavHeight = subnavHasNoLogo ? 40 : 95.5;

	const mainContent = document.getElementById('mainContent');
	useEffect(() => {
		if (mainContent) {
			const currentMargin = mainContent.style.margin;
			mainContent.style.margin = '0';
			return () => {
				mainContent.style.margin = currentMargin;
			};
		}
	}, [mainContent]);

	return { subnavHeight, heightDiff: 12.5 };
};
