import { DependencyList, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTop = (deps?: DependencyList) => {
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
	}, deps || [pathname]);
};
