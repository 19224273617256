import React, { FunctionComponent } from 'react';
import {
	FeedbackChoice,
	FeedbackChoicesTypes,
	FeedbackFormContent,
} from '@home-diy-toolbox/web/contentful';
import { NextButton } from '../../atoms';
import styled from 'styled-components';
import { FeedbackPayload } from '../FeedbackForm';
import { useTranslation } from 'react-i18next';
import { ManualSelectionChips } from '@soluto-private/mx-asurion-ui-react';
import { Entry } from 'contentful';
interface FormDropdown {
	form: FeedbackFormContent;
	onTagsChange: (string) => void;
	onCommentsChange: (string) => void;
	onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	feedback: FeedbackPayload;
}

const DropdownContainer = styled.div`
	padding: 2rem 1rem 0;
	max-width: 23.875rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Fieldset = styled.fieldset`
	border: none;
	padding: 0;
	margin: 1.875rem 0 0 0;
	display: inline-flex;
	flex-wrap: wrap;
	width: 90%;
`;

const Textarea = styled.textarea`
	box-sizing: border-box;
	font-family: inherit;
	border-color: ${({ theme }) => theme.feedback.commentsBorderColor};
	background: ${({ theme }) => theme.feedback.commentsBackgroundColor};
	border-radius: 0.25rem;
	width: 100%;
	height: 7rem;
	padding: 0.625rem;
	font-size: 1rem;
	resize: none;
	margin: 3.75rem 0 0;
	outline: none;
	&:focus-within {
		border: 0.125rem solid ${({ theme }) => theme.defaultFocusedBorderColor};
	}
`;

const Button = styled(NextButton)`
	padding: 0.75rem 4rem;
	font-size: 1rem;
	cursor: pointer;
	margin: 1.625rem 0 0;
	:disabled {
		cursor: not-allowed;
	}
`;

const Heading = styled.div`
	text-align: center;
	font-size: 1.25rem;
	margin-bottom: 0.75rem;
`;

const SubHeading = styled.div`
	text-align: center;
	font-size: 0.875rem;
`;

export const FeedbackDropdown: FunctionComponent<FormDropdown> = ({
	form,
	feedback,
	onTagsChange,
	onCommentsChange,
	onSubmit,
}) => {
	const { t } = useTranslation();

	let feedbackOptions: FeedbackChoice[];

	if (form.feedbackChoicesType === FeedbackChoicesTypes.OLD) {
		feedbackOptions = (form.feedbackChoices as Entry<FeedbackChoice>[]).map(
			({ fields }) => ({
				id: fields.id,
				label: fields.label,
			})
		);
	} else {
		feedbackOptions = form.feedbackChoices as FeedbackChoice[];
	}

	return (
		<DropdownContainer>
			<Heading id={'feedbackFormHeader'}>{form.header}</Heading>
			<SubHeading>{form.subheader}</SubHeading>
			<Fieldset>
				<ManualSelectionChips
					chips={feedbackOptions.map(({ id, label }) => ({
						isSelected: (feedback.tags || []).includes(id),
						onClick: () => onTagsChange(id),
						label,
					}))}
					layout="wrap"
					options={{
						edgeItemsMargin: '0px',
					}}
					size="small"
					type="selection"
					variant="normal"
				/>
			</Fieldset>
			<Textarea
				tabIndex={0}
				aria-label={t('feedbackCommentName')}
				rows={5}
				value={feedback.comment}
				placeholder={form.commentsPlaceholder}
				onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
					onCommentsChange(e.target.value)
				}
			/>
			<Button
				aria-label={t('feedbackSubmitName')}
				data-cy="feedbackFormSubmit"
				disabled={feedback.rating === null}
				type="submit"
				onClick={onSubmit}
			>
				{form.submitButtonCopy}
			</Button>
		</DropdownContainer>
	);
};
