import React, {
	Context,
	createContext,
	FunctionComponent,
	useContext,
	useMemo,
	useState,
} from 'react';
import { createClient, EntryCollection } from 'contentful';
import {
	ArticleClientContextType,
	ArticleContentProviderProps,
	Article,
	ArticleCard,
	GetArticleCards,
} from './types';
import { CheckupCategoryType } from '@home-diy-toolbox/web/common-types/refresh-base';
import { getArticlePartnerEntryId, sortArticleCards } from './utils';

const defaultContentfulArticleContextType: ArticleClientContextType = {
	getSuggestedArticles: null,
	getArticleContent: null,
	getArticleCards: null,
	getTopArticles: null,
	getTags: null,
};

const ContentfulArticleContext: Context<ArticleClientContextType> = createContext(
	defaultContentfulArticleContextType
);

export const useArticleContentContext = () => useContext(ContentfulArticleContext);

export const ArticleContentProvider: FunctionComponent<ArticleContentProviderProps> = ({
	spaceId,
	accessToken,
	environment,
	locale,
	partner,
	retryOnError = false,
	children,
}) => {
	const [articleCardsRecord, setArticleCardsRecord] = useState<
		Record<string, ArticleCard>
	>({});
	const [tagArticleIds, setTagArticleIds] = useState<Record<string, string[]>>({});
	const defaultDeviceOS = 'ios';

	const client = useMemo(
		() =>
			createClient({
				space: spaceId,
				accessToken,
				environment,
				retryOnError,
				retryLimit: 5,
			}),
		[spaceId, accessToken, environment, retryOnError]
	);

	const getArticles = (
		tags: string[],
		limit: number,
		deviceOS: string,
		excludeTags?: string[]
	) => {
		return client.getEntries<Article>({
			content_type: 'diyArticles',
			include: 1,
			locale,
			limit: limit,
			'fields.category.sys.id[in]': getArticlePartnerEntryId(partner),
			'metadata.tags.sys.id[all]': deviceOS ? deviceOS : defaultDeviceOS,
			'metadata.tags.sys.id[in]': tags.join(','),
			'metadata.tags.sys.id[nin]': excludeTags?.join(','),
		});
	};

	const getTags = (tags: string[], checkup: CheckupCategoryType): string[] => {
		switch (checkup) {
			case CheckupCategoryType.SIGNAL_CHECKUP:
				return tags.concat('signal', 'certain_areas', 'everywhere');
			case CheckupCategoryType.SPEED_CHECKUP:
				return tags.concat('speed', 'general', 'app_issues');
			case CheckupCategoryType.BATTERY_CHECKUP:
				return tags.concat('battery', 'protect');
		}

		return [...new Set(tags)];
	};

	/**
	 * @deprecated this function randomly selects 6 articles that has at least one matching tags.
	 * For better results, use getTopArticles instead.
	 */
	const getSuggestedArticles = async (
		tags: string[],
		deviceOS: string,
		checkup: CheckupCategoryType,
		excludeTags?: string[]
	) => {
		const flowDataTags = getTags(tags, checkup);
		const res: EntryCollection<Article> = await getArticles(
			flowDataTags,
			6,
			deviceOS,
			excludeTags
		);
		const ArticleEntries: Article[] = res.items.map((article) => ({
			id: article.sys.id,
			...article.fields,
		}));

		return ArticleEntries;
	};

	const getTopArticles = async (
		requiredTags: string[],
		optionalTags: string[],
		deviceOS: string,
		excludeTags: string[] = [],
		limit = 6
	) => {
		const articles = await getArticleCards({ tags: requiredTags, deviceOS, excludeTags });
		const sortedArticlesCards = sortArticleCards(articles, optionalTags);

		return sortedArticlesCards.slice(0, limit);
	};

	const getRecordKey = (articleId: string) => articleId + locale;

	const getSavedCards = (tagsKey: string) => {
		const articleIds = tagArticleIds[tagsKey];
		if (articleIds) {
			return articleIds.map((articleId) => {
				const key = getRecordKey(articleId);
				return articleCardsRecord[key];
			});
		}
	};

	const saveArticleCards = (tagsKey: string, articleCards: ArticleCard[]) => {
		setTagArticleIds({
			...tagArticleIds,
			[tagsKey]: articleCards.map(({ id }) => id),
		});
		const recordsCopy = { ...articleCardsRecord };
		articleCards.forEach((articleCard) => {
			const key = getRecordKey(articleCard.id);
			recordsCopy[key] = articleCard;
		});
		setArticleCardsRecord(recordsCopy);
	};

	const getArticleCards = async ({ tags, deviceOS, excludeTags }: GetArticleCards) => {
		if (deviceOS) {
			tags = tags.concat(deviceOS);
		}

		const tagsStr = tags.join(',');
		const tagsKey = tagsStr + locale;
		const savedCards = getSavedCards(tagsKey);
		if (savedCards) return savedCards;

		const res: EntryCollection<Article> = await client.getEntries<Article>({
			content_type: 'diyArticles',
			include: 1,
			locale,
			'fields.category.sys.id[in]': getArticlePartnerEntryId(partner),
			'metadata.tags.sys.id[nin]': (excludeTags ?? []).join(','),
			'metadata.tags.sys.id[all]': tagsStr,
			select: 'fields.title,fields.contentCard,metadata.tags',
		});

		const articleCards = res.items.map((article) => ({
			id: article.sys.id,
			tags: article.metadata.tags.map((tag) => tag.sys.id),
			...article.fields,
		}));
		saveArticleCards(tagsKey, articleCards);

		return articleCards;
	};

	const getArticleContent = async (articleId: string) => {
		const foundArticle = await client.getEntry<Article>(articleId, {
			include: 10,
			locale,
		});
		const articleData = foundArticle?.fields;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const tags = foundArticle?.metadata?.tags?.map((tag) => tag?.sys?.id) || [];

		return { articleData, tags };
	};

	return (
		<ContentfulArticleContext.Provider
			value={{
				getSuggestedArticles,
				getArticleContent,
				getArticleCards,
				getTopArticles,
				getTags,
			}}
		>
			{children}
		</ContentfulArticleContext.Provider>
	);
};
