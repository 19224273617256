import { useContext, useEffect, useState } from 'react';
import {
	AnalyticViewType,
	useAnalytics,
	withExtras,
	AnalyticEventType,
	useDispatchEvent,
} from '@home-diy-toolbox/web/analytics';
import {
	useCheckupContentContext,
	RichText,
	useArticleContentContext,
	Article,
	FeedbackChoicesTypes,
	useContactExpertCard,
	Button as ContentfulButton,
} from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	CheckupCategoryType,
	ResultPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled, { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useResults, useFlow } from '../../../providers';
import {
	CTACard,
	OtherCheckupSection,
	ArticleSuggestionsSection,
	ResultSectionDivider,
	ResultPageHeader,
	FeedbackForm,
	ActionCard,
	FeedbackComponentTypes,
	FeedbackTypes,
	CheckupContainer,
} from '../../../components';
import { Text, Button, colors } from '@soluto-private/mx-asurion-ui-react';
import { LoadingPage } from '../../LoadingPage';
import { getAct, openUrl } from './actions';
import { ContactExpertsCard } from '../../Checkups/BatteryCheckupV2/results/ContactExpertsCard';
import { useMediaQuery } from '@home-diy-toolbox/web/utils';
import { getChatClient } from '../../../features/chat/components';
import { ContactExpertsModal } from '../../../components/ContactExpertCard/ContactExpertsModal';
import { ContactExpertsDrawer } from '../../../components/ContactExpertCard/ContactExpertsDrawer';
import { BLOCKS } from '@contentful/rich-text-types';

const ResultPageBody = styled(Text)`
	line-height: 1.3;
	p {
		margin: 0;
	}

	a {
		color: ${colors.black};
	}
`;

const FeedbackFormContainer = styled.div`
	margin-top: 1.5rem;
	margin-bottom: 1rem;
`;

const BatteryResultsPage = () => {
	const analytics = useAnalytics();
	const {
		resultsCalculated,
		resultsData: {
			pageId,
			isBadOrIndeterminate,
			isHazardousDamage,
			isNonAllowableDamage,
			isPlanAndDeviceEligible,
		},
	} = useResults(Checkups.BATTERY);

	const contactExpertCardData = useContactExpertCard('talkToAnExpert');
	const theme = useContext(ThemeContext);
	const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop})`);

	const [drawerIsOpen, setDrawerIsOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const { dispatchEvent } = useDispatchEvent();

	const checkButtonAction = (button: ContentfulButton) => {
		if (button.action === 'openChat') {
			getChatClient().openMessagingOverlay();
		} else if (button.action === 'phoneNumber') {
			window.location.href = 'tel:' + button.telephoneNumber;
		}
	};

	const { getSuggestedArticles } = useArticleContentContext();

	const { flow, getTagsForCheckup } = useFlow();
	const {
		flowId,
		sessionData: { deviceOS, deductibles },
	} = flow;

	const { useResultPageContent } = useCheckupContentContext();
	const {
		resultPageTitle,
		resultPageBody,
		actionCard,
		ctaCardTitle,
		ctaCardBody,
		articleResultsTitle,
		feedbackTitle,
		positiveFeedback,
		negativeFeedback,
		ctaCardButtonUrl,
		ctaCardButtonCopy,
	} = useResultPageContent(pageId);

	const {
		t,
		i18n: { language },
	} = useTranslation();

	const [articles, setArticles] = useState<Article[]>([]);

	useEffect(() => {
		const tags = getTagsForCheckup(CheckupCategoryType.BATTERY_CHECKUP);
		const excludeTags = ['external'];
		getSuggestedArticles(
			tags,
			deviceOS,
			CheckupCategoryType.BATTERY_CHECKUP,
			excludeTags
		).then((foundArticles) => {
			setArticles(foundArticles);
		});
	}, [language]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		analytics.dispatcher
			.extend(
				withExtras({
					PageId: ResultPageIds.BATTERY_RESULT,
					BatteryCheckupResult: pageId,
					IsNonAllowableDamage: isNonAllowableDamage,
					IsPlanAndDeviceEligible: isPlanAndDeviceEligible,
					IsHazardousDamage: isHazardousDamage,
					IsBadOrIndeterminate: isBadOrIndeterminate,
					CheckupType: Checkups.BATTERY,
					ComponentType: AnalyticViewType.PAGE,
				})
			)
			.dispatch(AnalyticEventType.VIEW);
	}, [
		analytics,
		isBadOrIndeterminate,
		isHazardousDamage,
		isNonAllowableDamage,
		isPlanAndDeviceEligible,
		pageId,
	]);

	if (!resultsCalculated) {
		return <LoadingPage />;
	}

	const dispatchOnClick = () => {
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'batteryResultsCtaButtonLink',
					CheckupType: Checkups.BATTERY,
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	};

	const getDeductible = () => {
		if (
			deductibles &&
			deductibles[actionCard.fields.deductible] &&
			actionCard.fields.deductible &&
			typeof deductibles[actionCard.fields.deductible] !== 'undefined'
		) {
			return deductibles[actionCard.fields.deductible];
		}

		return undefined;
	};

	return (
		<CheckupContainer>
			<ResultPageHeader>
				<RichText document={resultPageTitle} />
			</ResultPageHeader>
			<RichText
				document={resultPageBody}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => (
							<ResultPageBody size={3}>{children}</ResultPageBody>
						),
					},
				}}
			/>
			{contactExpertCardData &&
			pageId === ResultPageIds.BATTERY_RESULT_HEALTHY_NO_DAMAGE ? (
				<ContactExpertsCard
					mainHeading={contactExpertCardData.mainHeading}
					cardHeading={contactExpertCardData.cardHeading}
					cardSubheading={contactExpertCardData.cardDescription}
					imageUrl={contactExpertCardData.coverImage.asset.url}
					imageAlt={contactExpertCardData.coverImage.asset.url}
					isDesktop={isDesktop}
					onClickBanner={() => {
						if (contactExpertCardData.cardCtAs.length > 1) {
							isDesktop ? setModalIsOpen(true) : setDrawerIsOpen(true);
						} else {
							checkButtonAction(contactExpertCardData.cardCtAs[0]);
						}

						dispatchEvent(AnalyticEventType.CLICK, {
							ActionId: 'batteryResultsLiveExpertCard',
						});
					}}
				/>
			) : null}
			{actionCard && (
				<ActionCard
					image={{
						url: actionCard.fields?.image?.fields.file.url,
						alt: actionCard.fields?.image?.fields?.description,
					}}
					tags={actionCard.fields?.tags}
					title={actionCard.fields.title.fields.copy}
					body={actionCard.fields.body.fields.copy}
					cost={getDeductible()}
					costDescription={getDeductible() ? t('deductible') : undefined}
					button={{
						onClick: () => {
							dispatchOnClick();
							const action = actionCard.fields?.buttonAction?.fields;
							if (action) {
								const act = getAct(action);
								act();
							}
						},
						text: actionCard.fields?.buttonText?.fields?.copy,
					}}
				/>
			)}
			{(ctaCardTitle || ctaCardBody) && (
				<CTACard title={ctaCardTitle} body={ctaCardBody}>
					{ctaCardButtonCopy && (
						<Button
							onClick={() => {
								dispatchOnClick();
								openUrl(ctaCardButtonUrl);
							}}
							color="secondary"
						>
							{ctaCardButtonCopy}
						</Button>
					)}
				</CTACard>
			)}

			{!isHazardousDamage && (
				<>
					{articles && articles.length > 0 && (
						<>
							<ResultSectionDivider />
							<ArticleSuggestionsSection
								headerCopy={articleResultsTitle}
								articleSuggestions={articles}
								checkupType={Checkups.BATTERY}
								buttonVariant={
									ctaCardButtonCopy || actionCard?.fields.buttonText
										? 'outline'
										: 'default'
								}
							/>
						</>
					)}
					{feedbackTitle && (
						<FeedbackFormContainer>
							<FeedbackForm
								variant={FeedbackComponentTypes.STARS}
								type={FeedbackTypes.SESSION}
								flowId={flowId}
								feedbackTitle={feedbackTitle}
								positiveForm={{
									...positiveFeedback.fields,
									feedbackChoicesType: FeedbackChoicesTypes.OLD,
								}}
								negativeForm={{
									...negativeFeedback.fields,
									feedbackChoicesType: FeedbackChoicesTypes.OLD,
								}}
								analyticsExtras={{ CheckupType: Checkups.BATTERY }}
							/>
						</FeedbackFormContainer>
					)}
				</>
			)}
			<ResultSectionDivider />
			<OtherCheckupSection />

			{contactExpertCardData &&
			pageId === ResultPageIds.BATTERY_RESULT_HEALTHY_NO_DAMAGE ? (
				<>
					<ContactExpertsModal
						isOpen={modalIsOpen}
						title={contactExpertCardData.popupTitle}
						cardCtas={contactExpertCardData.cardCtAs}
						onCloseClick={() => {
							setModalIsOpen(false);
						}}
					/>

					<ContactExpertsDrawer
						title={contactExpertCardData.popupTitle}
						cardCtas={contactExpertCardData.cardCtAs}
						isOpen={drawerIsOpen}
						onOpenUpdate={() => {
							setDrawerIsOpen(false);
						}}
					/>
				</>
			) : null}
		</CheckupContainer>
	);
};

export default BatteryResultsPage;
