import React, { useCallback } from 'react';
import styled from 'styled-components';
import type { AnswerOption } from '@home-diy-toolbox/web/contentful';
import {
	RadioGroupSlider,
	RadioGroupSliderProps,
	Value,
} from '@home-diy-toolbox/web/radio-group-slider';
import type { UsageHabit } from '@home-diy-toolbox/shared/clients';

type Props = AnswerOption &
	Partial<RadioGroupSliderProps> & {
		setUsageTimeInHours: (id: UsageHabit, value: Value) => void;
	};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 110px;
`;

const Label = styled.span`
	line-height: 2rem;
	font-size: 1.25rem;
	font-weight: bold;
`;

export const UsageHabitSelection = ({
	id,
	copy,
	radioValues,
	initialValue,
	setUsageTimeInHours,
}: Props): JSX.Element => {
	const handleUsageValueChange = useCallback(
		(value: Value) => setUsageTimeInHours(id as UsageHabit, value),
		[id, setUsageTimeInHours]
	);

	return (
		<Container>
			<Label tabIndex={0} key={`Title_${id}`}>
				{copy}
			</Label>
			<RadioGroupSlider
				group={id}
				radioValues={radioValues}
				initialValue={initialValue}
				data-cy={`Slider_${id}`}
				onSelected={handleUsageValueChange}
			/>
		</Container>
	);
};
