import styled from 'styled-components';
import { Card } from '@soluto-private/mx-asurion-ui-react';

const PhoneCheckupContainer = styled(Card)`
	padding: 16px;
	cursor: pointer;
	@media (hover: hover) {
		&:hover {
			box-shadow: 0 0 0 1px ${({ theme }) => theme.answerOptions.hoverBorderColor} inset;
		}
	}
`;

export const PhoneCheckupButton = ({ children, ...others }) => (
	<PhoneCheckupContainer element="button" {...others}>
		{children}
	</PhoneCheckupContainer>
);
