import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { PhoneCheckupButton } from '../atoms';
import Speedometer from '../../assets/speedometer.svg';
import { useTranslation } from 'react-i18next';
import {
	AnalyticEventType,
	useAnalytics,
	withExtras,
} from '@home-diy-toolbox/web/analytics';

const Title = styled(Text)`
	display: block;
	margin-bottom: 0.75rem;
`;

const CheckupOptionsContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const ImageLabel = styled(Text)`
	margin: 0.5rem 0.375rem 0.125rem;
`;

export const OtherCheckupSection: FunctionComponent = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const analytics = useAnalytics();

	const onClick = () => {
		const navigationState = {
			from: history.location.pathname,
		};

		history.push('/', navigationState);

		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: `otherCheckups_allCheckups`,
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	};

	return (
		<>
			<Title weight="feather" size={4} forwardedAs="h2">
				{t('otherServices')}
			</Title>
			<CheckupOptionsContainer>
				<PhoneCheckupButton
					key="home"
					data-cy="otherCheckups_home"
					data-pw="otherCheckups_home"
					onClick={() => onClick()}
				>
					<img src={Speedometer} alt={`other home checkup`} />
					<ImageLabel as="p">{t('deviceCheckups')}</ImageLabel>
				</PhoneCheckupButton>
			</CheckupOptionsContainer>
		</>
	);
};
