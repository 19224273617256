import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	useAnalytics,
	AnalyticEventType,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	DiagnosticsCategoryType,
	DiagnosticsPageIds,
	DiagnosticsPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { convertToDecimal } from '@home-diy-toolbox/web/utils';
import { PercentageInput } from '@home-diy-toolbox/web/percentage-input';
import { useFlow } from '../../../../../providers';
import {
	CheckupQuestionsProgress,
	CheckupContainer,
	NextButton,
	QuestionHeader,
	PreviousButton,
} from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { useCheckupPageFlow } from '../../../../../features/checkupFlows';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 2em;
`;

export const BatteryPercentage = (): JSX.Element => {
	const pageId = DiagnosticsPageIds.ANDROID_BATTERY_CHARGE_PERCENTAGE;
	const { t } = useTranslation();
	const history = useHistory();
	const [goNext] = useCheckupPageFlow(
		Checkups.BATTERY,
		DiagnosticsPages.ANDROID_BATTERY_CHARGE_PERCENTAGE
	);
	const { dispatcher } = useAnalytics();
	const { useDiagnosticsContent } = useCheckupContentContext();
	const {
		flow: {
			sessionData: { deviceOS, deviceMake, currentQuestionIndex },
		},
		updateDiagnostics,
		setCurrentQuestionIndex,
		getDiagnosticsByTestId,
	} = useFlow();
	const { questionTitleCopy, nextButtonCopy } = useDiagnosticsContent(
		pageId,
		deviceOS,
		deviceMake
	);

	const enhancedDispatcher = dispatcher.extend(
		withExtras({
			PageId: pageId,
			CheckupType: Checkups.BATTERY,
		})
	);

	const initialValue: number =
		(getDiagnosticsByTestId(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId
		) as number) ?? undefined;
	const percentage = useRef<number | undefined>(initialValue);
	const [isNextEnabled, setIsNextEnabled] = useState<boolean>(
		typeof initialValue !== 'undefined'
	);
	const setPercentage = (value: number) => {
		percentage.current = value;
		setIsNextEnabled(typeof value !== 'undefined');
	};

	const onNext = useCallback(() => {
		updateDiagnostics(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId,
			percentage.current
		);

		const remainingBatteryLife =
			(getDiagnosticsByTestId(
				DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
				DiagnosticsPageIds.ANDROID_REMAINING_BATTERY_LIFE
			) as number) ?? undefined;

		const chargeDuration = convertToDecimal(
			remainingBatteryLife / (percentage.current / 100)
		);

		updateDiagnostics(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			DiagnosticsPageIds.BATTERY_LIFE,
			chargeDuration
		);

		enhancedDispatcher
			.extend(
				withExtras({
					ActionId: `${pageId}_NextButton`,
					BatteryPercentage: percentage.current,
					FullChargeLastsAbout: chargeDuration,
				})
			)
			.dispatch(AnalyticEventType.CLICK);

		setCurrentQuestionIndex(currentQuestionIndex + 1);

		goNext();
	}, [
		currentQuestionIndex,
		enhancedDispatcher,
		getDiagnosticsByTestId,
		goNext,
		pageId,
		setCurrentQuestionIndex,
		updateDiagnostics,
	]);

	const onBackButtonClick = () => {
		history.goBack();
	};

	const handleKeyDown = useCallback(
		(key: string) => key === 'Enter' && isNextEnabled && onNext(),
		[isNextEnabled, onNext]
	);

	useEffect(() => {
		enhancedDispatcher.dispatch(AnalyticEventType.VIEW);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<QuestionHeader data-cy={'questionHeader'}>{questionTitleCopy}</QuestionHeader>
			<PercentageInput
				value={percentage.current}
				setValue={setPercentage}
				aria-label={t('battery_charge_percentage_input')}
				onKeyDown={(e) => handleKeyDown(e.key)}
			/>
			<Container>
				<NextButton onClick={onNext} data-cy="next-button" disabled={!isNextEnabled}>
					{nextButtonCopy}
				</NextButton>
				<PreviousButton onClick={onBackButtonClick} />
			</Container>
		</CheckupContainer>
	);
};
