import React from 'react';
import styled from 'styled-components';
import {
	formatSpeed,
	getProgressData,
	useNetworkTestContext,
} from '@home-diy-toolbox/web/network-test';
import DownloadUploadSpeeds from './DownloadUploadSpeeds';
import { SpeedProgress } from './SpeedProgress';

const SpeedTestContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
`;

const SpeedTestProgressContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 2rem;
`;

export const UploadDownloadTest = () => {
	const { scanStatus, downloadSpeed } = useNetworkTestContext();

	return (
		<SpeedTestContainer>
			<SpeedTestProgressContainer>
				<SpeedProgress
					status={scanStatus}
					progressData={getProgressData(scanStatus, downloadSpeed)}
				/>
				<DownloadUploadSpeeds
					status={scanStatus}
					downloadSpeed={formatSpeed(downloadSpeed)}
				/>
			</SpeedTestProgressContainer>
		</SpeedTestContainer>
	);
};
