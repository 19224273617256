import React, { FunctionComponent, useState } from 'react';
import { union } from 'lodash';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	CheckupPageIds,
	ComponentIds,
	CheckupCategoryType,
	CheckupPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow } from '../../../../providers';
import { removeArrayFromArray } from '@home-diy-toolbox/web/utils';
import {
	CheckupContainer,
	QuestionHeader,
	NextButton,
	CheckupQuestionsProgress,
	AnswerSelectionInstructionsWrapper,
	CheckboxWithLabel,
	PreviousButton,
} from '../../../../components';
import { useCheckupPageFlow } from '../../../../features/checkupFlows';
import { useHistory } from 'react-router-dom';

const NextButtonWrapper = styled(NextButton)`
	margin-top: 48px;
`;

const BatteryIssuesPage: FunctionComponent = () => {
	const [goNext] = useCheckupPageFlow(Checkups.BATTERY, CheckupPages.BATTERY_ISSUES);
	const history = useHistory();
	const pageId = CheckupPageIds.BATTERY_ISSUES;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;
	const {
		flow: {
			sessionData: { currentQuestionIndex, partner },
		},
		setCurrentQuestionIndex,
	} = useFlow();

	const { usePageContent } = useCheckupContentContext();

	const { questionTitleCopy, instructionsCopy, answerOptions, nextButtonCopy } =
		usePageContent(pageId, partner, Checkups.BATTERY);

	const { updateCheckupPage, getCheckupForPageId } = useFlow();
	const { answers: latestAnswers, tags: latestTags } = getCheckupForPageId(
		CheckupCategoryType.BATTERY_CHECKUP,
		pageId
	) || {
		answers: [],
		tags: [],
	};

	const [selectedAnswers, setSelectedAnswers] = useState<string[]>(latestAnswers);

	const [selectedTags, setSelectedTags] = useState<string[]>(latestTags);

	const onAnswerChange = (answerSelected: string, tags: string[] = []) => {
		const answerAlreadyExistsIndex = selectedAnswers.indexOf(answerSelected);
		const indexOfNone = selectedAnswers.indexOf('none');

		if (answerAlreadyExistsIndex > -1) {
			selectedAnswers.splice(answerAlreadyExistsIndex, 1);
			setSelectedAnswers([...selectedAnswers]);

			setSelectedTags(removeArrayFromArray(selectedTags, tags));
		} else {
			if (answerSelected === 'none') {
				setSelectedAnswers([answerSelected]);
				setSelectedTags(tags);
			} else {
				const updatedState =
					indexOfNone > -1 ? [answerSelected] : [...selectedAnswers, answerSelected];
				setSelectedAnswers(updatedState);

				const updatedTags = indexOfNone > -1 ? tags : union(selectedTags, tags);
				setSelectedTags(updatedTags);
			}
		}
	};

	const onNextButtonClick = () => {
		updateCheckupPage(
			CheckupCategoryType.BATTERY_CHECKUP,
			pageId,
			selectedAnswers,
			selectedTags
		);
		setCurrentQuestionIndex(currentQuestionIndex + 1);
		goNext();
	};

	const renderAnswerOptions = () =>
		answerOptions.map((answerOption) => {
			const { id, copy, tags } = answerOption.fields;
			const buttonId = `${pageId}_${id}`;
			return (
				<CheckboxWithLabel
					value={id}
					label={copy}
					name={`${pageId}_answerOptions`}
					key={buttonId}
					data-cy={buttonId}
					checked={selectedAnswers.includes(id.toLowerCase())}
					onChange={() => onAnswerChange(id, tags)}
				/>
			);
		});
	const onBackButtonClick = () => {
		setCurrentQuestionIndex(currentQuestionIndex - 1);
		history.goBack();
	};

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<QuestionHeader>{questionTitleCopy}</QuestionHeader>
			<AnswerSelectionInstructionsWrapper>
				<RichText document={instructionsCopy} />
			</AnswerSelectionInstructionsWrapper>
			{renderAnswerOptions()}
			<NextButtonWrapper
				actionId={nextButtonId}
				data-cy="next-button"
				onClick={onNextButtonClick}
				disabled={selectedAnswers.length === 0}
				analyticExtras={{
					SelectedAnswers: selectedAnswers,
					CheckupType: Checkups.BATTERY,
				}}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
			<PreviousButton onClick={onBackButtonClick} />
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.BATTERY_ISSUES,
	CheckupType: Checkups.BATTERY,
})(BatteryIssuesPage);
