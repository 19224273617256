import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BatteryResultIds } from '@home-diy-toolbox/web/contentful';
import { useFlow } from '../../../../providers';
import {
	initialBatteryChargeAnswer,
	initialMainIssuesAnswer,
	initialOtherIssuesAnswer,
	initialUsageHabitsAnswer,
} from '../answers/initialAnswers';
import { type GenericAndroidAnswers } from '../answers/types';
import {
	getAndroidBatteryResult,
	getBatteryReplacementResult,
	getMainIssuesResult,
	getTags,
} from './utils';
import { useSessionState, useResetSession, usePageStack } from './hooks';
import { articleIds } from './articleIds';
import { IssuePage, ResultPage } from '../pages';
import { useViewCheckup } from './hooks/useViewCheckup';
import { BatteryChargePage } from '../pages/BatteryChargePage';
import { UsageHabitsPage } from '../pages/UsageHabitsPage';
import { ArticlePage } from '../pages/ArticlePage';
import { MagicWandPage } from '../pages/MagicWandPage';
import { useMediaQuery } from '@home-diy-toolbox/web/utils';
import { Size } from '@soluto-private/mx-asurion-ui-react';
import { NoAndroidDeviceWarningDrawer, NoAndroidDeviceWarningModal } from '../components';
import { FlowConfig } from './types';

type Articles = 'batterySamplePage';
type Others = 'magicWand';
type Pages = keyof GenericAndroidAnswers | 'result' | Articles | Others;

export const GenericAndroidBatteryFlow = () => {
	const history = useHistory();
	const resetSession = useResetSession();
	const stack = usePageStack<Pages>(
		['mainIssues'],
		'samsungAndroidBatteryPageStack',
		resetSession
	);
	const [disableContinue, setDisableContinue] = useState(true);

	const { flow: appFlow } = useFlow();

	const [closeTouched, setCloseTouched] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showDrawer, setShowDrawer] = useState(false);

	const isLargeScreen = useMediaQuery(`(min-width: ${Size.MD}px)`);

	useEffect(() => {
		if (closeTouched) return;
		const { deviceModel, deviceMake } = appFlow.sessionData;
		const isUnknownDevice =
			!deviceModel ||
			deviceModel === 'Generic Android' ||
			!deviceMake ||
			deviceMake === 'Android Other';
		if (isUnknownDevice) {
			setShowDrawer(!isLargeScreen);
			setShowModal(isLargeScreen);
		}
	}, [appFlow.sessionData, isLargeScreen, closeTouched]);

	const [tags, setTags] = useState(new Set<string>());

	const [result, setResult] = useSessionState(
		null,
		'samsungAndroidBatteryResult',
		resetSession
	);

	const [answers, setAnswers] = useSessionState<GenericAndroidAnswers>(
		{
			mainIssues: initialMainIssuesAnswer(),
			batteryCharge: initialBatteryChargeAnswer(),
			usageHabits: initialUsageHabitsAnswer(),
			otherIssues: initialOtherIssuesAnswer(),
		},
		'genericAndroidBatteryAnswers',
		resetSession
	);

	const handleSetValue = (
		field: keyof typeof answers,
		{ value, valid, tags: newTags = null }
	) => {
		setDisableContinue(!valid);
		setAnswers((answers) => ({ ...answers, [field]: value }));

		if (newTags) {
			setTags((currTags) => getTags(currTags, newTags, field === 'mainIssues'));
		}
	};

	const getArticleConfig = (articleId: string, pageId: string) => {
		return {
			pageId,
			pageComponent: (
				<ArticlePage
					onFirstRender={() => setDisableContinue(false)}
					articleId={articleId}
					continueButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
		};
	};

	const flow: FlowConfig<Pages> = {
		mainIssues: {
			pageId: 'batteryMainIssue',
			pageComponent: (
				<IssuePage
					index={{ currentIndex: 1, total: 4 }}
					issue={'mainIssue'}
					initialValue={answers.mainIssues}
					onSetValue={(props) => handleSetValue('mainIssues', props)}
					backButtonConfig={{ onClick: () => history.goBack() }}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							const result = getMainIssuesResult(answers.mainIssues);
							if (!result) {
								stack.push('batteryCharge');
								return;
							}

							setResult(result);
							stack.push('result');
						},
					}}
				/>
			),
		},
		batteryCharge: {
			pageId: 'batteryCharge',
			pageComponent: (
				<BatteryChargePage
					index={{ currentIndex: 2, total: 4 }}
					batteryCharge={'androidOthersBatteryCharge'}
					initialValue={answers.batteryCharge}
					onSetValue={(props) => handleSetValue('batteryCharge', props)}
					onGuideClick={() => stack.push('batterySamplePage')}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							if (answers.batteryCharge?.dontHaveOption) {
								setResult(BatteryResultIds.EXTRA_INFORMATION_NEEDED);
								stack.push('result');
								return;
							}

							stack.push('usageHabits');
						},
					}}
					backButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
		},
		usageHabits: {
			pageId: 'batteryUsageHabits',
			pageComponent: (
				<UsageHabitsPage
					index={{ currentIndex: 3, total: 4 }}
					initialValue={answers.usageHabits}
					onSetValue={(props) => handleSetValue('usageHabits', props)}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							stack.push('otherIssues');
						},
					}}
					backButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
		},
		otherIssues: {
			pageId: 'batteryOtherIssues',
			pageComponent: (
				<IssuePage
					index={{ currentIndex: 4, total: 4 }}
					issue={'otherIssues'}
					initialValue={answers.otherIssues}
					onSetValue={(props) => handleSetValue('otherIssues', props)}
					backButtonConfig={{ onClick: () => stack.pop() }}
					continueButtonConfig={{
						onClick: async () => {
							const { deviceMake, deviceModel, eligibility } = appFlow.sessionData;
							const batteryReplacementResult = getBatteryReplacementResult(eligibility);

							stack.push('magicWand');

							try {
								const result = await getAndroidBatteryResult(
									answers.batteryCharge,
									answers.usageHabits,
									{
										make: deviceMake,
										model: deviceModel,
									}
								);

								if (!result.isHealthy) {
									setResult(batteryReplacementResult);
								} else {
									setResult(BatteryResultIds.GOOD_BATTERY);
								}
							} catch (error) {
								setResult(BatteryResultIds.EXTRA_INFORMATION_NEEDED);
							} finally {
								stack.push('result');
							}
						},
					}}
				/>
			),
		},

		result: {
			pageId: 'batteryResult',
			pageComponent: (
				<ResultPage
					resultId={result}
					answerTags={tags}
					backButtonConfig={{
						onClick: () => {
							const lastLastPage = stack.stack[stack.stack.length - 2];
							stack.pop();
							if (lastLastPage === 'magicWand') {
								stack.pop();
							}
						},
					}}
				/>
			),
			extraData: {
				BatteryCheckupResult: result,
			},
		},
		batterySamplePage: getArticleConfig(
			articleIds.batterySamplePage,
			'batterySampleArticle'
		),
		magicWand: {
			pageId: 'batteryMagicWand',
			pageComponent: <MagicWandPage />,
		},
	};

	const currentPageId = stack.peek();
	const currentPage = flow[currentPageId];

	useViewCheckup(currentPage.pageId, currentPage.extraData);

	return (
		<Fragment>
			<NoAndroidDeviceWarningModal
				isOpen={showModal}
				onCloseClick={() => {
					setShowModal(false);
					setCloseTouched(true);
				}}
			/>
			<NoAndroidDeviceWarningDrawer
				isOpen={showDrawer}
				onCloseClick={() => {
					setShowDrawer(false);
					setCloseTouched(true);
				}}
			/>
			{currentPage.pageComponent}
		</Fragment>
	);
};
