import { useEffect } from 'react';
import {
	ArticleLoader,
	FeedbackComponentTypes,
	FeedbackTypes,
} from '../../../../components';
import {
	FeedbackChoicesTypes,
	useLayoutContentContext,
} from '@home-diy-toolbox/web/contentful';

export const ArticleComponent = ({ onFirstRender, articeId }) => {
	const {
		articlePage: { feedbackSectionTitle, positiveFeedbackForm, negativeFeedbackForm },
	} = useLayoutContentContext();

	useEffect(() => {
		onFirstRender();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ArticleLoader
			articleId={articeId}
			feedbackSectionProps={{
				variant: FeedbackComponentTypes.STARS,
				type: FeedbackTypes.ARTICLE,
				feedbackTitle: feedbackSectionTitle,
				positiveForm: {
					...positiveFeedbackForm,
					feedbackChoicesType: FeedbackChoicesTypes.NEW,
				},
				negativeForm: {
					...negativeFeedbackForm,
					feedbackChoicesType: FeedbackChoicesTypes.NEW,
				},
			}}
		/>
	);
};
