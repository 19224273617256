import { Button } from '@home-diy-toolbox/web/contentful';
import { Drawer } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { ActionButton } from '../atoms/ActionButton/ActionButton';
import {
	AnalyticEventType,
	AnalyticExtras,
	useDispatchEvent,
} from '@home-diy-toolbox/web/analytics';
import { ContactExpertButtonRenderer } from '../atoms/ActionButton/ContactExpertButtonRenderer';
import { ChatButtonHider } from './ChatButtonHider';

const DrawerButtons = styled.div`
	grid-gap: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ActionButtonContainer = styled.div`
	width: 20.625rem;

	@media (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		width: 90%;
	}
`;

const buttonRenderer = new ContactExpertButtonRenderer();

interface Props {
	title: string;
	cardCtas: Button[];
	isOpen: boolean;
	onOpenUpdate: (isOpen: boolean) => void;
	analyticExtras?: AnalyticExtras;
}
export const ContactExpertsDrawer = ({
	title,
	cardCtas,
	isOpen,
	onOpenUpdate,
	analyticExtras = {},
}: Props) => {
	const { dispatchEvent } = useDispatchEvent();

	return (
		<Drawer title={title} onOpenUpdate={(open) => onOpenUpdate(open)} open={isOpen}>
			{isOpen ? <ChatButtonHider /> : null}
			<DrawerButtons>
				<ActionButtonContainer>
					<ActionButton
						button={cardCtas[0]}
						buttonRenderer={buttonRenderer}
						onClick={() => {
							onOpenUpdate(false);
							dispatchEvent(AnalyticEventType.CLICK, {
								ActionId: 'batteryResultsLiveExpertCardCTA' + cardCtas[0]?.action,
								...analyticExtras,
							});
						}}
					/>
				</ActionButtonContainer>
				<ActionButtonContainer>
					<ActionButton
						button={cardCtas[1]}
						buttonRenderer={buttonRenderer}
						onClick={() => {
							onOpenUpdate(false);
							dispatchEvent(AnalyticEventType.CLICK, {
								ActionId: 'batteryResultsLiveExpertCardCTA' + cardCtas[1]?.action,
								...analyticExtras,
							});
						}}
					/>
				</ActionButtonContainer>
			</DrawerButtons>
		</Drawer>
	);
};
