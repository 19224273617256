import React from 'react';
import styled from 'styled-components';
import { colors } from '@soluto-private/mx-asurion-ui-react';

const InputStyle = styled.input`
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 3.5rem;
	padding-left: 0.875rem;
	border: 1px solid ${colors.neutralDeep};
	border-radius: 0.5rem;
	font-size: 1rem;
	font-family: inherit;

	&:focus {
		border-color: transparent;
		outline-color: ${colors.black};
	}
`;

export const Input = ({ value, placeholder, onChange }) => {
	return (
		<InputStyle
			value={value}
			placeholder={placeholder}
			onChange={(e) => onChange(e.target.value)}
		/>
	);
};
