import { Context, createContext, FunctionComponent, useContext, useState } from 'react';
import { Checkups, ResultPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow } from '../flows';
import { ResultsContextType } from './type';
import {
	CheckupResults,
	SupportedCheckupResults,
} from '../../features/checkupResults/configs';
import { CHECKUP_CALCULATIONS } from '../../features/checkupResults/configs';

const defaultResults: CheckupResults = {
	[Checkups.BATTERY]: {
		pageId: ResultPageIds.BATTERY_RESULT_DEFAULT,
		isHazardousDamage: undefined,
		isBadOrIndeterminate: undefined,
		isNonAllowableDamage: undefined,
		isFrontGlassBroken: undefined,
		isBackGlassBroken: undefined,
		isPlanAndDeviceEligible: undefined,
		sentiment: undefined,
	},
	[Checkups.SIGNAL]: {
		pageId: ResultPageIds.SIGNAL_RESULT_DEFAULT,
		signalTestResult: undefined,
		downloadSpeed: undefined,
	},
	[Checkups.SPEED]: {
		pageId: ResultPageIds.SPEED_RESULT_DEFAULT,
	},
};

const defaultResultsContext: ResultsContextType = {
	resultsCalculated: false,
	resultsData: defaultResults,
	calculateResults: () => undefined,
};

const ResultsContext: Context<ResultsContextType> = createContext(defaultResultsContext);
ResultsContext.displayName = 'Results';

/** Gets the calculate results function for the results provider */
export const useResultsCalc = () => useContext(ResultsContext).calculateResults;

type CalculateResultsFunc = <TCheckup extends SupportedCheckupResults>(
	checkup: TCheckup
) => Promise<void>;

/** The return type of useResults when a checkup type is provided */
type UseResultsCheckupReturn<TCheckup extends SupportedCheckupResults> = {
	resultsData: CheckupResults[TCheckup];
	calculateResults: CalculateResultsFunc;
	resultsCalculated: boolean;
};

type UseResultsFunc = {
	(): ResultsContextType;
	<TCheckup extends SupportedCheckupResults>(
		checkup: TCheckup
	): UseResultsCheckupReturn<TCheckup>;
};

/** When a checkup type is provided, it will attempt to calculate checkup results if no results have been calculated yet */
// @ts-expect-error Type narrowing & inference doesn't seem to be working for the function signature validation?
export const useResults: UseResultsFunc = <
	TCheckup extends SupportedCheckupResults | never
>(
	checkup?: TCheckup
) => {
	const resultsContext = useContext(ResultsContext);

	if (!checkup) {
		return resultsContext;
	}

	if (!resultsContext.resultsCalculated) {
		resultsContext.calculateResults(checkup);
	}

	return {
		resultsData: resultsContext.resultsData[checkup],
		calculateResults: resultsContext.calculateResults,
		resultsCalculated: resultsContext.resultsCalculated,
	};
};

export const ResultsProvider: FunctionComponent = ({ children }) => {
	const [resultsCalculated, setResultsCalculated] = useState(false);
	const [resultsData, setResultsData] = useState(defaultResults);
	const { flow } = useFlow();

	const calculateResults = async <TCheckup extends SupportedCheckupResults>(
		checkup: TCheckup
	) => {
		switch (checkup) {
			case Checkups.BATTERY: {
				const results = await CHECKUP_CALCULATIONS.battery(flow);
				setResultsData({
					...resultsData,
					battery: results,
				});
				break;
			}
			case Checkups.SIGNAL: {
				const results = await CHECKUP_CALCULATIONS.signal(flow);
				setResultsData({
					...resultsData,
					signal: results,
				});
				break;
			}
			case Checkups.SPEED: {
				const results = await CHECKUP_CALCULATIONS.speed();
				setResultsData({
					...resultsData,
					speed: results,
				});
				break;
			}
			default:
				throw new Error(`Unsupported Checkup type: ${checkup}`);
		}

		setResultsCalculated(true);
	};

	const value = {
		resultsCalculated,
		resultsData,
		calculateResults: calculateResults,
	};

	return <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>;
};
