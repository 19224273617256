export const TabletIcon = (): JSX.Element => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9 10.999C9.55228 10.999 10 10.5513 10 9.99902C10 9.44674 9.55228 8.99902 9 8.99902C8.44772 8.99902 8 9.44674 8 9.99902C8 10.5513 8.44772 10.999 9 10.999Z"
			fill="black"
		/>
		<path
			d="M10 12.999C10 13.5513 9.55228 13.999 9 13.999C8.44772 13.999 8 13.5513 8 12.999C8 12.4467 8.44772 11.999 9 11.999C9.55228 11.999 10 12.4467 10 12.999Z"
			fill="black"
		/>
		<path
			d="M12 10.999C12.5523 10.999 13 10.5513 13 9.99902C13 9.44674 12.5523 8.99902 12 8.99902C11.4477 8.99902 11 9.44674 11 9.99902C11 10.5513 11.4477 10.999 12 10.999Z"
			fill="black"
		/>
		<path
			d="M16 9.99902C16 10.5513 15.5523 10.999 15 10.999C14.4477 10.999 14 10.5513 14 9.99902C14 9.44674 14.4477 8.99902 15 8.99902C15.5523 8.99902 16 9.44674 16 9.99902Z"
			fill="black"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18 2.00098H6C4.897 2.00098 4 2.89698 4 4.00098V20.001C4 21.105 4.897 22.001 6 22.001H18C19.103 22.001 20 21.105 20 20.001V4.00098C20 2.89698 19.103 2.00098 18 2.00098ZM18 4.00098V5.00098H6V4.00098H18ZM18 7.00098L18.001 17.001H6V7.00098H18ZM6 20.001V19.001H18.001V20.001H6Z"
			fill="black"
		/>
	</svg>
);
