const titleCase = (text: string) => text[0].toUpperCase() + text.slice(1);

export const formatAnalyticsObject = (
	dataObject: Record<string, unknown>
): Record<string, unknown> => {
	let formattedObject = {};
	try {
		for (const [key, value] of Object.entries(dataObject)) {
			if (typeof value === 'string') {
				formattedObject[titleCase(key)] = value;
			} else if (value && typeof value === 'object') {
				const nestedObject = formatAnalyticsObject(value as Record<string, unknown>);
				formattedObject = { ...formattedObject, ...nestedObject };
			}
		}
	} catch (error) {
		console.error('error in data format');
	}

	return formattedObject;
};
