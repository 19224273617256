import { ThemeType } from './ThemeType';
import { colors, useTheme } from '@soluto-private/mx-asurion-ui-react';
import { useMemo } from 'react';

const defaultBreakPoints = {
	mobile: '480px',
	tablet: '744px',
	desktop: '1024px',
	desktopLarge: '1200px',
};

const defaultQuestionTheme = {
	textColor: colors.black,
};

const defaultAnswerOptionTheme = {
	textColor: colors.black,
	borderColor: colors.neutralBright,
	backgroundColor: colors.white,
	hoverBorderColor: colors.black,
	checkedBorderColor: colors.black,
	checkedColor: colors.white,
	checkedBackgroundColor: colors.black,
};

const defaultPreviousButtonTheme = {
	textColor: colors.black,
	backgroundColor: colors.black,
	borderColor: colors.black,
};

const defaultCheckSignalAgainButtonTheme = {
	textColor: colors.black,
	backgroundColor: colors.white,
	borderColor: colors.neutralBright,
};

const defaultFeedback = {
	thumbsDefaultColor: colors.black,
	thumbsHoverBackground: colors.neutralBrightest,
	thumbsPressedColor: colors.neutralDeep,
	thumbsPressedBackground: colors.neutralBrighter,
	thumbsDisabledColor: colors.neutralBrighter,
	thumbsDisabledBackground: colors.neutralBrighter,
	starActiveColor: colors.warningBright,
	starActiveStrokeColor: '#8A5300',
	starInactiveColor: colors.neutralBrightest,
	starInactiveStrokeColor: colors.neutralDeeper,
	pillActiveColor: colors.black,
	pillInactiveColor: colors.neutralDeep,
	commentsBorderColor: colors.neutralDeeper,
	commentsBackgroundColor: colors.white,
};

const defaultModal = {
	modalBackdropColor: colors.black,
};

const defaultSignalTest = {
	progressStroke: colors.black,
	resultDialColorIdeal: colors.brandGreenDeep,
	resultDialColorAlmostIdeal: colors.successBright,
	resultDialColorLessThanIdeal: colors.warningBright,
	resultDialColorPoor: colors.errorBright,
	fontColorMain: colors.black,
	idealSignalLabelBackground: colors.neutralBrightest,
};

const defaultArticle = {
	subtextBackgroundColor: colors.neutralBrightest,
	externalLinkColor: colors.primaryDeep,
	ctaButton: {
		color: colors.white,
		backgroundColor: colors.black,
	},
};

export const defaultTheme: ThemeType = {
	appMaxWidth: '540px',
	minWidth: '300px',
	defaultBackgroundColor: colors.white,
	dividerBackgroundColor: colors.neutralBrighter,
	disabledTextColor: colors.disabledText,
	disabledBackgroundColor: colors.neutralBrighter,
	defaultFocusedBorderColor: colors.black,
	breakpoints: defaultBreakPoints,
	questions: defaultQuestionTheme,
	answerOptions: defaultAnswerOptionTheme,
	previousButton: defaultPreviousButtonTheme,
	checkSignalAgainButtonType: defaultCheckSignalAgainButtonTheme,
	feedback: defaultFeedback,
	modal: defaultModal,
	signalTest: defaultSignalTest,
	article: defaultArticle,
} as const;

export const useBlendedTheme = () => {
	const asurionUITheme = useTheme();
	return useMemo(() => {
		const deviceCareAppTheme = defaultTheme;

		return { ...deviceCareAppTheme, ...asurionUITheme };
	}, [asurionUITheme]);
};
