import styled from 'styled-components';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import {
	AnalyticEventType,
	AnalyticViewType,
	useAnalytics,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import {
	GenericPageIds,
	Partners,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFlow } from '../../providers';
import {
	useChatOnlyPagePrompts,
	useValidateMxContext,
} from '@home-diy-toolbox/web/contentful';
import { getChatClient } from '../../features/chat/components';

const ExpertSetupContainer = styled.div`
	display: flex;
	flex-direction: column;
	color: ${colors.black};
	align-items: flex-start;
	margin: 0 auto;
	max-width: 600px;
	width: 100%;
	height: 70vh;
	border: 3px solid ${colors.neutralBrighter};
	border-radius: 8px;
`;

export const ExpertChatPage = () => {
	const analytics = useAnalytics();
	const history = useHistory();
	const { client } = useValidateMxContext();
	const chatOnlyPagePrompts = useChatOnlyPagePrompts();

	const {
		flow: {
			sessionData: { utm },
		},
	} = useFlow();

	useEffect(() => {
		const enabledPartners = {
			[Partners.ATT]: true,
			[Partners.VERIZON]: true,
		};

		if (!enabledPartners[client]) {
			history.push('/');
			return;
		}

		const chatClient = getChatClient();
		const chatSender = chatClient.createSender();

		chatClient.attachToContainer('chat-sdk-container').then(() => {
			if (!utm) return;

			if (utm === 'vz_troubleshoot') {
				chatSender.sendVZTroubleshootMessages(client);
			} else {
				const prompt = chatOnlyPagePrompts?.find(({ utmValues }) =>
					utmValues.includes(utm)
				)?.prompt;

				if (prompt) {
					chatSender.sendPromptMessage(prompt);
				}
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [client, utm, chatOnlyPagePrompts]);

	useEffect(
		() =>
			analytics.dispatcher
				.extend(
					withExtras({
						PageId: GenericPageIds.CHAT_ONLY,
						ComponentType: AnalyticViewType.PAGE,
					})
				)
				.dispatch(AnalyticEventType.VIEW),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return <ExpertSetupContainer id="chat-sdk-container" />;
};
