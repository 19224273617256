import React from 'react';
import styled from 'styled-components';
import { getFontSize } from '@soluto-private/mx-asurion-ui-react';

const Container = styled.div`
	margin-bottom: 8px;
	h1 {
		text-align: left;
		font-weight: 300;
		font-size: ${getFontSize(5)};
		line-height: 120%;
		margin: 0 auto;

		@media screen and (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
			font-size: 32px;
			line-height: 120%;
		}
	}
`;

export const ResultPageHeader: React.FunctionComponent = ({ children, ...others }) => (
	<Container tabIndex={-1} {...others}>
		{children}
	</Container>
);
