import React, { Suspense, useMemo } from 'react';
import styled from 'styled-components';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';
import { CheckupContainer, NextButton } from '../../components';
import ErrorIcon from '../../assets/error.png';
import { createI18n } from '@soluto-private/mx-i18n';
import { errorTranslations } from './translations';
import {
	colors,
	getFontSize,
	getFontWeight,
	Text,
} from '@soluto-private/mx-asurion-ui-react';

//creates new i18n client to serve local translations
const createI18nClient = (language: string) => {
	const instance = createI18n();

	instance.init({
		init: {
			interpolation: {
				escapeValue: false,
			},
			resources: errorTranslations,
			lng: language,
			fallbackLng: 'en-US',
			load: 'currentOnly',
			backend: {},
		},
	});

	return instance.getClient();
};

const Container = styled(CheckupContainer)`
	align-items: center;
`;

const Img = styled.img`
	max-width: 150px;
	max-height: 100px;
	margin-bottom: 20px;
`;

const Header = styled(Text)`
	font-weight: ${getFontWeight('feather')};
	font-size: ${getFontSize(5)};
	text-align: center;
	margin: 0.5rem 0;
`;

const Paragraph = styled(Text)`
	font-weight: ${getFontWeight('feather')};
	text-align: center;
`;

const TryAgainButton = styled(NextButton)`
	margin-top: 60px;
	max-width: 450px;
	background-color: ${colors.black};
	color: ${colors.white};
`;

export const ErrorPage = () => {
	const { language } = useValidateMxContext();

	const i18n = useMemo(() => createI18nClient(language), [language]);

	const onTryAgainClick = () => window.location.reload();

	const buttonId = `${GenericPageIds.ERROR_PAGE}TryAgainButton`;

	return (
		<Suspense fallback={null}>
			<Container>
				<Img src={ErrorIcon} alt={i18n.t('ErrorIconAlt')} />
				<Header>{i18n.t('ErrorPageHeader')}</Header>
				<Paragraph>{i18n.t('ErrorPageParagraph')}</Paragraph>
				<TryAgainButton data-test-cy={buttonId} onClick={onTryAgainClick}>
					{i18n.t('ErrorPageTryAgainButton')}
				</TryAgainButton>
			</Container>
		</Suspense>
	);
};
