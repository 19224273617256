import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { RichText, useCheckupPage } from '@home-diy-toolbox/web/contentful';
import {
	CheckupCategoryType,
	CheckupPageIds,
	CheckupResultPathnames,
	ComponentIds,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow, useResults } from '../../../../providers';
import {
	CheckupContainer,
	QuestionHeader,
	NextButton,
	CheckupQuestionsProgress,
	RadioButtonWithLabel,
	AnswerSelectionInstructionsWrapper,
} from '../../../../components';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';
import { QuestionBlocks } from '../SpeedIssueFrequencyPage/SpeedIssueFrequencyPage';

const NextButtonWrapper = styled(NextButton)`
	margin-top: 56px;
`;

const SpeedIssueOccurrencePage: FunctionComponent = () => {
	const pageId = CheckupPageIds.SPEED_ISSUE_OCCURRENCE;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;

	const history = useHistory();

	const { updateCheckupPage, getCheckupForPageId } = useFlow();

	const { calculateResults } = useResults();

	const { answers: latestAnswers, tags: latestTags } = getCheckupForPageId(
		CheckupCategoryType.SPEED_CHECKUP,
		pageId
	) || {
		answers: [],
		tags: [],
	};

	const [selectedAnswer, setSelectedAnswer] = useState<string[]>(latestAnswers);
	const [selectedTags, setSelectedTags] = useState<string[]>(latestTags);

	const pageContent = useCheckupPage('speedCheckupPage');

	const onAnswerChange = (answerSelected: string, tags: string[] = []) => {
		setSelectedAnswer([answerSelected]);
		setSelectedTags(tags);
	};

	const onNextButtonClick = () => {
		updateCheckupPage(
			CheckupCategoryType.SPEED_CHECKUP,
			pageId,
			selectedAnswer,
			selectedTags
		);

		calculateResults(Checkups.SPEED);
		history.push(CheckupResultPathnames.SPEED_RESULT_LOADING, {
			from: history.location.pathname,
		});
	};

	if (!pageContent) return <PageLoader />;

	const {
		speed_occurrence_question: { question, questionDescription, answers },
	} = pageContent.blocks.reduce((speedBlocks, block) => {
		if ('speed_occurrence_question'.includes(block.entryName)) {
			speedBlocks[block.entryName] = block;
		}
		return speedBlocks;
	}, {} as QuestionBlocks);

	const renderAnswerOptions = () =>
		answers.map(({ text: copy, entryName: answerId, tags }) => {
			const buttonId = `${pageId}_${answerId}`;
			return (
				<RadioButtonWithLabel
					value={answerId}
					label={copy}
					name={`${pageId}_answerOptions`}
					key={buttonId}
					data-cy={buttonId}
					checked={selectedAnswer.includes(answerId)}
					onChange={() => onAnswerChange(answerId, tags)}
				/>
			);
		});

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress currQuestion={3} checkupType={Checkups.SPEED} />
			<QuestionHeader>{question}</QuestionHeader>
			<AnswerSelectionInstructionsWrapper>
				<RichText document={questionDescription} />
			</AnswerSelectionInstructionsWrapper>
			{renderAnswerOptions()}
			<NextButtonWrapper
				onClick={onNextButtonClick}
				disabled={!selectedAnswer.length}
				actionId={nextButtonId}
				data-cy="next-button"
				analyticExtras={{
					SelectedAnswers: selectedAnswer,
					CheckupType: Checkups.SPEED,
				}}
			>
				{pageContent.ctas.next.copy}
			</NextButtonWrapper>
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.SPEED_ISSUE_OCCURRENCE,
	CheckupType: Checkups.SPEED,
})(SpeedIssueOccurrencePage);
