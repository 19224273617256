export const homePageQuery = `
query HomePageCollection ($partner: String!, $locale: String!) {
  homePageCollection(limit: 1, where: {partner: $partner}, locale: $locale) {
    items {
      name
      partner
      designType
      heroTitle
      heroTitle2
      heroSubtitle
      servicesTitle
      servicesSubtitle
      guidesSectionTitles
      inStoreServicesTitle
      inStoreServicesSubtitle
      chatCtaCardTitle
      chatCtaPrompts
      heroCtasCollection {
        items {
          entryName
          copy
          url
          telephoneNumber
          action
        }
      }
      heroImage {
        url
      }
      servicesCollection {
        items {
          id
          checkupType
          title
          description
          action
          location
          tags
          cover {
            url
          }
        }
      }
      guidesSectionFiltersCollection {
        items {
          id
          label
          url
          values
        }
      }
      inStoreServicesCollection {
        items {
          id
          checkupType
          title
          description
          action
          location
          tags
          cover {
            url
          }
        }
      }
    }
  }
}
`;
