import React, { useCallback, useEffect } from 'react';
import {
	useAnalytics,
	AnalyticEventType,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	DiagnosticsCategoryType,
	DiagnosticsPageIds,
	DiagnosticsPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow } from '../../../../../providers';
import { CheckupQuestionsProgress, CheckupContainer } from '../../../../../components';
import { BatteryCharge } from '../BatteryCharge';
import { DurationChangeEvent } from '@home-diy-toolbox/web/duration-picker';
import { useCheckupPageFlow } from '../../../../../features/checkupFlows';
import { useHistory } from 'react-router-dom';

export const RemainingBatteryLife = (): JSX.Element => {
	const pageId = DiagnosticsPageIds.ANDROID_REMAINING_BATTERY_LIFE;
	const { dispatcher } = useAnalytics();
	const history = useHistory();
	const [goNext, skipPage] = useCheckupPageFlow(
		Checkups.BATTERY,
		DiagnosticsPages.ANDROID_REMAINING_BATTERY_LIFE
	);
	const { useDiagnosticsContent } = useCheckupContentContext();
	const {
		flow: {
			sessionData: { deviceOS, deviceMake, currentQuestionIndex, partner },
		},
		updateDiagnostics,
		getDiagnosticsByTestId,
	} = useFlow();
	const content = useDiagnosticsContent(
		pageId,
		deviceOS,
		deviceMake,
		partner,
		Checkups.BATTERY
	);

	const enhancedDispatcher = dispatcher.extend(
		withExtras({
			PageId: pageId,
			CheckupType: Checkups.BATTERY,
		})
	);

	const onNext = useCallback(
		({
			value: duration,
			totalDurationInHours,
		}: Omit<DurationChangeEvent, 'isDurationReady'>) => {
			updateDiagnostics(
				DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
				`${pageId}_duration`,
				JSON.stringify(duration)
			);
			updateDiagnostics(
				DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
				pageId,
				totalDurationInHours
			);

			enhancedDispatcher
				.extend(withExtras({ ActionId: `${pageId}_NextButton` }))
				.dispatch(AnalyticEventType.CLICK);

			goNext();
		},
		[enhancedDispatcher, goNext, pageId, updateDiagnostics]
	);

	const onSkip = useCallback(
		({ value }: Pick<DurationChangeEvent, 'value'>) => {
			updateDiagnostics(
				DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
				`${pageId}_duration`,
				JSON.stringify(value)
			);

			enhancedDispatcher
				.extend(withExtras({ ActionId: `${pageId}_SkipButton` }))
				.dispatch(AnalyticEventType.CLICK);

			skipPage();
		},
		[enhancedDispatcher, pageId, skipPage, updateDiagnostics]
	);
	const onBackButtonClick = () => {
		history.goBack();
	};
	const onModalOpen = () => {
		enhancedDispatcher
			.extend(
				withExtras({
					ActionId: `${pageId}_ModalOpen`,
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	};

	const onModalExit = () => {
		enhancedDispatcher
			.extend(
				withExtras({
					ActionId: `${pageId}_ModalClose`,
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	};

	const initialDuration = JSON.parse(
		(getDiagnosticsByTestId(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			`${pageId}_duration`
		) as string) || '{}'
	);

	useEffect(() => {
		enhancedDispatcher.dispatch(AnalyticEventType.VIEW);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<BatteryCharge
				{...content}
				initialDuration={initialDuration}
				onNext={onNext}
				onSkip={onSkip}
				onBack={onBackButtonClick}
				onModalOpen={onModalOpen}
				onModalExit={onModalExit}
				skipIncrement={true}
			/>
		</CheckupContainer>
	);
};
