import React, { useCallback, useState } from 'react';
import QuestionIcon from '../../../../../assets/question.svg';
import { useTranslation } from 'react-i18next';
import { RichText, Page } from '@home-diy-toolbox/web/contentful';
import {
	Duration,
	DurationChangeEvent,
	DurationPicker,
} from '@home-diy-toolbox/web/duration-picker';
import styled from 'styled-components';
import { NextButton, PreviousButton, QuestionHeader } from '../../../../../components';
import { Modal, Button } from '@soluto-private/mx-asurion-ui-react';
import { useFlow } from '../../../../../providers';

const Instructions = styled.div`
	margin-bottom: 1.5rem;

	& > p {
		margin-top: 0;
		line-height: 24px;
		display: inline;
	}
`;

const Icon = styled.img`
	margin-left: 4px;
	border: none;
	background: none;
	cursor: pointer;
	vertical-align: text-top;
`;

const SkipButton = styled(Button)`
	padding: 0;
	min-height: 0;
	margin-top: 16px;
`;

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > img {
		width: 90%;
		margin-top: 10px;
	}

	& > p {
		font-size: 20px;
		line-height: 30px;
		font-weight: bold;
		margin-bottom: 0;

		@media screen and (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
			font-size: 24px;
			line-height: 32px;
		}
	}
`;

const DurationPickerContainer = styled.div`
	margin-bottom: 24px;
`;

type PageContent = Pick<
	Page,
	| 'questionTitleCopy'
	| 'instructionsCopy'
	| 'nextButtonCopy'
	| 'skipButtonCopy'
	| 'modalTitle'
	| 'modalContent'
	| 'questionCopy'
>;

interface PageProps {
	initialDuration: Duration;
	onNext: (event: Omit<DurationChangeEvent, 'isDurationReady'>) => void;
	onSkip?: (event: Pick<DurationChangeEvent, 'value'>) => void;
	onModalOpen?: VoidFunction;
	onModalExit?: VoidFunction;
	onBack: () => void;
	skipIncrement?: boolean;
}

export const BatteryCharge = ({
	questionCopy,
	instructionsCopy,
	nextButtonCopy,
	skipButtonCopy,
	modalTitle,
	modalContent,
	initialDuration,
	onNext,
	onSkip,
	onBack,
	onModalOpen,
	onModalExit,
	skipIncrement = false,
}: PageContent & PageProps): JSX.Element => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isNextEnabled, setIsNextEnabled] = useState<boolean>(false);
	const [duration, setDuration] = useState<Duration>(initialDuration);
	const [totalDurationInHours, setTotalDurationInHours] = useState<number>(0);
	const [isDefaultDuration, setIsDefaultDuration] = useState<boolean>(true);
	const { flow, setCurrentQuestionIndex } = useFlow();
	const { t } = useTranslation();

	const handleModalOpen = useCallback(() => {
		setIsModalOpen(true);
		onModalOpen?.();
	}, [onModalOpen]);

	const handleModalExit = useCallback(() => {
		setIsModalOpen(false);
		onModalExit?.();
	}, [onModalExit]);

	const handleMoreInfoKeyDown = useCallback(
		({ key }: React.KeyboardEvent<HTMLImageElement>) =>
			key === 'Enter' && handleModalOpen(),
		[handleModalOpen]
	);

	const handleDurationChanged = useCallback(
		({
			isDurationReady,
			value,
			totalDurationInHours,
			isDefaultDuration,
		}: DurationChangeEvent) => {
			setDuration(value);
			setTotalDurationInHours(totalDurationInHours);
			setIsDefaultDuration(isDefaultDuration);
			setIsNextEnabled(() => isDurationReady);
		},
		[]
	);

	const handleSkipClick = useCallback(() => {
		onSkip?.({ value: duration });
	}, [duration, onSkip]);

	const handleNextClick = useCallback(() => {
		onNext({ value: duration, totalDurationInHours, isDefaultDuration });
		!skipIncrement && setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex + 1);
	}, [
		duration,
		flow.sessionData.currentQuestionIndex,
		isDefaultDuration,
		onNext,
		setCurrentQuestionIndex,
		totalDurationInHours,
		skipIncrement,
	]);

	const handleKeyDown = useCallback(
		(key: string) =>
			key === 'Enter' &&
			(isNextEnabled && !isDefaultDuration
				? onNext({ value: duration, totalDurationInHours, isDefaultDuration })
				: onSkip?.({ value: duration })),
		[duration, isDefaultDuration, isNextEnabled, onNext, onSkip, totalDurationInHours]
	);

	return (
		<>
			<QuestionHeader data-cy={'questionHeader'}>
				<RichText document={questionCopy} />
			</QuestionHeader>
			<Instructions>
				<RichText document={instructionsCopy} />
				{onModalOpen && (
					<Icon
						onClick={handleModalOpen}
						onKeyDown={handleMoreInfoKeyDown}
						src={QuestionIcon}
						alt={t('questionMarkIconName')}
						tabIndex={0}
					/>
				)}
				{isModalOpen && (
					<Modal isOpen title={modalTitle} onCloseClick={handleModalExit}>
						<ModalContainer>
							<RichText document={modalContent} />
						</ModalContainer>
					</Modal>
				)}
			</Instructions>
			<DurationPickerContainer>
				<DurationPicker
					initialDuration={initialDuration}
					onDurationChanged={handleDurationChanged}
					onKeyDown={(e) => handleKeyDown(e.key)}
				/>
			</DurationPickerContainer>
			<NextButton
				onClick={handleNextClick}
				data-cy="next-button"
				disabled={!isNextEnabled}
			>
				{nextButtonCopy}
			</NextButton>
			{onSkip && skipButtonCopy && (
				<SkipButton
					variant="outline"
					color="secondary"
					type="button"
					onClick={handleSkipClick}
					data-cy="skip-button"
				>
					{skipButtonCopy}
				</SkipButton>
			)}
			<PreviousButton onClick={onBack} />
		</>
	);
};
