import { useMemo } from 'react';
import type { RadioGroupSliderProps } from './types';
import { Slider } from './elements/slider';
import { RadioToggle } from './elements/radio-toggle';
import { useGroupState, useCheckIsFocus } from './hooks/use-group-state';

export const RadioGroupSlider = ({
	group,
	radioValues,
	initialValue,
	buttonSize = 8,
	onSelected,
}: RadioGroupSliderProps): JSX.Element => {
	const { setIsFocus } = useCheckIsFocus();

	const values = useMemo(
		() =>
			radioValues.map((props, index) => ({
				...props,
				index,
				id: `${group}-${index}`,
			})),
		[group, radioValues]
	);

	const {
		checkedValue,
		checkedValueIndex,
		focusedIndex,
		isAnyChecked,
		resetFocus,
		...events
	} = useGroupState(values, onSelected, initialValue);

	return (
		<Slider
			role="radiogroup"
			aria-label={group}
			radioCount={radioValues.length}
			checkedValueIndex={checkedValueIndex}
			isAnyChecked={isAnyChecked}
			buttonSize={buttonSize}
			onBlur={(e) => {
				resetFocus(e);
				setIsFocus(false);
			}}
			onFocus={() => setIsFocus(true)}
		>
			{values.map(({ id, index, ...rest }) => {
				return (
					<RadioToggle
						key={`key-${id}`}
						id={id}
						index={index}
						shouldFocus={focusedIndex === index}
						isChecked={checkedValueIndex === index}
						{...events}
						{...rest}
					/>
				);
			})}
		</Slider>
	);
};
