import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	margin-top: 16px 0px;
	padding: 24px 16px 16px;
	text-align: center;
`;

const MinHeightContainer = styled(Container)`
	min-height: 190px;
`;

export const ImageContainer: React.FunctionComponent = ({ children }) => (
	<Container>{children}</Container>
);

export const QuestionImageContainer: React.FunctionComponent = ({ children }) => (
	<MinHeightContainer>{children}</MinHeightContainer>
);
