import { AnswerType, AppFlowData } from '../../../providers/flows';
import {
	CheckupPageIds,
	CheckupPages,
	CheckupQuestionPathnames,
	CheckupResultKeys,
	DeviceOS,
	DiagnosticsPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { RESULT_PAGES } from '../constants';
import { FlowPagesConfig } from '../types';
import {
	isBackGlassBroken,
	isBadOrIndeterminate,
	isFrontGlassBroken,
	isHazardousDamage,
} from '../../checkupResults/calculations/battery';

/** Definitions for the next() function params keyed to the page it's evaluated on */
export type BatteryFlowPageNextParams = {
	[CheckupPages.PHONE_CHARGER]: { deviceOS: DeviceOS };
	[DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT]: {
		isDefaultDuration: boolean;
	};
	[DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_APPROXIMATELY]: {
		isDefaultDuration: boolean;
	};
	[CheckupPages.PHONE_DAMAGE]: AnswerType & { deviceOS: DeviceOS };
	[DiagnosticsPages.IOS_BATTERY_MESSAGES]: { flow: AppFlowData };
	[DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS]: { flow: AppFlowData };
};

export const BatteryCheckupFlow: FlowPagesConfig<BatteryFlowPageNextParams> = {
	start: CheckupQuestionPathnames.BATTERY_LIFE,
	[CheckupPages.BATTERY_LIFE]: {
		next: CheckupPages.BATTERY_ISSUES,
	},
	[CheckupPages.BATTERY_ISSUES]: {
		next: CheckupPages.PHONE_DAMAGE,
	},
	[CheckupPages.PHONE_DAMAGE]: {
		next: CheckupPages.PHONE_CHARGER,
	},
	[CheckupPages.PHONE_CHARGER]: {
		next: ({ deviceOS }) => {
			switch (deviceOS) {
				case DeviceOS.IOS:
					return DiagnosticsPages.IOS_BATTERY_MAX_CAPACITY;
				case DeviceOS.ANDROID:
					return DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT;
				default:
					return RESULT_PAGES.battery; //TODO: Make constant, logic to trigger calculateResult()?
			}
		},
	},
	[DiagnosticsPages.IOS_BATTERY_MAX_CAPACITY]: {
		next: DiagnosticsPages.IOS_BATTERY_MESSAGES,
	},
	[DiagnosticsPages.IOS_BATTERY_MESSAGES]: {
		next: RESULT_PAGES.battery,
	},
	[DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT]: {
		next: ({ isDefaultDuration }) => {
			if (isDefaultDuration) {
				return DiagnosticsPages.ANDROID_REMAINING_BATTERY_LIFE;
			}

			return DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS;
		},
		skip: DiagnosticsPages.ANDROID_REMAINING_BATTERY_LIFE,
	},
	[DiagnosticsPages.ANDROID_REMAINING_BATTERY_LIFE]: {
		next: DiagnosticsPages.ANDROID_BATTERY_CHARGE_PERCENTAGE,
	},
	[DiagnosticsPages.ANDROID_BATTERY_CHARGE_PERCENTAGE]: {
		next: DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS,
	},
	[DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS]: {
		next: RESULT_PAGES.battery,
	},
} as const;

const determineFinalStep = async (flow: AppFlowData) => {
	const phoneDamageCheckup = flow.batteryCheckup.get(CheckupPageIds.PHONE_DAMAGE);
	const isBad = await isBadOrIndeterminate(flow);
	const isBackBroken = isBackGlassBroken(phoneDamageCheckup);

	// Skip to results page, which should display bad battery page
	if (isBad || isBackBroken) {
		return CheckupResultKeys.BATTERY_RESULT;
	}

	return CheckupPages.BATTERY_ISSUES;
};
export const NewBatteryCheckupFlow: FlowPagesConfig<BatteryFlowPageNextParams> = {
	start: CheckupQuestionPathnames.BATTERY_INTRO,
	[CheckupPages.BATTERY_INTRO]: {
		next: CheckupPages.PHONE_DAMAGE,
	},
	[CheckupPages.PHONE_DAMAGE]: {
		next: ({ answers, tags, deviceOS }) => {
			const isHazardous = isHazardousDamage({ answers, tags });
			const isFrontBroken = isFrontGlassBroken({ answers, tags });
			const isBackBroken = isBackGlassBroken({ answers, tags });
			// Skip to results page, which should display hazardous damage page
			if (isHazardous || isFrontBroken || isBackBroken) {
				return CheckupResultKeys.BATTERY_RESULT;
			}

			switch (deviceOS) {
				case DeviceOS.IOS:
					return DiagnosticsPages.IOS_BATTERY_STATUS;
				case DeviceOS.ANDROID:
					return DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT;
				default:
					return RESULT_PAGES.battery;
			}
		},
	},
	[DiagnosticsPages.IOS_BATTERY_MESSAGES]: {
		next: async ({ flow }) => await determineFinalStep(flow),
	},
	[DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS]: {
		next: async ({ flow }) => await determineFinalStep(flow),
	},
	[DiagnosticsPages.ANDROID_REMAINING_BATTERY_LIFE]: {
		next: DiagnosticsPages.ANDROID_BATTERY_CHARGE_PERCENTAGE,
		skip: DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_APPROXIMATELY,
	},
	[DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_APPROXIMATELY]: {
		next: DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS,
	},
	[CheckupPages.BATTERY_ISSUES]: {
		next: CheckupResultKeys.BATTERY_RESULT,
	},
} as const;
