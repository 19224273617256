import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { Answer, RichText } from '@home-diy-toolbox/web/contentful';
import { getFontSize, getFontWeight } from '@soluto-private/mx-asurion-ui-react';
import { RadioButtonWithLabel } from '../../../../components';
import { type SamsungMembersAppAnswer } from '../answers/types';
import { type Props } from './types';
import { InputContainer } from './styles';

const Question = styled(Text)`
	&& {
		display: block;
		margin-bottom: 0.5rem;
		font-size: ${getFontSize(6)};
		font-weight: ${getFontWeight('feather')};
	}
`;

const Description = styled(Text)`
	&& {
		display: block;
		margin-bottom: 2.25rem;
		font-size: ${getFontSize(4)};
	}
`;

interface SamsungMembersAppQuestion {
	question: Document;
	description: Document;
	options: Answer[];
}

export const SamsungMembersApp = ({
	initialValue,
	onSetValue,
	content,
}: Props<SamsungMembersAppAnswer, SamsungMembersAppQuestion>) => {
	const { question, description, options } = content;
	const [answer, setAnswer] = useState(initialValue);

	useEffect(() => {
		onSetValue({ value: answer, valid: isValid(answer) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (value) => {
		setAnswer(value);
		onSetValue({ value, valid: isValid(value) });
	};

	const isValid = (answer) => !!answer;

	return (
		<div>
			<RichText
				document={question}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => <Question>{children}</Question>,
					},
				}}
			/>
			<RichText
				document={description}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => <Description>{children}</Description>,
					},
				}}
			/>
			{options.map((option) => (
				<InputContainer key={option.value}>
					<RadioButtonWithLabel
						name={option.value}
						value={option.value}
						label={option.text}
						checked={answer === option.value}
						onChange={() => handleChange(option.value)}
					/>
				</InputContainer>
			))}
		</div>
	);
};
