import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	DiagnosticsCategoryType,
	DiagnosticsCheckupsMap,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	Article,
	FeedbackChoicesTypes,
	useArticlePage,
} from '@home-diy-toolbox/web/contentful';
import { useBreadcrumb, useFlow } from '../../providers';
import { useTranslation } from 'react-i18next';
import { useHeader } from '../../features/header';
import styled from 'styled-components';
import { ArticleLoader } from '../../components/ArticleLoader';
import { FeedbackComponentTypes, FeedbackTypes } from '../../components';

const StyledArticle = styled.article`
	width: 90%;
	max-width: 560px;
`;

type ArticlePageProps = {
	articleId: string;
};

const ArticlePage: FunctionComponent<ArticlePageProps> = ({ articleId }) => {
	const page = useArticlePage(`old_articlePage`);

	const { t } = useTranslation();
	const { getActiveFlowType } = useFlow();
	const { currentState, updateHeader } = useHeader();
	const { setBreadcrumbSteps, resetBreadcrumbSteps } = useBreadcrumb();

	const [article, setArticle] = useState<Article>(null);

	useEffect(() => {
		if (article) {
			setBreadcrumbSteps([
				{
					path: '/',
					label: 'Device Care',
				},
				{
					label: article?.title,
				},
			]);
		}
		return () => {
			resetBreadcrumbSteps();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [article]);

	const onArticleLoad = (articleData) => {
		setArticle(articleData?.articleData);
		const activeFlow = getActiveFlowType();

		if (activeFlow && currentState().title) {
			return;
		}

		// Active flow, but page has been refreshed. No title. Must set and then return.
		if (activeFlow && !currentState().title) {
			let checkup = activeFlow;

			// We can be in a diagnostics flow, we want to translate that to the checkup type
			if (checkup in DiagnosticsCategoryType) {
				checkup = DiagnosticsCheckupsMap[checkup];
			}

			updateHeader({
				title: t(`${checkup}Header`),
			});

			return;
		}

		// Assumed new navigation directly to article, must set default title
		updateHeader({
			title: t('Device Care'),
		});
	};

	return (
		<StyledArticle>
			<ArticleLoader
				articleId={articleId}
				onArticleLoad={onArticleLoad}
				feedbackSectionProps={{
					variant: FeedbackComponentTypes.THUMBS,
					type: FeedbackTypes.ARTICLE,
					feedbackTitle: page?.feedbackSectionTitle,
					positiveForm: {
						...page?.positiveFeedbackForm,
						formId: page?.positiveFeedbackForm?.entryName,
						feedbackChoices: page?.positiveFeedbackForm?.feedbackChoices.map(
							(choice) => ({
								id: choice.entryName,
								label: choice.copy,
							})
						),
						submitButtonCopy: page?.positiveFeedbackForm?.submitCta?.copy,
						feedbackChoicesType: FeedbackChoicesTypes.NEW,
					},
					negativeForm: {
						...page?.negativeFeedbackForm,
						formId: page?.negativeFeedbackForm?.entryName,
						feedbackChoices: page?.negativeFeedbackForm?.feedbackChoices.map(
							(choice) => ({
								id: choice.entryName,
								label: choice.copy,
							})
						),
						submitButtonCopy: page?.negativeFeedbackForm?.submitCta?.copy,
						feedbackChoicesType: FeedbackChoicesTypes.NEW,
					},
				}}
			/>
		</StyledArticle>
	);
};

export default ArticlePage;
