import React, { FunctionComponent } from 'react';
import { useCheckupContentContext, RichText } from '@home-diy-toolbox/web/contentful';
import { PercentageInput } from '@home-diy-toolbox/web/percentage-input';
import {
	ComponentIds,
	DiagnosticsPageIds,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { QuestionHeader } from '../../../../components';
import { useFlow } from '../../../../providers';
import { useTranslation } from 'react-i18next';
import { colors, Text } from '@soluto-private/mx-asurion-ui-react';
import { useAnalytics } from 'react-shisell';
import { AnalyticEventType, withExtras } from '@home-diy-toolbox/web/analytics';
const ContentContainer = styled.div`
	margin-top: 2rem;
	display: flex;
	justify-content: stretch;
	flex-direction: column;
`;

const QuestionPrompt = styled(Text)``;
const Instructions = styled(Text)``;

type Props = {
	maxCapacity: number;
	setMaxCapacity: (capacity: number) => void;
};

export const BatteryMaxCapacityQuestion: FunctionComponent<Props> = ({
	setMaxCapacity,
	maxCapacity,
}): JSX.Element => {
	const pageId = DiagnosticsPageIds.IOS_BATTERY_MAX_CAPACITY;
	const { t } = useTranslation();

	const { useDiagnosticsContent } = useCheckupContentContext();
	const analytics = useAnalytics();
	const {
		flow: {
			sessionData: { deviceOS, deviceMake, partner },
		},
	} = useFlow();

	const { questionCopy, instructionsCopy, questionTitleCopy } = useDiagnosticsContent(
		pageId,
		deviceOS,
		deviceMake,
		partner,
		Checkups.BATTERY
	);

	return (
		<>
			<QuestionHeader data-cy={`${ComponentIds.QUESTION_HEADER}`}>
				<RichText document={questionCopy} />
			</QuestionHeader>

			<QuestionPrompt size={4} weight="feather">
				{questionTitleCopy}
			</QuestionPrompt>
			<ContentContainer>
				<PercentageInput
					value={maxCapacity}
					setValue={setMaxCapacity}
					aria-label={t('battery_max_capacity_input')}
					onBlur={() => {
						analytics.dispatcher
							.extend(
								withExtras({
									BatteryHealth: maxCapacity,
								})
							)
							.dispatch(AnalyticEventType.INPUT);
					}}
					autoFocus
				/>
				<Instructions color={colors.neutralDeeper}>
					<RichText document={instructionsCopy} />
				</Instructions>
			</ContentContainer>
		</>
	);
};
