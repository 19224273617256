import { Entry } from 'contentful';

export type FeedbackChoice = {
	id: string;
	label: string;
};

export enum FeedbackChoicesTypes {
	OLD = 'old',
	NEW = 'new',
}

export type FeedbackFormContent = {
	formId: string;
	header: string;
	subheader: string;
	feedbackChoices: FeedbackChoice[] | Entry<FeedbackChoice>[];
	feedbackChoicesType: FeedbackChoicesTypes;
	commentsPlaceholder: string;
	submitButtonCopy: string;
	completeMessageCopy: string;
};
