import { BLOCKS } from '@contentful/rich-text-types';
import {
	QuestionBlock,
	RichText,
	useCheckupPage,
} from '@home-diy-toolbox/web/contentful';
import {
	Text,
	colors,
	getFontSize,
	getFontWeight,
} from '@soluto-private/mx-asurion-ui-react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { PercentageInput } from '@home-diy-toolbox/web/percentage-input';
import { RadioButtonWithLabel } from '../../../../components';
import { StartClaimCard } from '../components/StartClaimCard';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { InitialPageAnswers, iPhoneBatteryAnswer } from '../answers';
import { InputContainer } from '../components';
import { Spinner } from '../components/Spinner';

const Container = styled.div`
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	gap: 1rem;
`;

const Question = styled(Text)`
	&& {
		font-size: ${getFontSize(4)};
		font-weight: ${getFontWeight('feather')};
	}
`;

const QuestionDescription = styled(Text)`
	&& {
		color: ${colors.neutralDeeper};
		line-height: 1.5;
	}
`;

const PercentageInputContainer = styled(InputContainer)`
	&& {
		color: ${colors.neutralDeeper};
		line-height: 1.5;
	}
`;

const PercentageInputDescription = styled(Text)`
	&& {
		display: block;
		margin: 0.25rem 0.75rem;
		color: ${colors.neutralDeeper};
	}
`;

const questionDescRenderer = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: (_, children: ReactNode) => (
			<QuestionDescription>{children}</QuestionDescription>
		),
	},
};

type Props = {
	answers: iPhoneBatteryAnswer;
	setAnswers: (newAnswers: InitialPageAnswers) => void;
};

export const IPhoneBatteryHealthFlow = ({ answers, setAnswers }: Props) => {
	const iPhoneBatteryContent = useCheckupPage('iPhoneBatteryFlow');

	const { dispatchEvent } = useDispatchEvent();

	if (!iPhoneBatteryContent) return <Spinner />;

	const { iPhoneBatteryPercentage, batteryMessage } = Object.fromEntries(
		iPhoneBatteryContent.blocks.map((block) => [block.entryName, block as QuestionBlock])
	);

	return (
		<Container>
			<Question>{iPhoneBatteryPercentage.question}</Question>
			<RichText
				document={iPhoneBatteryPercentage.questionDescription}
				renderOptionsOverride={questionDescRenderer}
			/>
			<PercentageInputContainer>
				<PercentageInput
					value={answers?.batteryPercentage}
					setValue={(percentage) =>
						setAnswers({ ...answers, batteryPercentage: percentage?.toString() })
					}
					placeholder={iPhoneBatteryPercentage.answers[0].fieldTitle}
					onBlur={() => {
						dispatchEvent(AnalyticEventType.INPUT, {
							batteryPercentage: +answers.batteryPercentage,
						});
					}}
				/>
				<PercentageInputDescription>
					{iPhoneBatteryPercentage.answers[0].description}
				</PercentageInputDescription>
			</PercentageInputContainer>
			<div>
				<Question>{batteryMessage.question}</Question>
				{batteryMessage.answers.map((option) => (
					<InputContainer key={option.value}>
						<RadioButtonWithLabel
							name={option.value}
							value={option.value}
							label={option.text}
							checked={answers?.batteryMessage === option.value}
							onChange={() => {
								setAnswers({ ...answers, batteryMessage: option.value });
								dispatchEvent(AnalyticEventType.CLICK, {
									batteryMessage: option.value,
								});
							}}
						/>
					</InputContainer>
				))}
			</div>
			<StartClaimCard />
		</Container>
	);
};
