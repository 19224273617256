import { getDispatcher, MxAnalyticsDispatcher } from '@soluto-private/mx-analytics';
import { withExtras } from 'shisell/extenders';
import { UserBrowser } from './browserData';
import { Options } from './types';

const scope = {
	path: '/device-care',
	packageName: '@asurion/device-care',
};

export const getRootDispatcher = (
	{ isProduction = false, appName, partner }: Options,
	extraData: Record<string, unknown> = {}
): MxAnalyticsDispatcher<void> => {
	const userBrowser = UserBrowser.get();

	const extenders = withExtras({
		UserBrowserId: userBrowser.UserBrowserId,
		IsTest: !isProduction,
		AppName: appName,
		BrowserOsType: userBrowser.Os,
		NavigatorLanguage: navigator.language,
		UserAgent: userBrowser.UserAgent,
		Partner: partner,
		...extraData,
	});

	const _dispatcher = getDispatcher(scope);

	return _dispatcher.extend(extenders);
};
