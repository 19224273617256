import { FC, memo, useState } from 'react';
import { BatteryCheckupLayout } from '../components';
import { DeviceProps, Pages } from '../pages/types';
import { BATTERY_STATUS, SamsungBatteryAnswer } from '../answers';
import { useOnClickBack } from '../hooks';
import { BatteryResultV3Ids } from '../answers/BatteryResultV3';
import { getAndroidBatteryResult, getDisabledContinueSamsungFlow } from '../utils';
import { useFlow } from '@home-diy-toolbox/apps/device-care/flow-provider';
import SamsungMemberHabitsAndOtherIssues from '../pages/SamsungMemberHabitsAndOtherIssues';
import ResultsPage from '../pages/ResultsPage';
import InitialPage from '../pages/InitialPage';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';

export const SamsungPage: FC<DeviceProps<SamsungBatteryAnswer>> = memo(
	({ currentPage, answers, setAnswers, stack, result, setResult, tags, setTags }) => {
		const { dispatchEvent } = useDispatchEvent();
		const backFuncs = useOnClickBack(stack);
		const [disabledContinueBtn, setIsDisabledContinueBtn] = useState(false);
		const { flow: appFlow } = useFlow();
		const { deviceMake, deviceModel } = appFlow.sessionData;

		const getResult = async (battery, habits, device) => {
			try {
				setIsDisabledContinueBtn(true);
				const result = await getAndroidBatteryResult(battery, habits, device);

				if (result.isHealthy) {
					setResult(BatteryResultV3Ids.BATTERY_HEALTHY);
				} else {
					setResult(BatteryResultV3Ids.BATTERY_REPLACEMENT_BASED_ON_ENTRY);
				}
			} catch (error) {
				setResult(BatteryResultV3Ids.NOT_ENOUGH_INFORMATION);
			} finally {
				setIsDisabledContinueBtn(false);
				stack.push('resultPage');
			}
		};

		const initialPageBtn = async () => {
			const initialPageAns = answers.initialPage;

			if (initialPageAns.batteryStatus === BATTERY_STATUS.GOOD) {
				stack.push('resultPage');
				setResult(BatteryResultV3Ids.BATTERY_HEALTHY);
				return;
			}

			if (initialPageAns.batteryStatus === BATTERY_STATUS.BAD) {
				stack.push('resultPage');
				setResult(BatteryResultV3Ids.BATTERY_REPLACEMENT_LIFE_RATING_SELECTED);
				return;
			}

			if (initialPageAns.chargeLast === 'dontHave') {
				setResult(BatteryResultV3Ids.NOT_ENOUGH_INFORMATION);
				stack.push('resultPage');
				return;
			}

			stack.push('otherIssuesPage');
		};

		const otherIssuesPageBtn = async () => {
			if (!answers?.initialPage && !answers?.otherIssuesPage) {
				return;
			}

			const initialPageAns = answers?.initialPage;

			await getResult(
				{
					remainingHours: +initialPageAns.chargeLast ?? 0,
					currentCharge: +initialPageAns.batteryPercentage ?? 0,
				},
				answers.otherIssuesPage.habits,
				{
					make: deviceMake,
					model: deviceModel,
				}
			);
		};

		const continueBtnFunc: Record<Pages, VoidFunction> = {
			initialPage: initialPageBtn,
			otherIssuesPage: otherIssuesPageBtn,
			resultPage: undefined,
		};

		const flow = {
			initialPage: (
				<InitialPage
					answers={answers?.initialPage}
					setAnswers={setAnswers}
					setTags={setTags}
				/>
			),
			otherIssuesPage: (
				<SamsungMemberHabitsAndOtherIssues
					answers={answers?.otherIssuesPage}
					setAnswers={setAnswers}
					setTags={setTags}
				/>
			),
			resultPage: <ResultsPage resultId={result} tags={tags} />,
		};

		const onContinue = continueBtnFunc[currentPage]
			? () => {
					continueBtnFunc[currentPage]();
					dispatchEvent(AnalyticEventType.CLICK, {
						ActionId: 'continueClick',
						PageId: stack.peek(),
					});
			  }
			: undefined;

		return (
			<BatteryCheckupLayout
				disabledContinue={getDisabledContinueSamsungFlow(currentPage, answers)}
				isContinueLoading={disabledContinueBtn}
				onBack={() => {
					backFuncs[currentPage]();
					dispatchEvent(AnalyticEventType.CLICK, {
						ActionId: 'backClick',
						PageId: stack.peek(),
					});
				}}
				onContinue={onContinue}
				currPage={stack.peek()}
			>
				{flow[currentPage]}
			</BatteryCheckupLayout>
		);
	}
);
