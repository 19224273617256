import { FunctionComponent } from 'react';
import { Checkups, DeviceOS } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow } from '../../../providers';
import { useTranslation } from 'react-i18next';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { isNewCheckupExperience } from '@home-diy-toolbox/web/contentful';

const validCheckups = [Checkups.BATTERY, Checkups.SIGNAL, Checkups.SPEED] as const;

type ProgressProps = {
	currQuestion: number;
	totalQuestions?: number;
	checkupType: typeof validCheckups[number];
};

export const CheckupQuestionsProgress: FunctionComponent<ProgressProps> = ({
	currQuestion,
	checkupType,
	totalQuestions,
}) => {
	const {
		flow: {
			sessionData: { deviceOS, partner },
		},
	} = useFlow();

	const iosBatteryCheckupCount = isNewCheckupExperience(partner, Checkups.BATTERY)
		? 3
		: 6;
	const androidBatteryCheckupCount = isNewCheckupExperience(partner, Checkups.BATTERY)
		? 4
		: 6;
	const signalCheckupCount = 2;
	const speedCheckupCount = 3;
	const { t } = useTranslation();

	if (!totalQuestions) {
		switch (checkupType) {
			case Checkups.BATTERY:
				totalQuestions =
					deviceOS === DeviceOS.IOS ? iosBatteryCheckupCount : androidBatteryCheckupCount;
				break;
			case Checkups.SIGNAL:
				totalQuestions = signalCheckupCount;
				break;
			case Checkups.SPEED:
				totalQuestions = speedCheckupCount;
				break;
		}
	}

	return (
		<Text size={1} weight="heavy">
			{t('question')} {currQuestion}/{totalQuestions}
		</Text>
	);
};
