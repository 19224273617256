import { FontSize, Text } from '@soluto-private/mx-asurion-ui-react';
import { RichText } from '@home-diy-toolbox/web/contentful';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { FC } from 'react';
import styled from 'styled-components';

const Title = styled(Text)`
	&& {
		display: block;
		margin-bottom: 1rem;
	}
`;

type HeaderProps = {
	heading: Document;
	size: FontSize;
};

export const Header: FC<HeaderProps> = ({ heading, size }) => (
	<RichText
		document={heading}
		renderOptionsOverride={{
			renderNode: {
				[BLOCKS.PARAGRAPH]: (_, children) => (
					<Title weight="heavy" size={size}>
						{children}
					</Title>
				),
			},
		}}
	/>
);
