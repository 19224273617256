import React, { ReactElement } from 'react';
import { QuizFlowOption } from './QuizFlowOption';
import { ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const QuestionContainer = styled.div<{ visible: boolean }>`
	margin-top: 2rem;
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const OptionsContainer = styled.div`
	margin-top: 1rem;
	display: flex;
	justify-content: space-around;
	> .quiz-flow-button {
		margin-left: 1rem;
		&:first-of-type {
			margin-left: 0;
		}
	}
`;

export interface QuizFlowQuestion {
	/** Question Identifier, must be unique */
	id: string;
	/** Displayed question title node */
	title: string;
	options: QuizFlowOption[];
}

export interface QuizFlowQuestionProps {
	config: QuizFlowQuestion;
	/** Value of the selection option, must be referentially or strictly equal */
	selectedValue?: unknown;
	visible?: boolean;
	/** Callback whenever the question answer changes */
	onSelect?: (
		event: React.MouseEvent<HTMLElement>,
		value: unknown,
		questionId: string
	) => void;
	optionsButton: ReactElement<ButtonProps>;
}

export const QuizFlowQuestion = ({
	config,
	selectedValue,
	visible,
	onSelect,
	optionsButton,
	...rest
}: QuizFlowQuestionProps) => {
	const handleSelect = (event: React.MouseEvent<HTMLElement>, optionValue: unknown) => {
		onSelect?.(event, optionValue, config.id);
	};

	return (
		<QuestionContainer
			visible={visible}
			data-value={config.id}
			data-testid={`quiz-flow-question_${config.id}`}
			className="quiz-flow-question-container"
		>
			{config.title}
			<OptionsContainer className="quiz-flow-options-container">
				{config.options.map((option) => {
					const selected = selectedValue === option.value;
					return (
						<QuizFlowOption
							key={option.text}
							config={option}
							button={optionsButton}
							onSelect={handleSelect}
							selected={selected}
							data-testid={`quiz-flow-question_${config.id}-option_${option.value}`}
						></QuizFlowOption>
					);
				})}
			</OptionsContainer>
		</QuestionContainer>
	);
};
