import { SpeedTestDelegate, SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { useEffect, useState } from 'react';

export const useSpeedTestStatus = (): SpeedTestStatus => {
	const [status, setStatus] = useState<SpeedTestStatus>(SpeedTestStatus.none);

	useEffect(() => {
		SpeedTestDelegate.onStatusChange = (speedTestStatus: SpeedTestStatus) => {
			setStatus(speedTestStatus);
		};

		return () => {
			SpeedTestDelegate.onStatusChange = undefined;
		};
	}, []);

	return status;
};

export const useSpeedTestDownloadSpeed = (): string => {
	const [speed, setSpeed] = useState<string>('N/A');

	useEffect(() => {
		SpeedTestDelegate.onDownloadSpeedChange = (downloadSpeed: string) => {
			setSpeed(downloadSpeed);
		};

		return () => {
			SpeedTestDelegate.onDownloadSpeedChange = undefined;
		};
	}, []);

	return speed;
};
