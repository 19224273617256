import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import {
	useAnalytics,
	AnalyticEventType,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import type { UsageTime } from '@home-diy-toolbox/shared/clients';
import {
	Checkups,
	DiagnosticsCategoryType,
	DiagnosticsPageIds,
	DiagnosticsPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow, useResults } from '../../../../../providers';
import {
	CheckupQuestionsProgress,
	CheckupContainer,
	NextButton,
	QuestionHeader,
	PreviousButton,
} from '../../../../../components';
import { UsageHabits } from './UsageHabits';
import { useScrollToTop } from '@home-diy-toolbox/web/utils';
import { useCheckupPageFlow } from '../../../../../features/checkupFlows';

const Container = styled.div`
	margin-bottom: 20px;
`;

export const PhoneUsageHabits = (): JSX.Element => {
	const pageId = DiagnosticsPageIds.BATTERY_PHONE_USAGE_HABITS;
	const history = useHistory();
	const [goNext] = useCheckupPageFlow(
		Checkups.BATTERY,
		DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS
	);
	const { dispatcher } = useAnalytics();
	const { useDiagnosticsContent } = useCheckupContentContext();
	const { calculateResults } = useResults();

	const {
		flow: {
			sessionData: { deviceOS, deviceMake, currentQuestionIndex },
		},
		updateDiagnostics,
		setCurrentQuestionIndex,
		getDiagnosticsByTestId,
		flow,
	} = useFlow();
	const { questionTitleCopy, nextButtonCopy, answerOptions } = useDiagnosticsContent(
		pageId,
		deviceOS,
		deviceMake
	);

	const enhancedDispatcher = dispatcher.extend(
		withExtras({
			PageId: pageId,
			CheckupType: Checkups.BATTERY,
		})
	);

	const initialValue: Partial<UsageTime> = JSON.parse(
		(getDiagnosticsByTestId(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId
		) as string) || '{}'
	);
	const usageTimeInHours = useRef<Partial<UsageTime>>(initialValue);

	const onNext = useCallback(() => {
		updateDiagnostics(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId,
			JSON.stringify(usageTimeInHours.current)
		);
		calculateResults(Checkups.BATTERY);
		enhancedDispatcher
			.extend(
				withExtras({
					ActionId: `${pageId}_NextButton`,
					PhoneUsageHabits: usageTimeInHours.current,
				})
			)
			.dispatch(AnalyticEventType.CLICK);

		setCurrentQuestionIndex(currentQuestionIndex + 1);
		goNext({ flow });
	}, [
		calculateResults,
		currentQuestionIndex,
		enhancedDispatcher,
		flow,
		goNext,
		pageId,
		setCurrentQuestionIndex,
		updateDiagnostics,
	]);

	const onBackButtonClick = () => {
		setCurrentQuestionIndex(currentQuestionIndex - 1);
		history.goBack();
	};

	useEffect(() => {
		enhancedDispatcher.dispatch(AnalyticEventType.VIEW);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useScrollToTop();

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<QuestionHeader data-cy={'questionHeader'}>{questionTitleCopy}</QuestionHeader>
			<Container>
				<UsageHabits
					answerOptions={answerOptions}
					usageTimeInHours={usageTimeInHours.current}
				/>
			</Container>
			<NextButton onClick={onNext} data-cy="next-button" disabled={false}>
				{nextButtonCopy}
			</NextButton>
			<PreviousButton onClick={onBackButtonClick} />
		</CheckupContainer>
	);
};
