import React, { FunctionComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
	RouteProps,
	Checkups,
	Partners,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	withOSSelection,
	BatteryIntroPage,
	BatteryIssuesPage,
	PhoneDamagePage,
	PerformanceIssuesPage,
	SpeedIssueFrequencyPage,
	SpeedIssueFrequencyPageV2,
	SpeedIssueOccurrencePage,
	SignalIssuePlacesPage,
	SignalIssuesPage,
	SpeedSentimentPage,
	BatteryCheckupV2,
	BatteryCheckupV3,
} from '../pages';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';
import { useFeatureFlags } from '../providers/featureFlag';

const BatteryIntroWithOS = withOSSelection(BatteryIntroPage);
const BatteryIssuesWithOS = withOSSelection(BatteryIssuesPage);
const PhoneDamageWithOS = withOSSelection(PhoneDamagePage);
const PerformanceIssuesWithOS = withOSSelection(PerformanceIssuesPage);
const SpeedSentimentPageWithOS = withOSSelection(SpeedSentimentPage);
const SpeedIssueFrequencyPageWithOS = withOSSelection(SpeedIssueFrequencyPage);
const SpeedIssueFrequencyPageV2WithOS = withOSSelection(SpeedIssueFrequencyPageV2);
const SpeedIssueOccurrenceWithOS = withOSSelection(SpeedIssueOccurrencePage);
const SignalIssuePlacesWithOS = withOSSelection(SignalIssuePlacesPage);
const SignalIssuesWithOS = withOSSelection(SignalIssuesPage);
const BatteryCheckupV2WithOS = withOSSelection(BatteryCheckupV2);

export const CheckupRoutes: FunctionComponent<RouteProps> = ({ baseRoute }) => {
	const { client: partner } = useValidateMxContext();
	const {
		features: { batteryCheckupRedesign2024 },
	} = useFeatureFlags();

	return (
		<Switch>
			{partner !== Partners.ATT ? (
				<Route
					exact
					path={`${baseRoute}/battery`}
					render={() =>
						batteryCheckupRedesign2024 ? (
							<BatteryCheckupV3 />
						) : (
							<BatteryCheckupV2WithOS checkupType={Checkups.BATTERY} />
						)
					}
				/>
			) : null}
			<Route
				exact
				path={`${baseRoute}/battery/intro`}
				render={() =>
					partner === Partners.ATT ? (
						<BatteryIntroWithOS checkupType={Checkups.BATTERY} />
					) : (
						<Redirect to={`${baseRoute}/battery`} />
					)
				}
			/>
			<Route
				exact
				path={`${baseRoute}/battery/battery-life`}
				render={() =>
					partner === Partners.ATT ? (
						<BatteryIntroWithOS checkupType={Checkups.BATTERY} />
					) : (
						<Redirect to={`${baseRoute}/battery`} />
					)
				}
			/>
			<Route
				exact
				path={`${baseRoute}/battery/battery-issues`}
				render={() => <BatteryIssuesWithOS checkupType={Checkups.BATTERY} />}
			/>
			<Route
				exact
				path={`${baseRoute}/battery/phone-damage`}
				render={() => <PhoneDamageWithOS checkupType={Checkups.BATTERY} />}
			/>
			<Route
				exact
				path={`${baseRoute}/speed/performance-sentiment`}
				render={() => <SpeedSentimentPageWithOS checkupType={Checkups.SPEED} />}
			/>
			<Route
				exact
				path={`${baseRoute}/speed/speed-issue-frequency`}
				render={() => <SpeedIssueFrequencyPageWithOS checkupType={Checkups.SPEED} />}
			/>
			<Route
				exact
				path={`${baseRoute}/speed/issue-frequency-drg`}
				render={() => <SpeedIssueFrequencyPageV2WithOS checkupType={Checkups.SPEED} />}
			/>
			<Route
				exact
				path={`${baseRoute}/speed/performance-issues`}
				render={() => <PerformanceIssuesWithOS checkupType={Checkups.SPEED} />}
			/>
			<Route
				exact
				path={`${baseRoute}/speed/speed-issue-occurrence`}
				render={() => <SpeedIssueOccurrenceWithOS checkupType={Checkups.SPEED} />}
			/>
			<Route
				exact
				path={`${baseRoute}/signal/signal-issues`}
				render={() => <SignalIssuesWithOS checkupType={Checkups.SIGNAL} />}
			/>
			<Route
				exact
				path={`${baseRoute}/signal/signal-issue-places`}
				render={() => <SignalIssuePlacesWithOS checkupType={Checkups.SIGNAL} />}
			/>
			<Route path="*" render={() => <Redirect to={'/'} />} />
		</Switch>
	);
};
