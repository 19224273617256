import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BatteryResultIds } from '@home-diy-toolbox/web/contentful';
import { useFlow } from '../../../../providers';
import {
	initialIphoneBatteryHealthAnswer,
	initialMainIssuesAnswer,
	initialOtherIssuesAnswer,
} from '../answers/initialAnswers';
import { type IphoneAnswers } from '../answers/types';
import { getMainIssuesResult, getTags } from './utils';
import { useSessionState, useResetSession, usePageStack } from './hooks';
import { IphoneBatteryHealthPage, IssuePage, ResultPage } from '../pages';
import { useViewCheckup } from './hooks/useViewCheckup';
import { FlowConfig } from './types';

type Pages = keyof IphoneAnswers | 'result';

export const IphoneBatteryFlow = () => {
	const history = useHistory();
	const resetSession = useResetSession();
	const stack = usePageStack<Pages>(
		['mainIssues'],
		'iphoneBatteryPageStack',
		resetSession
	);

	const { flow: appFlow } = useFlow();
	const [answers, setAnswers] = useSessionState<IphoneAnswers>(
		{
			mainIssues: initialMainIssuesAnswer(),
			iphoneBatteryHealth: initialIphoneBatteryHealthAnswer(),
			otherIssues: initialOtherIssuesAnswer(),
		},
		'iPhoneBatteryAnswers',
		resetSession
	);

	const [result, setResult] = useSessionState(null, 'iphoneBatteryResult', resetSession);
	const [tags, setTags] = useState(new Set<string>());

	const [disableContinue, setDisableContinue] = useState(true);

	const handleSetValue = (
		field: keyof typeof answers,
		{ value, valid, tags: newTags = null }
	) => {
		setAnswers((answers) => ({ ...answers, [field]: value }));
		setDisableContinue(!valid);

		if (newTags) {
			setTags((currTags) => getTags(currTags, newTags, field === 'mainIssues'));
		}
	};

	const getResult = (): BatteryResultIds => {
		const maxCapacity = parseFloat(answers.iphoneBatteryHealth.percentage);
		const batteryHasMessage = answers.iphoneBatteryHealth.batteryMessage !== 'none';

		const isBadBattery = maxCapacity < 80 || batteryHasMessage;
		if (isBadBattery) {
			if (appFlow.sessionData.eligibility.batteryReplacement) {
				return BatteryResultIds.BATTERY_REPLACEMENT_FREE;
			}

			return BatteryResultIds.BATTERY_REPLACEMENT;
		}

		return BatteryResultIds.GOOD_BATTERY;
	};

	const flow: FlowConfig<Pages> = {
		mainIssues: {
			pageId: 'batteryMainIssue',
			pageComponent: (
				<IssuePage
					index={{ currentIndex: 1, total: 3 }}
					issue={'mainIssue'}
					initialValue={answers.mainIssues}
					onSetValue={(props) => handleSetValue('mainIssues', props)}
					backButtonConfig={{ onClick: () => history.goBack() }}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							const result = getMainIssuesResult(answers.mainIssues);
							if (!result) {
								stack.push('iphoneBatteryHealth');
								return;
							}

							setResult(result);
							stack.push('result');
						},
					}}
				/>
			),
		},
		iphoneBatteryHealth: {
			pageId: 'iphoneBatteryHealth',
			pageComponent: (
				<IphoneBatteryHealthPage
					index={{ currentIndex: 2, total: 3 }}
					initialValue={answers.iphoneBatteryHealth}
					onSetValue={(props) => handleSetValue('iphoneBatteryHealth', props)}
					backButtonConfig={{ onClick: () => stack.pop() }}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							stack.push('otherIssues');
						},
					}}
				/>
			),
		},
		otherIssues: {
			pageId: 'batteryOtherIssues',
			pageComponent: (
				<IssuePage
					index={{ currentIndex: 3, total: 3 }}
					issue={'otherIssues'}
					initialValue={answers.otherIssues}
					onSetValue={(props) => handleSetValue('otherIssues', props)}
					backButtonConfig={{ onClick: () => stack.pop() }}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							const result = getResult();

							setResult(result);
							stack.push('result');
						},
					}}
				/>
			),
		},
		result: {
			pageId: 'batteryResult',
			pageComponent: (
				<ResultPage
					resultId={result}
					answerTags={tags}
					backButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
			extraData: {
				BatteryCheckupResult: result,
			},
		},
	};

	const currentPageId = stack.peek();
	const currentPage = flow[currentPageId];

	useViewCheckup(currentPage.pageId, currentPage.extraData);

	return currentPage.pageComponent;
};
