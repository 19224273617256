import styled from 'styled-components';
import { Tag } from '@soluto-private/mx-asurion-ui-react';
import { TabletIcon } from './assets/tabletIcon';
import { PhoneIcon } from './assets/phoneIcon';
import { useTranslation } from 'react-i18next';
import { AnswerOption } from '@home-diy-toolbox/web/contentful';

const Container = styled.div`
	display: inline-flex;
	margin-top: 0.75em;
	gap: 0.75em;
`;

const IconAlign = styled.div`
	display: flex;
	margin-top: -7px;
`;

const tagIcons: Record<string, JSX.Element> = {
	Tablet: (
		<IconAlign>
			<TabletIcon />
		</IconAlign>
	),
	Phone: (
		<IconAlign>
			<PhoneIcon />
		</IconAlign>
	),
};

export const Tags = ({ tags }: Pick<AnswerOption, 'tags'>): JSX.Element => {
	const { t } = useTranslation();

	const components = tags.map((tag) => (
		<Tag key={tag} type="light" text={t(tag.toLowerCase())} iconSrc={tagIcons[tag]} />
	));

	return (
		<Container>
			{
				//TODO: tag images don't match figma but requires making custom tag component
				components
			}
		</Container>
	);
};
