import {
	Checkups,
	Partner,
	ResultPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { AppFlowData } from '../../../../providers/flows/models';
import {
	SignalSpeedResult,
	SIGNAL_SPEEDS_MAP,
	SIGNAL_SPEED_RESULTS,
} from '../../constants';
import { SignalCheckResult } from '../../types';
import { findResultPage } from '../../utility';

export const calculateSignalResults = async (
	flow: AppFlowData
): Promise<SignalCheckResult> => {
	const {
		signalDiagnostics,
		sessionData: { partner },
	} = flow;

	const downloadSpeed = signalDiagnostics.get('downloadSpeed') as number;
	const resultOptions = Object.values(SIGNAL_SPEED_RESULTS) as SignalSpeedResult[];
	let result: SignalSpeedResult = SIGNAL_SPEED_RESULTS.POOR;

	// Break early if no download speed, just go to default page
	if (downloadSpeed === undefined) {
		return {
			pageId: ResultPageIds.SIGNAL_RESULT_DEFAULT,
			signalTestResult: result,
			downloadSpeed: downloadSpeed,
		};
	}

	for (let i = 0; i < resultOptions.length; i++) {
		const resultOption = resultOptions[i];
		const targetSpeed = SIGNAL_SPEEDS_MAP[resultOption];

		if (downloadSpeed >= targetSpeed) {
			result = resultOption;
			break;
		}
	}

	const results: SignalCheckResult = {
		pageId: ResultPageIds.SIGNAL_RESULT_DEFAULT,
		signalTestResult: result,
		downloadSpeed: downloadSpeed,
	};

	const pageId = await findResultPage(Checkups.SIGNAL, results, partner as Partner);
	results.pageId = pageId;

	return results;
};
