import { FC, useMemo } from 'react';
import { OldServices } from './OldServices';
import { OtherServicesProps } from './types';
import { useFeatureFlags } from '../../providers/featureFlag';
import { getCookieValue } from '@home-diy-toolbox/web/utils';
import { NewServices } from './NewServices';

export const OtherServices: FC<OtherServicesProps> = ({
	title,
	services,
	xScrollable,
}) => {
	const {
		features: { redesign2022 },
	} = useFeatureFlags();
	const isNewDesign = redesign2022 || getCookieValue('redesign_2022');

	const { oldServices, newServices } = useMemo(() => {
		const oldServices = [];
		const newServices = [];

		services.forEach((service) => {
			if (service.id.startsWith('v1')) {
				oldServices.push({
					...service,
					id: service.id.replace('v1_', ''),
				});
			} else {
				newServices.push(service);
			}
		});

		return {
			oldServices,
			newServices,
		};
	}, [services]);

	return isNewDesign ? (
		<NewServices title={title} services={newServices} xScrollable={xScrollable} />
	) : (
		<OldServices title={title} services={oldServices} xScrollable={xScrollable} />
	);
};
