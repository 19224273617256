import { FunctionComponent } from 'react';
import { IconSrc, Tag } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

type Props = {
	text: string;
	icon: IconSrc | JSX.Element;
};

const StyledTag = styled(Tag)`
	&& {
		span:first-child {
			display: flex;
			align-items: center;
		}
	}
`;

export const ArticleTag: FunctionComponent<Props> = ({ text, icon }) => (
	<StyledTag iconSrc={icon} type="light" text={text} />
);
