import { ReactElement, useEffect, useState } from 'react';
import { QuizFlowQuestion } from './QuizFlowQuestion';
import { ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

export interface QuizFlowConfig {
	questions: QuizFlowQuestion[];
}

interface QuizFlowProps {
	/** Already selected answers keyed as [questionId]: value  */
	answers?: Record<string, unknown>;

	/** Callback when any question is answered, or question answer changes */
	onAnswer?: (questionName: string, value: unknown) => void;

	/** Callback with all answers whenever any answer changes */
	onChange?: (answers: Record<string, unknown>) => void;

	/** Callback with all answers when all questions have been answered */
	onComplete?: (answers: Record<string, unknown>) => void;

	/** The quiz configuration that defines the questions & options */
	config: QuizFlowConfig;

	/** If all questions should be displayed, vs shown 1 at a time */
	displayAllQuestions?: boolean;

	/** Button used to render each of the options, will have it's variant, onClick, onChange, and onKeyPress props overridden */
	optionButton: ReactElement<ButtonProps>;
	className?: string;
}
const QuizFlowRoot = styled.div``;

const getMaxIndex = (questions: QuizFlowQuestion[], answers: Record<string, unknown>) => {
	let maxIndex = 0;
	questions.forEach((question, index) => {
		if (question.id in answers) {
			maxIndex = index + 1;
		}
	});

	return Math.min(maxIndex, questions.length);
};

const answersAreEqual = (
	value: Record<string, unknown>,
	other: Record<string, unknown>
) => {
	if (value === other) {
		return true;
	}

	if (!value || !other) {
		return value && other;
	}

	const valueKeys = Object.keys(value).sort();
	const otherKeys = Object.keys(other).sort();

	if (valueKeys.length !== otherKeys.length) {
		return false;
	}

	for (let i = 0; i < valueKeys.length; i++) {
		// We only care about strict || referential equality here
		if (value[valueKeys[i]] !== other[valueKeys[i]]) {
			return false;
		}
	}
};

export const QuizFlow = ({
	answers,
	onAnswer,
	onChange,
	onComplete,
	config: { questions },
	optionButton,
	className,
	displayAllQuestions,
}: QuizFlowProps) => {
	const [currentIndex, setCurrentIndex] = useState(0); // MUST match questions answered + 1
	const [answersInternal, setAnswersInternal] = useState<Record<string, unknown>>({});

	// Hook intended just for updating state when answers || questions change
	useEffect(() => {
		if (!answers || answersAreEqual(answers, answersInternal)) {
			return;
		}

		setAnswersInternal(answers);

		if (!displayAllQuestions) {
			setCurrentIndex(getMaxIndex(questions, answers));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answers, questions, answersInternal]);

	// Hook intended just for updating state when displayAllQuestions || questions change
	useEffect(() => {
		if (displayAllQuestions) {
			setCurrentIndex(questions.length - 1);
		} else {
			setCurrentIndex(getMaxIndex(questions, answers));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayAllQuestions, questions]);

	const handleSelect = (event, selectedValue, questionId) => {
		const questionIndex = questions.findIndex((x) => x.id === questionId);

		// TODO: Get preferred verbiage for robustness check
		if (questionIndex === -1) {
			throw new Error('Unexpected Error. Question not found in questions');
		}

		// TODO: Get preferred verbiage for robustness check
		if (questionIndex > currentIndex) {
			throw new Error(
				'Invalid Question Index. Question index is expected to be <= to current index'
			);
		}

		const isLatestQuestion = questionIndex === currentIndex;
		const isFinalQuestion = currentIndex === questions.length - 1;

		answersInternal[questionId] = selectedValue;
		setAnswersInternal({ ...answersInternal });

		onAnswer?.(questionId, selectedValue);
		onChange?.(answersInternal);

		if (!displayAllQuestions && isLatestQuestion && !isFinalQuestion) {
			setCurrentIndex(currentIndex + 1);
		}

		if (isLatestQuestion && isFinalQuestion) {
			onComplete?.(answersInternal);
		}
	};

	return (
		<QuizFlowRoot className={`${className} quiz-flow-root`}>
			{questions.map((question, index) => {
				const visible = index <= currentIndex;
				return (
					<QuizFlowQuestion
						key={question.id}
						visible={visible}
						config={question}
						optionsButton={optionButton}
						selectedValue={answersInternal[question.id]}
						onSelect={handleSelect}
					/>
				);
			})}
		</QuizFlowRoot>
	);
};
