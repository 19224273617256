export const transformGraphqlEntry = (entry: unknown) => {
	if (typeof entry !== 'object' || !entry) return entry;

	if (Array.isArray(entry)) {
		return entry.map((x) => transformGraphqlEntry(x));
	}

	const holder = {};
	for (const key of Object.keys(entry)) {
		const value = entry[key];

		const isCollection = value?.items && key.endsWith('Collection');
		if (isCollection) {
			holder[key.replace('Collection', '')] = transformGraphqlEntry(value.items);
			continue;
		}
		const isRichText = value?.json && value.json.content && value.json.nodeType;
		if (isRichText) {
			holder[key] = value.json;
			continue;
		}

		holder[key.replace('__', '')] = transformGraphqlEntry(value);
	}

	return holder;
};
