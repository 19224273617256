import React, { FunctionComponent, Suspense } from 'react';
import {
	CheckupContentProvider,
	ArticleContentProvider,
	LayoutContentProvider,
	PageContentProvider,
	MxContextProvider,
} from '@home-diy-toolbox/web/contentful';
import { AppName } from '@home-diy-toolbox/web/common-types/refresh-base';
import { LoadingPage } from '../pages';
import { NetworkTestProvider } from '@home-diy-toolbox/web/network-test';
import {
	AnalyticsProvider,
	getRootDispatcher,
	formatAnalyticsObject,
} from '@home-diy-toolbox/web/analytics';
import App from '../app/app';
import { ThemeProvider } from '@home-diy-toolbox/web/theme';
import { I18nextProvider } from 'react-i18next';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';
import { environment } from '../environments/environment';
import { FlowProvider, useFlow, ResultsProvider, BreadcrumbProvider } from '.';
import { HeaderProvider } from '../features/header';
import { useI18nClient } from './useI18nClient';
import { FeatureFlagsProvider } from './featureFlag';

const AnalyticsApp = ({ partner, locale }) => {
	const { flow } = useFlow();

	return (
		<AnalyticsProvider
			dispatcher={() =>
				getRootDispatcher(
					{
						scope: '@asurion/device-care',
						appName: AppName,
						isProduction: environment.production,
						partner: partner,
					},
					{
						...formatAnalyticsObject({
							flowId: flow.flowId,
							...flow.sessionData,
						}),
					}
				)
			}
		>
			<App partner={partner} locale={locale} />
		</AnalyticsProvider>
	);
};

const OtherProviders: FunctionComponent = () => {
	const { client: partner } = useValidateMxContext();

	const i18n = useI18nClient({ namespace: environment.localizationNamespace });
	const { language: currentLocale } = i18n;

	return (
		<I18nextProvider i18n={i18n}>
			{/* Suspense is required here for the above i18n provider to work correctly */}
			<Suspense fallback={LoadingPage}>
				<FlowProvider>
					<ArticleContentProvider
						spaceId={environment.contentful.article.spaceId}
						accessToken={environment.contentful.article.accessToken}
						environment={environment.contentful.article.environment}
						locale={currentLocale}
						partner={partner}
					>
						<CheckupContentProvider
							spaceId={environment.contentful.checkup.spaceId}
							accessToken={environment.contentful.checkup.accessToken}
							environment={environment.contentful.checkup.environment}
							locale={currentLocale}
							partner={partner}
						>
							<LayoutContentProvider
								spaceId={environment.contentful.checkup.spaceId}
								accessToken={environment.contentful.checkup.accessToken}
								environment={environment.contentful.checkup.environment}
								locale={currentLocale}
								partner={partner}
							>
								<PageContentProvider
									spaceId={environment.contentful.page.spaceId}
									accessToken={environment.contentful.page.accessToken}
									environment={environment.contentful.page.environment}
								>
									<ResultsProvider>
										<NetworkTestProvider>
											<BreadcrumbProvider>
												<ThemeProvider>
													<HeaderProvider>
														<FeatureFlagsProvider>
															<AnalyticsApp partner={partner} locale={currentLocale} />
														</FeatureFlagsProvider>
													</HeaderProvider>
												</ThemeProvider>
											</BreadcrumbProvider>
										</NetworkTestProvider>
									</ResultsProvider>
								</PageContentProvider>
							</LayoutContentProvider>
						</CheckupContentProvider>
					</ArticleContentProvider>
				</FlowProvider>
			</Suspense>
		</I18nextProvider>
	);
};

export const Providers = () => {
	return (
		<MxContextProvider>
			<OtherProviders />
		</MxContextProvider>
	);
};
