import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { Answer, RichText } from '@home-diy-toolbox/web/contentful';
import {
	colors,
	getFontSize,
	getFontWeight,
	Text,
} from '@soluto-private/mx-asurion-ui-react';
import { PercentageInput } from '@home-diy-toolbox/web/percentage-input';
import { RadioButtonWithLabel } from '../../../../components';
import { type iPhoneBatteryHealthAnswer } from '../answers/types';
import { type Props } from './types';
import { InputContainer } from './styles';
import { withExtras } from 'shisell/extenders';
import { AnalyticEventType, useAnalytics } from '@home-diy-toolbox/web/analytics';

const Intro = styled(Text)`
	&& {
		margin-bottom: 0.5rem;
		display: block;
		font-size: ${getFontSize(6)};
		font-weight: ${getFontWeight('feather')};
	}
`;

const Instruction = styled(Text)`
	&& {
		display: block;
		margin-bottom: 2.25rem;
		font-size: ${getFontSize(4)};
		font-weight: ${getFontWeight('feather')};
	}
`;

const MaxCapacityQuestion = styled(Text)`
	&& {
		display: block;
		margin-bottom: 1rem;
		font-size: ${getFontSize(4)};
	}
`;

const MaxCapcityInputDescription = styled(Text)`
	&& {
		display: block;
		margin: 0.5rem auto 2.25rem 0.75rem;
		color: ${colors.neutralDeep};
	}
`;

const introRenderOptions = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: (_, children) => <Intro>{children}</Intro>,
	},
};

const instructionRenderOptions = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: (_, children) => <Instruction>{children}</Instruction>,
	},
};

interface iPhoneBatteryHealthQuestion {
	intro: Document;
	instruction: Document;
	maxCapacityPlaceholder: string;
	maxCapacityQuestion: string;
	maxCapacityInputLabel: string;
	maxCapacityInputDescription: string;
	batteryMessageQuestion: string;
	batteryMessageOptions: Answer[];
}

export const IphoneBatteryHealth = ({
	initialValue,
	onSetValue,
	content,
}: Props<iPhoneBatteryHealthAnswer, iPhoneBatteryHealthQuestion>) => {
	const {
		intro,
		instruction,
		maxCapacityQuestion,
		maxCapacityInputLabel,
		maxCapacityInputDescription,
		maxCapacityPlaceholder,
		batteryMessageQuestion,
		batteryMessageOptions,
	} = content;

	const [answers, setAnswers] = useState(initialValue);
	const analytics = useAnalytics();

	useEffect(() => {
		onSetValue({ value: answers, valid: isValid(answers) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (field: string, value) => {
		setAnswers((answers) => {
			const newVal = { ...answers, [field]: value };
			onSetValue({
				value: newVal,
				valid: isValid(newVal),
			});
			return newVal;
		});
	};

	const isValid = (answers) => {
		return Object.values(answers).every((answer) => answer);
	};

	return (
		<div>
			{RichText({ document: intro, renderOptionsOverride: introRenderOptions })}
			{RichText({
				document: instruction,
				renderOptionsOverride: instructionRenderOptions,
			})}
			<MaxCapacityQuestion>{maxCapacityQuestion}</MaxCapacityQuestion>
			<InputContainer>
				<PercentageInput
					value={answers.percentage}
					setValue={(percentage) => handleChange('percentage', percentage)}
					label={maxCapacityInputLabel}
					placeholder={maxCapacityPlaceholder}
					onBlur={() => {
						analytics.dispatcher
							.extend(
								withExtras({
									BatteryHealth: answers.percentage,
								})
							)
							.dispatch(AnalyticEventType.INPUT);
					}}
				/>
			</InputContainer>
			<MaxCapcityInputDescription>
				{maxCapacityInputDescription}
			</MaxCapcityInputDescription>
			<Text size={4}>{batteryMessageQuestion}</Text>
			{batteryMessageOptions.map((option) => (
				<InputContainer key={option.value}>
					<RadioButtonWithLabel
						name={option.value}
						value={option.value}
						label={option.text}
						checked={answers.batteryMessage === option.value}
						onChange={() => handleChange('batteryMessage', option.value)}
					/>
				</InputContainer>
			))}
		</div>
	);
};
