import {
	SpeedtestType,
	SpeedTestStatus,
	WixiSnap,
	WixiCore,
} from '@soluto-private/wixi-web-sdk';
import React, {
	FunctionComponent,
	useCallback,
	useEffect,
	useState,
	createContext,
	useContext,
} from 'react';
import { useWixiSession, useSpeedTestDownloadSpeed, useSpeedTestStatus } from '../hooks';
import { IScanResults, WebScanState } from '../models';

export type NetworkTestContextType = {
	startSpeedTest: () => void;
	cancelSpeedTest: () => void;
	downloadSpeed: string;
	scanStatus: SpeedTestStatus;
	webScanState: WebScanState;
};

const defaultNetworkTestContext: NetworkTestContextType = {
	startSpeedTest: undefined,
	cancelSpeedTest: undefined,
	downloadSpeed: undefined,
	scanStatus: SpeedTestStatus.none,
	webScanState: WebScanState.NOT_STARTED,
};

const NetworkTestContext = createContext<NetworkTestContextType>(
	defaultNetworkTestContext
);

export const useNetworkTestContext = () => useContext(NetworkTestContext);

type NetworkTestProviderProps = {
	onScanComplete?: (results: IScanResults) => void;
	onScanStart?: () => void;
	onScanCancel?: () => void;
};

export const NetworkTestProvider: FunctionComponent<NetworkTestProviderProps> = ({
	onScanComplete,
	onScanStart,
	onScanCancel,
	children,
}) => {
	const [webScanState, setWebScanState] = useState<WebScanState>(
		WebScanState.NOT_STARTED
	);
	const wixiSession = useWixiSession();
	const downloadSpeed = useSpeedTestDownloadSpeed();
	const [wixiSnap] = useState<WixiSnap>(
		new WixiSnap({
			wixiHealthConfig: {
				speedtestType: SpeedtestType.DOWNLOAD,
			},
		})
	);

	const onSnapStarted = useCallback(() => {
		setWebScanState(WebScanState.STARTED);
	}, []);

	const startSpeedTest = useCallback(async () => {
		setWebScanState(WebScanState.INITIALIZING);
		wixiSnap.onSnapStart = onSnapStarted;
		await wixiSnap?.snap();
		onScanStart?.();
	}, [onScanStart, wixiSnap, onSnapStarted]);

	const speedTestStatus = useSpeedTestStatus();
	const [scanStatus, setScanStatus] = useState<SpeedTestStatus>(speedTestStatus);
	const scanCompleted = scanStatus === SpeedTestStatus.completed;

	useEffect(() => {
		setScanStatus(speedTestStatus);
	}, [speedTestStatus]);

	useEffect(() => {
		if (scanCompleted) {
			setWebScanState(WebScanState.FINISHED);
			const results: IScanResults = {
				clientId: WixiCore.clientId,
				goId: wixiSession.goId || '',
				downloadSpeed,
			};
			onScanComplete?.(results);
		}
	}, [downloadSpeed, onScanComplete, scanCompleted, wixiSession.goId]);

	const cancelSpeedTest = useCallback(() => {
		wixiSnap?.cancel();
		setWebScanState(WebScanState.NOT_STARTED);
		onScanCancel?.();
	}, [wixiSnap, onScanCancel]);

	const contextValues: NetworkTestContextType = {
		startSpeedTest,
		cancelSpeedTest,
		scanStatus,
		downloadSpeed,
		webScanState,
	};

	return (
		<NetworkTestContext.Provider value={contextValues}>
			{children}
		</NetworkTestContext.Provider>
	);
};
