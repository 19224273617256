import { FC, memo, useState } from 'react';
import { BatteryCheckupLayout } from '../components';
import { DeviceProps, Pages } from '../pages/types';
import { BatteryResultV3Ids } from '../answers/BatteryResultV3';
import { useOnClickBack } from '../hooks';
import { AndroidOthersBatteryAnswer } from '../answers';
import { getAndroidBatteryResult } from '../utils';
import { useFlow } from '@home-diy-toolbox/apps/device-care/flow-provider';
import { getDisabledContinueAndroidOthersFlow } from '../utils/getDisabledContinueAndroidOthersFlow';
import AndroidOthersHabitsAndOtherIssues from '../pages/AndroidOthersHabitsAndOtherIssues';
import ResultsPage from '../pages/ResultsPage';
import InitialPage from '../pages/InitialPage';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';

export const AndroidOthersPage: FC<DeviceProps<AndroidOthersBatteryAnswer>> = memo(
	({ currentPage, answers, setAnswers, tags, setTags, stack, result, setResult }) => {
		const { dispatchEvent } = useDispatchEvent();
		const backFuncs = useOnClickBack(stack);
		const [disabledContinueBtn, setIsDisabledContinueBtn] = useState(false);
		const { flow: appFlow } = useFlow();
		const { deviceMake, deviceModel } = appFlow.sessionData;

		const initialPageAnswers = () => {
			const initialPageAns = answers.initialPage;

			if (initialPageAns.chargeLast === 'cantFindInfo') {
				setResult(BatteryResultV3Ids.NOT_ENOUGH_INFORMATION);
				stack.push('resultPage');
			} else {
				stack.push('otherIssuesPage');
			}
		};

		const otherIssuesPageAnswers = async () => {
			if (!answers?.initialPage && !answers.otherIssuesPage) {
				return;
			}

			const initialPageAns = answers.initialPage;

			try {
				setIsDisabledContinueBtn(true);
				const androidBatteryResult = await getAndroidBatteryResult(
					{
						remainingHours: +initialPageAns.chargeLast ?? 0,
						currentCharge: +initialPageAns.currentBatteryCharge ?? 0,
					},
					answers.otherIssuesPage.habits,
					{
						make: deviceMake,
						model: deviceModel,
					}
				);

				if (androidBatteryResult.isHealthy) {
					setResult(BatteryResultV3Ids.BATTERY_HEALTHY);
				} else {
					setResult(BatteryResultV3Ids.BATTERY_REPLACEMENT_BASED_ON_ENTRY);
				}
			} catch (error) {
				setResult(BatteryResultV3Ids.NOT_ENOUGH_INFORMATION);
			} finally {
				setIsDisabledContinueBtn(false);
				stack.push('resultPage');
			}
		};

		const continueBtnFunc: Record<Pages, VoidFunction> = {
			initialPage: initialPageAnswers,
			otherIssuesPage: otherIssuesPageAnswers,
			resultPage: undefined,
		};

		const flow = {
			initialPage: (
				<InitialPage
					answers={answers?.initialPage}
					setAnswers={setAnswers}
					setTags={setTags}
				/>
			),
			otherIssuesPage: (
				<AndroidOthersHabitsAndOtherIssues
					answers={answers?.otherIssuesPage}
					setAnswers={setAnswers}
					setTags={setTags}
				/>
			),
			resultPage: <ResultsPage resultId={result} tags={tags} />,
		};

		const onContinue = continueBtnFunc[currentPage]
			? () => {
					continueBtnFunc[currentPage]();
					dispatchEvent(AnalyticEventType.CLICK, {
						ActionId: 'continueClick',
						PageId: stack.peek(),
					});
			  }
			: undefined;

		return (
			<BatteryCheckupLayout
				disabledContinue={getDisabledContinueAndroidOthersFlow(currentPage, answers)}
				isContinueLoading={disabledContinueBtn}
				onBack={() => {
					backFuncs[currentPage]();
					dispatchEvent(AnalyticEventType.CLICK, {
						ActionId: 'backClick',
						PageId: stack.peek(),
					});
				}}
				onContinue={onContinue}
				currPage={stack.peek()}
			>
				{flow[currentPage]}
			</BatteryCheckupLayout>
		);
	}
);
