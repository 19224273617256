import styled, { css, CSSObject } from 'styled-components';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import { radioClassName, toggleClassName } from './radio-toggle';

interface SliderProps {
	buttonSize: number;
	radioCount: number;
	checkedValueIndex: number;
	isAnyChecked: boolean;
}

type Line = 'full' | 'selected';

const lineTypeStyleMapping = ({
	radioCount,
	checkedValueIndex,
}: Omit<SliderProps, 'buttonSize'>): Record<Line, CSSObject> => {
	const fullWidth = (radioCount - 1) / radioCount;
	const selectedRelativeWidth = (1 / radioCount) * checkedValueIndex;

	return {
		full: {
			width: fullWidth,
			left: 0.5,
			background: colors.neutralBright,
		},
		selected: {
			width: selectedRelativeWidth,
			left: 0.5 - (fullWidth - selectedRelativeWidth) / 2,
			background: colors.black,
		},
	};
};

const lineTypeToStyle =
	(lineType: Line) =>
	({ buttonSize, ...props }: SliderProps) => {
		const { width, left, background } = lineTypeStyleMapping(props)[lineType];

		return css`
			content: ' ';
			position: absolute;
			height: ${buttonSize / 4}px;
			width: calc(100% * ${width});
			top: 50%;
			left: calc(100% * ${left});
			transform: translate(-50%, -50%);
			background: ${background};
		`;
	};

const resetStyle = css`
  padding: 0;
  margin: 0;
  list-style: none;
}`;

const content = css`
	cursor: pointer;
	font-size: 14px;
	letter-spacing: 0.4px;
	line-height: 0.1em;
	font-weight: 400;
	white-space: nowrap;
`;

export const Slider = styled.ul<SliderProps>`
	${resetStyle}
	display: flex;
	flex-direction: row;
	align-content: stretch;
	justify-content: space-around;
	position: relative;
	height: 50px;
	user-select: none;

	&::before {
		${(props) => lineTypeToStyle('full')(props)}
	}

	&::after {
		${(props) => lineTypeToStyle('selected')(props)}
		transition: background 2s linear;
	}

	> .${radioClassName} {
		margin: 0;
		padding: 0;
		user-select: none;
		-webkit-tap-highlight-color: transparent;

		cursor: pointer;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		width: 20%;
		height: 100%;
		z-index: 1;

		${content}
		color: transparent;
		transition: color 0.2s linear;

		> .${toggleClassName} {
			margin: 0;
			padding: 0;
			${({ buttonSize }) => `margin-top: ${buttonSize / 2}px;`}

			display: flex;
			align-items: flex-end;
			box-sizing: border-box;
			height: 50%;
		}

		> .${toggleClassName}::before {
			content: ' ';
			display: inline-block;
			box-sizing: border-box;

			${({ buttonSize }) => `height: ${buttonSize}px;`}
			${({ buttonSize }) => `width: ${buttonSize}px;`}

      border-radius: 50%;
			box-shadow: 0px 0px 0px 2px ${colors.neutralBright} inset;
			transition: box-shadow 0.2s, background-color 0.2s, transform 0.2s ease-in-out;
		}

		&:not([disabled]) {
			${({ isAnyChecked }) =>
				isAnyChecked &&
				`&:not([aria-checked="true"] ~ .${radioClassName}) {
          > .${toggleClassName}::before {
            box-shadow: 0px 0px 0px 2px ${colors.black} inset;
            background-color: ${colors.black};
          }
      }`}

			// Unchecked state
      &[aria-checked="false"] {
				color: transparent;

				> .${toggleClassName}::before {
					box-shadow: 0px 0px 0px 2px ${colors.neutralBright} inset;
					background-color: ${colors.white};
				}

				// Hover state, Focus state
				&:hover,
				&:focus:not([focus-visible]) {
					color: ${colors.neutralDeep};

					> .${toggleClassName}::before {
						box-shadow: 0px 0px 0px 2px ${colors.neutralDeep} inset;
						background-color: ${colors.neutralDeep};
						transform: scale(2);
					}
				}

				&:focus-visible {
					color: ${colors.neutralDeep};

					> .${toggleClassName}::before {
						box-shadow: 0px 0px 0px 2px ${colors.neutralDeep} inset;
						background-color: ${colors.neutralDeep};
						transform: scale(2);
					}
				}
			}

			// Checked state
			&[aria-checked='true'] {
				color: ${colors.black};

				> .${toggleClassName}::before {
					box-shadow: 0px 0px 0px 2px ${colors.black} inset;
					background-color: ${colors.black};
					transform: scale(2);
				}
			}
		}
	}
`;
