import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import {
	RichText,
	useCheckupContentContext,
	useCheckupPage,
	Image,
} from '@home-diy-toolbox/web/contentful';
import {
	CheckupQuestionPathnames,
	CheckupDiagnosticsPathnames,
	ComponentIds,
	DiagnosticsPageIds,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import {
	CheckupContainer,
	QuestionHeader,
	ImageContainer,
	NextButton,
} from '../../../../components';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';
import { BLOCKS } from '@contentful/rich-text-types';

const CheckupContainerWrapper = styled(CheckupContainer)`
	padding-top: 32px;
`;

const AccordionContainer = styled.div``;

const StartTestButton = styled(NextButton)`
	margin-top: 15px;
`;

const SkipTestButton = styled(NextButton)`
	&& {
		margin-top: 15px;
	}
`;

const FootnoteContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 10px;
`;

const Footnote = styled.div`
	max-width: 250px;
	text-align: center;
	font-size: 14px;
	line-height: 16px;
`;

const TurnOffWiFiPage: FunctionComponent = () => {
	const pageId = DiagnosticsPageIds.SIGNAL_TURN_OFF_WIFI;
	const startTestButtonId = `${pageId}_${ComponentIds.START_TEST_BUTTON}`;
	const skipTestButtonId = `${pageId}_${ComponentIds.SKIP_TEST_BUTTON}`;

	const history = useHistory();
	const { usePageContent } = useCheckupContentContext();
	console.log(usePageContent);

	const pageContent = useCheckupPage(pageId);
	const questionImage = pageContent?.blocks[0] as Image;

	const startTestButtonCopy = pageContent?.ctas.StartTest.copy;
	const skipButtonCopy = pageContent?.ctas.SkipTest.copy;
	const footnote = pageContent?.footnote;

	//TODO: FDH - ask if the instruction accordion is still needed.
	const { instructionsCopy } = usePageContent(pageId);

	const onStartTestButtonClick = () =>
		history.push(CheckupDiagnosticsPathnames.SIGNAL_UPLOAD_DOWNLOAD, {
			from: history.location.pathname,
		});

	const onSkipTestButtonClick = () =>
		history.push(CheckupQuestionPathnames.SIGNAL_ISSUES, {
			from: history.location.pathname,
		});

	if (!pageContent) return <PageLoader />;

	return (
		<CheckupContainerWrapper>
			<RichText
				document={pageContent.heading}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => (
							<QuestionHeader>{children}</QuestionHeader>
						),
					},
				}}
			/>
			<ImageContainer>
				<img src={questionImage.asset.url} alt="" />
			</ImageContainer>
			<AccordionContainer>
				<RichText document={instructionsCopy} />
			</AccordionContainer>
			<StartTestButton
				onClick={onStartTestButtonClick}
				actionId={startTestButtonId}
				data-cy={startTestButtonId}
				analyticExtras={{
					CheckupType: Checkups.SIGNAL,
				}}
			>
				{startTestButtonCopy}
			</StartTestButton>
			<SkipTestButton
				variant="outline"
				onClick={onSkipTestButtonClick}
				actionId={skipTestButtonId}
				data-cy={'skipButton'}
				analyticExtras={{
					CheckupType: Checkups.SIGNAL,
				}}
			>
				{skipButtonCopy}
			</SkipTestButton>
			<FootnoteContainer>
				<Footnote>
					<RichText document={footnote} />
				</Footnote>
			</FootnoteContainer>
		</CheckupContainerWrapper>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: DiagnosticsPageIds.SIGNAL_TURN_OFF_WIFI,
	CheckupType: Checkups.SIGNAL,
})(TurnOffWiFiPage);
