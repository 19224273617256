import React, { FunctionComponent } from 'react';
import { Document } from '@contentful/rich-text-types';
import { RichText } from '@home-diy-toolbox/web/contentful';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { ReactComponent as MagicWand } from '../../../assets/spinning-wand.svg';

type Props = {
	label?: Document | string;
};

export const SpinningWand = styled(MagicWand)`
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: 0;
	margin-bottom: 0;
	width: 200px;
	height: 228px;

	@media all and (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		width: 166px;
		height: 189px;
	}

	@media all and (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		width: 250px;
		height: 285px;
		margin-top: 0;
		margin-bottom: 0;
	}

	#eo777urdvrca3_to {
		animation: eo777urdvrca3_to__to 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca3_to__to {
		0% {
			transform: translate(45.617px, 35.245611px);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		100% {
			transform: translate(69.561303px, 35.245611px);
		}
	}
	#eo777urdvrca3_ts {
		animation: eo777urdvrca3_ts__ts 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca3_ts__ts {
		0% {
			transform: scale(0.8, 0.8);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		100% {
			transform: scale(1.2, 1.2);
		}
	}
	#eo777urdvrca5 {
		animation: eo777urdvrca5_c_o 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca5_c_o {
		0% {
			opacity: 0;
		}
		16.500000% {
			opacity: 0;
		}
		27.500000% {
			opacity: 1;
		}
		53.500000% {
			opacity: 1;
		}
		65.500000% {
			opacity: 0;
		}
		100% {
			opacity: 0;
		}
	}
	#eo777urdvrca7 {
		animation: eo777urdvrca7_c_o 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca7_c_o {
		0% {
			opacity: 0;
		}
		32% {
			opacity: 0;
		}
		43% {
			opacity: 1;
		}
		69% {
			opacity: 1;
		}
		81% {
			opacity: 0;
		}
		100% {
			opacity: 0;
		}
	}
	#eo777urdvrca8_tr {
		animation: eo777urdvrca8_tr__tr 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca8_tr__tr {
		0% {
			transform: translate(83.499401px, 107.775513px) rotate(0deg);
		}
		8.500000% {
			transform: translate(83.499401px, 107.775513px) rotate(0deg);
		}
		92.500000% {
			transform: translate(83.499401px, 107.775513px) rotate(360deg);
		}
		100% {
			transform: translate(83.499401px, 107.775513px) rotate(360deg);
		}
	}
	#eo777urdvrca8_ts {
		animation: eo777urdvrca8_ts__ts 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca8_ts__ts {
		0% {
			transform: scale(0, 0);
		}
		41% {
			transform: scale(0, 0);
		}
		55.500000% {
			transform: scale(1, 1);
		}
		88% {
			transform: scale(1, 1);
		}
		99.500000% {
			transform: scale(0, 0);
		}
		100% {
			transform: scale(0, 0);
		}
	}
	#eo777urdvrca10_tr {
		animation: eo777urdvrca10_tr__tr 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca10_tr__tr {
		0% {
			transform: translate(29.977854px, -10.265272px) rotate(0deg);
		}
		100% {
			transform: translate(29.977854px, -10.265272px) rotate(360deg);
		}
	}
	#eo777urdvrca10_ts {
		animation: eo777urdvrca10_ts__ts 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca10_ts__ts {
		0% {
			transform: scale(0, 0);
		}
		15% {
			transform: scale(1, 1);
		}
		43.500000% {
			transform: scale(1, 1);
		}
		60% {
			transform: scale(0, 0);
		}
		100% {
			transform: scale(0, 0);
		}
	}
	#eo777urdvrca13 {
		animation: eo777urdvrca13_c_o 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca13_c_o {
		0% {
			opacity: 0;
		}
		21% {
			opacity: 0;
		}
		32% {
			opacity: 1;
		}
		58% {
			opacity: 1;
		}
		70% {
			opacity: 0;
		}
		100% {
			opacity: 0;
		}
	}
	#eo777urdvrca14_tr {
		animation: eo777urdvrca14_tr__tr 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca14_tr__tr {
		0% {
			transform: translate(108.943573px, 40.321768px) rotate(0deg);
		}
		100% {
			transform: translate(108.943573px, 40.321768px) rotate(360deg);
		}
	}
	#eo777urdvrca14_ts {
		animation: eo777urdvrca14_ts__ts 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca14_ts__ts {
		0% {
			transform: scale(0, 0);
		}
		18.500000% {
			transform: scale(0, 0);
		}
		29.500000% {
			transform: scale(1, 1);
		}
		56.500000% {
			transform: scale(1, 1);
		}
		67.500000% {
			transform: scale(0, 0);
		}
		100% {
			transform: scale(0, 0);
		}
	}
	#eo777urdvrca17 {
		animation: eo777urdvrca17_c_o 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca17_c_o {
		0% {
			opacity: 0;
		}
		25.500000% {
			opacity: 0;
		}
		36.500000% {
			opacity: 1;
		}
		62.500000% {
			opacity: 1;
		}
		74.500000% {
			opacity: 0;
		}
		100% {
			opacity: 0;
		}
	}
	#eo777urdvrca18_tr {
		animation: eo777urdvrca18_tr__tr 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca18_tr__tr {
		0% {
			transform: translate(17.944385px, 78.248875px) rotate(0deg);
		}
		100% {
			transform: translate(17.944385px, 78.248875px) rotate(360deg);
		}
	}
	#eo777urdvrca18_ts {
		animation: eo777urdvrca18_ts__ts 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca18_ts__ts {
		0% {
			transform: scale(0, 0);
		}
		29.500000% {
			transform: scale(0, 0);
		}
		42% {
			transform: scale(1, 1);
		}
		67.500000% {
			transform: scale(1, 1);
		}
		80% {
			transform: scale(0, 0);
		}
		100% {
			transform: scale(0, 0);
		}
	}
	#eo777urdvrca20_tr {
		animation: eo777urdvrca20_tr__tr 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca20_tr__tr {
		0% {
			transform: translate(21.652274px, 130.334346px) rotate(-11.368462deg);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		25% {
			transform: translate(21.652274px, 130.334346px) rotate(10.778259deg);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		50% {
			transform: translate(21.652274px, 130.334346px) rotate(-11.368462deg);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		75% {
			transform: translate(21.652274px, 130.334346px) rotate(10.778259deg);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		100% {
			transform: translate(21.652274px, 130.334346px) rotate(-11.368462deg);
		}
	}
	#eo777urdvrca20_ts {
		animation: eo777urdvrca20_ts__ts 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca20_ts__ts {
		0% {
			transform: scale(1, 1);
		}
		12.500000% {
			transform: scale(1, 0.89348);
			animation-timing-function: cubic-bezier(0.305, 0, 1, 1);
		}
		25% {
			transform: scale(1, 1);
			animation-timing-function: cubic-bezier(0.305, 0, 1, 1);
		}
		36% {
			transform: scale(1, 1.064975);
		}
		50% {
			transform: scale(1, 1);
		}
		62.500000% {
			transform: scale(1, 0.89348);
			animation-timing-function: cubic-bezier(0.305, 0, 1, 1);
		}
		75% {
			transform: scale(1, 1);
			animation-timing-function: cubic-bezier(0.305, 0, 1, 1);
		}
		86% {
			transform: scale(1, 1.064975);
		}
		100% {
			transform: scale(1, 1);
		}
	}
	#eo777urdvrca23 {
		animation: eo777urdvrca23_s_do 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca23_s_do {
		0% {
			stroke-dashoffset: -229.1;
		}
		100% {
			stroke-dashoffset: 230;
		}
	}
	#eo777urdvrca24_to {
		animation: eo777urdvrca24_to__to 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca24_to__to {
		0% {
			transform: translate(60.674093px, 27.78164px);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		25% {
			transform: translate(64.435317px, 30.684733px);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		50% {
			transform: translate(60.674093px, 27.78164px);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		75% {
			transform: translate(64.435317px, 30.684733px);
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
		100% {
			transform: translate(60.674093px, 27.78164px);
		}
	}
	#eo777urdvrca24 {
		animation: eo777urdvrca24_s_do 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca24_s_do {
		0% {
			stroke-dashoffset: 220;
		}
		100% {
			stroke-dashoffset: 60;
		}
	}
	#eo777urdvrca25 {
		animation: eo777urdvrca25_s_do 2000ms linear infinite normal forwards;
	}
	@keyframes eo777urdvrca25_s_do {
		0% {
			stroke-dashoffset: 29;
		}
		25% {
			stroke-dashoffset: 16;
		}
		36% {
			stroke-dashoffset: 9;
		}
		100% {
			stroke-dashoffset: -31;
		}
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TextLabel = styled(Text)`
	&& {
		text-align: center;
		line-height: 135%;
	}
`;

export const MagicWandWithLabel: FunctionComponent<Props> = ({ label }) => {
	const getLabel = () => {
		if (!label) return null;

		return typeof label === 'string' ? (
			<TextLabel size={4}>{label}</TextLabel>
		) : (
			<RichText document={label} />
		);
	};

	return (
		<Container>
			<SpinningWand />
			{getLabel()}
		</Container>
	);
};
