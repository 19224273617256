import { ValueOf } from '../utility';

export const Checkups = {
	BATTERY: 'battery',
	SIGNAL: 'signal',
	SPEED: 'speed',
	DEVICE_SANITIZATION: 'sanitization',
	DEVICE_PERFORMANCE: 'performance',
	DEVICE_PERFORMANCE_YEAR_2: 'performanceYear2',
	DATA_RECOVERY: 'recovery',
	DEVICE_DIAGNOSTICS: 'deviceDiagnostics',
} as const;
export type Checkup = ValueOf<typeof Checkups>;

export enum CheckupCategoryType {
	BATTERY_CHECKUP = 'batteryCheckup',
	SPEED_CHECKUP = 'speedCheckup',
	SIGNAL_CHECKUP = 'signalCheckup',
}

export enum DiagnosticsCategoryType {
	BATTERY_DIAGNOSTICS = 'batteryDiagnostics',
	SIGNAL_DIAGNOSTICS = 'signalDiagnostics',
}

export enum BatteryCheckupTags {
	UNCOVERED_DAMAGE = 'not_covered',
	BROKEN_FRONT_GLASS = 'frontGlassCracked',
	BROKEN_BACK_GLASS = 'backGlassCracked',
	HAZARDOUS_DAMAGE = 'hazardousDamage',
	UNEXPECTED_SHUTDOWNS_ALWAYS = 'turnsOff',
	CHARGES_SLOWLY_ALWAYS = 'slowCharge',
}

export const DiagnosticsCheckupsMap = {
	[DiagnosticsCategoryType.BATTERY_DIAGNOSTICS]: Checkups.BATTERY,
	[DiagnosticsCategoryType.SIGNAL_DIAGNOSTICS]: Checkups.SIGNAL,
} as const;
