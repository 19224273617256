import React, { useCallback, useState } from 'react';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import {
	DiagnosticsPageIds,
	Checkups,
	DiagnosticsCategoryType,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { CheckupContainer, CheckupQuestionsProgress } from '../../../../components';
import { useFlow } from '../../../../providers';
import { BatteryMaxCapacityQuestion } from './BatteryMaxCapacityQuestion';
import { BatteryMessagesQuestion } from './BatteryMessagesQuestion';

const BatteryStatusPage = (): JSX.Element => {
	const {
		updateDiagnostics,
		getDiagnosticsByTestId,
		flow: {
			sessionData: { currentQuestionIndex },
		},
		setCurrentQuestionIndex,
	} = useFlow();

	const initialValue =
		(getDiagnosticsByTestId(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			DiagnosticsPageIds.IOS_BATTERY_MAX_CAPACITY
		) as number) ?? undefined;

	const [maxCapacity, setMaxCapacity] = useState<number>(initialValue);

	const onNextButtonClick = useCallback(() => {
		updateDiagnostics(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			DiagnosticsPageIds.IOS_BATTERY_MAX_CAPACITY,
			maxCapacity
		);

		setCurrentQuestionIndex(currentQuestionIndex + 1);
	}, [currentQuestionIndex, maxCapacity, setCurrentQuestionIndex, updateDiagnostics]);

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<BatteryMaxCapacityQuestion
				maxCapacity={maxCapacity}
				setMaxCapacity={(capacity) => setMaxCapacity(capacity)}
			/>
			<BatteryMessagesQuestion
				onNextClicked={onNextButtonClick}
				isNextEnabled={maxCapacity !== undefined}
			/>
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: DiagnosticsPageIds.IOS_BATTERY_STATUS,
	CheckupType: Checkups.BATTERY,
})(BatteryStatusPage);
