import { Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { CheckupResults } from '../../features/checkupResults/configs';

const resultsCheckupTypes = [Checkups.BATTERY, Checkups.SIGNAL, Checkups.SPEED] as const;
export type ResultsCategoryType = typeof resultsCheckupTypes[number];

export type CalculateResultsFn = (checkupCategory: ResultsCategoryType) => void;

export type ResultsContextType = {
	resultsCalculated: boolean;
	resultsData: CheckupResults;
	calculateResults: CalculateResultsFn;
};
