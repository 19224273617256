export const errorTranslations = {
	'en-US': {
		translation: {
			ErrorIconAlt: 'Error Icons',
			ErrorPageHeader: 'We seem to be having some difficulties',
			ErrorPageParagraph: 'Please tap the button below to try again.',
			ErrorPageTryAgainButton: 'Try Again',
		},
	},
	'en-CA': {
		translation: {
			ErrorIconAlt: 'Error Icons',
			ErrorPageHeader: 'We seem to be having some difficulties',
			ErrorPageParagraph: 'Please tap the button below to try again.',
			ErrorPageTryAgainButton: 'Try Again',
		},
	},
	'fr-CA': {
		translation: {
			ErrorIconAlt: 'Icône d’erreur',
			ErrorPageHeader: 'Nous semblons éprouver des difficultés',
			ErrorPageParagraph: 'Veuillez appuyer sur le bouton ci-dessous pour réessayer.',
			ErrorPageTryAgainButton: 'Réessayer',
		},
	},
};
