export const servicePageQuery = `
query ServicePage($serviceId: String!, $partnerTag: String, $locale: String) {
    servicePageCollection(
        limit: 1,  
        where: {
            id: $serviceId,
            contentfulMetadata: {tags: {id_contains_all: [$partnerTag]}}
        },
        locale: $locale
    ) {
        items {
            id
            pageTitle
            commonTag
            breadcrumbTitle
            breadcrumbSubtitle
            heroImage {
                url
            }
            filtersCollection {
                items {
                    id
                    label
                    url
                    values
                }
            }
            replacementEligibility {
                entryName
                type
                title
                description
                cardImage {
                    entryName
                    asset {
                        title
                        description
                        url
                    }
                    alternativeText
                }
                mainCta {
                    entryName
                    copy
                }
            }
            serviceListTitle
            serviceListCollection {
                items {
                    id
                    checkupType
                    title
                    description
                    cover {
                        title
                        description
                        url
                    }
                    action
                    location
                    tags
                }
            }
            feedbackSectionTitle 
            positiveFeedbackForm {
                entryName
                header
                subheader
                commentsPlaceholder
                completeMessageCopy
                submitCta {
                    copy
                }
                feedbackChoicesCollection {
                    items {
                        entryName
                        copy
                    }
                }
            }
            negativeFeedbackForm {
                entryName
                header
                subheader
                commentsPlaceholder
                completeMessageCopy
                submitCta {
                    copy
                }
                feedbackChoicesCollection {
                    items {
                        entryName
                        copy
                    }
                }
            }
            contactExpertCard {
                entryName
                coverImage {
                    asset {
                        url
                    }
                    alternativeText
                }
                cardHeading {
                    json
                }
                cardDescription
                cardCtAsCollection {
                    items {
                        entryName
                        copy
                        url
                        telephoneNumber
                        action
                    }
                }
            }
        }
    }
}
`;
