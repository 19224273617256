/* eslint-disable react/jsx-no-useless-fragment */
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS } from '@contentful/rich-text-types';
import { defaultRenderOptions } from './renderOptions/defaultRenderOptions';

export type Props = {
	document: Document | string;
	renderOptionsOverride?: Options;
};

const trimEmptyParagraph = (document: Document) => {
	try {
		const { content } = document;
		const lastItem = content[content.length - 1];
		if (
			!lastItem ||
			lastItem.nodeType !== BLOCKS.PARAGRAPH ||
			lastItem.content.length > 1
		) {
			return document;
		}

		if (lastItem.content.length) {
			const node = lastItem.content[0];
			if (node.nodeType !== 'text' || node.value) {
				return document;
			}
		}

		return { ...document, content: content.slice(0, content.length - 1) };
	} catch (error) {
		console.error('failed to trim empty paragraph', document);
		return document;
	}
};

const overrideRenderOptions = ({ renderText, renderMark, renderNode }: Options) => ({
	renderText: renderText ? renderText : defaultRenderOptions.renderText,
	renderMark: {
		...defaultRenderOptions.renderMark,
		...renderMark,
	},
	renderNode: {
		...defaultRenderOptions.renderNode,
		...renderNode,
	},
});

export const RichText = ({ document, renderOptionsOverride = {} }: Props) => (
	<>
		{document &&
			(document as Document).content &&
			documentToReactComponents(
				trimEmptyParagraph(document as Document),
				overrideRenderOptions(renderOptionsOverride)
			)}
	</>
);
