import { useEffect } from 'react';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { PageConfig } from '../types';

export const useViewCheckup = (
	pageId: PageConfig['pageId'],
	extraData?: PageConfig['extraData']
) => {
	const { dispatchEvent } = useDispatchEvent();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		});

		let analyticsData = {
			PageId: pageId,
			CheckupType: Checkups.BATTERY,
		};

		if (extraData) {
			analyticsData = { ...analyticsData, ...extraData };
		}

		dispatchEvent(AnalyticEventType.VIEW, analyticsData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageId]);
};
