import { FC } from 'react';
import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { OSCheckSection } from '../components';
import { IPhoneBatteryHealthFlow } from './IPhoneBatteryHealthFlow';
import { SamsungBatteryCheckupV3 } from './SamsungBatteryCheckupV3';
import { AndroidOthersBatteryCheckupV3 } from './AndroidOthersBatteryCheckupV3';
import {
	DeviceType,
	InitialPageAnswers,
	SamsungBatteryAnswer,
	iPhoneBatteryAnswer,
	AndroidOthersBatteryAnswer,
} from '../answers/types';
import { checkIsMobile } from '../utils';
import { useGetDeviceType } from '../hooks';
import { DeviceProps } from './types';
import styled from 'styled-components';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { Spinner } from '../components/Spinner';

const Container = styled.div`
	${breakpoints.md2} {
		padding-bottom: 2rem;
		padding-top: 2rem;
	}
`;

const SpinnerContainer = styled.div`
	padding-top: 1rem;
`;

type Props = Pick<DeviceProps, 'setAnswers' | 'setTags'> & {
	answers: InitialPageAnswers;
};

const InitialPage: FC<Props> = ({ answers, setAnswers, setTags }) => {
	const deviceType = useGetDeviceType();
	const isMobile = checkIsMobile();
	const pageContent = useCheckupPage('batteryCheckupLayout');

	const onChangeAnswers = (newAnswers: InitialPageAnswers) => {
		setAnswers({
			initialPage: { deviceType, ...newAnswers },
		});
	};

	const renderDeviceCheckupFlow: Record<DeviceType, React.ReactElement> = {
		ios_apple: (
			<IPhoneBatteryHealthFlow
				answers={answers as iPhoneBatteryAnswer}
				setAnswers={onChangeAnswers}
			/>
		),
		android_samsung: (
			<SamsungBatteryCheckupV3
				answers={answers as SamsungBatteryAnswer}
				setAnswers={onChangeAnswers}
				setTags={setTags}
			/>
		),
		android_other: (
			<AndroidOthersBatteryCheckupV3
				answers={answers as AndroidOthersBatteryAnswer}
				setAnswers={onChangeAnswers}
				setTags={setTags}
			/>
		),
	};

	if (!pageContent)
		return (
			<SpinnerContainer>
				<Spinner />
			</SpinnerContainer>
		);

	return (
		<>
			<OSCheckSection pageContent={pageContent} hideOptions={isMobile} />
			<Container>{renderDeviceCheckupFlow[deviceType]}</Container>
		</>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: 'initialPage',
	CheckupType: Checkups.BATTERY,
})(InitialPage);
