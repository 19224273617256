import { PageLoader } from '@soluto-private/mx-asurion-ui-react';
import styled, { CSSProperties } from 'styled-components';

type SpinnerProps = {
	style?: CSSProperties;
};

const SpinnerContainer = styled.div`
	&& {
		overflow-y: hidden;
	}
`;

export const Spinner = ({ style }: SpinnerProps) => {
	return (
		<SpinnerContainer style={style}>
			<PageLoader />
		</SpinnerContainer>
	);
};
