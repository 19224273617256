import React, { FunctionComponent } from 'react';
import { useAnalytics } from 'react-shisell';
import { AnalyticEventType, withExtras } from '@home-diy-toolbox/web/analytics';
import styled from 'styled-components';
import { useArticleRendererContext } from '../../helpers';
import { Partner } from '@home-diy-toolbox/web/common-types';

const AnchorAsButton = styled.a`
	cursor: pointer;
	border-radius: 9999px;
	height: 3rem;
	max-width: 25rem;
	width: 80%;
	margin: 1rem auto 1.5rem auto;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	background-color: transparent;
	border: 1px solid rgb(0, 0, 0);
	color: rgb(0, 0, 0);
`;

export type Props = {
	defaultText: string;
	defaultLink: string;
	alternateText: Array<string>;
	alternateLink: Array<string>;
	requireAuth?: boolean;
};

type TemplateVars = Partial<{
	deviceMakeAndModel: string;
	assetId: string;
	subscriberId: string;
}>;

export const expandTemplateVars = (stringValue: string, tags: TemplateVars): string => {
	for (const key in tags) {
		stringValue = stringValue.replace(`{{${key}}}`, tags[key]);
	}
	return stringValue;
};

const getLinkFromPartner = (
	defaultText: string,
	defaultLink: string,
	alternateText: Array<string>,
	alternateLink: Array<string>,
	partner: Partner,
	templateVars: TemplateVars
) => {
	const devLinkMark = `{{${partner}-dev}}`;
	const prodLinkMark = `{{${partner}}}`;
	const linkMark = process.env.NODE_ENV === 'development' ? devLinkMark : prodLinkMark;
	const rawLink = (
		alternateLink.find((link) => link.includes(linkMark)) ||
		alternateLink.find((link) => link.includes(prodLinkMark)) || // fallback
		defaultLink
	)
		.replace(linkMark, '')
		.replace(prodLinkMark, ''); // fallback

	const link = expandTemplateVars(rawLink, templateVars);

	const textTemplate = `{{${partner}}}`;
	const text = (
		alternateText.find((text) => text.includes(textTemplate)) || defaultText
	).replace(textTemplate, '');

	return { link, text };
};

export const OpenChatButon: FunctionComponent<Props> = ({
	defaultText,
	defaultLink,
	alternateText,
	alternateLink,
}) => {
	const analytics = useAnalytics();

	const { partner, deviceMakeAndModel, assetId, subscriberId } =
		useArticleRendererContext();

	const { link, text } = getLinkFromPartner(
		defaultText,
		defaultLink,
		alternateText,
		alternateLink,
		partner,
		{
			deviceMakeAndModel,
			assetId,
			subscriberId,
		}
	);

	const onClick = () => {
		try {
			const chat: HTMLButtonElement = document.querySelector(
				'[title="ChatBubbleToggle"]'
			);
			chat.click();
		} catch (error) {
			console.log(error);
		}

		analytics &&
			analytics.dispatcher
				.extend(
					withExtras({
						ActionId: 'articleCTAButton',
						CtaText: text,
						CtaLink: link,
					})
				)
				.dispatch(AnalyticEventType.CLICK);
	};

	return (
		<AnchorAsButton tabIndex={0} onClick={onClick}>
			{text}
		</AnchorAsButton>
	);
};
