import { useFlow } from '@home-diy-toolbox/apps/device-care/flow-provider';
import { DeviceType } from '../answers/types';
import { DEVICE_TYPES } from '../answers';

export const useGetDeviceType = (): DeviceType | undefined => {
	const { flow: appFlow } = useFlow();
	const { deviceOS, deviceMake } = appFlow.sessionData;

	const isOtherAndroidDevice =
		deviceOS?.toLowerCase() === 'android' && deviceMake?.toLowerCase() !== 'samsung';

	if (isOtherAndroidDevice) {
		return 'android_other';
	}

	const device =
		`${deviceOS?.toLocaleLowerCase()}_${deviceMake?.toLocaleLowerCase()}` as DeviceType;

	if (DEVICE_TYPES.includes(device)) {
		return device;
	}
};
