import { AnalyticEventType, withExtras } from '@home-diy-toolbox/web/analytics';
import { ChatSender } from '../../senders';
import { ChatInterface } from '../types';
import { Analytics } from 'react-shisell';

declare global {
	interface Window {
		SimplrChat: {
			show: VoidFunction;
			open: (text?: string) => void;
			hide: VoidFunction;
		};
	}

	interface WindowEventMap {
		'simplr-chat': {
			detail?: {
				category: string;
				data: { eventType: string };
			};
		};
	}
}

const simplrAiEvents = {
	WIDGET_INTERACTION_EVENT_OPENED_FROM_LAUNCHER: (analytics: Analytics) => {
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'SIMPLR_CLICKED_open',
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	},
	WIDGET_INTERACTION_EVENT_CLOSE: (analytics: Analytics) => {
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'SIMPLR_CLICKED_close',
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	},
};

export class SimplrChatAI implements ChatInterface<[string, Analytics]> {
	private _isLoaded = false;

	public async init(partner: string, analytics: Analytics) {
		// simplr config script
		const configScript = document.createElement('script');
		configScript.innerHTML = `window.SimplrChatConfig = {
    		widgetId: '${partner}'
		}`;
		document.body.appendChild(configScript);

		// simplr package script
		const script = document.createElement('script');
		script.src = 'https://chat-web.simplr.ai';
		script.defer = true;
		document.body.appendChild(script);

		script.onload = () => {
			this._isLoaded = true;

			window.addEventListener('simplr-chat', (ev) => {
				if (ev.detail?.data) {
					simplrAiEvents[`${ev.detail.category}_${ev.detail.data.eventType}`]?.(
						analytics
					);
				}
			});
		};
	}

	public get isLoaded(): boolean {
		return this._isLoaded;
	}

	public hideMessaging() {
		try {
			window.SimplrChat.hide();
		} catch (e) {
			console.log(`Simplr SDK ERROR: ${e as string}`);
		}
	}

	public async openMessagingOverlay(message?: string): Promise<void> {
		try {
			window.SimplrChat.open(message);
		} catch (e) {
			console.log(`Simplr SDK ERROR: ${e as string}`);
		}
	}

	public showMessaging(): void {
		try {
			window.SimplrChat.show();
		} catch (e) {
			console.log(`Simplr SDK ERROR: ${e as string}`);
		}
	}

	sendHiddenMessage: (message: string) => void;
	attachToContainer: (containerId: string) => Promise<void>;
	sendMessage: (type: string, message: string) => void;
	createSender: () => ChatSender;
}

export const simplrChatAI = new SimplrChatAI();
