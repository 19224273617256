import styled from 'styled-components';
import { Entry } from 'contentful';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { AnswerOption } from '@home-diy-toolbox/web/contentful';
import { servicesMap } from './servicesMapping';
import { ServiceCard } from './ServiceCard';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useHeader } from '../../features/header';
import { useFlow } from '../../providers';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;

	${breakpoints.lg} {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	& > * {
		max-width: 18.4375rem;
	}
`;

export interface ListProps {
	services: Entry<AnswerOption>[];
}

export const getServiceCards = (services) =>
	services.map((services) => {
		const checkup = services.fields.id;
		const serviceRoute = servicesMap[checkup];

		return (
			serviceRoute && (
				<ServiceCard
					id={checkup}
					key={checkup}
					routePath={serviceRoute}
					checkupType={checkup}
					data-cy={checkup}
					{...services.fields}
				/>
			)
		);
	});

export const ServiceList = ({ services }: ListProps): JSX.Element => {
	const { t } = useTranslation();
	const { flow, setCurrentQuestionIndex } = useFlow();
	const { updateHeader } = useHeader();

	useEffect(() => {
		updateHeader(
			{
				title: t('Device Care'),
			},
			undefined,
			() => setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex - 1)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flow.sessionData.currentQuestionIndex, setCurrentQuestionIndex, t]);

	const cards = getServiceCards(services);

	return <Container role="list">{cards}</Container>;
};
