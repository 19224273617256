import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { Text, getFontSize, getFontWeight } from '@soluto-private/mx-asurion-ui-react';
import { Answer, RichText } from '@home-diy-toolbox/web/contentful';
import { RadioButtonWithLabel } from '../../../../components';
import { type SamsungBatteryDiagnosticAnswer } from '../answers/types';
import { type Props } from './types';
import { InputContainer } from './styles';

const Instruction = styled(Text)`
	&& {
		display: block;
		margin-bottom: 0.5rem;
		font-size: ${getFontSize(6)};
		font-weight: ${getFontWeight('feather')};
		max-width: 50rem;
	}
`;

const Guide = styled.button`
	&& {
		display: block;
		text-decoration: underline;
		margin-bottom: 2.25rem;
		font-size: ${getFontSize(3)};
		cursor: pointer;
		background: transparent;
		border: 0;
	}
`;

const Question = styled(Text)`
	&& {
		display: block;
		margin-bottom: 0.5rem;
		font-size: ${getFontSize(4)};
	}
`;

interface SamsungBatteryDiagnosticQuestion {
	instruction: Document;
	guide: Document;
	question: string;
	options: Answer[];
}
interface ThisProps
	extends Props<SamsungBatteryDiagnosticAnswer, SamsungBatteryDiagnosticQuestion> {
	onGuideClick: () => void;
}

export const SamsungBatteryDiagnostic = ({
	initialValue,
	onSetValue,
	onGuideClick,
	content,
}: ThisProps) => {
	const { instruction, guide, question, options } = content;
	const [answer, setAnswer] = useState(initialValue);

	useEffect(() => {
		onSetValue({ value: answer, valid: isValid(answer) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (value) => {
		setAnswer(value);
		onSetValue({ value, valid: isValid(value) });
	};

	const isValid = (answer) => Boolean(answer);

	return (
		<div>
			<RichText
				document={instruction}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => <Instruction>{children}</Instruction>,
					},
				}}
			/>
			<RichText
				document={guide}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => (
							<Guide onClick={() => onGuideClick()}>{children}</Guide>
						),
					},
				}}
			/>
			<Question>{question}</Question>
			{options.map((option) => (
				<InputContainer key={option.value}>
					<RadioButtonWithLabel
						name={option.value}
						value={option.value}
						label={option.text}
						checked={answer === option.value}
						onChange={() => handleChange(option.value)}
					/>
				</InputContainer>
			))}
		</div>
	);
};
