import {
	BatteryCheckupTags,
	CheckupPageIds,
	DiagnosticsPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	AppFlowData,
	CheckupAnswersType,
	DiagnosticsAnswersType,
} from '../../../../providers/flows/models';
import { deviceCareApiClient } from '../../../../clients';

const isBadOrIndeterminate = async (flow: AppFlowData): Promise<boolean> => {
	const {
		batteryCheckup,
		batteryDiagnostics,
		sessionData: { deviceOS, deviceMake, deviceModel },
	} = flow;

	let isUnhealthy = false;

	switch (deviceOS) {
		case 'ios': {
			isUnhealthy = checkiOS(batteryDiagnostics);
			break;
		}
		case 'android': {
			isUnhealthy = await checkAndroid(batteryDiagnostics, deviceModel, deviceMake);
			break;
		}
		default: {
			isUnhealthy = checkUnknownOs(batteryCheckup);
			break;
		}
	}

	return isUnhealthy;
};

const checkiOS = (batteryDiagnostics: DiagnosticsAnswersType) => {
	const batteryPercentage = batteryDiagnostics.get(
		DiagnosticsPageIds.IOS_BATTERY_MAX_CAPACITY
	) as number;

	const batteryMessage = batteryDiagnostics.get(DiagnosticsPageIds.IOS_BATTERY_MESSAGES);

	const unhealthy =
		batteryPercentage > 100 ||
		batteryPercentage < 80 ||
		batteryPercentage === undefined ||
		batteryMessage !== 'none';

	return unhealthy;
};

const checkAndroid = async (
	batteryDiagnostics: DiagnosticsAnswersType,
	deviceModel: string,
	deviceMake: string
) => {
	// TODO: This structure is pretty esoteric, and is not obvious
	const usageTime = JSON.parse(
		(batteryDiagnostics.get(DiagnosticsPageIds.BATTERY_PHONE_USAGE_HABITS) as string) ||
			'{}'
	);

	const batteryLife = batteryDiagnostics.get(DiagnosticsPageIds.BATTERY_LIFE) as number;

	if ([batteryLife, usageTime].some((x) => x === undefined)) {
		return false;
	}

	const result = await deviceCareApiClient.getAndroidBatteryResults({
		usageTime,
		batteryLife,
		asset: { model: deviceModel, make: deviceMake },
	});

	return !result.isHealthy;
};

const checkUnknownOs = (batteryCheckup: CheckupAnswersType) => {
	const batteryIssuesCheckup = batteryCheckup.get(CheckupPageIds.BATTERY_ISSUES);

	const relevantTags = [
		BatteryCheckupTags.UNEXPECTED_SHUTDOWNS_ALWAYS,
		BatteryCheckupTags.CHARGES_SLOWLY_ALWAYS,
	];

	return relevantTags.some((tag) => batteryIssuesCheckup.tags.includes(tag));
};

export { isBadOrIndeterminate };
