import { useHistory } from 'react-router-dom';
import { Pages, StackPagesProps } from '../pages';

export const useOnClickBack = (stack: StackPagesProps) => {
	const history = useHistory();

	const backFuncs: Record<Pages, VoidFunction> = {
		initialPage: () => history.push('/device-care'), // redirect to main page
		otherIssuesPage: () => stack.pop(),
		resultPage: () => stack.pop(),
	};

	return backFuncs;
};
