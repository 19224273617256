import React from 'react';
import styled from 'styled-components';
import { Button, colors, breakpoints, Size } from '@soluto-private/mx-asurion-ui-react';
import { useMediaQuery } from '@home-diy-toolbox/web/utils';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	background-color: ${colors.white};
	border-top: 1px solid ${colors.neutralBrighter};
	padding: 1.5rem 1rem;
	display: flex;
	gap: 0.5rem;
	box-sizing: border-box;

	${breakpoints.md} {
		height: 7rem;
		padding: 1rem 1.5rem;
	}

	${breakpoints.xl} {
		padding: 1rem 3rem;
	}
`;

const ActionButton = styled(Button)`
	flex: 1 1 auto;
	padding: 0.75rem;

	${breakpoints.md} {
		flex: 0 0 auto;
		min-width: 10.4688rem;
	}
`;

const ContinueButton = styled(ActionButton)`
	${breakpoints.md} {
		margin-left: auto;
	}
`;

export const ActionButtons = ({ backButtonConfig, continueButtonConfig }) => {
	const { t } = useTranslation();

	const matchedMedia = useMediaQuery(`(min-width: ${Size.MD}px)`);

	const getBackButtonProps = () => {
		return !matchedMedia
			? { variant: 'outline' as const, color: 'secondary' as const }
			: { color: 'white' as const };
	};

	const noButton = backButtonConfig.hide && continueButtonConfig.hide;
	if (noButton) return null;

	return (
		<Container>
			{!backButtonConfig.hide && (
				<ActionButton
					onClick={() => backButtonConfig.onClick()}
					iconSrc="ArrowLeft"
					{...getBackButtonProps()}
				>
					{backButtonConfig.customLabel || t('Back')}
				</ActionButton>
			)}
			{!continueButtonConfig.hide && (
				<ContinueButton
					onClick={() => continueButtonConfig.onClick()}
					color="secondary"
					disabled={continueButtonConfig.disabled}
				>
					{continueButtonConfig.customLabel || t('Continue')}
				</ContinueButton>
			)}
		</Container>
	);
};
