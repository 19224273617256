import { CheckupQuestionPathnames } from '@home-diy-toolbox/web/common-types/refresh-base';
import { breakpoints, Card, Text, Button } from '@soluto-private/mx-asurion-ui-react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const StyledCard = styled(Card)`
	display: grid;
	padding: 1.5rem;
	gap: 1.5rem;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		'text image'
		'button button';

	${breakpoints.md} {
		grid-template-columns: auto 1fr auto;
		grid-template-areas: 'image text button';
		align-items: center;
	}
`;

const Image = styled.img`
	grid-area: image;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	flex-grow: 1;
	grid-area: text;
`;

const ButtonContainer = styled.div`
	grid-area: button;
	display: inline;
`;

interface Props {
	batteryReplacementEligibilityCard: {
		entryName: string;
		title: string;
		description: string;
		mainCta: {
			copy: string;
		};
		cardImage: {
			asset: {
				url: string;
			};
		};
	};
}

export const BatteryReplacementEligibilityCard = ({
	batteryReplacementEligibilityCard: props,
}: Props): JSX.Element => {
	const history = useHistory();
	return (
		<StyledCard>
			<Image src={props.cardImage.asset.url} alt="" />
			<TextContainer>
				<Text weight="heavy" size={3}>
					{props.title}
				</Text>
				<Text size={3}>{props.description}</Text>
			</TextContainer>
			<ButtonContainer>
				<Button
					iconSide="right"
					iconSrc="ChevronRight"
					variant="outline"
					size="small"
					color="secondary"
					onClick={() =>
						history.push(CheckupQuestionPathnames.BATTERY, {
							from: history.location.pathname,
						})
					}
				>
					{props.mainCta.copy}
				</Button>
			</ButtonContainer>
		</StyledCard>
	);
};
