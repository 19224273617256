import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
	AnalyticEventType,
	useAnalytics,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import { FeedbackFormContent } from '@home-diy-toolbox/web/contentful';
import { FeedbackDropdown } from './FeedbackDropdown';
import StarSelection from './StarSelection';
import ThumbsSelection from './ThumbsSelection';
import { FeedbackComponentTypes, FeedbackTypes } from './types';

export type FeedbackPayload = {
	tags: string[];
	rating: number;
	comment: string;
	isCompleted: boolean;
};

export type FeedbackFormProps = {
	variant: FeedbackComponentTypes;
	type: FeedbackTypes;
	feedbackTitle: string;
	positiveForm: FeedbackFormContent;
	negativeForm: FeedbackFormContent;
	flowId?: string;
	articleId?: string;
	disabled?: boolean;
	analyticsExtras?: Record<string, unknown>;
};

const defaultFeedback: FeedbackPayload = {
	tags: [],
	rating: null,
	comment: undefined,
	isCompleted: false,
};

const Form = styled.form`
	margin: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const FormTitle = styled.div`
	font-size: 24px;
	text-align: center;
	font-weight: lighter;
`;

export const FeedbackForm: FunctionComponent<FeedbackFormProps> = ({
	flowId,
	feedbackTitle,
	variant,
	type,
	positiveForm,
	negativeForm,
	articleId,
	analyticsExtras = null,
}) => {
	const [feedback, setFeedback] = useState<FeedbackPayload>(defaultFeedback);
	const [currentForm, setCurrentForm] = useState<FeedbackFormContent>(negativeForm);
	const analytics = useAnalytics();

	const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		setFeedback({ ...feedback, isCompleted: true });
		analytics.dispatcher
			.extend(
				withExtras({
					FeedbackRating: feedback.rating,
					FeedbackTags: feedback.tags,
					FeedbackComment: feedback.comment,
					FeedbackType: variant,
					FeedbackOn: type,
					EntityId: type === FeedbackTypes.SESSION ? flowId : undefined,
					ArticleId: type === FeedbackTypes.ARTICLE ? articleId : undefined,
					...analyticsExtras,
				})
			)
			.dispatch(AnalyticEventType.SUBMIT);
	};

	useEffect(() => {
		variant === FeedbackComponentTypes.STARS
			? setCurrentForm(feedback.rating < 5 ? negativeForm : positiveForm)
			: setCurrentForm(feedback.rating === 0 ? negativeForm : positiveForm);
	}, [feedback.rating, negativeForm, positiveForm]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setFeedback({
			...feedback,
			tags: [],
		});
	}, [currentForm]); // eslint-disable-line react-hooks/exhaustive-deps

	const onFeedbackChange = (rating: number) => setFeedback({ ...feedback, rating });

	return (
		<Form onSubmit={(e) => e.preventDefault()}>
			<FormTitle>
				{feedback.isCompleted ? currentForm.completeMessageCopy : feedbackTitle}
			</FormTitle>
			{variant === FeedbackComponentTypes.STARS ? (
				<StarSelection
					currentRating={feedback.rating}
					disabled={feedback.isCompleted}
					onChange={onFeedbackChange}
				/>
			) : (
				<ThumbsSelection disabled={feedback.isCompleted} onChange={onFeedbackChange} />
			)}
			{feedback.rating !== null && !feedback.isCompleted && (
				<FeedbackDropdown
					form={currentForm}
					onTagsChange={(tag) =>
						setFeedback({
							...feedback,
							tags: feedback.tags.includes(tag)
								? feedback.tags.filter((existingTag) => existingTag !== tag)
								: [...feedback.tags, tag],
						})
					}
					onCommentsChange={(comment) => setFeedback({ ...feedback, comment })}
					onSubmit={handleSubmit}
					feedback={feedback}
				/>
			)}
		</Form>
	);
};
