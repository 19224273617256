import { createContext, useContext, useEffect, useState } from 'react';
import { Key, storageEvents, ContextKey } from '@soluto-private/mx-context';

const getStorageItem = (key: string): string | null => {
	try {
		const rawValue = sessionStorage.getItem(key);
		console.log('mx-context raw: ', key, rawValue);

		const parsedValue = JSON.parse(rawValue) || null;
		console.log('mx-context parsed: ', key, rawValue);

		return parsedValue;
	} catch (error) {
		console.error('getStorageItem error: ', error);
		return null;
	}
};

export const MxContext = createContext<{
	language: string | null;
	client: string | null;
}>({
	language: getStorageItem(Key.Language),
	client: getStorageItem(Key.Client),
});

export const useMxContext = () => useContext(MxContext);

const useContextValue = (key: ContextKey) => {
	const [value, setValue] = useState(getStorageItem(key));

	useEffect(() => {
		const listener = () => {
			setValue(getStorageItem(key));
		};

		document.addEventListener(storageEvents.keyUpdated(key), listener);

		return () => {
			document.removeEventListener(storageEvents.keyUpdated(key), listener);
		};
	}, [key]);

	return value;
};

export const MxContextProvider = ({ children }) => {
	const client = useContextValue(Key.Client);
	const language = useContextValue(Key.Language);

	return <MxContext.Provider value={{ client, language }}>{children}</MxContext.Provider>;
};
