import { QuestionBlock, useCheckupPage } from '@home-diy-toolbox/web/contentful';
import React from 'react';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { PageBanner } from '../components/PageBanner';
import { PageCard } from '../components/PageCard';
import { QuestionIndex } from '../components/QuestionIndex';
import { SamsungMembersApp } from '../questions';
import { ButtonConfig, Index, OnSetValueFn } from './types';
import { SamsungMembersAppAnswer } from '../answers/types';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

type Props = {
	index: Index;
	initialValue: SamsungMembersAppAnswer;
	onSetValue: OnSetValueFn;
	continueButtonConfig: ButtonConfig;
	backButtonConfig: ButtonConfig;
};

export const SamsungMembersAppPage = ({
	index,
	initialValue,
	onSetValue,
	continueButtonConfig,
	backButtonConfig,
}: Props) => {
	const pageContent = useCheckupPage('samsungMembersApp');

	if (!pageContent) return <PageLoader />;

	const questionBlock = pageContent.blocks.find(
		(block) => block.entryName === 'samsungMembersApp'
	) as QuestionBlock;

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={<PageCard banner={pageContent?.banners[0]} />}
			backButtonConfig={{
				customLabel: pageContent?.ctas.back.copy,
				...backButtonConfig,
			}}
			continueButtonConfig={{
				customLabel: pageContent?.ctas.continue.copy,
				...continueButtonConfig,
			}}
		>
			<QuestionIndex currentIndex={index.currentIndex} total={index.total} />
			<SamsungMembersApp
				onSetValue={onSetValue}
				initialValue={initialValue}
				content={{
					question: pageContent.heading,
					description: pageContent.description,
					options: questionBlock.answers,
				}}
			/>
		</CheckupPageLayout>
	);
};
