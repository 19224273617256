import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
	const getMatches = (query: string): boolean => {
		// Prevents SSR issues
		if (typeof window === 'undefined') return false;

		return window.matchMedia(query).matches;
	};

	const [matches, setMatches] = useState<boolean>(getMatches(query));

	function handleChange() {
		setMatches(getMatches(query));
	}

	useEffect(() => {
		const matchMedia = window.matchMedia(query);
		handleChange();

		// Older browser versions only support matchMedia.addListener.
		if (matchMedia.addEventListener) {
			matchMedia.addEventListener('change', handleChange);
		} else {
			matchMedia.addListener(handleChange);
		}

		return () => {
			if (matchMedia.removeEventListener) {
				matchMedia.removeEventListener('change', handleChange);
			} else {
				matchMedia.removeListener(handleChange);
			}
		};
	}, [query]);

	return matches;
};
