import React, {
	FunctionComponent,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { createClient, ContentfulClientApi, EntryCollection, Entry } from 'contentful';
import {
	AppContent,
	Page,
	ResultPageContent,
	CheckupContentProviderProps,
	Configuration,
	GlobalConfiguration,
	ConfigurationOption,
} from './models';
import { Partner } from '@home-diy-toolbox/web/common-types';
import { Checkup, Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { isNewCheckupExperience } from './isNewCheckupExperience';

const SUPPORTED_DEVICE_MAKES = ['samsung', 'apple', 'other'];

export type ContentfulClientContextType = {
	client: ContentfulClientApi | undefined;
	app: AppContent;
	pages: Page[];
	globalConfiguration: GlobalConfiguration;
	usePageContent: (pageId: string, partner?: Partner, checkupType?: Checkup) => Page;
	useResultPageContent: (pageId: string) => ResultPageContent;
	useDiagnosticsContent: (
		pageId: string,
		os: string,
		make: string,
		partner?: Partner,
		checkupType?: Checkup
	) => Page;
	useGlobalConfiguration: (id: string) => ConfigurationOption[];
};

const defaultContentfulClientContext: ContentfulClientContextType = {
	client: undefined,
	app: null,
	pages: null,
	globalConfiguration: {},
	usePageContent: null,
	useResultPageContent: null,
	useDiagnosticsContent: null,
	useGlobalConfiguration: () => [],
};

const ContentfulCheckupContext = createContext<ContentfulClientContextType>(
	defaultContentfulClientContext
);

export const useCheckupContentContext = () => useContext(ContentfulCheckupContext);

const flattenPages = ({
	checkupQuestions,
	checkupTests,
	genericPages,
}: AppContent): Array<Page> => {
	const pageArray = [];

	if (checkupQuestions)
		checkupQuestions.forEach((checkup) =>
			pageArray.push(...checkup.fields.pages.map((page) => page.fields))
		);

	if (checkupTests)
		checkupTests.forEach((checkup) =>
			pageArray.push(...checkup.fields.pages.map((page) => page.fields))
		);

	if (genericPages) pageArray.push(...genericPages.map((page) => page.fields));

	return pageArray;
};

const determinePageVersion = (
	pageId: string,
	partner?: Partner,
	checkupType?: Checkup
): string => {
	// ATT requested changes and we could only make them for ATT. This adds V3 at the end of pageIds
	// so we can get ATT specific content from contentful. Eventually all partners will be updated
	// and v3 can be the default. This could also be used to map partners to different versions in the future
	// so might be a good idea to keep around
	const suffix =
		isNewCheckupExperience(partner, checkupType) && checkupType === Checkups.BATTERY
			? 'V3'
			: '';

	return pageId + suffix;
};

export const CheckupContentProvider: FunctionComponent<CheckupContentProviderProps> = ({
	spaceId,
	accessToken,
	environment,
	locale,
	partner,
	retryOnError = false,
	children,
}) => {
	const [app, setApp] = useState<AppContent>(null);
	const [pages, setPages] = useState<Array<Page>>(null);
	const [globalConfiguration, setGlobalConfiguration] = useState<GlobalConfiguration>({});
	const contentfulAppName = `${partner}_app`;

	const client = useMemo(
		() =>
			createClient({
				space: spaceId,
				accessToken,
				environment,
				retryOnError,
				retryLimit: 5,
			}),
		[accessToken, environment, retryOnError, spaceId]
	);

	useEffect(() => {
		client
			.getEntries({
				'fields.id': contentfulAppName,
				content_type: 'app',
				include: 10,
				locale,
			})
			.then((res: EntryCollection<AppContent>) => {
				setPages(flattenPages(res.items[0].fields));
				setApp(res.items[0].fields);
			});
	}, [contentfulAppName, locale]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		client
			.getEntries({
				content_type: 'configuration',
			})
			.then(({ items }: EntryCollection<Configuration>) => {
				const entries = items.map(
					({ fields: { id, options } }: Entry<Configuration>) => ({
						[id]: options.map(({ fields }) => fields),
					})
				);
				const configurations = Object.assign({}, ...entries);
				setGlobalConfiguration(configurations);
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const usePageContent = (pageId, partner, checkupType) => {
		const pageToLookup = determinePageVersion(pageId, partner, checkupType);
		const page = pages.find((page) => page.id === pageToLookup);

		if (!page) {
			throw new Error(`PageId ${pageToLookup} not found in contentful entries.`);
		}

		return page;
	};

	const useResultPageContent = (pageId) => {
		const foundResult = app.resultsPages.find(
			(page) => page.fields.id === `${partner}_${pageId}` || page.fields.id === pageId
		);
		return foundResult ? foundResult.fields : undefined;
	};

	const useDiagnosticsContent = (
		pageId: string,
		os: string,
		make: string,
		partner,
		checkupType
	) => {
		const pageToLookup = determinePageVersion(pageId, partner, checkupType);

		const osValue = os.toLowerCase().replace(' ', '_');
		let makeValue = make.toLowerCase().replace(' ', '_');

		if (!SUPPORTED_DEVICE_MAKES.includes(make.toLowerCase())) {
			makeValue = 'other';
		}

		return pages.find((page) =>
			[
				`${osValue}_${makeValue}_${pageToLookup}`,
				`${makeValue}_${pageToLookup}`,
				`${osValue}_${pageToLookup}`,
				`${pageToLookup}`,
			].includes(page.id)
		);
	};

	const useGlobalConfiguration = (id: string) => globalConfiguration?.[id];

	const value: ContentfulClientContextType = {
		client,
		app,
		pages,
		globalConfiguration,
		usePageContent,
		useResultPageContent,
		useDiagnosticsContent,
		useGlobalConfiguration,
	};

	return (
		<ContentfulCheckupContext.Provider value={value}>
			{children}
		</ContentfulCheckupContext.Provider>
	);
};
