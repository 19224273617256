import React, { useCallback, useState, useRef } from 'react';
import {
	AnalyticViewType,
	withAnalyticOnView,
	withAnalytics,
} from '@home-diy-toolbox/web/analytics';
import { useCheckupContentContext, RichText } from '@home-diy-toolbox/web/contentful';
import { PercentageInput } from '@home-diy-toolbox/web/percentage-input';
import {
	ComponentIds,
	DiagnosticsPageIds,
	DiagnosticsCategoryType,
	Checkups,
	DiagnosticsPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import {
	CheckupContainer,
	QuestionHeader,
	NextButton,
	CheckupQuestionsProgress,
	PreviousButton,
} from '../../../../../components';
import { useFlow } from '../../../../../providers';
import { useTranslation } from 'react-i18next';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import { useCheckupPageFlow } from '../../../../../features/checkupFlows';
import { useHistory } from 'react-router-dom';

const ContentContainer = styled.div`
	margin-top: 2em;
	display: flex;
	justify-content: stretch;
	flex-direction: column;
`;

const NextButtonWrapper = styled(NextButton)`
	margin-top: 36px;
`;

const SkipButton = styled(withAnalytics(Button))`
	justify-content: center;
	padding: 0;
	min-height: 0;
`;

const BatteryMaxCapacityPage = (): JSX.Element => {
	const pageId = DiagnosticsPageIds.IOS_BATTERY_MAX_CAPACITY;
	const { t } = useTranslation();
	const history = useHistory();

	const [goNext] = useCheckupPageFlow(
		Checkups.BATTERY,
		DiagnosticsPages.IOS_BATTERY_MAX_CAPACITY
	);

	const { useDiagnosticsContent } = useCheckupContentContext();
	const {
		updateDiagnostics,
		getDiagnosticsByTestId,
		flow: {
			sessionData: { deviceOS, deviceMake, currentQuestionIndex },
		},
		setCurrentQuestionIndex,
	} = useFlow();

	const { questionTitleCopy, instructionsCopy, nextButtonCopy, skipButtonCopy } =
		useDiagnosticsContent(pageId, deviceOS, deviceMake);

	const initialValue =
		(getDiagnosticsByTestId(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId
		) as number) ?? undefined;
	const maxCapacity = useRef<number | undefined>(initialValue);
	const [isNextEnabled, setIsNextEnabled] = useState<boolean>(
		typeof initialValue !== 'undefined'
	);
	const setMaxCapacity = (value: number) => {
		maxCapacity.current = value;
		setIsNextEnabled(typeof value !== 'undefined');
	};

	const onNextButtonClick = useCallback(() => {
		updateDiagnostics(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId,
			maxCapacity.current
		);

		setCurrentQuestionIndex(currentQuestionIndex + 1);

		goNext();
	}, [currentQuestionIndex, goNext, pageId, setCurrentQuestionIndex, updateDiagnostics]);

	const onBackButtonClick = () => {
		setCurrentQuestionIndex(currentQuestionIndex - 1);
		history.goBack();
	};

	const onSkipButtonClick = useCallback(() => {
		updateDiagnostics(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId,
			maxCapacity.current
		);

		setCurrentQuestionIndex(currentQuestionIndex + 1);
		goNext();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goNext, pageId, updateDiagnostics]);

	const handleKeyDown = useCallback(
		(key: string) =>
			key === 'Enter' &&
			(isNextEnabled && maxCapacity.current !== 0
				? onNextButtonClick()
				: onSkipButtonClick()),
		[isNextEnabled, onNextButtonClick, onSkipButtonClick]
	);

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<QuestionHeader data-cy={`${ComponentIds.QUESTION_HEADER}`}>
				{questionTitleCopy}
			</QuestionHeader>
			<ContentContainer>
				<PercentageInput
					value={maxCapacity.current}
					setValue={setMaxCapacity}
					aria-label={t('battery_max_capacity_input')}
					onKeyDown={(e) => handleKeyDown(e.key)}
					autoFocus
				/>
				<RichText document={instructionsCopy} />
			</ContentContainer>
			<SkipButton
				color="secondary"
				variant="text"
				onClick={onSkipButtonClick}
				data-cy={'skip-button'}
				actionId={`${pageId}_${ComponentIds.SKIP_TEST_BUTTON}`}
				analyticExtras={{
					CheckupType: Checkups.BATTERY,
					PageId: pageId,
				}}
			>
				{skipButtonCopy}
			</SkipButton>
			<NextButtonWrapper
				type="submit"
				data-cy="next-button"
				onClick={onNextButtonClick}
				disabled={!isNextEnabled}
				actionId={`${pageId}_${ComponentIds.NEXT_BUTTON}`}
				analyticExtras={{
					BatteryMaxCapacity: maxCapacity.current,
					CheckupType: Checkups.BATTERY,
					PageId: pageId,
				}}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
			<PreviousButton onClick={onBackButtonClick} />
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: DiagnosticsPageIds.IOS_BATTERY_MAX_CAPACITY,
	CheckupType: Checkups.BATTERY,
})(BatteryMaxCapacityPage);
