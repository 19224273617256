import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';

const CTACardContainer = styled.div`
	max-width: 382px;
`;

const CTACardParagraph = styled(Text)`
	margin: 1rem 0;
`;

type CTACardProps = {
	title: string;
	body: string;
};

export const CTACard: FunctionComponent<CTACardProps> = ({
	title,
	body,
	children,
	...others
}) => {
	return (
		<CTACardContainer tabIndex={0} {...others}>
			<Text as="span" size={3} weight="heavy">
				{title}
			</Text>
			<CTACardParagraph forwardedAs="p" size={2}>
				{body}
			</CTACardParagraph>
			{children}
		</CTACardContainer>
	);
};
