import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { FC, useMemo } from 'react';
import { useFlow } from '../../providers';
import { OtherServicesProps } from './types';
import { ServiceCard2 } from '../ServiceCards';
import { servicesMap } from '../ServiceCards/servicesMapping';

const Title = styled(Text)`
	&& {
		margin-bottom: 1rem;
		display: inline-block;
	}
`;

const Container = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		flex-direction: row;
		& > * {
			flex: 0 0 18.75rem;
		}
	}
`;

const ScrollableContainer = styled.div`
	display: flex;
	gap: 1em;
	width: 100%;
	overflow: auto;

	& > * {
		flex: 0 0 18.75rem;
	}
`;

export const OldServices: FC<OtherServicesProps> = ({
	title,
	services: answerOptions,
	xScrollable = false,
}) => {
	const { flow } = useFlow();
	const { eligibility } = flow.sessionData;

	const services = useMemo(
		() => answerOptions.filter((answer) => eligibility[answer.id] !== false),
		[answerOptions, eligibility]
	);

	const Wrapper = xScrollable ? ScrollableContainer : Container;

	return (
		<div>
			<Title weight="feather" size={4} forwardedAs="h2">
				{title}
			</Title>
			<Wrapper>
				{services.map((service) => (
					<ServiceCard2
						key={service.id}
						id={service.id}
						routePath={servicesMap[service.checkupType]}
						checkupType={service.checkupType}
						description={service.description}
						copy={service.title}
						tags={service.tags}
						image={service.cover.url}
					/>
				))}
			</Wrapper>
		</div>
	);
};
