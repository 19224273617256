import styled from 'styled-components';
import { Button, colors, Text } from '@soluto-private/mx-asurion-ui-react';
import {
	ContactExpertCard,
	RichText,
	useManageMxClient,
} from '@home-diy-toolbox/web/contentful';
import { withAnalytics } from '@home-diy-toolbox/web/analytics';
import { getChatClient } from '../../features/chat/components';
import { BLOCKS } from '@contentful/rich-text-types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	border: 0.0625rem solid ${colors.neutralBright};
	border-radius: 0.5rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		flex-direction: row;
		align-items: center;
		gap: 1rem;
	}
`;

const ContactInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 1rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		gap: 1rem;
	}

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		flex: 1 1 0;
	}
`;

const Title = styled(Text)`
	&& {
		line-height: 1.15;
	}
`;

const Description = styled(Text)`
	&& {
		line-height: 1.3;
		text-align: inherit;
		color: ${colors.neutralDeepest};
	}
`;

const Buttons = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column-reverse;
	gap: 0.5rem;

	& > * {
		width: 100%;
		max-width: 18.4375rem;

		@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
			width: auto;
		}
	}

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		flex-direction: row;
		justify-content: flex-start;
	}
`;

const Models = styled.img`
	object-fit: cover;
	max-height: 17.5rem;
	min-height: 17.1875rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		min-height: 17.1875rem;
		flex: 0 1 auto;
		min-width: 0;
	}
`;

const AnalyticsButton = withAnalytics(Button);

interface Props {
	from: string;
	expertContactCard: ContactExpertCard;
}

export const ExpertContactCard = ({ from, expertContactCard }: Props) => {
	const partner = useManageMxClient();

	if (!expertContactCard) return null;

	const chatButton = expertContactCard.cardCtAs.find(
		(cardCtA) => cardCtA.entryName === 'chatWithAnExpert'
	);

	const callButton = expertContactCard.cardCtAs.find(
		(cardCtA) => cardCtA.entryName === `${partner}CallButton`
	);

	return (
		<Container data-cy="expertContactCard" data-pw="expertContactCard">
			<Models src={expertContactCard.coverImage.asset.url} alt="protech" />
			<ContactInfo>
				<RichText
					document={expertContactCard.cardHeading}
					renderOptionsOverride={{
						renderNode: {
							[BLOCKS.PARAGRAPH]: (_, children) => (
								<Title weight="feather" size={5}>
									{children}
								</Title>
							),
						},
					}}
				/>
				<Description>{expertContactCard.cardDescription}</Description>
				<Buttons>
					{callButton ? (
						<AnalyticsButton
							color="secondary"
							onClick={() => window.open(`tel:${callButton.telephoneNumber}`, '_self')}
							variant="outline"
							size="medium"
							data-testid="call-btn"
							data-pw="call-btn"
							actionId="expertContactCard_callButton"
							analyticExtras={{ From: from }}
						>
							{callButton.copy}
						</AnalyticsButton>
					) : null}
					{/* Chat button should be the first index */}
					{chatButton ? (
						<AnalyticsButton
							color="secondary"
							onClick={() => getChatClient().openMessagingOverlay()}
							size="medium"
							data-testid="chat-btn"
							data-pw="chat-btn"
							actionId="expertContactCard_chatButton"
							analyticExtras={{ From: from }}
						>
							{chatButton.copy}
						</AnalyticsButton>
					) : null}
				</Buttons>
			</ContactInfo>
		</Container>
	);
};
