import {
	Checkup,
	CheckupResultPathnames,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import type { History } from 'history';

const routeToResultPage = (checkup: Checkup, expectedPath: string, history: History) => {
	// Assume we have navigated somewhere else
	if (history.location.pathname !== expectedPath) {
		return;
	}

	let resultPath = '';
	switch (checkup) {
		case Checkups.BATTERY:
			resultPath = CheckupResultPathnames.BATTERY_RESULT;
			break;
		case Checkups.SIGNAL:
			resultPath = CheckupResultPathnames.SIGNAL_RESULT;
			break;
		case Checkups.SPEED:
			resultPath = CheckupResultPathnames.SPEED_RESULT;
			break;
		default:
			throw new Error('unsupported checkup type');
	}

	history.replace(resultPath, {
		from: expectedPath,
	});
};

export const useResultPageLoading = (checkup: Checkup) => {
	const navigating = useRef(false);
	const history = useHistory();
	const currentPath = useRef(history.location.pathname);

	useEffect(() => {
		const timeoutRef = setTimeout(() => {
			navigating.current = true;
			routeToResultPage(checkup, currentPath.current, history);
		}, 3000);
		return () => clearTimeout(timeoutRef);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
};
