import React, {
	Context,
	createContext,
	FunctionComponent,
	useContext,
	useState,
} from 'react';

export interface Step {
	path?: string;
	label: string;
}

type BreadcrumbContextType = {
	currentSteps: Step[];
	setBreadcrumbSteps: (steps: Step[]) => void;
	resetBreadcrumbSteps: () => void;
};

const defaultBreadcrumbContext = {
	currentSteps: null,
	setBreadcrumbSteps: () => undefined,
	resetBreadcrumbSteps: () => undefined,
};

const BreadcrumbContext: Context<BreadcrumbContextType> = createContext(
	defaultBreadcrumbContext
);
BreadcrumbContext.displayName = 'Breadcrumb';

export const useBreadcrumb = () => useContext(BreadcrumbContext);

export const BreadcrumbProvider: FunctionComponent = ({ children }) => {
	const [currentSteps, setSteps] = useState<Step[]>(null);
	const setBreadcrumbSteps = (steps?: Step[]) => {
		setSteps(steps);
	};
	const resetBreadcrumbSteps = () => {
		setSteps(null);
	};

	const value = {
		currentSteps,
		setBreadcrumbSteps,
		resetBreadcrumbSteps,
	};

	return (
		<BreadcrumbContext.Provider value={value}>{children}</BreadcrumbContext.Provider>
	);
};
