import React, { useCallback, useState } from 'react';
import {
	NumericFormat,
	NumberFormatValues,
	NumericFormatProps,
	InputAttributes,
} from 'react-number-format';
import styled from 'styled-components';
import { colors, getFontSize, Text } from '@soluto-private/mx-asurion-ui-react';

const InputContainer = styled.div`
	position: relative;
	justify-content: end;
	box-sizing: border-box;
	height: 3.5rem;
	border: 1px solid ${colors.neutralDeep};
	border-radius: 0.5rem;
	outline-style: solid;
	outline-color: ${colors.black};
	background-color: ${colors.white};
	margin-left: 0.1rem;
	margin-right: 0.1rem;
`;

const Label = styled(Text)`
	display: block;
	position: absolute;
	top: 0.625rem;
	left: 0.75rem;
	color: ${colors.neutralDeep};
`;

const Input = styled(NumericFormat)`
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0 0.75rem;
	border: 0;
	outline: 0;
	font-size: ${getFontSize(2)};
	font-family: inherit;
	background: none;
`;

type Props = NumericFormatProps<InputAttributes> & {
	setValue: (percentage: number) => void;
	label?: string;
};

const withPercentageLimit = (value?: number) =>
	typeof value === 'undefined' || value <= 100;

export const PercentageInput = ({
	setValue,
	label,
	onBlur,
	...rest
}: Props): JSX.Element => {
	const [focused, setFocused] = useState(false);
	const handleValueChange = useCallback(
		({ floatValue }: NumberFormatValues) => setValue(floatValue),
		[setValue]
	);

	const getInputContainerStyle = () => {
		return {
			outlineWidth: focused ? '2px' : 0,
			borderColor: focused ? 'transparent' : colors.neutralDeep,
		};
	};

	const getInputStyle = () => {
		return label ? { paddingTop: '1.125rem' } : null;
	};

	return (
		<InputContainer style={getInputContainerStyle()}>
			{label ? <Label size={1}>{label}</Label> : null}
			<Input
				inputMode={'numeric'}
				style={getInputStyle()}
				placeholder={'00%'}
				allowNegative={false}
				allowLeadingZeros={false}
				suffix="%"
				decimalScale={0}
				isAllowed={({ floatValue }) => withPercentageLimit(floatValue)}
				onValueChange={handleValueChange}
				data-cy={'PercentageInput'}
				onFocus={() => setFocused(true)}
				onBlur={(e) => {
					setFocused(false);
					onBlur?.(e);
				}}
				{...rest}
			/>
		</InputContainer>
	);
};
