import { AppError, Partner, Partners } from '@home-diy-toolbox/web/common-types';
import {
	SupportedCheckupResults,
	RESULT_PAGES_CONFIGS,
	ResultsModelsByCheckup,
} from '../configs';
import { ResultsConfig, ValidResultPageIds, ResultPageConfig } from '../types';

/** Will find the appropriate results based on existing configs and the provided parameters */
export const findResultPage = async <TCheckup extends SupportedCheckupResults>(
	checkup: TCheckup,
	results: ResultsModelsByCheckup[TCheckup],
	partner: Partner
) => {
	const configs = await getDefaultConfigs(checkup, partner);

	let found: ValidResultPageIds | null = null;
	let defaultPage: ValidResultPageIds | null = null;

	for (let i = 0; i < configs.length; i++) {
		const config = configs[i];

		if (!(config as ResultPageConfig<TCheckup>).results) {
			defaultPage = config.pageId;
			continue;
		}

		const resultsConfig = (config as ResultPageConfig<TCheckup>).results;
		let allKeysMatch = true;
		for (const key in resultsConfig) {
			if (resultsConfig[key] !== results[key]) {
				allKeysMatch = false;
			}
		}

		if (allKeysMatch) {
			found = config.pageId;
			break;
		}
	}

	if (!defaultPage && !found) {
		throw new AppError('No result page found, and no default page exists', {
			checkup,
			results,
			partner,
		});
	}

	return found || defaultPage;
};

const getDefaultConfigs = async <TCheckup extends SupportedCheckupResults>(
	checkup: TCheckup,
	partner: Partner
): Promise<ResultsConfig<TCheckup>> => {
	return (
		RESULT_PAGES_CONFIGS[checkup][partner] ||
		(RESULT_PAGES_CONFIGS[checkup][Partners.DEFAULT] as ResultsConfig<TCheckup>)
	);
};
