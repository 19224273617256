import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react-v3';
import { FC } from 'react';
import styled from 'styled-components';

const BackButton = styled(Button)`
	&& {
		flex-grow: 0 !important;
		padding: 0.75rem 0;
	}
`;

const ContinueButton = styled(Button)`
	flex: 1;
	max-width: 16.5625rem;
	${breakpoints.md2} {
		flex: unset;
	} ;
`;

export type NavButtonsProps = {
	onBack?: VoidFunction; // if undefined, meaning there's no back button
	disabledBack?: boolean;
	isBackLoading?: boolean;
	onContinue?: VoidFunction; // if undefined, meaning there's no continue button
	disabledContinue?: boolean;
	isContinueLoading?: boolean;
};

export const NavButtons: FC<NavButtonsProps> = ({
	onBack,
	disabledBack = false,
	isBackLoading = false,
	onContinue,
	disabledContinue = false,
	isContinueLoading = false,
}) => {
	const pageContent = useCheckupPage('batteryCheckupLayout');

	if (!pageContent) {
		return null;
	}

	return (
		<>
			{Boolean(onBack) && (
				<BackButton
					isLoading={isBackLoading}
					onClick={onBack}
					disabled={disabledBack}
					iconSide="left"
					iconSrc="ArrowLeft"
					variant="text"
				>
					{pageContent?.ctas.back.copy}
				</BackButton>
			)}
			{Boolean(onContinue) && (
				<ContinueButton
					isLoading={isContinueLoading}
					onClick={onContinue}
					color="secondary"
					disabled={disabledContinue}
				>
					{pageContent?.ctas.continue.copy}
				</ContinueButton>
			)}
		</>
	);
};
