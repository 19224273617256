import React, { createContext, useContext, useEffect, useState } from 'react';
import { createClient } from 'contentful';
import { PartnerApp } from './models';

const LayoutContentContext = createContext<PartnerApp>(null);

export const useLayoutContentContext = () => useContext(LayoutContentContext);

interface LayoutContentProviderProps {
	accessToken: string;
	children: React.ReactNode;
	environment: string;
	locale: string;
	partner: string;
	spaceId: string;
}

const transformEntry = (entry) => {
	if (!entry) return;

	const isEntry = (x) => x.fields && x.sys;
	const processArray = (arr) =>
		arr.map((item) => {
			if (!isEntry(item)) return item;
			return transformEntry(item);
		});

	if (Array.isArray(entry)) {
		return processArray(entry);
	}
	if (isEntry(entry)) {
		entry = entry.fields;
		const isAsset = entry.file?.url;
		if (isAsset) return entry.file.url;
	}

	const holder = {};
	for (const field of Object.keys(entry)) {
		const value = entry[field];
		if (Array.isArray(value)) {
			holder[field] = processArray(value);
		} else if (isEntry(value)) {
			holder[field] = transformEntry(value);
		} else {
			holder[field] = value;
		}
	}

	return holder;
};

export const LayoutContentProvider = ({
	accessToken,
	children,
	environment,
	locale,
	partner,
	spaceId,
}: LayoutContentProviderProps) => {
	const [partnerApp, setPartnerApp] = useState<PartnerApp>(null);

	useEffect(() => {
		const client = createClient({
			space: spaceId,
			accessToken,
			environment,
		});

		client
			.getEntries<Record<string, unknown>>({
				'fields.id': `${partner}_app`,
				content_type: 'app',
				limit: 1,
				include: 10,
				locale,
			})
			.then((entries) => {
				const app = entries.items[0].fields;
				setPartnerApp({
					checkupServices: transformEntry(app.checkupServices),
					expertContactCard: transformEntry(app.expertContactCard),
					signalPage: transformEntry(app.signalPage),
					speedPage: transformEntry(app.speedPage),
					batteryPage: transformEntry(app.batteryPage),
					articlePage: transformEntry(app.articlePage) || {},
					chatOnlyPagePrompts: transformEntry(app.chatOnlyPagePrompts) || [],
					batteryCheckupPage: transformEntry(app.batteryCheckupPage),
					batteryResults: transformEntry(app.batteryResults),
					batteryResultCards: transformEntry(app.batteryResultCards),
					expertContactBanner: transformEntry(app.expertContactBanner),
				});
			});
	}, [locale, partner, accessToken, environment, spaceId]);

	return (
		<LayoutContentContext.Provider value={partnerApp}>
			{children}
		</LayoutContentContext.Provider>
	);
};
