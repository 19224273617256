import { SentimentOption } from '@home-diy-toolbox/web/common-types/refresh-base';
import { AnswerType } from '../../../../providers/flows/models';
import { BatteryCheckupQuestionsConfig } from '../../types';

export const getBatteryLifeSentiment = (
	config: BatteryCheckupQuestionsConfig,
	batteryLifeCheckup: AnswerType
): SentimentOption => {
	if (!batteryLifeCheckup?.answers?.length) {
		throw new Error('Expected Battery life Checkup to have answers, no answers found');
	}

	const sentiment: SentimentOption = config.batteryLife[batteryLifeCheckup.answers[0]];

	if (!sentiment) {
		throw new Error(
			'Failed to retrieve battery life sentiment, potential answer Id Mismatch'
		);
	}

	return sentiment;
};
