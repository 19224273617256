import styled from 'styled-components';
import { colors } from '@soluto-private/mx-asurion-ui-react';

const Container = styled.div`
	padding: 10px 15px;
	border-radius: 999em;
	color: ${colors.neutralDeepest};
	border: 1px dashed ${colors.neutralDeep};
	margin: 0.125em 1em;
	display: inline-block;
	cursor: pointer;

	&:hover {
		border-color: ${colors.black};
	}
`;

interface Props {
	message: string;
	onClick: typeof Container.defaultProps.onClick;
}

export const ChatButton = ({ message, onClick }: Props) => {
	return <Container onClick={onClick}>{message}</Container>;
};
