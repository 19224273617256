import { Partners, Partner } from '@home-diy-toolbox/web/common-types';
import {
	CheckupPageIds,
	Checkups,
	ResultPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { AppFlowData } from '../../../../providers/flows/models';
import { BATTERY_QUESTIONS_CONFIG } from '../../configs';
import { BatteryCheckResult } from '../../types';
import { findResultPage } from '../../utility';
import { getBatteryLifeSentiment } from './getBatteryLifeSentiment';
import { isBadOrIndeterminate } from './isBadOrIndeterminate';
import { isHazardousDamage } from './isHazardousDamage';
import {
	isBackGlassBroken,
	isFrontGlassBroken,
	isNonAllowableDamage,
} from './isNonAllowableDamage';

export const calculateBatteryResults = async (flow: AppFlowData) => {
	const {
		batteryCheckup,
		sessionData: { eligibility, partner },
	} = flow;
	const phoneDamageCheckup = batteryCheckup.get(CheckupPageIds.PHONE_DAMAGE);
	const batteryLifeCheckup = batteryCheckup.get(CheckupPageIds.BATTERY_LIFE);

	const questionsConfig = await getQuestionsConfig(partner);
	const results: BatteryCheckResult = {
		pageId: ResultPageIds.BATTERY_RESULT_DEFAULT,
		isHazardousDamage: isHazardousDamage(phoneDamageCheckup),
		isBadOrIndeterminate: await isBadOrIndeterminate(flow),
		isNonAllowableDamage: isNonAllowableDamage(phoneDamageCheckup),
		isBackGlassBroken: isBackGlassBroken(phoneDamageCheckup),
		isFrontGlassBroken: isFrontGlassBroken(phoneDamageCheckup),
		isPlanAndDeviceEligible: eligibility.batteryReplacement,
		sentiment: questionsConfig[CheckupPageIds.BATTERY_LIFE]
			? getBatteryLifeSentiment(questionsConfig, batteryLifeCheckup)
			: undefined,
	};
	const pageId = await findResultPage(Checkups.BATTERY, results, partner as Partner);
	results.pageId = pageId;

	return results;
};

const getQuestionsConfig = async (partner: string) => {
	return (
		BATTERY_QUESTIONS_CONFIG[Checkups.BATTERY][partner] ||
		BATTERY_QUESTIONS_CONFIG[Checkups.BATTERY][Partners.DEFAULT]
	);
};
