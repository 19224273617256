import React from 'react';
import { NodeRenderer, Options } from '@contentful/rich-text-react-renderer';
import {
	FunFact,
	RichText,
	EmbeddedContent,
	EmbeddedContentTypes,
	Links as LinkContent,
	LinkTypes,
	ArticleContentTypes,
	StyledImage as StyledImageType,
} from '@home-diy-toolbox/web/contentful';
import { BLOCKS, INLINES, Document } from '@contentful/rich-text-types';
import { FunFactBlock } from './FunFact';
import { Note } from './Note';
import { Phone } from './Phone';
import { LinkURL } from './LinkURL';
import { ArticleAccordion } from './ArticleAccordion';
import { LinkButton } from './LinkButton';
import StyledImage from './StyledImage';
import { OpenChatButon } from './OpenChatButton';
import { Table, TableCell, TableRow, TableBody } from './Table';

type BlockEntries = ArticleContentTypes;
type InlineEntries = Extract<
	ArticleContentTypes,
	ArticleContentTypes.EMBEDDED_CONTENT | ArticleContentTypes.LINKS
>;
type Props = {
	content: Document;
};

const renderEmbeddedContent = ({ title, content, contentType }: EmbeddedContent) => {
	switch (contentType) {
		case EmbeddedContentTypes.INSTRUCTION_NOTE:
			return <Note title={title} content={content} />;
		case EmbeddedContentTypes.ACCORDION:
			return <ArticleAccordion title={title} content={content} />;
	}
};

const renderLinks = ({
	linkType,
	defaultLink,
	defaultText,
	alternativeLink,
	alternativeText,
	requireAuth,
	alternativeNumber,
}: LinkContent) => {
	switch (linkType) {
		case LinkTypes.BUTTON:
			return (
				<LinkButton
					defaultLink={defaultLink || ''}
					defaultText={defaultText || ''}
					alternateLink={alternativeLink || []}
					alternateText={alternativeText || []}
					requireAuth={requireAuth}
				/>
			);
		case LinkTypes.URL:
			return (
				<LinkURL
					defaultLink={defaultLink || ''}
					defaultText={defaultText || ''}
					alternateLink={alternativeLink || []}
					alternateText={alternativeText || []}
				/>
			);
		case LinkTypes.PHONE_NUMBER:
			return (
				<Phone
					defaultLink={defaultLink || ''}
					defaultText={defaultText || ''}
					alternateNumber={alternativeNumber || []}
					alternateText={alternativeText || []}
				/>
			);
		case LinkTypes.OPEN_CHAT_BUTTON:
			return (
				<OpenChatButon
					defaultLink={defaultLink || ''}
					defaultText={defaultText || ''}
					alternateLink={alternativeLink || []}
					alternateText={alternativeText || []}
				/>
			);
	}
};

const blockEntryRenderer: NodeRenderer = (node, children) => {
	const {
		fields,
		sys: {
			contentType: {
				sys: { id: contentTypeId },
			},
		},
	} = node.data.target;

	switch (contentTypeId as BlockEntries) {
		case ArticleContentTypes.FUN_FACT: {
			const { id, header, content } = fields as FunFact;
			return <FunFactBlock key={id} header={header} content={content} />;
		}
		case ArticleContentTypes.EMBEDDED_CONTENT: {
			return renderEmbeddedContent(fields as EmbeddedContent);
		}
		case ArticleContentTypes.LINKS: {
			return renderLinks(fields as LinkContent);
		}
		case ArticleContentTypes.STYLED_IMAGE: {
			const { image, style } = fields as StyledImageType;
			return <StyledImage image={image} style={style} />;
		}
	}
};

const inlineEntryRenderer: NodeRenderer = (node, children) => {
	const {
		fields,
		sys: {
			contentType: {
				sys: { id: contentTypeId },
			},
		},
	} = node.data.target;

	switch (contentTypeId as InlineEntries) {
		case ArticleContentTypes.EMBEDDED_CONTENT: {
			return renderEmbeddedContent(fields as EmbeddedContent);
		}
		case ArticleContentTypes.LINKS: {
			return renderLinks(fields as LinkContent);
		}
	}
};

const renderOptionsOverride: Options = {
	renderNode: {
		[BLOCKS.TABLE]: (node, children) => (
			<Table>
				<TableBody>{children}</TableBody>
			</Table>
		),
		[BLOCKS.TABLE_ROW]: (node, children) => <TableRow>{children}</TableRow>,
		[BLOCKS.TABLE_CELL]: (node, children) => <TableCell>{children}</TableCell>,
		[BLOCKS.EMBEDDED_ENTRY]: blockEntryRenderer,
		[INLINES.EMBEDDED_ENTRY]: inlineEntryRenderer,
	},
};

const RichTextOverride = ({ content }: Props) => {
	return <RichText document={content} renderOptionsOverride={renderOptionsOverride} />;
};

export default RichTextOverride;
