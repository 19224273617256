import { useEffect } from 'react';
import { ResultSectionDivider } from '../../../../components';
import { Text, getFontSize, getFontWeight } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { RichText } from '@home-diy-toolbox/web/contentful';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';

const MainContainer = styled.div`
	width: 100%;
	@media (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const Container = styled.div`
	width: 21.438rem;
`;

const Heading = styled(Text)`
	&& {
		display: block;
		font-size: ${getFontSize(4)};
		font-weight: ${getFontWeight('feather')};
		margin: 0;
		margin-bottom: 0.25rem;

		@media (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
			margin-bottom: 1.5rem;
		}
	}
`;

const Card = styled.div`
	box-shadow: 0rem 0rem 0.25rem 0.125rem #0c0e110a;
	height: 18.625rem;
	border-radius: 0.5rem;
	margin-bottom: 0.264rem;

	&:hover {
		cursor: pointer;
	}
`;

const ImageContainer = styled.div`
	height: 10rem;
`;

const ExpertImg = styled.img`
	border-top-right-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
`;

const TextContainer = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
`;

const TextHeading = styled(Text)`
	font-size: 1.5rem;
	font-weight: 300;
`;

const TextSubHeading = styled(Text)`
	font-size: 0.875rem;
	font-weight: 400;
	color: #5d5e61;
`;

const Divider = styled(ResultSectionDivider)`
	margin-bottom: 1.5rem;
`;

interface Props {
	mainHeading: string;
	cardHeading: Document;
	cardSubheading: string;
	imageUrl: string;
	imageAlt: string;
	isDesktop: boolean;
	onClickBanner: () => void;
}

export const ContactExpertsCard = ({
	mainHeading,
	cardHeading,
	cardSubheading,
	imageUrl,
	imageAlt,
	onClickBanner,
}: Props) => {
	const { dispatchEvent } = useDispatchEvent();

	useEffect(() => {
		dispatchEvent(AnalyticEventType.VIEW, {
			ActionId: 'batteryResultsLiveExpertCard',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Divider />
			<MainContainer>
				<Container>
					<Heading>{mainHeading}</Heading>
					<Card
						onClick={() => {
							onClickBanner();
						}}
					>
						<ImageContainer>
							<ExpertImg src={imageUrl} alt={imageAlt} />
						</ImageContainer>
						<TextContainer>
							<RichText
								document={cardHeading}
								renderOptionsOverride={{
									renderNode: {
										[BLOCKS.PARAGRAPH]: (_, children) => (
											<TextHeading>{children}</TextHeading>
										),
									},
								}}
							/>
							<div>
								<TextSubHeading>{cardSubheading}</TextSubHeading>
							</div>
						</TextContainer>
					</Card>
				</Container>
			</MainContainer>
		</>
	);
};
