import { SelectedOS } from '@home-diy-toolbox/apps/device-care/flow-provider';
import { Card, Text, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { getModelFromMake } from '../../../../utils/device-utils';
import styled from 'styled-components';
import { AnalyticsElementProps, withAnalytics } from '@home-diy-toolbox/web/analytics';

const Flex = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0.8rem 0.5313rem;
	gap: 0.5313rem;
`;

const DeviceCard = styled(Card)<{ selected: boolean }>`
	&& {
		&:hover {
			box-shadow: rgb(0, 0, 0) 0px 0px 0px 1px inset;
		}
		box-shadow: ${(props) =>
			props.selected ? `rgb(0, 0, 0) 0px 0px 0px 1px inset` : 'unset'};
		padding: unset;
		border: solid 1px ${colors.neutralBright};
	}
`;

const DeviceCardWithAnalytics = withAnalytics(DeviceCard);

type CardProps = {
	deviceOsLabel: string;
	deviceOsValue: string;
	deviceMake: string;
	isSelected: boolean;
	onChangeDevice: (device: SelectedOS) => void;
} & AnalyticsElementProps;

export const OSCheckCard = ({
	deviceOsLabel,
	deviceOsValue,
	deviceMake,
	isSelected,
	onChangeDevice,
	actionId,
	analyticExtras = {},
}: CardProps) => {
	return (
		<DeviceCardWithAnalytics
			disableSvgColorOverride
			element="div"
			selected={isSelected}
			onClick={() =>
				onChangeDevice({
					deviceOS: deviceOsValue,
					deviceMake,
					deviceModel: getModelFromMake(deviceMake),
				})
			}
			type="selection"
			actionId={actionId}
			analyticExtras={analyticExtras}
		>
			<Flex data-pw={`osCheck_${deviceMake}`}>
				<Text size={2} weight="feather">
					{deviceOsLabel}
				</Text>
			</Flex>
		</DeviceCardWithAnalytics>
	);
};
