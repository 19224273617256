import { FunctionComponent, useState } from 'react';
import { union } from 'lodash';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	CheckupCategoryType,
	CheckupPageIds,
	ComponentIds,
	CheckupPages,
	DeviceOS,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow } from '../../../../providers';
import { removeArrayFromArray } from '@home-diy-toolbox/web/utils';
import {
	AnswerSelectionInstructionsWrapper,
	CheckupContainer,
	CheckboxWithLabel,
	CheckupQuestionsProgress,
	QuestionHeader,
	NextButton,
	PreviousButton,
} from '../../../../components';
import { useCheckupPageFlow } from '../../../../features/checkupFlows';
import { useHistory } from 'react-router-dom';

const NextButtonWrapper = styled(NextButton)`
	margin-top: 3rem;
`;

const PhoneDamagePage: FunctionComponent = () => {
	const pageId = CheckupPageIds.PHONE_DAMAGE;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;
	const history = useHistory();

	const [goNext] = useCheckupPageFlow(Checkups.BATTERY, CheckupPages.PHONE_DAMAGE);
	const { usePageContent } = useCheckupContentContext();
	const { updateCheckupPage, getCheckupForPageId, setCurrentQuestionIndex, flow } =
		useFlow();
	const { deviceOS, partner } = flow.sessionData;

	const { answers: latestAnswers, tags: latestTags } = getCheckupForPageId(
		CheckupCategoryType.BATTERY_CHECKUP,
		pageId
	) || {
		answers: [],
		tags: [],
	};

	const [selectedPhoneDamageAnswer, setSelectedPhoneDamageAnswer] =
		useState<string[]>(latestAnswers);
	const [selectedPhoneDamageTags, setSelectedPhoneDamageTags] =
		useState<string[]>(latestTags);

	const onAnswerChange = (answerSelected: string, tags: string[] = []) => {
		const answerAlreadyExistsIndex = selectedPhoneDamageAnswer.indexOf(answerSelected);
		const indexOfNone = selectedPhoneDamageAnswer.indexOf('none');

		if (answerAlreadyExistsIndex > -1) {
			selectedPhoneDamageAnswer.splice(answerAlreadyExistsIndex, 1);
			setSelectedPhoneDamageAnswer([...selectedPhoneDamageAnswer]);
			setSelectedPhoneDamageTags(removeArrayFromArray(selectedPhoneDamageTags, tags));
		} else {
			if (answerSelected === 'none') {
				setSelectedPhoneDamageAnswer([answerSelected]);
				setSelectedPhoneDamageTags(tags);
			} else {
				const updatedState =
					indexOfNone > -1
						? [answerSelected]
						: [...selectedPhoneDamageAnswer, answerSelected];
				setSelectedPhoneDamageAnswer(updatedState);

				const updatedTags =
					indexOfNone > -1 ? tags : union(selectedPhoneDamageTags, tags);
				setSelectedPhoneDamageTags(updatedTags);
			}
		}
	};

	const onNextButtonClick = () => {
		updateCheckupPage(
			CheckupCategoryType.BATTERY_CHECKUP,
			pageId,
			selectedPhoneDamageAnswer,
			selectedPhoneDamageTags
		);

		setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex + 1);

		goNext({
			answers: selectedPhoneDamageAnswer,
			tags: selectedPhoneDamageTags,
			deviceOS: deviceOS as DeviceOS,
		});
	};
	const onBackButtonClick = () => {
		setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex - 1);
		history.goBack();
	};

	const { answerOptions, questionTitleCopy, instructionsCopy, nextButtonCopy } =
		usePageContent(pageId, partner, Checkups.BATTERY);

	const renderAnswerOptions = () =>
		answerOptions.map(({ fields: { id: answerId, copy, tags, description } }) => {
			const buttonId = `${pageId}_${answerId}`;
			return (
				<CheckboxWithLabel
					description={description}
					value={answerId}
					label={copy}
					name={`${pageId}_answerOptions`}
					key={buttonId}
					data-cy={buttonId}
					checked={selectedPhoneDamageAnswer.includes(answerId.toLowerCase())}
					onChange={() => onAnswerChange(answerId, tags)}
				/>
			);
		});

	const isNextButtonDisabled = () => selectedPhoneDamageAnswer.length === 0;

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={flow.sessionData.currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<QuestionHeader>{questionTitleCopy}</QuestionHeader>
			<AnswerSelectionInstructionsWrapper>
				<RichText document={instructionsCopy} />
			</AnswerSelectionInstructionsWrapper>
			{renderAnswerOptions()}
			<NextButtonWrapper
				actionId={nextButtonId}
				data-cy="next-button"
				disabled={isNextButtonDisabled()}
				onClick={onNextButtonClick}
				analyticExtras={{
					SelectedAnswers: selectedPhoneDamageAnswer,
					CheckupType: Checkups.BATTERY,
				}}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
			<PreviousButton onClick={onBackButtonClick} />
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.PHONE_DAMAGE,
	CheckupType: Checkups.BATTERY,
})(PhoneDamagePage);
