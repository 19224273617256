import { parse } from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

export const getQueryParam = (param: string) => {
	const locationParams = window.location.search;
	return new URLSearchParams(locationParams).get(param);
};

export const getAllQueryParams = (): {
	[key: string]: string | string[];
} => {
	const allParams = parse(window.location.search);
	return allParams;
};

export const clearAllQueryParams = (history: RouteComponentProps['history']) =>
	history.replace({
		search: undefined,
	});
