export type DeviceType = 'ios_apple' | 'android_samsung' | 'android_other';

export type UserInputsType = string | number | boolean | null;

export enum SAMSUNG_MEMBER {
	YES = 'yes',
	NO = 'no',
}

export enum BATTERY_STATUS {
	GOOD = 'good',
	BAD = 'bad',
	DONT_HAVE_THIS_OPTION = 'iDontHaveThis',
}

export type iPhoneBatteryAnswer = {
	deviceType: 'ios_apple';
	batteryPercentage: string;
	batteryMessage: string;
};

export type SamsungBatteryAnswer = {
	deviceType: 'android_samsung';
	samsungMembersApp: SAMSUNG_MEMBER;
	batteryStatus: BATTERY_STATUS;
	batteryPercentage: UserInputsType;
	chargeLast: UserInputsType;
};

export type AndroidOthersBatteryAnswer = {
	deviceType: 'android_other';
	currentBatteryCharge: UserInputsType;
	chargeLast: UserInputsType | 'cantFindInfo';
};

// Included deviceType here to determine which answers will be required
// TODO: Add more answers here based on the `deviceType`
export type InitialPageAnswers =
	| {
			deviceType: DeviceType;
	  }
	| SamsungBatteryAnswer
	| iPhoneBatteryAnswer
	| AndroidOthersBatteryAnswer;

export type UsageHabitAnswer = {
	call: number;
	video: number;
	socialMedia: number;
	gaming: number;
	audio: number;
};

export type OtherIssueAnswers = {
	issues: string[];
	habits: UsageHabitAnswer;
};

// if BatteryCheckupV3Answers is not supplied any type,
// the `InitialPageAnswers` will be default type
export type BatteryCheckupV3Answers<T extends InitialPageAnswers = InitialPageAnswers> = {
	initialPage?: T;
	otherIssuesPage?: OtherIssueAnswers;
};
