import { useState, useEffect } from 'react';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import {
	RichText,
	Article,
	useArticleContentContext,
	FeedbackChoicesTypes,
	useCheckupPage,
	ListSection,
	FeedbackForm as FeedbackType,
} from '@home-diy-toolbox/web/contentful';
import {
	CheckupContainer,
	ResultPageHeader,
	ArticleSuggestionsSection,
	OtherCheckupSection,
	ResultSectionDivider,
	FeedbackForm,
	FeedbackComponentTypes,
	FeedbackTypes,
} from '../../../components';
import {
	CheckupCategoryType,
	ResultPageIds,
	Checkups,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useResults, useFlow } from '../../../providers';
import { LoadingPage } from '../../LoadingPage';

const ResultContainer = styled(CheckupContainer)`
	padding-top: 25px;
`;

type Blocks = {
	speedFeedbackForm: ListSection;
	speedArticleResultsTitle: ListSection;
	checkupPositive: FeedbackType;
	checkupNegative: FeedbackType;
};

const SpeedResultsPage = () => {
	const {
		resultsData: { pageId },
	} = useResults(Checkups.SPEED);
	const { getSuggestedArticles } = useArticleContentContext();
	const pageContent = useCheckupPage(pageId);
	const [articles, setArticles] = useState<Article[]>([]);

	const {
		i18n: { language },
	} = useTranslation();

	const { flow, getTagsForCheckup } = useFlow();
	const { flowId } = flow;

	useEffect(() => {
		const tags = getTagsForCheckup(CheckupCategoryType.SPEED_CHECKUP);
		getSuggestedArticles(
			tags,
			flow.sessionData.deviceOS,
			CheckupCategoryType.SPEED_CHECKUP
		).then((foundArticles) => {
			setArticles(foundArticles);
		});
	}, [language]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!pageContent) {
		return <LoadingPage />;
	}

	const {
		checkupPositive,
		checkupNegative,
		speedFeedbackForm,
		speedArticleResultsTitle,
	} = pageContent?.blocks.reduce((prev, curr) => {
		prev[curr.entryName] = curr;
		return prev;
	}, {} as Blocks);

	return (
		<ResultContainer>
			<ResultPageHeader>
				<RichText document={pageContent.heading} />
			</ResultPageHeader>
			<ArticleSuggestionsSection
				headerCopy={speedArticleResultsTitle.heading}
				articleSuggestions={articles}
				checkupType={Checkups.SPEED}
			/>
			<ResultSectionDivider />
			<FeedbackForm
				variant={FeedbackComponentTypes.STARS}
				type={FeedbackTypes.SESSION}
				flowId={flowId}
				feedbackTitle={speedFeedbackForm.heading}
				positiveForm={{
					commentsPlaceholder: checkupPositive.commentsPlaceholder,
					completeMessageCopy: checkupPositive.completeMessageCopy,
					feedbackChoices: checkupPositive.feedbackChoices.map((choice) => ({
						id: choice.entryName,
						label: choice.copy,
					})),
					feedbackChoicesType: FeedbackChoicesTypes.NEW,
					formId: checkupPositive.entryName,
					header: checkupPositive.header,
					subheader: checkupPositive.subheader,
					submitButtonCopy: checkupPositive.submitCta.copy,
				}}
				negativeForm={{
					commentsPlaceholder: checkupNegative.commentsPlaceholder,
					completeMessageCopy: checkupNegative.completeMessageCopy,
					feedbackChoices: checkupNegative.feedbackChoices.map((choice) => ({
						id: choice.entryName,
						label: choice.copy,
					})),
					feedbackChoicesType: FeedbackChoicesTypes.NEW,
					formId: checkupNegative.entryName,
					header: checkupNegative.header,
					subheader: checkupNegative.subheader,
					submitButtonCopy: checkupNegative.submitCta.copy,
				}}
				analyticsExtras={{ CheckupType: Checkups.SPEED }}
			/>
			<ResultSectionDivider />
			<OtherCheckupSection />
		</ResultContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: ResultPageIds.SPEED_RESULT,
	CheckupType: Checkups.SPEED,
})(SpeedResultsPage);
