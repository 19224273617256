import React, { FunctionComponent } from 'react';
import { useAnalytics } from 'react-shisell';
import { AnalyticEventType, withExtras } from '@home-diy-toolbox/web/analytics';
import { useArticleRendererContext } from '../../helpers';
import { Link, colors } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { Partners } from '@home-diy-toolbox/web/common-types/refresh-base';

const ExternalLinkImg = (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.5 2.5H17.5V7.5"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.3335 11.6667L17.5002 2.5"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

const AsurionExternalLinkIcon = styled.span`
	width: 1.25rem;
	height: 1.25rem;
	margin-left: 0.125rem;

	& svg {
		stroke: ${({ theme }) => theme.article.externalLinkColor};
	}
`;

const PartnerExternalLinkIcon = styled.span`
	width: 1.25rem;
	height: 1.25rem;
	margin-left: 0.125rem;
	& svg {
		stroke: ${() => colors.black};
	}
`;

export type Props = {
	defaultText: string;
	defaultLink: string;
	alternateText: Array<string>;
	alternateLink: Array<string>;
};

const getLinkFromPartner = (
	defaultText: string,
	defaultLink: string,
	alternateText: Array<string>,
	alternateLink: Array<string>,
	partner: string
) => {
	const link = (
		alternateLink.find((link) => link.includes(`{{${partner}}}`)) || defaultLink
	).replace(`{{${partner}}}`, '');

	const text = (
		alternateText.find((text) => text.includes(`{{${partner}}}`)) || defaultText
	).replace(`{{${partner}}}`, '');

	return { link, text };
};

export const LinkURL: FunctionComponent<Props> = ({
	defaultText,
	defaultLink,
	alternateText,
	alternateLink,
}) => {
	const analytics = useAnalytics();
	const { partner } = useArticleRendererContext();

	const { link, text } = getLinkFromPartner(
		defaultText,
		defaultLink,
		alternateText,
		alternateLink,
		partner.toLowerCase()
	);

	const onClick = () =>
		analytics &&
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'articleExternalLink',
					CtaText: text,
				})
			)
			.dispatch(AnalyticEventType.CLICK);

	return (
		<Link
			tabIndex={0}
			href={link}
			onClick={onClick}
			target="_blank"
			rel="noreferrer noopener"
			role="link"
		>
			{text}
			{partner === Partners.ASURION ? (
				<AsurionExternalLinkIcon>{ExternalLinkImg}</AsurionExternalLinkIcon>
			) : (
				<PartnerExternalLinkIcon>{ExternalLinkImg}</PartnerExternalLinkIcon>
			)}
		</Link>
	);
};
