import { Skeleton } from '@soluto-private/mx-asurion-ui-react';
import ArticleList from './ArticleList';

const ScrollableAricleList = ({ children }) => (
	<ArticleList xScrollable>{children}</ArticleList>
);

export const ArticleSkeleton = ({ xScrollable }: { xScrollable?: boolean }) => (
	<Skeleton
		elementCount={3}
		height="18.9375rem"
		width="100%"
		wrapper={xScrollable ? ScrollableAricleList : ArticleList}
	/>
);
