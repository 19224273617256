import { ValueOf } from '../utility';

export const Partners = {
	DEFAULT: 'asurion',
	VERIZON: 'verizon',
	VZW: 'verizon',
	ATT: 'att',
	ASURION: 'asurion',
	KOODO: 'koodo',
	TELUS: 'telus',
	USCELLULAR: 'uscellular',
	CRICKET: 'cricket',
	COX: 'cox',
	LIBERTY: 'liberty',
} as const;
export type Partner = ValueOf<typeof Partners>;
