import { useState } from 'react';
import styled from 'styled-components';
import { colors, Link, Button, PageLoader } from '@soluto-private/mx-asurion-ui-react';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { InputContainer } from './InputContainer';

const ClaimBanner = styled(InputContainer)`
	row-gap: 1rem;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 1.5rem 1rem 1rem 1rem;
	background-color: ${colors.neutralBrightest};
`;

const Content = styled.div`
	display: flex;
	align-items: flex-start;
	padding: 0;
	column-gap: 0.5rem;
	line-height: 1.5;
`;

const Image = styled.img`
	width: 1.5rem;
	height: 1.5rem;
`;

const Description = styled.span`
	max-width: 39.375rem;
	flex: 1;
`;

const Close = styled(Button)`
	&& {
		padding: 0;
		margin-left: auto;
	}
`;

const ClaimLink = styled(Link)`
	&& {
		margin-left: auto;
	}
`;

export const StartClaimCard = () => {
	const { resultCards } = useCheckupPage('batteryCheckupLayout');
	const [isOpen, setIsOpen] = useState(true);
	const { dispatchEvent } = useDispatchEvent();

	if (!resultCards) return <PageLoader />;

	// Some partners don't have claim
	if (!resultCards.length) return null;

	const {
		cardCta: { copy: claimCtaLabel, url: claimCtaUrl },
		cardHeading: claimDescription,
		coverImage: {
			asset: { url: claimImg },
		},
	} = resultCards[0];

	if (!isOpen) return null;

	return (
		<ClaimBanner>
			<Content>
				<Image src={claimImg} alt="claim" />
				<Description>{claimDescription}</Description>
				<Close
					variant="flat"
					iconSrc="CloseX"
					onClick={() => {
						setIsOpen(!isOpen);
						dispatchEvent(AnalyticEventType.CLICK, {
							ActionId: 'closeClaimClick',
						});
					}}
				/>
			</Content>
			<ClaimLink
				href={claimCtaUrl}
				target="_blank"
				onClick={() => {
					dispatchEvent(AnalyticEventType.CLICK, {
						ActionId: 'startClaimClick',
					});
				}}
			>
				{claimCtaLabel}
			</ClaimLink>
		</ClaimBanner>
	);
};
