import { Deductibles } from '@home-diy-toolbox/shared/clients';
import { Partner, Partners } from '@home-diy-toolbox/web/common-types';
import { Eligibility } from '@home-diy-toolbox/web/common-types/refresh-base';

export interface SelectedOS {
	deviceMake: string;
	deviceOS: string;
	deviceModel: string;
}

export interface DeviceSelectionFlow {
	assetsFetched: boolean;
	selectionRequired: boolean;
	selectionDone: boolean;
}

export interface FlowSessionData extends Partial<SelectedOS> {
	cid?: string;
	utm?: string;
	partner: Partner;
	currentQuestionIndex: number;
	assetId?: string;
	subscriberId?: string;
	deviceMake?: string;
	deviceModel?: string;
	deviceOS?: string;
	deviceType?: string;
	productId?: string;
	purchaseDate?: string;
	redirectpath?: string;
	eligibility: Eligibility;
	deductibles?: Deductibles;
	deviceSelection: DeviceSelectionFlow;
}

export const DEFAULT_FLOW_SESSION: FlowSessionData = {
	cid: undefined,
	partner: Partners.DEFAULT,
	currentQuestionIndex: 0,
	assetId: undefined,
	subscriberId: undefined,
	deviceMake: undefined,
	deviceModel: undefined,
	deviceOS: undefined,
	productId: undefined,
	purchaseDate: undefined,
	deviceType: undefined,
	redirectpath: undefined,
	eligibility: {
		batteryReplacement: false,
		sanitization: false,
		performance: false,
		recovery: false,
		performanceYear2: false,
		deviceDiagnostics: false,
	},
	deviceSelection: {
		assetsFetched: false,
		selectionRequired: false,
		selectionDone: false,
	},
};
