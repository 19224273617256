import { ValueOf } from '../utility';

/** Simple constant keys for Checkup Pages. Used as keys for other constants that reference these pages. */
export const CheckupPages = {
	BATTERY: 'BATTERY',
	BATTERY_INTRO: 'BATTERY_INTRO',
	BATTERY_LIFE: 'BATTERY_LIFE',
	BATTERY_ISSUES: 'BATTERY_ISSUES',
	PHONE_DAMAGE: 'PHONE_DAMAGE',
	PHONE_CHARGER: 'PHONE_CHARGER',
	SIGNAL_ISSUES: 'SIGNAL_ISSUES',
	SIGNAL_ISSUE_PLACES: 'SIGNAL_ISSUE_PLACES',
	SPEED_SENTIMENT: 'SPEED_SENTIMENT',
	PERFORMANCE_ISSUES: 'PERFORMANCE_ISSUES',
	SPEED_ISSUE_FREQUENCY: 'SPEED_ISSUE_FREQUENCY',
	SPEED_ISSUE_FREQUENCY_V2: 'SPEED_ISSUE_FREQUENCY_V2',
	SPEED_ISSUE_OCCURRENCE: 'SPEED_ISSUE_OCCURRENCE',
} as const;
export type CheckupPage = ValueOf<typeof CheckupPages>;

/** Simple constant keys for Diagnostic Pages. Used as keys for other constants that reference these pages.*/
export const DiagnosticsPages = {
	IOS_BATTERY_MESSAGES: 'IOS_BATTERY_MESSAGES',
	IOS_BATTERY_MAX_CAPACITY: 'IOS_BATTERY_MAX_CAPACITY',
	IOS_BATTERY_STATUS: 'IOS_BATTERY_STATUS',
	ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT: 'ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT',
	ANDROID_BATTERY_FULL_CHARGE_LASTS_APPROXIMATELY:
		'ANDROID_BATTERY_FULL_CHARGE_LASTS_APPROXIMATELY',
	ANDROID_REMAINING_BATTERY_LIFE: 'ANDROID_REMAINING_BATTERY_LIFE',
	ANDROID_BATTERY_CHARGE_PERCENTAGE: 'ANDROID_BATTERY_CHARGE_PERCENTAGE',
	BATTERY_PHONE_USAGE_HABITS: 'BATTERY_PHONE_USAGE_HABITS',
	BATTERY_LIFE: 'BATTERY_LIFE',
	SIGNAL_TURN_OFF_WIFI: 'SIGNAL_TURN_OFF_WIFI',
	SIGNAL_UPLOAD_DOWNLOAD: 'SIGNAL_UPLOAD_DOWNLOAD',
	SIGNAL_TEST_COMPLETE: 'SIGNAL_TEST_COMPLETE',
} as const;
export type DiagnosticsPage = ValueOf<typeof DiagnosticsPages>;

export const GenericPageIds = {
	CHECKUP_HOME: 'checkupHomePage',
	OS_SELECTION: 'osSelectionPage',
	ERROR_PAGE: 'errorPage',
	IN_STORE_HOME: 'checkupHomePage',
	NEW_HOME_PAGE: 'newHomePage',
	SIGNAL_PAGE: 'signalPage',
	SPEED_PAGE: 'speedPage',
	BATTERY_PAGE: 'batteryPage',
	ARTICLE_PAGE: 'articlePage',
	CHAT_ONLY: 'chatOnlyPage',
	BATTERY_CHECKUP: 'batteryCheckup',
} as const;

export const BatteryPages = {
	POPULAR: 'POPULAR',
	BETTER_CHARGING: 'BETTER_CHARGING',
	HEALTHIER_BATTERY: 'HEALTHIER_BATTERY',
	CONSERVING_BATTERY: 'CONSERVING_BATTERY',
	ALL: 'ALL',
};

export const BatteryPageIds = {
	[BatteryPages.POPULAR]: 'batteryPopular',
	[BatteryPages.BETTER_CHARGING]: 'batteryBetterCharging',
	[BatteryPages.HEALTHIER_BATTERY]: 'batteryHealthierBattery',
	[BatteryPages.CONSERVING_BATTERY]: 'batteryConservingBattery',
	[BatteryPages.ALL]: 'batteryAll',
} as const;

export const SignalPages = {
	POPULAR: 'POPULAR',
	CALL_QUALITY: 'CALL_QUALITY',
	TEXT_MESSAGES: 'TEXT_MESSAGES',
	DOWNLOADING_CONTENT: 'DOWNLOADING_CONTENT',
	ALL: 'ALL',
};

export const SignalPageIds = {
	[SignalPages.POPULAR]: 'signalPopular',
	[SignalPages.CALL_QUALITY]: 'signalCallQuality',
	[SignalPages.TEXT_MESSAGES]: 'signalTextMessages',
	[SignalPages.DOWNLOADING_CONTENT]: 'signalDownloadingContent',
	[SignalPages.ALL]: 'signalAll',
} as const;

export const SpeedPages = {
	POPULAR: 'POPULAR',
	APP_PERFORMANCE: 'APP_PERFORMANCE',
	LOADING_CONTENT: 'LOADING_CONTENT',
	OPTIMIZATION: 'OPTIMIZATION',
	ALL: 'ALL',
};

export const SpeedPageIds = {
	[SpeedPages.POPULAR]: 'speedPopular',
	[SpeedPages.APP_PERFORMANCE]: 'speedAppPerformance',
	[SpeedPages.LOADING_CONTENT]: 'speedLoadingContent',
	[SpeedPages.OPTIMIZATION]: 'speedOptimization',
	[SpeedPages.ALL]: 'speedAll',
};

export const CheckupPageIds = {
	[CheckupPages.BATTERY_INTRO]: 'batteryIntro',
	[CheckupPages.BATTERY_LIFE]: 'batteryLife',
	[CheckupPages.BATTERY_ISSUES]: 'batteryIssues',
	[CheckupPages.PHONE_DAMAGE]: 'phoneDamage',
	[CheckupPages.PHONE_CHARGER]: 'phoneCharger',
	[CheckupPages.SIGNAL_ISSUES]: 'signalIssues',
	[CheckupPages.SIGNAL_ISSUE_PLACES]: 'signalIssuePlaces',
	[CheckupPages.SPEED_SENTIMENT]: 'speedPerformanceSentiment',
	[CheckupPages.PERFORMANCE_ISSUES]: 'performanceIssues',
	[CheckupPages.SPEED_ISSUE_FREQUENCY]: 'speedIssueFrequency',
	[CheckupPages.SPEED_ISSUE_FREQUENCY_V2]: 'speedIssueFrequency_v2',
	[CheckupPages.SPEED_ISSUE_OCCURRENCE]: 'speedIssueOccurrence',
} as const;
export type CheckupPageId = ValueOf<typeof CheckupPageIds>;

export const DiagnosticsPageIds = {
	[DiagnosticsPages.IOS_BATTERY_MESSAGES]: 'BatteryMessages',
	[DiagnosticsPages.IOS_BATTERY_MAX_CAPACITY]: 'BatteryMaxCapacity',
	[DiagnosticsPages.IOS_BATTERY_STATUS]: 'BatteryStatus',
	[DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT]: 'FullChargeLastsAbout',
	[DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_APPROXIMATELY]:
		'FullChargeLastsApproximately',
	[DiagnosticsPages.ANDROID_REMAINING_BATTERY_LIFE]: 'RemainingBatteryLife',
	[DiagnosticsPages.ANDROID_BATTERY_CHARGE_PERCENTAGE]: 'ChargePercentage',
	[DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS]: 'PhoneUsageHabits',
	[DiagnosticsPages.BATTERY_LIFE]: 'BatteryLife',
	[DiagnosticsPages.SIGNAL_TURN_OFF_WIFI]: 'signalTurnOffWiFi',
	[DiagnosticsPages.SIGNAL_UPLOAD_DOWNLOAD]: 'signalUploadDownload',
	[DiagnosticsPages.SIGNAL_TEST_COMPLETE]: 'signalTestComplete',
} as const;
export type DiagnosticsPageId = ValueOf<typeof DiagnosticsPageIds>;

export const ResultPageIds = {
	BATTERY_RESULT: 'batteryResult',
	BATTERY_RESULT_UNHEALTHY: 'batteryResultUnhealthy',
	BATTERY_RESULT_DEFAULT: 'batteryResultDefault',
	BATTERY_RESULT_DAMAGE: 'batteryResultDamage',
	BATTERY_RESULT_HEALTHY_NO_DAMAGE: 'battery_healthy_no_damage',
	ATT_BATTERY_RESULT_UNHEALTHY_INELIGIBLE: 'battery_unhealthy_ineligible',
	ATT_BATTERY_RESULT_HAZARDOUS_DAMAGE: 'battery_hazardous_damage', // Any Hazardous Damage
	ATT_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE: 'battery_unhealthy_no_damage',
	ATT_BATTERY_RESULT_BROKEN_FRONT_GLASS: 'battery_broken_front_glass',
	ATT_BATTERY_RESULT_BROKEN_BACK_GLASS: 'battery_broken_back_glass',
	DEFAULT_BATTERY_RESULT_UNHEALTHY_INELIGIBLE: 'default_battery_unhealthy_ineligible',
	DEFAULT_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE: 'default_battery_unhealthy_no_damage',
	VERIZON_BATTERY_RESULT_HAZARDOUS_DAMAGE: 'verizon_battery_hazardous_damage', // Any Hazardous Damage
	VERIZON_BATTERY_RESULT_BROKEN_FRONT_GLASS: 'verizon_battery_broken_front_glass',
	VERIZON_BATTERY_RESULT_BROKEN_BACK_GLASS: 'verizon_battery_broken_back_glass',
	COX_BATTERY_RESULT_UNHEALTHY_INELIGIBLE: 'cox_battery_unhealthy_ineligible',
	COX_BATTERY_RESULT_HAZARDOUS_DAMAGE: 'cox_battery_hazardous_damage', // Any Hazardous Damage
	COX_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE: 'cox_battery_unhealthy_no_damage',
	COX_BATTERY_RESULT_BROKEN_FRONT_GLASS: 'cox_battery_broken_front_glass',
	COX_BATTERY_RESULT_BROKEN_BACK_GLASS: 'cox_battery_broken_back_glass',
	USCELLULAR_BATTERY_RESULT_UNHEALTHY_INELIGIBLE:
		'uscellular_battery_unhealthy_ineligible',
	USCELLULAR_BATTERY_RESULT_HAZARDOUS_DAMAGE: 'uscellular_battery_hazardous_damage', // Any Hazardous Damage
	USCELLULAR_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE: 'uscellular_battery_unhealthy_no_damage',
	USCELLULAR_BATTERY_RESULT_BROKEN_FRONT_GLASS: 'uscellular_battery_broken_front_glass',
	USCELLULAR_BATTERY_RESULT_BROKEN_BACK_GLASS: 'uscellular_battery_broken_back_glass',
	CRICKET_BATTERY_RESULT_UNHEALTHY_INELIGIBLE: 'cricket_battery_unhealthy_ineligible',
	CRICKET_BATTERY_RESULT_HAZARDOUS_DAMAGE: 'cricket_battery_hazardous_damage', // Any Hazardous Damage
	CRICKET_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE: 'cricket_battery_unhealthy_no_damage',
	CRICKET_BATTERY_RESULT_BROKEN_FRONT_GLASS: 'cricket_battery_broken_front_glass',
	CRICKET_BATTERY_RESULT_BROKEN_BACK_GLASS: 'cricket_battery_broken_back_glass',
	CALCULATING_BATTERY_RESULT: 'calculatingBatteryResult',
	SIGNAL_RESULT: 'signalResult',
	SIGNAL_RESULT_IDEAL: 'signalResultIdeal',
	SIGNAL_RESULT_ALMOST_IDEAL: 'signalResultAlmostIdeal',
	SIGNAL_RESULT_LESS_THAN_IDEAL: 'signalResultLessThanIdeal',
	SIGNAL_RESULT_POOR: 'signalResultPoor',
	SIGNAL_RESULT_DEFAULT: 'signalResultDefault',
	CALCULATING_SIGNAL_RESULT: 'calculatingSignalResult',
	SPEED_RESULT: 'speedResult',
	SPEED_RESULT_DEFAULT: 'speedResultDefault',
	CALCULATING_SPEED_RESULT: 'calculatingSpeedResult',
	//ToDo - this should be a part of generic pages, but just included along with results Id only since contentful result model is used for article page. This should be refactored. this is not a blocker.
	ARTICLE_PAGE: 'articlePage',
} as const;

export const ComponentIds = {
	NEXT_BUTTON: 'nextButton',
	START_TEST_BUTTON: 'startTestButton',
	SKIP_TEST_BUTTON: 'skipTestButton',
	CANCEL_TEST_BUTTON: 'cancelTestButton',
	PREVIOUS_BUTTON: 'previousButton',
	QUESTION_HEADER: 'questionHeader',
	CHAT_PROMPT_BUTTON: 'chatPromptButton',
} as const;
