import { createGlobalStyle } from 'styled-components';
import { colors } from '@soluto-private/mx-asurion-ui-react';

export const GlobalStyle = createGlobalStyle`
    #oneservice-global-header-content::before {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        box-shadow: 0 0 0 100vmax ${colors.white};
        clip-path: inset(0 -100vmax);
    }
`;
