import { useEffect } from 'react';
import { useHeader } from '../../../../features/header';

export const useUpdateHeader = () => {
	const { updateHeader, currentState } = useHeader();

	useEffect(() => {
		const currentHeaderState = currentState();

		updateHeader({
			subNavConfig: {
				showPartnerLogo: true,
				showBackButton: false,
				title: '',
			},
		});

		return () => updateHeader(currentHeaderState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
