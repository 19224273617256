import React, { FunctionComponent } from 'react';
import { withAnalytics, AnalyticsElementProps } from '@home-diy-toolbox/web/analytics';
import styled from 'styled-components';
import { isSpaceOrEnterPress } from '@home-diy-toolbox/web/utils';
import {
	CheckboxSelectionCardProps,
	CheckboxSelectionCard,
	Text,
	colors,
} from '@soluto-private/mx-asurion-ui-react';

type RadioButtonProps = AnalyticsElementProps &
	Omit<CheckboxSelectionCardProps, 'children'> & {
		name: string;
		value: string;
		label?: string;
		checked?: boolean;
		description?: string;
	};

const AnalyticsCheckbox = withAnalytics(CheckboxSelectionCard);

const ParentContainer = styled.div`
	&& {
		margin-top: 1rem;

		& .cardContent {
			width: 100%;
		}
	}
`;

const LabelAndDescription = styled.div`
	margin-right: auto;
	margin-left: 1rem;
	display: flex;
	flex-direction: column;
`;

export const CheckboxWithLabel: FunctionComponent<RadioButtonProps> = ({
	name,
	value,
	checked = false,
	label,
	onChange,
	actionId,
	analyticExtras = {},
	description,
	...others
}) => {
	const onClick = (event) => {
		onChange(event);
	};

	return (
		<ParentContainer>
			<AnalyticsCheckbox
				checked={checked}
				onKeyDown={(event) => isSpaceOrEnterPress(event) && onClick(event)}
				name={name}
				value={value}
				onChange={onClick}
				data-cy={`option_${value}`}
				contentClassName={'cardContent'}
				actionId={actionId}
				analyticExtras={analyticExtras}
				{...others}
			>
				<LabelAndDescription>
					{label}
					<Text size={1} color={colors.neutralDeeper}>
						{description}
					</Text>
				</LabelAndDescription>
			</AnalyticsCheckbox>
		</ParentContainer>
	);
};
