import { Text } from '@soluto-private/mx-asurion-ui-react';
import React from 'react';
import styled from 'styled-components';

const ComponentWrapper = styled(Text)`
	p {
		margin: 0 0 8px;
	}
`;

export const AnswerSelectionInstructionsWrapper: React.FunctionComponent = ({
	children,
}) => <ComponentWrapper size={3}>{children}</ComponentWrapper>;
