import * as React from 'react';

export const ListBullets = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="#000000"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2 5C2 3.345 3.346 2 5 2C6.654 2 8 3.346 8 5C8 6.654 6.654 8 5 8C3.346 8 2 6.655 2 5ZM4 5C4 5.551 4.449 6 5 6C5.552 6 6 5.551 6 5C6 4.449 5.552 4 5 4C4.449 4 4 4.449 4 5Z"
			fill="#000000"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2 12C2 10.346 3.346 9 5 9C6.654 9 8 10.346 8 12C8 13.654 6.654 15 5 15C3.346 15 2 13.654 2 12ZM4 12C4 12.551 4.449 13 5 13C5.552 13 6 12.551 6 12C6 11.449 5.552 11 5 11C4.449 11 4 11.449 4 12Z"
			fill="#000000"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5 16C3.346 16 2 17.346 2 19C2 20.654 3.346 22 5 22C6.654 22 8 20.654 8 19C8 17.346 6.654 16 5 16ZM5 20C4.449 20 4 19.551 4 19C4 18.449 4.449 18 5 18C5.552 18 6 18.449 6 19C6 19.551 5.552 20 5 20Z"
			fill="#000000"
		/>
		<path d="M10 4H22V6H10V4Z" fill="#000000" />
		<path d="M22 11H10V13H22V11Z" fill="#000000" />
		<path d="M10 18H22V20H10V18Z" fill="#000000" />
	</svg>
);
