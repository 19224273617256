import { FEATURE_LIST } from './flags';

const getKey = (word: string): string => {
	return 'override' + word.charAt(0).toUpperCase() + word.slice(1);
};

const possibleValues = {
	'1': true,
	'0': false,
};

export const overrideFeature = (features: { [key: string]: unknown }) => {
	FEATURE_LIST.forEach((key) => {
		// if this key is present in the sessionstorage `override` + CAPITALIZED_FEATURE_NAME
		// ex. (`overrideBatteryCheckupRedesign2024`= 1 or 0),
		// ignore the value of the feature from `deviceCareApiClient.getFeatureValue` api
		const overrideKey = getKey(key);
		const flagValue = sessionStorage.getItem(overrideKey);

		if (flagValue !== null && flagValue in possibleValues) {
			features[key] = possibleValues[flagValue];
		}
	});

	return features;
};
