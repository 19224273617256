import React from 'react';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { PageBanner } from '../components/PageBanner';
import { PageCard } from '../components/PageCard';
import { QuestionIndex } from '../components/QuestionIndex';
import { UsageHabits } from '../questions';
import { QuestionBlock, useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { ButtonConfig, Index, OnSetValueFn } from './types';
import { UsageHabitAnswer } from '../answers/types';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

type Props = {
	index: Index;
	initialValue: UsageHabitAnswer;
	onSetValue: OnSetValueFn;
	continueButtonConfig: ButtonConfig;
	backButtonConfig: ButtonConfig;
};

export const UsageHabitsPage = ({
	index,
	initialValue,
	onSetValue,
	continueButtonConfig,
	backButtonConfig,
}: Props) => {
	const pageContent = useCheckupPage('batteryUsageHabits');

	if (!pageContent) return <PageLoader />;

	const habits = pageContent.blocks as QuestionBlock[];

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={<PageCard banner={pageContent?.banners[0]} />}
			backButtonConfig={{
				customLabel: pageContent?.ctas.back.copy,
				...backButtonConfig,
			}}
			continueButtonConfig={{
				customLabel: pageContent?.ctas.continue.copy,
				...continueButtonConfig,
			}}
		>
			<QuestionIndex currentIndex={index.currentIndex} total={index.total} />
			<UsageHabits
				onSetValue={onSetValue}
				initialValue={initialValue}
				content={{
					question: pageContent.heading,
					habits: habits.map((habit) => ({
						value: habit.entryName,
						label: habit.question,
						options: habit.answers,
					})),
				}}
			/>
		</CheckupPageLayout>
	);
};
