import { ValueOf } from '../utility';
import {
	BatteryPages,
	CheckupPages,
	DiagnosticsPages,
	SignalPages,
	SpeedPages,
} from './ids';
import { keysAsObject } from '@home-diy-toolbox/web/utils';

export const GenericPathnames = {
	HOME: '/',
	OS_SELECTION: '/os-selection',
} as const;

export const BatteryFilterPathNames = {
	[BatteryPages.POPULAR]: '/battery-popular',
	[BatteryPages.BETTER_CHARGING]: '/battery-better-charging',
	[BatteryPages.HEALTHIER_BATTERY]: '/battery-healthier-battery',
	[BatteryPages.CONSERVING_BATTERY]: '/battery-conserving-battery',
	[BatteryPages.ALL]: '/battery-all',
};

export const SignalFilterPathnames = {
	[SignalPages.POPULAR]: '/signal-popular',
	[SignalPages.CALL_QUALITY]: '/signal-call-quality',
	[SignalPages.TEXT_MESSAGES]: '/signal-text-messages',
	[SignalPages.DOWNLOADING_CONTENT]: '/signal-downloading-content',
	[SignalPages.ALL]: '/signal-all',
};

export const SpeedFilterPathnames = {
	[SpeedPages.POPULAR]: '/speed-popular',
	[SpeedPages.APP_PERFORMANCE]: '/speed-app-performance',
	[SpeedPages.LOADING_CONTENT]: '/speed-loading-content',
	[SpeedPages.OPTIMIZATION]: '/speed-optimization',
	[SpeedPages.ALL]: '/speed-all',
};

export const CheckupQuestionPathnames = {
	[CheckupPages.BATTERY]: '/checkups/battery',
	[CheckupPages.BATTERY_INTRO]: '/checkups/battery/intro',
	[CheckupPages.BATTERY_LIFE]: '/checkups/battery/battery-life',
	[CheckupPages.BATTERY_ISSUES]: '/checkups/battery/battery-issues',
	[CheckupPages.PHONE_DAMAGE]: '/checkups/battery/phone-damage',
	[CheckupPages.PHONE_CHARGER]: '/checkups/battery/phone-charger',
	[CheckupPages.SPEED_ISSUE_FREQUENCY]: '/checkups/speed/speed-issue-frequency',
	[CheckupPages.SPEED_ISSUE_FREQUENCY_V2]: '/checkups/speed/issue-frequency-drg',
	[CheckupPages.PERFORMANCE_ISSUES]: '/checkups/speed/performance-issues',
	[CheckupPages.SPEED_SENTIMENT]: '/checkups/speed/performance-sentiment',
	[CheckupPages.SPEED_ISSUE_OCCURRENCE]: '/checkups/speed/speed-issue-occurrence',
	[CheckupPages.SIGNAL_ISSUES]: '/checkups/signal/signal-issues',
	[CheckupPages.SIGNAL_ISSUE_PLACES]: '/checkups/signal/signal-issue-places',
} as const;
export type CheckupQuestionPath = ValueOf<typeof CheckupQuestionPathnames>;

export const CheckupDiagnosticsPathnames = {
	[DiagnosticsPages.IOS_BATTERY_STATUS]: '/diagnostics/battery/status',
	[DiagnosticsPages.IOS_BATTERY_MESSAGES]: '/diagnostics/battery/battery-message',
	[DiagnosticsPages.IOS_BATTERY_MAX_CAPACITY]: '/diagnostics/battery/max-capacity',
	[DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT]:
		'/diagnostics/battery/full-charge-lasts-about',
	[DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_APPROXIMATELY]:
		'/diagnostics/battery/full-charge-lasts-approximately',
	[DiagnosticsPages.ANDROID_REMAINING_BATTERY_LIFE]:
		'/diagnostics/battery/remaining-battery-life',
	[DiagnosticsPages.ANDROID_BATTERY_CHARGE_PERCENTAGE]:
		'/diagnostics/battery/battery-percentage',
	[DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS]:
		'/diagnostics/battery/phone-usage-habits',
	[DiagnosticsPages.SIGNAL_TURN_OFF_WIFI]: '/diagnostics/signal/turn-off-wifi',
	[DiagnosticsPages.SIGNAL_UPLOAD_DOWNLOAD]: '/diagnostics/signal/upload-download',
	[DiagnosticsPages.SIGNAL_TEST_COMPLETE]: '/diagnostics/signal/test-complete',
} as const;
export type CheckupDiagnosticsPath = ValueOf<typeof CheckupDiagnosticsPathnames>;

export const CheckupResults = {
	BATTERY_RESULT_LOADING: 'BATTERY_RESULT_LOADING',
};

export const CheckupResultPathnames = {
	BATTERY_RESULT_LOADING: '/result/battery/loading',
	BATTERY_RESULT: '/result/battery',
	SIGNAL_RESULT_LOADING: '/result/signal/loading',
	SIGNAL_RESULT: '/result/signal',
	SPEED_RESULT_LOADING: '/result/speed/loading',
	SPEED_RESULT: '/result/speed',
} as const;
export const CheckupResultKeys = keysAsObject(CheckupResultPathnames);
export type CheckupResultPath = ValueOf<typeof CheckupResultPathnames>;
export type CheckupResultKey = keyof typeof CheckupResultPathnames;

export const ArticlePathnames = {
	ARTICLE: '/article/:articleId',
} as const;
