import React from 'react';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { QuestionBlock, useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { PageBanner } from '../components/PageBanner';
import { PageCard } from '../components/PageCard';
import { QuestionIndex } from '../components/QuestionIndex';
import { BatteryCharge } from '../questions';
import { ButtonConfig, Index, OnGuideClickFn, OnSetValueFn } from './types';
import { BatteryChargeAnswer } from '../answers/types';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

type QuestionBlocks = {
	batteryPercentage: QuestionBlock;
	chargeDuration: QuestionBlock;
};

const BATTERY_BLOCK_ENTRY_NAMES = ['batteryPercentage', 'chargeDuration'];

type Props = {
	index: Index;
	batteryCharge: 'androidSamsungBatteryCharge' | 'androidOthersBatteryCharge';
	initialValue: BatteryChargeAnswer;
	onGuideClick: OnGuideClickFn;
	onSetValue: OnSetValueFn;
	continueButtonConfig: ButtonConfig;
	backButtonConfig: ButtonConfig;
};

export const BatteryChargePage = ({
	index,
	batteryCharge,
	initialValue,
	onGuideClick,
	onSetValue,
	continueButtonConfig,
	backButtonConfig,
}: Props) => {
	const pageContent = useCheckupPage(batteryCharge);

	if (!pageContent) return <PageLoader />;

	const { batteryPercentage, chargeDuration } = pageContent.blocks.reduce(
		(batteryBlocks, block) => {
			if (BATTERY_BLOCK_ENTRY_NAMES.includes(block.entryName)) {
				batteryBlocks[block.entryName] = block;
			}
			return batteryBlocks;
		},
		{} as QuestionBlocks
	);

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={<PageCard banner={pageContent?.banners[0]} />}
			backButtonConfig={{
				customLabel: pageContent?.ctas.back.copy,
				...backButtonConfig,
			}}
			continueButtonConfig={{
				customLabel: pageContent?.ctas.continue.copy,
				...continueButtonConfig,
			}}
		>
			<QuestionIndex currentIndex={index.currentIndex} total={index.total} />
			<BatteryCharge
				onSetValue={onSetValue}
				initialValue={initialValue}
				onGuideClick={onGuideClick}
				content={{
					instruction: pageContent.heading,
					guide: pageContent.description,
					currentBatteryChargeQuestion: batteryPercentage.question,
					currentBatteryChargePlaceholder: batteryPercentage.answers[0].placeholder,
					currentBatteryChargeLabel: batteryPercentage.answers[0].fieldTitle,
					currentBatteryChargeDescription: batteryPercentage.answers[0].description,
					remainingTimeQuestion: chargeDuration.question,
					dontHaveOption: chargeDuration.answers.find(
						(answer) => answer.entryName === 'dontHave'
					),
				}}
			/>
		</CheckupPageLayout>
	);
};
