import React from 'react';
import { Banner } from '@home-diy-toolbox/web/contentful';
import { ExpertContactBanner } from './ExpertContactBanner';
import { ImageBanner } from './ImageBanner';
import { getChatClient } from '../../../../features/chat/components';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';

export const PageBanner = ({ banner }: { banner: Banner }) => {
	const { dispatchEvent } = useDispatchEvent();

	if (!banner) return null;

	return banner.type === 'expert' ? (
		<ExpertContactBanner
			title={banner.title}
			subtitle={banner.description}
			imageUrl={banner.bannerImage.asset.url}
			imageAlt={banner.bannerImage.alternativeText}
			callButton={
				banner.mainCta
					? {
							text: banner.mainCta.copy,
							href: `tel:${banner.mainCta.telephoneNumber}`,
							onClick: () => {
								dispatchEvent(AnalyticEventType.CLICK, {
									ActionId: 'expertContactBanner_callButton',
									From: GenericPageIds.BATTERY_CHECKUP,
								});
							},
					  }
					: null
			}
			chatButton={
				banner.secondaryCta
					? {
							text: banner.secondaryCta.copy,
							onClick: () => {
								getChatClient().openMessagingOverlay();
								dispatchEvent(AnalyticEventType.CLICK, {
									ActionId: 'expertContactBanner_chatButton',
									From: GenericPageIds.BATTERY_CHECKUP,
								});
							},
					  }
					: null
			}
		/>
	) : (
		<ImageBanner
			imageUrl={banner.bannerImage.asset.url}
			imageAlt={banner.bannerImage.alternativeText}
		/>
	);
};
