import React, { FunctionComponent } from 'react';
import { Location } from 'history';
import { Redirect, useLocation } from 'react-router-dom';
import { GenericPathnames } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow } from '../../providers';
import { getAllQueryParams } from '@home-diy-toolbox/web/utils';
import { LoadingPage } from '../LoadingPage';

export type RedirectProps = {
	checkupType?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

export const withOSSelection =
	(Component: React.ComponentType): FunctionComponent<RedirectProps> =>
	({ checkupType = undefined, ...otherProps }) => {
		const location: Location<RedirectProps> = useLocation();

		const {
			flow: {
				sessionData: {
					deviceOS,
					deviceSelection: { assetsFetched, selectionRequired, selectionDone },
				},
			},
		} = useFlow();
		if (!deviceOS && assetsFetched && selectionRequired && !selectionDone) {
			// Multi-asset flow: Redirect to device selection page
			const queryParams = getAllQueryParams();
			const deviceSelectionURL = new URL('/devices/select', window.location.origin);
			Object.entries(queryParams).forEach(([key, value]) => {
				if (typeof value === 'string') {
					deviceSelectionURL.searchParams.set(key, value);
				} else {
					value.forEach((v) => deviceSelectionURL.searchParams.set(key, v));
				}
			});
			deviceSelectionURL.searchParams.set('redirectpath', location.pathname);
			deviceSelectionURL.searchParams.set('devicetype', 'phone');
			deviceSelectionURL.searchParams.set('cid', 'dashboard_services_menu');
			window.location.href = deviceSelectionURL.href;
			return <LoadingPage />;
		}
		if (deviceOS && deviceOS !== '') {
			return <Component {...otherProps} />;
		}

		return (
			<Redirect
				to={{
					pathname: GenericPathnames.OS_SELECTION,
					state: { redirectPath: location.pathname, checkupType },
				}}
			/>
		);
	};
