import { FunctionComponent } from 'react';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	CheckupPageIds,
	ComponentIds,
	CheckupPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import styled from 'styled-components';
import { CheckupContainer, NextButton, ImageContainer } from '../../../../components';
import { useCheckupPageFlow } from '../../../../features/checkupFlows';
import { Button, Text } from '@soluto-private/mx-asurion-ui-react';
import { useHistory } from 'react-router-dom';
import { useFlow } from '../../../../providers';

const NextButtonWrapper = styled(NextButton)`
	&& {
		margin-top: 24px;
	}
`;

const BackHomeButton = styled(Button)`
	&& {
		justify-content: start;
		margin-top: 24px;
		padding: 0;
		min-height: 0;
		align-self: center;
	}
`;

const InstructionCopy = styled(Text)`
	line-height: 1.3;
`;

const Header = styled(Text)`
	margin: 0px;
`;

const BatteryIntroPage: FunctionComponent = () => {
	const pageId = CheckupPageIds.BATTERY_INTRO;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;
	const history = useHistory();
	const { usePageContent } = useCheckupContentContext();
	const [goNext] = useCheckupPageFlow(Checkups.BATTERY, CheckupPages.BATTERY_INTRO);
	const {
		setCurrentQuestionIndex,
		flow: {
			sessionData: { partner },
		},
	} = useFlow();

	const onBackButtonClicked = () => {
		history.push('/', {
			from: history.location.pathname,
		});
	};

	const {
		questionTitleCopy,
		instructionsCopy,
		nextButtonCopy,
		questionImage,
		previousButtonCopy,
	} = usePageContent(pageId, partner, Checkups.BATTERY);
	return (
		<CheckupContainer>
			<Header size={5} weight="feather">
				{questionTitleCopy}
			</Header>
			<InstructionCopy size={3}>
				<RichText document={instructionsCopy} />
			</InstructionCopy>
			<ImageContainer>
				<img src={questionImage.fields.file.url} alt="" />
			</ImageContainer>
			<NextButtonWrapper
				onClick={() => {
					goNext();
					setCurrentQuestionIndex(1);
				}}
				actionId={nextButtonId}
				data-cy="next-button"
				analyticExtras={{
					CheckupType: Checkups.BATTERY,
				}}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
			<BackHomeButton
				color="secondary"
				variant="text"
				type="button"
				onClick={onBackButtonClicked}
				data-cy="back-button"
			>
				{previousButtonCopy}
			</BackHomeButton>
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.BATTERY_INTRO,
	CheckupType: Checkups.BATTERY,
})(BatteryIntroPage);
