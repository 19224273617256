import { useState } from 'react';
import { persistFlowToStorage } from '../flowDataAccess';
import { AppFlowData } from '../models';

type UsePersistentFlowReturn = [
	AppFlowData,
	/** Sets the flow data, and persists it to session storage */
	(flow: AppFlowData) => void
];

export const usePersistentFlow = (defaultFlow: AppFlowData): UsePersistentFlowReturn => {
	const [flow, setFlow] = useState<AppFlowData>(defaultFlow);

	const setAndPersistFlow = (flow: AppFlowData) => {
		setFlow({ ...flow });
		persistFlowToStorage(flow);
	};

	return [flow, setAndPersistFlow];
};
