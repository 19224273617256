import { useMemo } from 'react';
import { ContentfulBackend, createI18n } from '@soluto-private/mx-i18n';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';

interface Props {
	namespace: string;
}

export const useI18nClient = ({ namespace }: Props) => {
	const { language } = useValidateMxContext();

	const i18n = useMemo(
		() => {
			const instance = createI18n();

			instance.init({
				plugins: [ContentfulBackend],
				init: {
					ns: [namespace],
					defaultNS: namespace,
					fallbackNS: namespace,
					lng: language,
					interpolation: {
						escapeValue: false,
					},
					load: 'currentOnly',
					backend: {},
				},
			});

			return instance.getClient();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[language]
	);

	return i18n;
};
