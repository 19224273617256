import { AsurionIdApiClient } from '@home-diy-toolbox/shared/clients';
import { environment } from '../environments/environment';
import httpClient from './httpClient';

const asurionIdApiClient = new AsurionIdApiClient({
	domain: environment.asurionId.apiDomain,
	httpClient,
});

export default asurionIdApiClient;
