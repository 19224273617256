import { DeviceType } from './types';

export const DEVICE_TYPES: Readonly<DeviceType[]> = [
	'ios_apple',
	'android_samsung',
	'android_other',
];

export const HABITS_INIT_VALUE = {
	call: 0,
	gaming: 0,
	audio: 0,
	socialMedia: 0,
	video: 0,
} as const;
