import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';
import { AnalyticExtras, withAnalytics } from '@home-diy-toolbox/web/analytics';

const PreviousButtonStyled = styled(withAnalytics(Button))`
	&& {
		margin: 0.5rem 0;
	}
`;

type PreviousButtonProps = ButtonProps & {
	actionId?: string;
	analyticExtras?: AnalyticExtras;
};

export const PreviousButton: FunctionComponent<PreviousButtonProps> = ({
	className,
	actionId,
	analyticExtras,
	onClick,
	...others
}) => {
	const { t } = useTranslation();

	return (
		<PreviousButtonStyled
			color="secondary"
			size="medium"
			variant="flat"
			type="button"
			className={className}
			actionId={actionId}
			analyticExtras={analyticExtras}
			onClick={onClick}
			data-cy="previous-button"
			{...others}
		>
			{t('backButton')}
		</PreviousButtonStyled>
	);
};
