import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { PageBanner } from '../components/PageBanner';
import { PageCard } from '../components/PageCard';
import { ArticleComponent } from '../components';
import { ButtonConfig } from './types';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

type Props = {
	onFirstRender: () => void;
	continueButtonConfig: ButtonConfig;
	articleId: string;
};

export const ArticlePage = ({
	onFirstRender,
	continueButtonConfig,
	articleId,
}: Props) => {
	const pageContent = useCheckupPage('articlePage');

	if (!pageContent) return <PageLoader />;

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={<PageCard banner={pageContent?.banners[0]} />}
			backButtonConfig={{
				hide: true,
			}}
			continueButtonConfig={{
				customLabel: pageContent?.ctas.done.copy,
				...continueButtonConfig,
			}}
		>
			<ArticleComponent
				onFirstRender={onFirstRender}
				articleId={articleId}
				pageContent={pageContent}
			/>
		</CheckupPageLayout>
	);
};
