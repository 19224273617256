import { useState } from 'react';

export function useStackState<T>(initialStack?: T[]) {
	const [stack, setStack] = useState<T[]>(initialStack || []);

	const push = (item: T) => {
		setStack((stack) => [...stack, item]);
	};

	const pop = () => {
		if (!isEmpty()) {
			setStack((stack) => stack.slice(0, -1));
		}
	};

	const peek = () => stack[stack.length - 1];

	const isEmpty = () => stack.length === 0;

	const size = () => stack.length;

	const clear = () => {
		setStack([]);
	};

	return { push, pop, peek, isEmpty, size, clear, stack };
}
