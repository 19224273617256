import React from 'react';
import styled from 'styled-components';

interface Props
	extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	xScrollable?: boolean;
}

const Container = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

const XScrollableContainer = styled(Container)`
	overflow-x: auto;
	grid-template-columns: repeat(3, 1fr);
	& > * {
		min-width: 18.75rem;
	}
	margin-left: -1rem;
	margin-right: -1rem;
	padding: 0 1rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		padding: 0 1.5rem;
	}

	@media (min-width: 964px) {
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}
`;

const ArticleList = ({
	children,
	xScrollable = false,
	className,
	style,
}: Props): JSX.Element => {
	return xScrollable ? (
		<XScrollableContainer
			className={className}
			style={style}
			data-cy="xScrollableArticleList"
			data-pw="xScrollableArticleList"
		>
			{children}
		</XScrollableContainer>
	) : (
		<Container
			className={className}
			style={style}
			data-cy="articleList"
			data-pw="article-list"
		>
			{children}
		</Container>
	);
};

export default ArticleList;
