import React, { FunctionComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
	RouteProps,
	Checkups,
	Partners,
	DiagnosticsPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	withOSSelection,
	BatteryMaxCapacityPage,
	TurnOffWiFiPage,
	SignalUploadDownloadPage,
	SignalDownloadCompletePage,
	FullChargeLastsAbout,
	RemainingBatteryLife,
	BatteryPercentage,
	PhoneUsageHabits,
	BatteryStatusPage,
	FullChargeLastsApproximately,
} from '../pages';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';

const BatteryMaxCapacityWithOS = withOSSelection(BatteryMaxCapacityPage);
const BatteryStatusWithOS = withOSSelection(BatteryStatusPage);
const FullChargeLastsAboutWithOS = withOSSelection(FullChargeLastsAbout);
const FullChargeLastsApproximatelyWithOS = withOSSelection(FullChargeLastsApproximately);
const RemainingBatteryLifeWithOS = withOSSelection(RemainingBatteryLife);
const BatteryPercentageWithOS = withOSSelection(BatteryPercentage);
const PhoneUsageHabitsWithOS = withOSSelection(PhoneUsageHabits);
const TurnOffWiFiWithOS = withOSSelection(TurnOffWiFiPage);
const SignalUploadDownloadWithOS = withOSSelection(SignalUploadDownloadPage);
const SignalDownloadCompleteWithOS = withOSSelection(SignalDownloadCompletePage);

export const DiagnosticsRoutes: FunctionComponent<RouteProps> = ({ baseRoute }) => {
	const { client: partner } = useValidateMxContext();

	return (
		<Switch>
			{partner === Partners.ATT
				? [
						<Route
							exact
							path={`${baseRoute}/battery/max-capacity`}
							render={() => <BatteryMaxCapacityWithOS checkupType={Checkups.BATTERY} />}
							key={DiagnosticsPages.IOS_BATTERY_MAX_CAPACITY}
						/>,
						<Route
							exact
							path={`${baseRoute}/battery/status`}
							render={() => <BatteryStatusWithOS checkupType={Checkups.BATTERY} />}
							key={DiagnosticsPages.IOS_BATTERY_MESSAGES}
						/>,
						<Route
							exact
							path={`${baseRoute}/battery/full-charge-lasts-about`}
							render={() => <FullChargeLastsAboutWithOS checkupType={Checkups.BATTERY} />}
							key={DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_ABOUT}
						/>,
						<Route
							exact
							path={`${baseRoute}/battery/full-charge-lasts-approximately`}
							render={() => (
								<FullChargeLastsApproximatelyWithOS checkupType={Checkups.BATTERY} />
							)}
							key={DiagnosticsPages.ANDROID_BATTERY_FULL_CHARGE_LASTS_APPROXIMATELY}
						/>,
						<Route
							exact
							path={`${baseRoute}/battery/remaining-battery-life`}
							render={() => <RemainingBatteryLifeWithOS checkupType={Checkups.BATTERY} />}
							key={DiagnosticsPages.ANDROID_REMAINING_BATTERY_LIFE}
						/>,
						<Route
							exact
							path={`${baseRoute}/battery/battery-percentage`}
							render={() => <BatteryPercentageWithOS checkupType={Checkups.BATTERY} />}
							key={DiagnosticsPages.ANDROID_BATTERY_CHARGE_PERCENTAGE}
						/>,
						<Route
							exact
							path={`${baseRoute}/battery/phone-usage-habits`}
							render={() => <PhoneUsageHabitsWithOS checkupType={Checkups.BATTERY} />}
							key={DiagnosticsPages.BATTERY_PHONE_USAGE_HABITS}
						/>,
				  ]
				: null}
			<Route
				exact
				path={`${baseRoute}/signal/turn-off-wifi`}
				render={() => <TurnOffWiFiWithOS checkupType={Checkups.SIGNAL} />}
			/>
			<Route
				exact
				path={`${baseRoute}/signal/upload-download`}
				render={() => <SignalUploadDownloadWithOS checkupType={Checkups.SIGNAL} />}
			/>
			<Route
				exact
				path={`${baseRoute}/signal/test-complete`}
				render={() => <SignalDownloadCompleteWithOS checkupType={Checkups.SIGNAL} />}
			/>
			<Route path="*" render={() => <Redirect to={'/'} />} />
		</Switch>
	);
};
