import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BatteryResultIds } from '@home-diy-toolbox/web/contentful';
import { useFlow } from '../../../../providers';
import {
	initialBatteryChargeAnswer,
	initialMainIssuesAnswer,
	initialOtherIssuesAnswer,
	initialUsageHabitsAnswer,
} from '../answers/initialAnswers';
import { type SamsungAndroidAnswers } from '../answers/types';
import {
	getAndroidBatteryResult,
	getBatteryReplacementResult,
	getMainIssuesResult,
	getTags,
} from './utils';
import { useSessionState, useResetSession, usePageStack } from './hooks';
import { articleIds } from './articleIds';
import { IssuePage, ResultPage } from '../pages';
import { SamsungBatteryDiagnosticPage } from '../pages/SamsungBatteryDiagnosticPage';
import { SamsungMembersAppPage } from '../pages/SamsungMembersAppPage';
import { useViewCheckup } from './hooks/useViewCheckup';
import { BatteryChargePage } from '../pages/BatteryChargePage';
import { UsageHabitsPage } from '../pages/UsageHabitsPage';
import { ArticlePage } from '../pages/ArticlePage';
import { MagicWandPage } from '../pages/MagicWandPage';
import { FlowConfig } from './types';

type Articles =
	| 'batteryStatusCheck'
	| 'samsungMembersAppBatteryStatusCheck'
	| 'batterySamplePage';
type Others = 'magicWand';
type Pages = keyof SamsungAndroidAnswers | 'result' | Articles | Others;

export const SamsungAndroidBatteryFlow = () => {
	const history = useHistory();
	const resetSession = useResetSession();
	const stack = usePageStack<Pages>(
		['mainIssues'],
		'samsungAndroidBatteryPageStack',
		resetSession
	);
	const [disableContinue, setDisableContinue] = useState(true);
	const { flow: appFlow } = useFlow();

	const [tags, setTags] = useState(new Set<string>());

	const [result, setResult] = useSessionState(
		null,
		'samsungAndroidBatteryResult',
		resetSession
	);

	const [answers, setAnswers] = useSessionState<SamsungAndroidAnswers>(
		{
			mainIssues: initialMainIssuesAnswer(),
			samsungSettingsBatteryDiagnostic: null,
			samsungMembersApp: null,
			samsungMembersAppBatteryDiagnostic: null,
			batteryCharge: initialBatteryChargeAnswer(),
			usageHabits: initialUsageHabitsAnswer(),
			otherIssues: initialOtherIssuesAnswer(),
		},
		'samsungAndroidBatteryAnswers',
		resetSession
	);

	const handleSetValue = (
		field: keyof typeof answers,
		{ value, valid, tags: newTags = null }
	) => {
		setDisableContinue(!valid);
		setAnswers((answers) => ({ ...answers, [field]: value }));

		if (newTags) {
			setTags((currTags) => getTags(currTags, newTags, field === 'mainIssues'));
		}
	};

	const getArticleConfig = (articleId: string, pageId: string) => {
		return {
			pageId,
			pageComponent: (
				<ArticlePage
					onFirstRender={() => setDisableContinue(false)}
					articleId={articleId}
					continueButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
			extraData: null,
		};
	};

	const flow: FlowConfig<Pages> = {
		mainIssues: {
			pageId: 'batteryMainIssue',
			pageComponent: (
				<IssuePage
					index={{ currentIndex: 1, total: 3 }}
					issue={'mainIssue'}
					initialValue={answers.mainIssues}
					onSetValue={(props) => handleSetValue('mainIssues', props)}
					backButtonConfig={{ onClick: () => history.goBack() }}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							const result = getMainIssuesResult(answers.mainIssues);
							if (!result) {
								stack.push('samsungSettingsBatteryDiagnostic');
								return;
							}

							setResult(result);
							stack.push('result');
						},
					}}
				/>
			),
		},
		samsungSettingsBatteryDiagnostic: {
			pageId: 'samsungSettingsBatteryDiagnostic',
			pageComponent: (
				<SamsungBatteryDiagnosticPage
					index={{ currentIndex: 2, total: 3 }}
					batteryDiagnostic={'samsungSettingsBatteryDiagnostic'}
					initialValue={answers.samsungSettingsBatteryDiagnostic}
					onSetValue={(props) =>
						handleSetValue('samsungSettingsBatteryDiagnostic', props)
					}
					onGuideClick={() => stack.push('batteryStatusCheck')}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							if (answers.samsungSettingsBatteryDiagnostic === 'dontHave') {
								stack.push('samsungMembersApp');
								return;
							}

							stack.push('otherIssues');
						},
					}}
					backButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
		},
		samsungMembersApp: {
			pageId: 'batterySamsungMembersApp',
			pageComponent: (
				<SamsungMembersAppPage
					index={{ currentIndex: 2, total: 3 }}
					initialValue={answers.samsungMembersApp}
					onSetValue={(props) => handleSetValue('samsungMembersApp', props)}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							if (answers.samsungMembersApp === 'yes') {
								stack.push('samsungMembersAppBatteryDiagnostic');
								return;
							}

							stack.push('batteryCharge');
						},
					}}
					backButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
		},
		samsungMembersAppBatteryDiagnostic: {
			pageId: 'samsungMembersAppBatteryDiagnostic',
			pageComponent: (
				<SamsungBatteryDiagnosticPage
					index={{ currentIndex: 2, total: 3 }}
					batteryDiagnostic={'samsungMembersAppBatteryDiagnostic'}
					initialValue={answers.samsungMembersAppBatteryDiagnostic}
					onSetValue={(props) =>
						handleSetValue('samsungMembersAppBatteryDiagnostic', props)
					}
					onGuideClick={() => stack.push('batteryStatusCheck')}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							stack.push('otherIssues');
						},
					}}
					backButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
		},
		batteryCharge: {
			pageId: 'batteryCharge',
			pageComponent: (
				<BatteryChargePage
					index={{ currentIndex: 2, total: 3 }}
					batteryCharge={'androidSamsungBatteryCharge'}
					initialValue={answers.batteryCharge}
					onSetValue={(props) => handleSetValue('batteryCharge', props)}
					onGuideClick={() => stack.push('batterySamplePage')}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							if (answers.batteryCharge?.dontHaveOption) {
								setResult(BatteryResultIds.EXTRA_INFORMATION_NEEDED);
								stack.push('result');
								return;
							}

							stack.push('usageHabits');
						},
					}}
					backButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
		},
		usageHabits: {
			pageId: 'batteryUsageHabits',
			pageComponent: (
				<UsageHabitsPage
					index={{ currentIndex: 2, total: 3 }}
					initialValue={answers.usageHabits}
					onSetValue={(props) => handleSetValue('usageHabits', props)}
					continueButtonConfig={{
						disabled: disableContinue,
						onClick: () => {
							stack.push('otherIssues');
						},
					}}
					backButtonConfig={{
						onClick: () => stack.pop(),
					}}
				/>
			),
		},
		otherIssues: {
			pageId: 'batteryOtherIssues',
			pageComponent: (
				<IssuePage
					index={{ currentIndex: 3, total: 3 }}
					issue={'otherIssues'}
					initialValue={answers.otherIssues}
					onSetValue={(props) => handleSetValue('otherIssues', props)}
					backButtonConfig={{ onClick: () => stack.pop() }}
					continueButtonConfig={{
						onClick: async () => {
							const { deviceMake, deviceModel, eligibility } = appFlow.sessionData;
							const batteryReplacementResult = getBatteryReplacementResult(eligibility);

							if (answers.samsungSettingsBatteryDiagnostic === 'bad') {
								setResult(batteryReplacementResult);
								stack.push('result');
								return;
							} else if (answers.samsungSettingsBatteryDiagnostic !== 'dontHave') {
								setResult(BatteryResultIds.GOOD_BATTERY);
								stack.push('result');
								return;
							}

							if (answers.samsungMembersApp === 'yes') {
								if (answers.samsungMembersAppBatteryDiagnostic === 'bad') {
									setResult(batteryReplacementResult);
								} else {
									setResult(BatteryResultIds.GOOD_BATTERY);
								}

								stack.push('result');
								return;
							}

							stack.push('magicWand');

							try {
								const result = await getAndroidBatteryResult(
									answers.batteryCharge,
									answers.usageHabits,
									{
										make: deviceMake,
										model: deviceModel,
									}
								);

								if (!result.isHealthy) {
									setResult(batteryReplacementResult);
								} else {
									setResult(BatteryResultIds.GOOD_BATTERY);
								}
							} catch (error) {
								setResult(BatteryResultIds.EXTRA_INFORMATION_NEEDED);
							} finally {
								stack.push('result');
							}
						},
					}}
				/>
			),
		},

		result: {
			pageId: 'batteryResult',
			pageComponent: (
				<ResultPage
					resultId={result}
					answerTags={tags}
					backButtonConfig={{
						onClick: () => {
							const lastLastPage = stack.stack[stack.stack.length - 2];
							stack.pop();
							if (lastLastPage === 'magicWand') {
								stack.pop();
							}
						},
					}}
				/>
			),
			extraData: {
				BatteryCheckupResult: result,
			},
		},

		batteryStatusCheck: getArticleConfig(
			articleIds.samsungSettingsBatteryStatusCheck,
			'samsungSettingsBatteryStatusCheckArticle'
		),
		samsungMembersAppBatteryStatusCheck: getArticleConfig(
			articleIds.samsungMembersAppBatteryStatusCheck,
			'samsungMembersAppBatteryStatusCheckArticle'
		),
		batterySamplePage: getArticleConfig(
			articleIds.batterySamplePage,
			'batterySampleArticle'
		),
		magicWand: {
			pageId: 'batteryMagicWand',
			pageComponent: <MagicWandPage />,
			extraData: null,
		},
	};

	const currentPageId = stack.peek();
	const currentPage = flow[currentPageId];

	useViewCheckup(currentPage.pageId, currentPage.extraData);

	return currentPage.pageComponent;
};
