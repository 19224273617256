import { Entry } from 'contentful';
import React, { useCallback, useEffect, useState } from 'react';
import {
	AnswerOption,
	ConfigurationOption,
	useCheckupContentContext,
} from '@home-diy-toolbox/web/contentful';
import type { RadioValue, Value } from '@home-diy-toolbox/web/radio-group-slider';
import { UsageHabitSelection } from './UsageHabit';
import type { UsageHabit, UsageTime } from '@home-diy-toolbox/shared/clients';

export const UsageHabits = ({
	answerOptions,
	usageTimeInHours,
}: {
	answerOptions: Array<Entry<AnswerOption>>;
	usageTimeInHours: Partial<UsageTime>;
}): JSX.Element => {
	const { useGlobalConfiguration } = useCheckupContentContext();
	const configuration = useGlobalConfiguration('usageOptions');
	const [usageOptions, setUsageOptions] = useState<RadioValue[]>([]);

	useEffect(() => {
		if (!configuration?.length) return;

		const map: RadioValue[] = configuration.map(
			({ label, ariaLabel, value }: ConfigurationOption) => ({
				label,
				'aria-label': ariaLabel,
				value: parseFloat(value),
			})
		);
		setUsageOptions(map);
	}, [configuration]);

	const setUsageTimeInHours = useCallback(
		(id: UsageHabit, value: Value) =>
			(usageTimeInHours[id] = typeof value === 'string' ? parseFloat(value) : value),
		[usageTimeInHours]
	);

	useEffect(() => {
		if (
			!usageOptions?.length ||
			!answerOptions?.length ||
			(Object.keys(usageOptions).length > 0 && Object.keys(usageTimeInHours).length > 0)
		)
			return;

		answerOptions.forEach(({ fields: { id } }: Entry<AnswerOption>) =>
			setUsageTimeInHours(id as UsageHabit, usageOptions[0]?.value)
		);
	}, [answerOptions, usageOptions, setUsageTimeInHours, usageTimeInHours]);

	return (
		<React.Fragment>
			{answerOptions.map(({ fields: { id, ...rest } }: Entry<AnswerOption>) => (
				<UsageHabitSelection
					{...rest}
					id={id}
					key={`key-${id}`}
					radioValues={usageOptions}
					initialValue={usageTimeInHours[id] || usageOptions[0]?.value}
					setUsageTimeInHours={setUsageTimeInHours}
				/>
			))}
		</React.Fragment>
	);
};
