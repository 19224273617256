import { FC, ReactNode, memo } from 'react';
import { Answer, QuestionBlock, RichText } from '@home-diy-toolbox/web/contentful';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { PercentageInput } from '@home-diy-toolbox/web/percentage-input';
import { Checkbox, Size, colors } from '@soluto-private/mx-asurion-ui-react-v3';
import { UnitPicker } from '@home-diy-toolbox/web/duration-picker';
import { RadioButtonWithLabel } from '../../../../components/RadioButtonWithLabel';
import { useMediaQuery } from '@home-diy-toolbox/web/utils';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { InputContainer } from './InputContainer';

const QuestionDescContainer = styled.div`
	color: #5d5e61;
`;

const PercentageInputDescription = styled(Text)`
	&& {
		display: block;
		margin: 0.25rem 0.75rem;
		color: ${colors.neutralDeeper};
	}
`;

const AnswerBlockContainer = styled.div<{
	inputWidth?: number | string;
}>`
	margin-top: 1rem;

	> input {
		width: ${(props) => props.inputWidth};
	}
`;

const BlockWrapper = styled.div``;

type AnswerData = {
	userInput: string | number | boolean;
};

type AnswerBlockTypes = Answer &
	AnswerData & {
		questionEntryName: string;
		onChange: (answer: AnswerData['userInput'], tags: string[]) => void;
		disabled?: boolean;
	};

const Input: FC<Omit<AnswerBlockTypes, 'type'>> = (answer) => {
	const isLargeScreen = useMediaQuery(`(min-width: ${Size.MD}px)`);
	const isPercentageInput = answer.entryName.toLocaleLowerCase().includes('percentage');
	const { dispatchEvent } = useDispatchEvent();

	const textInput = isPercentageInput ? (
		<AnswerBlockContainer>
			<PercentageInput
				disabled={answer.disabled}
				value={answer.userInput as number}
				setValue={(newUserInput) => answer.onChange(newUserInput, answer.tags || [])}
				onBlur={() => {
					dispatchEvent(AnalyticEventType.INPUT, {
						[answer.questionEntryName]: answer.userInput,
					});
				}}
			/>
			{answer.description && (
				<PercentageInputDescription>{answer.description}</PercentageInputDescription>
			)}
		</AnswerBlockContainer>
	) : (
		<AnswerBlockContainer inputWidth={isLargeScreen ? '50%' : '100%'}>
			<UnitPicker
				unit="hours"
				disabled={answer.disabled}
				value={answer.userInput as number}
				onValueChange={(newUserInput) => answer.onChange(newUserInput, answer.tags || [])}
				onBlur={() => {
					dispatchEvent(AnalyticEventType.INPUT, {
						[answer.questionEntryName]: answer.userInput,
					});
				}}
			/>
		</AnswerBlockContainer>
	);

	return textInput;
};

const AnswerBlock: FC<AnswerBlockTypes> = ({
	type,
	userInput,
	questionEntryName,
	onChange,
	...props
}) => {
	const { dispatchEvent } = useDispatchEvent();

	const answerBlocks: Record<AnswerBlockTypes['type'], ReactNode> = {
		radio: (
			<RadioButtonWithLabel
				disabled={props.disabled}
				name={props.value}
				value={props.value}
				label={props.text}
				checked={props.value === userInput}
				onChange={() => {
					onChange(props.value, props?.tags || []);
					dispatchEvent(AnalyticEventType.CLICK, {
						[questionEntryName]: props.value,
					});
				}}
			/>
		),
		text: (
			<Input
				userInput={userInput}
				onChange={onChange}
				questionEntryName={questionEntryName}
				{...props}
			/>
		),
		checkbox: (
			<AnswerBlockContainer>
				<Checkbox
					label={props.text}
					name={props.entryName}
					disabled={props.disabled}
					checked={props.value === userInput}
					onChange={() => {
						const newUserInput = props.value !== userInput ? props.value : null;
						onChange(newUserInput, props.tags || []);
						dispatchEvent(AnalyticEventType.CLICK, {
							[questionEntryName]: props.value,
						});
					}}
				/>
			</AnswerBlockContainer>
		),
	};

	if (answerBlocks[type]) {
		return <InputContainer>{answerBlocks[type]}</InputContainer>;
	}

	return null;
};

const MemoizedAnswerBlock = memo(AnswerBlock) as typeof AnswerBlock;

type QuestionBlockTypes = QuestionBlock &
	AnswerData & {
		onChange: (answer: AnswerData['userInput'], tags: string[]) => void;
		disabledOption?: boolean | ((answer: Answer) => boolean);
	};

export const BatteryQuestionBlock: FC<QuestionBlockTypes> = ({
	question,
	questionDescription,
	answers,
	userInput,
	entryName,
	onChange,
	disabledOption = false,
}) => {
	return (
		<BlockWrapper>
			<Text size={4} weight="feather">
				{question}
			</Text>
			{questionDescription && (
				<QuestionDescContainer>
					<RichText document={questionDescription} />
				</QuestionDescContainer>
			)}
			{answers.map((answer) => {
				const disabled =
					typeof disabledOption === 'boolean' ? disabledOption : disabledOption(answer);
				return (
					<MemoizedAnswerBlock
						key={answer.value}
						questionEntryName={entryName}
						userInput={userInput}
						onChange={onChange}
						disabled={disabled}
						{...answer}
					/>
				);
			})}
		</BlockWrapper>
	);
};
