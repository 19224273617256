import { deviceCareApiClient } from '../../../../clients';
import { UsageHabitAnswer } from '../answers/types';
import { Asset } from '@home-diy-toolbox/shared/clients';

export const getAndroidBatteryResult = (
	batteryStatus: { remainingHours: number; currentCharge: number },
	usageHabitAnswer: UsageHabitAnswer,
	asset: Asset
) => {
	return deviceCareApiClient.getAndroidBatteryResults({
		batteryLife: (batteryStatus.remainingHours / batteryStatus.currentCharge) * 100,
		usageTime: usageHabitAnswer,
		asset,
	});
};
