import React from 'react';
import {
	BatteryResultIds,
	FeedbackForm,
	ListSection,
	useCheckupPage,
} from '@home-diy-toolbox/web/contentful';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { PageBanner } from '../components/PageBanner';
import { ResultContent } from '../results';
import { ButtonConfig } from './types';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

type Blocks = {
	relatedGuides: ListSection;
	otherServices: ListSection;
	feedbackForms: ListSection;
	checkupPositive: FeedbackForm;
	checkupNegative: FeedbackForm;
};

type Props = {
	resultId: BatteryResultIds;
	answerTags: Set<string>;
	backButtonConfig: ButtonConfig;
};

export const ResultPage = ({ resultId, answerTags, backButtonConfig }: Props) => {
	const pageContent = useCheckupPage(
		resultId,
		resultId === BatteryResultIds.EXTRA_INFORMATION_NEEDED ||
			resultId === BatteryResultIds.GOOD_BATTERY
	);

	if (!pageContent) return <PageLoader />;

	const {
		relatedGuides,
		otherServices,
		feedbackForms,
		checkupPositive,
		checkupNegative,
	} = pageContent.blocks.reduce((prev, curr) => {
		prev[curr.entryName] = curr;
		return prev;
	}, {} as Blocks);

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={null}
			backButtonConfig={{
				customLabel: pageContent?.ctas.back.copy,
				...backButtonConfig,
			}}
			continueButtonConfig={{
				hide: true,
			}}
		>
			<ResultContent
				resultId={resultId}
				resultTitle={pageContent.heading}
				resultDescription={pageContent.subheading}
				resultCard={pageContent.resultCards[0]}
				relatedGuidesTitle={relatedGuides.heading}
				relatedGuidesLink={relatedGuides.showMoreCta.copy}
				pageCard={pageContent.banners[0]}
				feedbackTitle={feedbackForms.heading}
				checkupPositive={checkupPositive}
				checkupNegative={checkupNegative}
				otherServicesTitle={otherServices.heading}
				answerTags={answerTags}
			/>
		</CheckupPageLayout>
	);
};
