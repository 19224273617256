import { Button } from '@home-diy-toolbox/web/contentful';
import { Modal } from '@soluto-private/mx-asurion-ui-react-v3';
import styled from 'styled-components';
import { ActionButton } from '../atoms/ActionButton/ActionButton';
import {
	AnalyticEventType,
	AnalyticExtras,
	useDispatchEvent,
} from '@home-diy-toolbox/web/analytics';
import { ContactExpertButtonRenderer } from '../atoms/ActionButton/ContactExpertButtonRenderer';
import { ChatButtonHider } from './ChatButtonHider';

const CustomModal = styled(Modal)`
	> div > header > div {
		padding-right: 2rem;
	}
`;

const ModalButtons = styled.div`
	grid-gap: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 3rem;
`;

const ActionButtonContainer = styled.div`
	width: 20.625rem;
`;

interface Props {
	isOpen: boolean;
	title: string;
	cardCtas: Button[];
	onCloseClick: () => void;
	analyticExtras?: AnalyticExtras;
}

const buttonRenderer = new ContactExpertButtonRenderer();

export const ContactExpertsModal = ({
	isOpen,
	title,
	cardCtas,
	onCloseClick,
	analyticExtras = {},
}: Props) => {
	const { dispatchEvent } = useDispatchEvent();

	return (
		<CustomModal
			title={title}
			isOpen={isOpen}
			onCloseClick={() => onCloseClick()}
			width={10}
		>
			{isOpen ? <ChatButtonHider /> : null}
			<ModalButtons>
				<ActionButtonContainer>
					<ActionButton
						button={cardCtas[0]}
						buttonRenderer={buttonRenderer}
						onClick={() => {
							onCloseClick();
							dispatchEvent(AnalyticEventType.CLICK, {
								ActionId: 'batteryResultsLiveExpertCardCTA' + cardCtas[0]?.action,
								...analyticExtras,
							});
						}}
					/>
				</ActionButtonContainer>
				<ActionButtonContainer>
					<ActionButton
						button={cardCtas[1]}
						buttonRenderer={buttonRenderer}
						onClick={() => {
							onCloseClick();
							dispatchEvent(AnalyticEventType.CLICK, {
								ActionId: 'batteryResultsLiveExpertCardCTA' + cardCtas[1]?.action,
								...analyticExtras,
							});
						}}
					/>
				</ActionButtonContainer>
			</ModalButtons>
		</CustomModal>
	);
};
