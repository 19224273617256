import { DeviceCareApiClient } from '@home-diy-toolbox/shared/clients';
import { environment } from '../environments/environment';
import httpClient from './httpClient';

const deviceCareApiClient = new DeviceCareApiClient({
	domain: environment.deviceCareApiDomain,
	httpClient,
});

export default deviceCareApiClient;
