import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SimpleArticleGridSection, useHomePage } from '@home-diy-toolbox/web/contentful';
import { breakpoints, PageLoader } from '@soluto-private/mx-asurion-ui-react';
import { ChatPromptsCTA, HeroSection } from '../../components';
import { useFlow } from '../../providers';
import { useHeader } from '../../features/header';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { GradientPage } from '@home-diy-toolbox/web/theme';
import { ArticleGridSection } from '../../components/ArticleGridSection';
import { ServiceSection2 } from '../../components/CheckupServiceSection/ServiceSection2';

const Container = styled(GradientPage)`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
`;

const OnlineServiceContainer = styled.section`
	margin-top: 1.5rem;
`;

const ArticleGridContainer = styled.section`
	margin-top: 2.25rem;

	${breakpoints.lg} {
		margin-top: 3.75rem;
	}
`;

const InStoreServiceContainer = styled.section`
	margin-top: 2.25rem;

	${breakpoints.lg} {
		margin-top: 3.6rem;
	}

	&:empty {
		margin-top: 0;
	}
`;

const ChatPromptContainer = styled.section`
	margin-top: 2.25rem;

	${breakpoints.lg} {
		margin-top: 4.4375rem;
	}
`;

export const NewHomePage = () => {
	const { t } = useTranslation();
	const homePage = useHomePage();

	const {
		flow: { sessionData },
		setCurrentQuestionIndex,
	} = useFlow();
	const { updateHeader } = useHeader();

	useEffect(() => {
		updateHeader(
			{
				title: t('Device Care'),
			},
			undefined,
			() => setCurrentQuestionIndex(sessionData.currentQuestionIndex - 1)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionData.currentQuestionIndex, setCurrentQuestionIndex, t]);

	if (!homePage) return <PageLoader />;
	if (homePage.designType === false) {
		return;
	}

	const gridSection: SimpleArticleGridSection = {
		id: homePage.name,
		title: homePage.guidesSectionTitles[sessionData.deviceOS],
		commonTag: 'home',
		filters: homePage.guidesSectionFilters.map((filter) => ({
			label: filter.label,
			value: filter.values,
			url: filter.url,
		})),
	};

	return (
		<Container className="widecontainer">
			<HeroSection />
			<OnlineServiceContainer>
				<ServiceSection2
					sectionTitle={homePage.servicesTitle}
					services={homePage.services}
				/>
			</OnlineServiceContainer>
			<ArticleGridContainer>
				<ArticleGridSection
					deviceOS={sessionData.deviceOS}
					articleGridSection={gridSection}
				/>
			</ArticleGridContainer>
			<InStoreServiceContainer>
				<ServiceSection2
					sectionTitle={homePage.inStoreServicesTitle}
					services={homePage.inStoreServices}
				/>
			</InStoreServiceContainer>
			<ChatPromptContainer>
				<ChatPromptsCTA />
			</ChatPromptContainer>
		</Container>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: GenericPageIds.NEW_HOME_PAGE,
	CheckupType: undefined,
})(NewHomePage);
