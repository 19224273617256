import React from 'react';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';

// Font size style covers Rich text question headers
const Header = styled(Text)`
	margin: 8px 0;

	& > p {
		font-size: 2rem;
	}
`;

export const QuestionHeader: React.FunctionComponent = ({ children, ...others }) => (
	<Header size={5} weight="feather" {...others} forwardedAs="h1">
		{children}
	</Header>
);
