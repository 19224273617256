import { ArticlePathnames } from '@home-diy-toolbox/web/common-types/refresh-base';
import { Card, Icon, Text } from '@soluto-private/mx-asurion-ui-react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface ArticleProps {
	id: string;
	title: string;
	subtitle: string;
	imageUrl?: string;
	originPath?: string;
	externalLink: string;
	onClick?: VoidFunction;
}

const TextContainer = styled.div`
	text-align: start;
	flex-grow: 1;
`;

const StyledCard = styled(Card)`
	&& {
		padding: 0;
		overflow: hidden;
		background-color: transparent;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		position: relative;

		&:active {
			background-color: transparent;
		}

		&:hover {
			box-shadow: #000000 0px 0px 0px 1px inset;
		}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: row;
	padding: 1rem;
	gap: 0;
	flex-grow: 1;

	& > .icon {
		flex-shrink: 0;
		align-self: center;
		margin-right: -0.125rem;
	}
`;

const Image = styled.div`
	height: 10.625rem;
	width: 100%;
	background-size: cover;
	background-position: center center;
	position: relative;
	z-index: -1;
`;

const Title = styled(Text)`
	line-height: 1.3;
	margin-bottom: 0.25rem;
	display: block;
`;

const Subtitle = styled(Text)`
	line-height: 1.3;
`;

const Link = styled.a`
	text-decoration: none;
	color: #000000;
	border-radius: 8px;
	box-shadow: rgb(165, 170, 175) 0px 0px 0px 1px inset;

	&& {
		padding: 0;
		overflow: hidden;
		background-color: transparent;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		position: relative;

		&:active {
			background-color: transparent;
		}

		&:hover {
			box-shadow: #000000 0px 0px 0px 1px inset;
		}
	}
`;

export const ArticleItem: FunctionComponent<ArticleProps> = ({
	id,
	title,
	subtitle,
	imageUrl,
	originPath,
	externalLink,
	onClick,
}) => {
	const history = useHistory();

	const onClickCard = (id: string) => {
		onClick?.();
		history.push(ArticlePathnames.ARTICLE.replace(':articleId', id), {
			from: history.location.pathname,
			originPath: originPath,
		});
	};

	const cardContent = (
		<>
			<Image style={{ backgroundImage: `url(${imageUrl})` }} />
			<Content>
				<TextContainer>
					<Title weight="feather" size={4}>
						{title}
					</Title>
					<Subtitle size={1}>{subtitle}</Subtitle>
				</TextContainer>
				<Icon className="icon" size="xlarge" src="ChevronRight"></Icon>
			</Content>
		</>
	);

	// render based on the external link
	return externalLink ? (
		<Link href={externalLink} onClick={onClick} target="_blank" rel="noreferrer">
			{cardContent}
		</Link>
	) : (
		<StyledCard
			element="button"
			type="call-to-action"
			onClick={() => onClickCard(id)}
			data-pw="article-item"
		>
			{cardContent}
		</StyledCard>
	);
};
