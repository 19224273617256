import { ValueOf } from '../utility';

const SENTIMENTS = {
	Positive: 'positive',
	Neutral: 'neutral',
	Negative: 'negative',
} as const;
type SentimentOption = ValueOf<typeof SENTIMENTS>;

export { SENTIMENTS, SentimentOption };
