import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import singleSpaReact from 'single-spa-react';
import { AppName } from '@home-diy-toolbox/web/common-types/refresh-base';
import { Providers } from './providers/Providers';
import { ErrorPage } from './pages';
import { monitor } from '@soluto-private/mx-monitor';
import { GlobalStyle } from '@home-diy-toolbox/web/theme';

const SingleSpaRootApp = () => (
	<React.StrictMode>
		<GlobalStyle />
		<BrowserRouter basename={AppName}>
			<Providers />
		</BrowserRouter>
	</React.StrictMode>
);

const lifecycles = singleSpaReact({
	React,
	ReactDOM,
	rootComponent: SingleSpaRootApp,
	errorBoundary: (error, _info, _props) => {
		monitor.error(error.message, error);
		return <ErrorPage />;
	},
});

export const { bootstrap, mount, unmount } = lifecycles;
