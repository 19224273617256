import { BatteryCheckupTags } from '@home-diy-toolbox/web/common-types/refresh-base';
import { AnswerType } from '../../../../providers/flows/models';

const NON_ALLOWABLE_DAMAGE_TAGS = [BatteryCheckupTags.UNCOVERED_DAMAGE];
const FRONT_GLASS_BROKEN_TAGS = [BatteryCheckupTags.BROKEN_FRONT_GLASS];
const BACK_GLASS_BROKEN_TAGS = [BatteryCheckupTags.BROKEN_BACK_GLASS];

const isNonAllowableDamage = (phoneDamageCheckup: AnswerType | undefined): boolean => {
	if (!phoneDamageCheckup?.tags?.length) {
		return false;
	}

	return NON_ALLOWABLE_DAMAGE_TAGS.some((tag) =>
		phoneDamageCheckup.tags.some((x) => x.startsWith(tag))
	);
};

const isFrontGlassBroken = (phoneDamageCheckup: AnswerType | undefined): boolean => {
	if (!phoneDamageCheckup?.tags?.length) {
		return false;
	}

	return FRONT_GLASS_BROKEN_TAGS.some((tag) =>
		phoneDamageCheckup.tags.some((x) => x.startsWith(tag))
	);
};

const isBackGlassBroken = (phoneDamageCheckup: AnswerType | undefined): boolean => {
	if (!phoneDamageCheckup?.tags?.length) {
		return false;
	}

	return BACK_GLASS_BROKEN_TAGS.some((tag) =>
		phoneDamageCheckup.tags.some((x) => x.startsWith(tag))
	);
};

export {
	NON_ALLOWABLE_DAMAGE_TAGS,
	isNonAllowableDamage,
	isFrontGlassBroken,
	isBackGlassBroken,
};
