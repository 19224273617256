import { convertToDecimal } from '@home-diy-toolbox/web/utils';
import { defaultDuration, Duration } from './types';

const validMaxValueOrUndefined = (maximum: number, value?: number) =>
	typeof value === 'undefined' || value <= maximum;

export const withHoursLimit = (value?: number) => validMaxValueOrUndefined(23, value);
export const withMinutesLimit = (value?: number) => validMaxValueOrUndefined(59, value);
export const withDaysLimit = (value?: number) => validMaxValueOrUndefined(29, value);

export const calculateTotalDurationInHours = ({
	days = 0,
	hours = 0,
	minutes = 0,
}: Duration) => convertToDecimal(days * 24 + hours + minutes / 60);

export const validDurationValue = (duration: Duration) =>
	Object.keys(duration)
		.filter((key) => duration[key] || duration[key] === 0)
		.reduce<Duration>((acc, key) => ({ ...acc, [key]: duration[key] }), defaultDuration);

export const isDefaultDurationValue = (duration: Duration) =>
	Object.entries(duration).every(
		([unit, value]) => typeof value === 'undefined' || value === defaultDuration[unit]
	);
