import { AndroidOthersBatteryAnswer, BatteryCheckupV3Answers } from '../answers';
import { Pages } from '../pages';

export const getDisabledContinueAndroidOthersFlow = (
	currentPage: Pages,
	answers: BatteryCheckupV3Answers<AndroidOthersBatteryAnswer>
): boolean => {
	const disabledContinueFlow: Record<Partial<Pages>, () => boolean> = {
		initialPage: () => {
			const { initialPage } = answers;

			const keys: Array<keyof AndroidOthersBatteryAnswer> = [
				'currentBatteryCharge',
				'chargeLast',
			];

			const keysLength = initialPage?.chargeLast !== 'cantFindInfo' ? 2 : 1;

			for (let i = 0; i < keysLength; i++) {
				if (!initialPage?.[keys[i]]) {
					return true;
				}
			}

			return false;
		},
		otherIssuesPage: (): boolean => {
			const { otherIssuesPage } = answers;
			// issues field must be atleast 1 checkbox checked
			if (!otherIssuesPage?.issues?.length) return true;
			return false;
		},
		resultPage: (): boolean => false,
	};

	return disabledContinueFlow[currentPage]();
};
