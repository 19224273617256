import { PageLoader } from '@soluto-private/mx-asurion-ui-react';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: center;
	width: 100%;
`;

export const LoadingPage = () => (
	<Container>
		<PageLoader />
	</Container>
);
