import { useEffect, useMemo, useState } from 'react';

export function useSessionState<T>(
	initialState: T,
	sessionKey: string,
	resetSession: boolean
) {
	const sessionState = useMemo(() => sessionStorage.getItem(sessionKey), [sessionKey]);
	const [state, setState] = useState<T>(
		!sessionState || resetSession ? initialState : JSON.parse(sessionState)
	);

	useEffect(() => {
		sessionStorage.setItem(sessionKey, JSON.stringify(state));
	}, [state, sessionKey]);

	return [state, setState] as const;
}
