import React, { useCallback } from 'react';
import styled from 'styled-components';
import { getServiceRedirect } from './servicesMapping';
import { AnalyticEventType } from '@home-diy-toolbox/web/analytics';
import { useHistory } from 'react-router-dom';
import { withExtras } from 'shisell/extenders';
import { useAnalytics } from '@home-diy-toolbox/web/analytics';
import { ServiceCard } from './ServiceCard';
import { Checkup, Partner } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';

const ListDiv = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	& > * {
		box-sizing: border-box;
	}

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		flex-direction: row;

		& > * {
			width: calc(calc(100% - 2rem) / 3);
		}
	}
`;

const XScrollableListDiv = styled(ListDiv)`
	flex-direction: row;
	overflow-x: auto;
	& > * {
		min-width: 18.75rem;
		width: calc(calc(100% - 2rem) / 3);
	}

	margin-left: -1rem;
	margin-right: -1rem;
	padding-left: 1rem;
	padding-right: 1rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		padding: 0 1.5rem;
	}

	@media (min-width: 980px) {
		margin: 0;
		padding: 0;
	}
`;

export const ServiceList = ({ services, xScrollable }) => {
	const history = useHistory();
	const analytics = useAnalytics();
	const { client: partner } = useValidateMxContext();

	const onClickCheckup = useCallback(
		(checkupType: Checkup, checkupTitle: string) => {
			analytics.dispatcher
				.extend(
					withExtras({
						ActionId: checkupType,
						CTA: checkupTitle,
						CheckupType: checkupType,
					})
				)
				.dispatch(AnalyticEventType.CLICK);
			history.push(getServiceRedirect(partner as Partner, checkupType), {
				from: history.location.pathname,
			});
		},
		[analytics.dispatcher, history, partner]
	);

	const cards = services.map((service) => {
		return (
			<ServiceCard
				key={service.title}
				title={service.title}
				subtitle={service.description}
				imageSrc={service.cover.url}
				buttonText={service.action}
				onClick={() => onClickCheckup(service.checkupType, service.title)}
			/>
		);
	});

	return xScrollable ? (
		<XScrollableListDiv>{cards}</XScrollableListDiv>
	) : (
		<ListDiv>{cards}</ListDiv>
	);
};
