import React from 'react';
import styled from 'styled-components';

const Divider = styled.hr`
	border: 1px solid ${({ theme }) => theme.dividerBackgroundColor};
	width: 100%;
	margin: 1.5rem 0;
`;

export const ResultSectionDivider = ({
	className,
}: React.HTMLAttributes<HTMLHRElement>): JSX.Element => {
	return <Divider className={className} />;
};
