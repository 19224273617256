import {
	CheckupPageIds,
	Checkups,
	Partners,
	ResultPageIds,
	SENTIMENTS,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { PHONE_DAMAGE_SEVERITY } from '../constants';
import { BatteryCheckupQuestionsConfig, ResultsConfig } from '../types';

export const BATTERY_RESULT_PAGES_CONFIG: ResultsConfig<'battery'> = [
	{
		pageId: ResultPageIds.BATTERY_RESULT_DEFAULT,
	},
	{
		pageId: ResultPageIds.BATTERY_RESULT_DAMAGE,
		results: {
			isHazardousDamage: true,
		},
	},
	{
		pageId: ResultPageIds.BATTERY_RESULT_UNHEALTHY,
		results: {
			isNonAllowableDamage: false,
			isBadOrIndeterminate: true,
			isPlanAndDeviceEligible: true,
		},
	},
] as const;

export const ATT_BATTERY_RESULT_PAGES_CONFIG: ResultsConfig<'battery'> = [
	{
		pageId: ResultPageIds.BATTERY_RESULT_DEFAULT,
	},
	{
		pageId: ResultPageIds.ATT_BATTERY_RESULT_HAZARDOUS_DAMAGE,
		results: {
			isHazardousDamage: true,
		},
	},
	{
		pageId: ResultPageIds.ATT_BATTERY_RESULT_BROKEN_FRONT_GLASS,
		results: {
			isFrontGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.ATT_BATTERY_RESULT_BROKEN_BACK_GLASS,
		results: {
			isBackGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.BATTERY_RESULT_HEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: false,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.ATT_BATTERY_RESULT_UNHEALTHY_INELIGIBLE,
		results: {
			isPlanAndDeviceEligible: false,
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.ATT_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
] as const;

export const VERIZON_BATTERY_RESULT_PAGES_CONFIG: ResultsConfig<'battery'> = [
	{
		pageId: ResultPageIds.BATTERY_RESULT_DEFAULT,
	},
	{
		pageId: ResultPageIds.VERIZON_BATTERY_RESULT_HAZARDOUS_DAMAGE,
		results: {
			isHazardousDamage: true,
		},
	},
	{
		pageId: ResultPageIds.VERIZON_BATTERY_RESULT_BROKEN_FRONT_GLASS,
		results: {
			isFrontGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.VERIZON_BATTERY_RESULT_BROKEN_BACK_GLASS,
		results: {
			isBackGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.BATTERY_RESULT_HEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: false,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.DEFAULT_BATTERY_RESULT_UNHEALTHY_INELIGIBLE,
		results: {
			isPlanAndDeviceEligible: false,
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.DEFAULT_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
] as const;

export const COX_BATTERY_RESULT_PAGES_CONFIG: ResultsConfig<'battery'> = [
	{
		pageId: ResultPageIds.BATTERY_RESULT_DEFAULT,
	},
	{
		pageId: ResultPageIds.COX_BATTERY_RESULT_HAZARDOUS_DAMAGE,
		results: {
			isHazardousDamage: true,
		},
	},
	{
		pageId: ResultPageIds.COX_BATTERY_RESULT_BROKEN_FRONT_GLASS,
		results: {
			isFrontGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.COX_BATTERY_RESULT_BROKEN_BACK_GLASS,
		results: {
			isBackGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.BATTERY_RESULT_HEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: false,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.COX_BATTERY_RESULT_UNHEALTHY_INELIGIBLE,
		results: {
			isPlanAndDeviceEligible: false,
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.COX_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
] as const;

export const USCELLULAR_BATTERY_RESULT_PAGES_CONFIG: ResultsConfig<'battery'> = [
	{
		pageId: ResultPageIds.BATTERY_RESULT_DEFAULT,
	},
	{
		pageId: ResultPageIds.USCELLULAR_BATTERY_RESULT_HAZARDOUS_DAMAGE,
		results: {
			isHazardousDamage: true,
		},
	},
	{
		pageId: ResultPageIds.USCELLULAR_BATTERY_RESULT_BROKEN_FRONT_GLASS,
		results: {
			isFrontGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.USCELLULAR_BATTERY_RESULT_BROKEN_BACK_GLASS,
		results: {
			isBackGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.BATTERY_RESULT_HEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: false,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.USCELLULAR_BATTERY_RESULT_UNHEALTHY_INELIGIBLE,
		results: {
			isPlanAndDeviceEligible: false,
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.USCELLULAR_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
] as const;

export const CRICKET_BATTERY_RESULT_PAGES_CONFIG: ResultsConfig<'battery'> = [
	{
		pageId: ResultPageIds.BATTERY_RESULT_DEFAULT,
	},
	{
		pageId: ResultPageIds.CRICKET_BATTERY_RESULT_HAZARDOUS_DAMAGE,
		results: {
			isHazardousDamage: true,
		},
	},
	{
		pageId: ResultPageIds.CRICKET_BATTERY_RESULT_BROKEN_FRONT_GLASS,
		results: {
			isFrontGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.CRICKET_BATTERY_RESULT_BROKEN_BACK_GLASS,
		results: {
			isBackGlassBroken: true,
		},
	},
	{
		pageId: ResultPageIds.BATTERY_RESULT_HEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: false,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.CRICKET_BATTERY_RESULT_UNHEALTHY_INELIGIBLE,
		results: {
			isPlanAndDeviceEligible: false,
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
	{
		pageId: ResultPageIds.CRICKET_BATTERY_RESULT_UNHEALTHY_NO_DAMAGE,
		results: {
			isBadOrIndeterminate: true,
			isBackGlassBroken: false,
			isFrontGlassBroken: false,
		},
	},
] as const;

// PR NOTE: I'm not liking this, but haven't thought of a better way to do it yet
const DEFAULT_BATTERY_QUESTIONS_CONFIG: BatteryCheckupQuestionsConfig = {
	[CheckupPageIds.BATTERY_LIFE]: {
		its_ok: SENTIMENTS.Positive,
		its_not_great: SENTIMENTS.Neutral,
		it_needs_help: SENTIMENTS.Negative,
	},
	[CheckupPageIds.PHONE_DAMAGE]: {
		swollen: PHONE_DAMAGE_SEVERITY.Hazardous,
		leaking: PHONE_DAMAGE_SEVERITY.Hazardous,
		liquid_damage: PHONE_DAMAGE_SEVERITY.Hazardous,
		glass_cracked_front: PHONE_DAMAGE_SEVERITY.NonHazardous,
		glass_cracked_back: PHONE_DAMAGE_SEVERITY.NonHazardous,
		none: PHONE_DAMAGE_SEVERITY.None,
	},
	[CheckupPageIds.PHONE_CHARGER]: {
		original_charger_yes: '',
		original_charger_no: '',
	},
} as const;

const NEW_BATTERY_QUESTIONS_CONFIG = {
	[CheckupPageIds.PHONE_DAMAGE]: {
		damaged: PHONE_DAMAGE_SEVERITY.Hazardous,
		glass_cracked_front: PHONE_DAMAGE_SEVERITY.NonHazardous,
		glass_cracked_back: PHONE_DAMAGE_SEVERITY.NonHazardous,
		none: PHONE_DAMAGE_SEVERITY.None,
	},
} as const;

/** Questions configs are the partner-specific configurations for evaluating questions that may be evaluated different for different partners
 * These question configurations influence the Results output of calculateResults()
 */
export const BATTERY_QUESTIONS_CONFIG = {
	[Checkups.BATTERY]: {
		[Partners.DEFAULT]: DEFAULT_BATTERY_QUESTIONS_CONFIG,
		[Partners.ATT]: NEW_BATTERY_QUESTIONS_CONFIG,
		[Partners.VERIZON]: NEW_BATTERY_QUESTIONS_CONFIG,
		[Partners.COX]: NEW_BATTERY_QUESTIONS_CONFIG,
		[Partners.USCELLULAR]: NEW_BATTERY_QUESTIONS_CONFIG,
		[Partners.CRICKET]: NEW_BATTERY_QUESTIONS_CONFIG,
	},
} as const;
