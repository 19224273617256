import styled from 'styled-components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { servicesMap } from './servicesMapping';
import { ServiceCard2 } from './ServiceCard2';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useHeader } from '../../features/header';
import { useFlow } from '../../providers';
import { Service } from '@home-diy-toolbox/web/contentful';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;

	${breakpoints.lg} {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	& > * {
		max-width: 18.4375rem;
	}
`;

export interface List2Props {
	services: Service[];
}

export const getServiceCards2 = (services) =>
	services.map((service: Service) => {
		const serviceRoute = servicesMap[service.checkupType];

		return (
			serviceRoute && (
				<ServiceCard2
					id={service.id}
					key={service.checkupType}
					routePath={serviceRoute}
					checkupType={service.checkupType}
					data-cy={service.checkupType}
					copy={service.title}
					description={service.description}
					image={service.cover.url}
					tags={service.tags}
				/>
			)
		);
	});

export const ServiceList2 = ({ services }: List2Props): JSX.Element => {
	const { t } = useTranslation();
	const { flow, setCurrentQuestionIndex } = useFlow();
	const { updateHeader } = useHeader();

	useEffect(() => {
		updateHeader(
			{
				title: t('Device Care'),
			},
			undefined,
			() => setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex - 1)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flow.sessionData.currentQuestionIndex, setCurrentQuestionIndex, t]);

	const cards = getServiceCards2(services);

	return <Container role="list">{cards}</Container>;
};
