import { ValueOf } from '@home-diy-toolbox/web/common-types';

export const SIGNAL_SPEED_RESULTS = {
	IDEAL: 'ideal',
	ALMOST_IDEAL: 'almost ideal',
	LESS_THAN_IDEAL: 'less than ideal',
	POOR: 'poor',
} as const;
export type SignalSpeedResult = ValueOf<typeof SIGNAL_SPEED_RESULTS>;

/** Greater than or equal to each maps the results */
export const SIGNAL_SPEEDS_MAP = {
	[SIGNAL_SPEED_RESULTS.IDEAL]: 30,
	[SIGNAL_SPEED_RESULTS.ALMOST_IDEAL]: 23,
	[SIGNAL_SPEED_RESULTS.LESS_THAN_IDEAL]: 15,
	[SIGNAL_SPEED_RESULTS.POOR]: 0,
} as const;
