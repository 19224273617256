import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { RichText } from '@home-diy-toolbox/web/contentful';
import { getFontSize, getFontWeight, Icon } from '@soluto-private/mx-asurion-ui-react';
import { BLOCKS, Document } from '@contentful/rich-text-types';

const MainContainer = styled.div`
	width: 100%;
	margin-bottom: 1.5rem;

	@media (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const Container = styled.div`
	width: 100%;
	max-width: 21.1875rem;
`;

const Card = styled.div`
	box-shadow: 0rem 0rem 0.25rem 0.125rem #0c0e110a;
	border-radius: 0.5rem;
	margin-bottom: 0.264rem;

	&:hover {
		cursor: pointer;
	}
`;

const ResultImg = styled.img`
	width: 100%;
	border-top-right-radius: 0.5rem;
	border-top-left-radius: 0.5rem;
`;

const TextContainer = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
`;

const TextHeading = styled(Text)`
	&& {
		font-size: ${getFontSize(4)};
		font-weight: ${getFontWeight('base')};
		flex-grow: 1;
	}
`;

const TextSubHeading = styled(Text)`
	&& {
		font-size: ${getFontSize(1)};
		font-weight: ${getFontWeight('base')};
		color: #5d5e61;
	}
`;

const TextRichTextDescription1 = styled(Text)`
	&& {
		font-size: ${getFontSize(2)};
		font-weight: ${getFontWeight('base')};
	}
`;

const TextRichTextDescription2 = styled(Text)`
	&& {
		font-size: ${getFontSize(0)};
		font-weight: ${getFontWeight('base')};
		color: #5d5e61;
	}
`;

const TextHeadingContainer = styled.div`
	align-items: center;
	display: flex;
`;

interface Props {
	cardHeading: string;
	cardSubheading: string;
	cardRichTextDescription: Document;
	cardRichTextDescription2: Document;
	imageUrl: string;
	imageAlt: string;
	onClickCard: () => void;
}

export const ResultCard = ({
	cardHeading,
	cardSubheading,
	cardRichTextDescription,
	cardRichTextDescription2,
	imageUrl,
	imageAlt,
	onClickCard,
}: Props) => {
	return (
		<MainContainer>
			<Container>
				<Card
					onClick={() => {
						onClickCard();
					}}
				>
					<ResultImg src={imageUrl} alt={imageAlt} />
					<TextContainer>
						<TextHeadingContainer>
							<TextHeading>{cardHeading}</TextHeading>
							<Icon size="large" src="CardsNavigationChevronRight" />
						</TextHeadingContainer>
						<TextSubHeading>{cardSubheading}</TextSubHeading>
						<RichText
							document={cardRichTextDescription}
							renderOptionsOverride={{
								renderNode: {
									[BLOCKS.PARAGRAPH]: (_, children) => {
										return (
											<TextRichTextDescription1>{children}</TextRichTextDescription1>
										);
									},
								},
							}}
						/>
						<RichText
							document={cardRichTextDescription2}
							renderOptionsOverride={{
								renderNode: {
									[BLOCKS.PARAGRAPH]: (_, children) => {
										return (
											<TextRichTextDescription2>{children}</TextRichTextDescription2>
										);
									},
								},
							}}
						/>
					</TextContainer>
				</Card>
			</Container>
		</MainContainer>
	);
};
