import React, { FunctionComponent } from 'react';
import { Button, ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { AnalyticExtras, withAnalytics } from '@home-diy-toolbox/web/analytics';

type NextButtonProps = ButtonProps & {
	actionId?: string;
	analyticExtras?: AnalyticExtras;
};

const NextButtonStyled = styled(withAnalytics(Button))`
	width: 100%;
`;

export const NextButton: FunctionComponent<NextButtonProps> = ({
	className,
	actionId,
	analyticExtras,
	children,
	onClick,
	...others
}) => {
	return (
		<NextButtonStyled
			color="secondary"
			size="medium"
			className={className}
			actionId={actionId}
			analyticExtras={analyticExtras}
			onClick={onClick}
			{...others}
		>
			{children as React.ReactChild}
		</NextButtonStyled>
	);
};
