import { FunctionComponent } from 'react';
import { ArticleTag, ArticleContent } from './atoms';
import {
	Article,
	InfoTag,
	InfoTagTypes,
	RichText,
} from '@home-diy-toolbox/web/contentful';
import { UbifApiClient } from '@home-diy-toolbox/shared/clients';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { ListBullets } from './assets';
import { Entry } from 'contentful';
import { ArticleRendererProvider } from './helpers';
import { DevicePartnerContextInfoType } from './types';
import { BLOCKS } from '@contentful/rich-text-types';

type Prop = {
	article: Article;
	devicePartnerContext: DevicePartnerContextInfoType;
	ubifApiClient: UbifApiClient;
};
const BaseContainer = styled.div`
	margin: 0 auto;
	p {
		line-height: 1.4375rem;
	}
`;
const ArticleHeader = styled(Text)`
	margin: 0.5rem 0;
`;
const TagContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	margin-bottom: 1rem;
`;

export const ArticleRenderer: FunctionComponent<Prop> = ({
	article: { title, richTextTitle, articleContent, infoTags: infoTagsContent = [] },
	devicePartnerContext,
	ubifApiClient,
}) => {
	const infoTags = infoTagsContent.map(
		({ fields: { text, infoType } }: Entry<InfoTag>, index) => {
			let icon: string | JSX.Element;
			if (infoType === InfoTagTypes.STEPS_TO_COMPLETE) {
				icon = <ListBullets />;
			} else {
				icon = 'Time';
			}
			return <ArticleTag key={`infoTag_${index}`} text={text} icon={icon} />;
		}
	);

	return (
		<BaseContainer>
			<ArticleRendererProvider context={{ ...devicePartnerContext, ubifApiClient }}>
				{richTextTitle && (
					<RichText
						document={richTextTitle}
						renderOptionsOverride={{
							renderNode: {
								[BLOCKS.PARAGRAPH]: (_, children) => (
									<ArticleHeader forwardedAs="h1" size={5} weight="feather">
										{children}
									</ArticleHeader>
								),
							},
						}}
					/>
				)}
				{title && !richTextTitle && (
					<ArticleHeader forwardedAs="h1" size={5} weight="feather">
						{title}
					</ArticleHeader>
				)}
				{!!infoTags.length && <TagContainer>{infoTags}</TagContainer>}
				{articleContent && <ArticleContent articleContent={articleContent} />}
			</ArticleRendererProvider>
		</BaseContainer>
	);
};
