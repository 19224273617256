import { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
	Text,
	Button,
	breakpoints,
	PageLoader,
} from '@soluto-private/mx-asurion-ui-react';
import { useHomePage } from '@home-diy-toolbox/web/contentful';
import { withAnalytics } from '@home-diy-toolbox/web/analytics';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import { getChatClient } from '../../features/chat/components';

const MD_LG_BREAKPOINT = '@media(min-width: 1024px)';

const Container = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 1.5rem;
	align-items: center;
	padding: 3rem 0;

	${MD_LG_BREAKPOINT} {
		flex-direction: row;
		justify-content: space-between;
		gap: 4rem;
		padding: 1.5rem 0;
	}
`;

const TextSection = styled.div`
	--lgText: 2.5rem;
	--mdText: 2rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;

	.title,
	.emp {
		font-size: var(--mdText);
		text-align: center;
		max-width: 40rem;

		${MD_LG_BREAKPOINT} {
			font-size: var(--lgText);
			text-align: start;
		}

		${breakpoints.xxl} {
			max-width: 45rem;
		}
	}

	.emp {
		display: none;

		${MD_LG_BREAKPOINT} {
			display: inline;
		}
	}

	${MD_LG_BREAKPOINT} {
		gap: 1.25rem;
		justify-content: center;
		align-items: start;
	}
`;

const ImageSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		ratio: 1;
		width: 280px;

		${MD_LG_BREAKPOINT} {
			width: 400px;
		}
	}
`;

const Subtitle = styled.div`
	text-align: center;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		text-align: left;
		color: ${colors.neutralDeepest};
	}
`;

const Actions = styled.div`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	gap: 0.75rem;
	margin-top: 16px;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		margin-top: 0;
		flex-direction: row;
		align-items: start;
	}
`;

const AnalyticsButton = withAnalytics(Button);

export const HeroSection: FunctionComponent = () => {
	const homePage = useHomePage();

	if (!homePage) return <PageLoader />;

	if (homePage.designType === false) {
		return;
	}

	return (
		<Container>
			<TextSection>
				<Text className="title" weight="feather">
					{homePage.heroTitle}
					<Text className="emp" weight="heavy">
						{' ' + homePage.heroTitle2}
					</Text>
				</Text>
				<Subtitle>
					<Text>{homePage.heroSubtitle}</Text>
				</Subtitle>
				<Actions>
					<AnalyticsButton
						color="secondary"
						variant="outline"
						onClick={() =>
							window.open(`tel:${homePage.heroCtas[0].telephoneNumber}`, '_self')
						}
						actionId="heroSection_callButton"
					>
						{homePage.heroCtas[0].copy}
					</AnalyticsButton>
					<AnalyticsButton
						color="secondary"
						onClick={() => {
							getChatClient().openMessagingOverlay();
						}}
						actionId="heroSection_chatButton"
					>
						{homePage.heroCtas[1].copy}
					</AnalyticsButton>
				</Actions>
			</TextSection>
			<ImageSection>
				<img src={homePage.heroImage.url} alt="" />
			</ImageSection>
		</Container>
	);
};
