import { ResultPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { SIGNAL_SPEED_RESULTS } from '../constants';
import { ResultsConfig } from '../types';

export const SIGNAL_RESULT_PAGES_CONFIG: ResultsConfig<'signal'> = [
	{
		pageId: ResultPageIds.SIGNAL_RESULT_DEFAULT,
	},
	{
		pageId: ResultPageIds.SIGNAL_RESULT_IDEAL,
		results: {
			signalTestResult: SIGNAL_SPEED_RESULTS.IDEAL,
		},
	},
	{
		pageId: ResultPageIds.SIGNAL_RESULT_ALMOST_IDEAL,
		results: {
			signalTestResult: SIGNAL_SPEED_RESULTS.ALMOST_IDEAL,
		},
	},
	{
		pageId: ResultPageIds.SIGNAL_RESULT_LESS_THAN_IDEAL,
		results: {
			signalTestResult: SIGNAL_SPEED_RESULTS.LESS_THAN_IDEAL,
		},
	},
	{
		pageId: ResultPageIds.SIGNAL_RESULT_POOR,
		results: {
			signalTestResult: SIGNAL_SPEED_RESULTS.POOR,
		},
	},
] as const;
