import { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	AnalyticEventType,
	AnalyticViewType,
	useAnalytics,
	withAnalyticOnView,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	CheckupCategoryType,
	CheckupQuestionPathnames,
	ComponentIds,
	CheckupPageIds,
	Checkups,
	FrequencyOption,
	FREQUENCY_OPTIONS,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow } from '../../../../providers';
import {
	CheckupContainer,
	CheckupQuestionsProgress,
	AnswerSelectionInstructionsWrapper,
	QuizFlow,
} from '../../../../components';
import { union } from 'lodash';
import {
	getArrayFromObject,
	getObjectFromArray,
	removeArrayFromArray,
} from '@home-diy-toolbox/web/utils';
import { Text, Button } from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';

const QuestionHeader = styled(Text)`
	margin-top: 16px;
`;

const NextButton = styled(Button)`
	width: 100%;
	margin-top: 56px;
`;

const AnswerOptionSection = styled.div`
	margin-top: 40px;
`;

const OptionButton = styled(Button)`
	&&& {
		padding: 0.75rem 1rem;
		flex: 1;
	}
`;

const SpeedIssueFrequencyPageV2: FunctionComponent = () => {
	const pageId = CheckupPageIds.SPEED_ISSUE_FREQUENCY_V2;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;

	const [t] = useTranslation();
	const { dispatcher } = useAnalytics();
	const enhancedDispatcher = dispatcher.extend(
		withExtras({
			PageId: pageId,
			CheckupType: Checkups.SPEED,
		})
	);

	const history = useHistory();
	const { usePageContent } = useCheckupContentContext();

	const {
		updateCheckupPage,
		getCheckupForPageId,
		flow: {
			sessionData: { partner },
		},
	} = useFlow();
	const { questionTitleCopy, instructionsCopy, nextButtonCopy, answerOptions } =
		usePageContent(pageId, partner, Checkups.SPEED);

	const { answers: latestAnswers, tags: latestTags } = getCheckupForPageId(
		CheckupCategoryType.SPEED_CHECKUP,
		pageId
	) || {
		answers: [],
		tags: [],
	};

	const [selectedAnswers, setSelectedAnswers] = useState(
		getObjectFromArray(latestAnswers)
	);
	const [selectedTags, setSelectedTags] = useState(latestTags);

	const onAnswerChange = (answerId: string, answer: FrequencyOption) => {
		const answerObj = answerOptions.find((x) => x.fields.id === answerId);
		const tags = answerObj.fields.tags || [];

		selectedAnswers[answerId] = answer;
		setSelectedAnswers({ ...selectedAnswers });

		const updatedTags =
			answer === 'never'
				? removeArrayFromArray(selectedTags, tags)
				: union(selectedTags, tags);

		setSelectedTags(updatedTags);
	};

	const onNextButtonClick = () => {
		const answers = getArrayFromObject(selectedAnswers);

		updateCheckupPage(CheckupCategoryType.SPEED_CHECKUP, pageId, answers, selectedTags);

		enhancedDispatcher
			.extend(
				withExtras({
					ActionId: nextButtonId,
					SelectedAnswers: selectedAnswers,
				})
			)
			.dispatch(AnalyticEventType.CLICK);

		history.push(CheckupQuestionPathnames.SPEED_ISSUE_FREQUENCY_V2, {
			from: history.location.pathname,
		});
	};

	const getQuizConfig = () => {
		const frequencyOptions = [
			{
				value: FREQUENCY_OPTIONS.Always,
				text: t(FREQUENCY_OPTIONS.Always),
			},
			{
				value: FREQUENCY_OPTIONS.Sometimes,
				text: t(FREQUENCY_OPTIONS.Sometimes),
			},
			{
				value: FREQUENCY_OPTIONS.Never,
				text: t(FREQUENCY_OPTIONS.Never),
			},
		];
		const questions = answerOptions.map((answerOption) => {
			const { id, copy } = answerOption.fields;

			return {
				id,
				title: copy,
				options: frequencyOptions,
			};
		});

		return {
			questions,
		};
	};

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress currQuestion={1} checkupType={Checkups.SPEED} />

			<QuestionHeader size={5} weight="feather" color="secondary" tabIndex={0}>
				{questionTitleCopy}
			</QuestionHeader>

			<AnswerSelectionInstructionsWrapper>
				<RichText document={instructionsCopy} />
			</AnswerSelectionInstructionsWrapper>

			<AnswerOptionSection>
				<QuizFlow
					answers={selectedAnswers}
					config={getQuizConfig()}
					onAnswer={onAnswerChange}
					optionButton={<OptionButton color="secondary" />}
				/>
			</AnswerOptionSection>

			<NextButton
				color="secondary"
				onClick={onNextButtonClick}
				disabled={Object.keys(selectedAnswers).length < answerOptions.length}
				data-cy="next-button"
			>
				{nextButtonCopy}
			</NextButton>
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.SPEED_ISSUE_FREQUENCY_V2,
	CheckupType: Checkups.SPEED,
})(SpeedIssueFrequencyPageV2);
