import { UbifApiClient } from '@home-diy-toolbox/shared/clients';
import { environment } from '../environments/environment';
import httpClient from './httpClient';

const ubifApiClient = new UbifApiClient({
	domain: environment.ubifApiDomain,
	httpClient,
});

export default ubifApiClient;
