export const PhoneIcon = (): JSX.Element => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.999 1.99902H7.99902C6.89602 1.99902 5.99902 2.89502 5.99902 3.99902V19.999C5.99902 21.103 6.89602 21.999 7.99902 21.999H15.999C17.102 21.999 17.999 21.103 17.999 19.999V3.99902C17.999 2.89602 17.102 1.99902 15.999 1.99902ZM15.999 3.99902V5.99902H7.99902V3.99902H15.999ZM16 7.99902V15.999H7.99902V7.99902H16ZM7.99902 19.999V17.999H16V19.999H7.99902Z"
			fill="black"
		/>
	</svg>
);
