/** A more useful error type for throwing that includes context */
export class AppError extends Error {
	[key: string]: unknown;

	constructor(message?: string, context?: Record<string, unknown>) {
		if (!context) {
			super(message);
			return;
		}

		super(`${message}. \n ${JSON.stringify(context)}`);

		for (const key in context) {
			Object.defineProperty(this, key, {
				value: context[key],
				enumerable: true,
			});
		}
	}
}
