import React, { FunctionComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RouteProps, Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	withOSSelection,
	CalculatingBatteryResultPage,
	BatteryResultsPage,
	CalculatingSignalResultPage,
	SignalResultsPage,
	CalculatingSpeedResultPage,
	SpeedResultsPage,
} from '../pages';

const BatteryResultCalcWithOS = withOSSelection(CalculatingBatteryResultPage);
const BatteryResultsWithOS = withOSSelection(BatteryResultsPage);
const SpeedResultCalcWithOS = withOSSelection(CalculatingSpeedResultPage);
const SpeedResultsWithOS = withOSSelection(SpeedResultsPage);
const SignalResultCalcWithOS = withOSSelection(CalculatingSignalResultPage);
const SignalResultsWithOS = withOSSelection(SignalResultsPage);

export const ResultRoutes: FunctionComponent<RouteProps> = ({ baseRoute }) => (
	<Switch>
		<Route
			exact
			path={`${baseRoute}/battery/loading`}
			render={() => <BatteryResultCalcWithOS checkupType={Checkups.BATTERY} />}
		/>
		<Route
			exact
			path={`${baseRoute}/battery`}
			render={() => <BatteryResultsWithOS checkupType={Checkups.BATTERY} />}
		/>
		<Route
			exact
			path={`${baseRoute}/speed/loading`}
			render={() => <SpeedResultCalcWithOS checkupType={Checkups.SPEED} />}
		/>
		<Route
			exact
			path={`${baseRoute}/speed`}
			render={() => <SpeedResultsWithOS checkupType={Checkups.SPEED} />}
		/>
		<Route
			exact
			path={`${baseRoute}/signal/loading`}
			render={() => <SignalResultCalcWithOS checkupType={Checkups.SIGNAL} />}
		/>
		<Route
			exact
			path={`${baseRoute}/signal`}
			render={() => <SignalResultsWithOS checkupType={Checkups.SIGNAL} />}
		/>
		<Route path="*" render={() => <Redirect to={'/'} />} />
	</Switch>
);
