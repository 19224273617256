import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { UAParser } from 'ua-parser-js';

const UNKNOWN_VALUE = 'Unknown';
const USER_BROWSER_ID_KEY = 'UserBrowserId';
const QUERY_PARAMS_KEY = 'flow-query-parameters';

export class UserBrowser {
	protected static instance: UserBrowser | undefined;

	UserBrowserId: string;
	Os: string;
	OsVersion: string;
	Vendor: string;
	Model: string;
	BrowserType: string;
	BrowserVersion: string;
	FormFactor: string;
	queryParams: string | URLSearchParams;
	UserAgent: string;
	CookiesEnabled: boolean;

	protected constructor() {
		this.initQueryParameters();

		const ua = new UAParser().getResult();
		this.Os = ua.os.name || UNKNOWN_VALUE;
		this.OsVersion = ua.os.version || UNKNOWN_VALUE;
		this.Vendor = ua.device.vendor || UNKNOWN_VALUE;
		this.Model = ua.device.model || UNKNOWN_VALUE;
		this.BrowserType = ua.browser.name || UNKNOWN_VALUE;
		this.BrowserVersion = ua.browser.major || UNKNOWN_VALUE;
		this.FormFactor =
			(ua.device.type === 'tablet' && 'Tablet') ||
			(ua.device.type === 'mobile' && 'Mobile') ||
			'Desktop';
		this.UserAgent = navigator.userAgent;
		this.CookiesEnabled = navigator.cookieEnabled;
		this.UserBrowserId = this.retrieveId();
	}

	private retrieveId(): string {
		let id = undefined;

		id = Cookies.get(USER_BROWSER_ID_KEY);

		if (!id) {
			id = localStorage.getItem(USER_BROWSER_ID_KEY);
		}

		if (!id) {
			id = uuidv4();
		}
		try {
			localStorage.setItem(USER_BROWSER_ID_KEY, id);
		} catch (err) {
			console.error('failed setting local storage with user browser id', err);
		}

		return id;
	}

	private initQueryParameters() {
		let queryParams = sessionStorage.getItem(QUERY_PARAMS_KEY);
		if (!queryParams) {
			queryParams = window.location.search;
			try {
				sessionStorage.setItem(QUERY_PARAMS_KEY, queryParams);
			} catch (err) {
				console.error('failed persisting the query params into browser storage', err);
			}
		}

		if (window.URLSearchParams) {
			this.queryParams = new window.URLSearchParams(queryParams);
		}
	}

	public static get(): UserBrowser {
		if (!UserBrowser.instance) {
			UserBrowser.instance = new UserBrowser();
		}

		return UserBrowser.instance;
	}

	protected static clearInstance() {
		UserBrowser.instance = undefined;
	}

	public isIE() {
		return this.BrowserType ? this.BrowserType.toLowerCase() === 'ie' : false;
	}
}
