import { v4 as uuidv4 } from 'uuid';
import {
	FLOW_CHECKUP_KEYS,
	FLOW_DIAGNOSTIC_KEYS,
	FLOW_VERSION,
	FLOW_VERSION_KEY,
} from './constants';
import {
	AppFlowData,
	CheckupAnswersType,
	DEFAULT_FLOW_SESSION,
	DiagnosticsAnswersType,
} from './models';
import {
	readFromStorage,
	readJsonFromStorage,
	readMapFromStorage,
	setInStorage,
	stringifyMap,
} from './utilities';

/****************************************************

WHAT:
	This contains Flow Data Access & Persistence logic as well as Versioned Flow Readers.

WHY:
	FlowData schema may be changed, this is stored and loaded from session storage. 
	Failure to load the flow data because of a schema change may interrupt the user's experience.
	This provides a pattern to support those changes without interrupting user experiences.

UPDATING:
  	A new reader version should be created (ie. v1 -> v2) and FLOW_VERSION_KEY updated whenever 
	the flowdata has been changed in a non-backwards compatible manner.
	If it has been changed in a compatible way, the current version can simply be updated.
	
	For example:
		- New Property: Update Current Reader
		- Property Renamed/Moved/Deleted: Create a new reader version, update FLOW_VERSION_KEY, and update 'flowReaders'

 ******************************************************/

export const flowReaders = {
	0: readV0,
	1: readV1,
};

/** Will fetch & parse the versioned flow from session storage.
 * In addition to updating the Flow Version & Schema to latest.
 */
export const fetchFlowFromStorage = (): AppFlowData => {
	const flowVersion = Number(readFromStorage(FLOW_VERSION_KEY)) || 0;
	const flowData = flowReaders[flowVersion]();

	if (flowVersion !== FLOW_VERSION) {
		updateFlowVersion(flowData);
	}

	return flowData;
};

export const persistFlowToStorage = (flowData: AppFlowData) => {
	try {
		setInStorage('flowId', flowData.flowId);
		setInStorage('flow_sessionData', JSON.stringify(flowData.sessionData));
		setInStorage(FLOW_CHECKUP_KEYS.Battery, stringifyMap(flowData.batteryCheckup));
		setInStorage(FLOW_CHECKUP_KEYS.Signal, stringifyMap(flowData.signalCheckup));
		setInStorage(FLOW_CHECKUP_KEYS.Speed, stringifyMap(flowData.speedCheckup));

		setInStorage(FLOW_DIAGNOSTIC_KEYS.Battery, stringifyMap(flowData.batteryDiagnostics));
		setInStorage(FLOW_DIAGNOSTIC_KEYS.Signal, stringifyMap(flowData.signalDiagnostics));
	} catch (error) {
		console.error('failed to persist flow data');
	}
};

function updateFlowVersion(flowData: AppFlowData) {
	window.sessionStorage.setItem(FLOW_VERSION_KEY, `${FLOW_VERSION}`);
	persistFlowToStorage(flowData);
}

function readV0(): AppFlowData {
	const flowId = readFromStorage('flowId') || uuidv4();
	const sessionData = readJsonFromStorage('flowDataData', DEFAULT_FLOW_SESSION);

	const batteryCheckup = readMapFromStorage<CheckupAnswersType>('batteryCheckupData');
	const signalCheckup = readMapFromStorage<CheckupAnswersType>('signalCheckupData');
	const speedCheckup = readMapFromStorage<CheckupAnswersType>('speedCheckupData');

	const batteryDiagnostics = readMapFromStorage<DiagnosticsAnswersType>(
		'batteryDiagnosticsData'
	);
	const signalDiagnostics = readMapFromStorage<DiagnosticsAnswersType>(
		'signalDiagnosticsData'
	);

	return {
		flowId,
		sessionData,
		batteryCheckup,
		signalCheckup,
		speedCheckup,
		batteryDiagnostics,
		signalDiagnostics,
	};
}

function readV1(): AppFlowData {
	const flowId = readFromStorage('flowId') || uuidv4();
	const sessionData = readJsonFromStorage('flow_sessionData', DEFAULT_FLOW_SESSION);

	const batteryCheckup = readMapFromStorage<CheckupAnswersType>(
		FLOW_CHECKUP_KEYS.Battery
	);
	const signalCheckup = readMapFromStorage<CheckupAnswersType>(FLOW_CHECKUP_KEYS.Signal);
	const speedCheckup = readMapFromStorage<CheckupAnswersType>(FLOW_CHECKUP_KEYS.Speed);

	const batteryDiagnostics = readMapFromStorage<DiagnosticsAnswersType>(
		FLOW_DIAGNOSTIC_KEYS.Battery
	);
	const signalDiagnostics = readMapFromStorage<DiagnosticsAnswersType>(
		FLOW_DIAGNOSTIC_KEYS.Signal
	);

	return {
		flowId,
		sessionData,
		batteryCheckup,
		signalCheckup,
		speedCheckup,
		batteryDiagnostics,
		signalDiagnostics,
	};
}
