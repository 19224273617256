/** Turns an object into a mapped object of just it's leys */
export function keysAsObject<T extends Record<string, unknown>>(obj: T) {
	const keys = Object.keys(obj);
	const output = keys.reduce((prev, current) => {
		prev[current] = current;
		return prev;
	}, {});

	return output as {
		[key in keyof T]: key;
	};
}
