import { simplrChatAI, SUPPORTED_PATHNAME_FOR_SIMPLR_LIST, remoteExpertChat } from '.';

let _isSupported = false;

const getCurrentPathname = () => {
	const path = window.location.pathname.replace('/device-care', '');
	return path.length > 0 ? path : '/';
};

export const getChatClient = () => {
	const path = getCurrentPathname();
	if (_isSupported && SUPPORTED_PATHNAME_FOR_SIMPLR_LIST.includes(path)) {
		return simplrChatAI;
	}
	return remoteExpertChat;
};

export const setIsSimplrAI2024Supported = (simplrAI2024: boolean) => {
	_isSupported = simplrAI2024;
};
