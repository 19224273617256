import { WixiCore } from '@soluto-private/wixi-web-sdk';

type WixiPartner = {
	partnerAppId: string;
	partnerAppSecret: string;
	partnerId: string;
	partnerSecret: string;
};

export const initializeWixiSdk = (wixiPartner: WixiPartner): void => {
	WixiCore.initialize({
		wixiPartner,
	});
};
