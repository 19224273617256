import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	margin-top: 16px;
`;

export type QuestionContainerProps = {
	className?: string;
};

export const QuestionContainer: React.FunctionComponent<QuestionContainerProps> = ({
	children,
	className,
}) => <Container className={className}>{children}</Container>;
