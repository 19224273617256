import type {
	BatteryChargeAnswer,
	MainIssuesAnswer,
	OtherIssuesAnswer,
	UsageHabitAnswer,
	iPhoneBatteryHealthAnswer,
} from './types';

export const initialMainIssuesAnswer = (): MainIssuesAnswer => {
	return {
		damaged: false,
		glass_cracked_front: false,
		glass_cracked_back: false,
		none: false,
	};
};

export const initialOtherIssuesAnswer = (): OtherIssuesAnswer => {
	return {
		hot: false,
		charge_suddenly_drops: false,
		turns_off_unexpectedly: false,
		charges_slowly: false,
		wont_charge: false,
		none: false,
	};
};

export const initialIphoneBatteryHealthAnswer = (): iPhoneBatteryHealthAnswer => {
	return {
		percentage: null,
		batteryMessage: null,
	};
};

export const initialBatteryChargeAnswer = (): BatteryChargeAnswer => ({
	currentCharge: null,
	duration: {
		hours: null,
		days: null,
		minutes: null,
	},
	remainingHours: null,
	dontHaveOption: false,
});

export const initialUsageHabitsAnswer = (): UsageHabitAnswer => {
	return {
		call: 0,
		gaming: 0,
		audio: 0,
		socialMedia: 0,
		video: 0,
	};
};
