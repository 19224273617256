import { Button as ButtonType } from '@home-diy-toolbox/web/contentful';
import { ButtonRenderer } from './ButtonRenderer';
import { ButtonOnClick } from './types';

export const ActionButton = (props: {
	button: ButtonType;
	buttonRenderer: ButtonRenderer;
	onClick: ButtonOnClick;
}) => {
	if (!props.button) return null;

	const { action, copy, url, telephoneNumber } = props.button;
	const { buttonRenderer, onClick } = props;

	switch (action) {
		case 'internalLink':
			return buttonRenderer.createInternalLinkButton(copy, url, onClick);
		case 'externalLink':
			return buttonRenderer.createExternalLinkButton(copy, url, onClick);
		case 'phoneNumber':
			return buttonRenderer.createPhoneButton(copy, telephoneNumber, onClick);
		case 'openChat':
			return buttonRenderer.createOpenChatButton(copy, onClick);
		default:
			return buttonRenderer.createDefaultButton(copy, onClick);
	}
};
