export enum AnalyticEventType {
	DEBUG = 'debug',
	VIEW = 'view',
	CLICK = 'click',
	SUBMIT = 'submit',
	INPUT = 'input',
	SIGNAL_TEST_COMPLETE = 'signalTestComplete',
	AE_SESSION_CHANGED = 'aeSdkSessionChanged',
}

export enum AnalyticViewType {
	PAGE = 'page',
	RESULTS = 'results',
	MODAL = 'modal',
	ARTICLE_SUGGESTION_ITEM = 'articleSuggestionItem',
	ARTICLE_SUGGESTION_SECTION = 'articleSuggestionSection',
	ARTICLE = 'article',
}

export interface Options {
	scope: string;
	appName: string;
	isProduction: boolean;
	partner?: string;
}

type AmbiguousObject = {
	[key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type AnalyticExtras = (() => AmbiguousObject | undefined) | AmbiguousObject;
