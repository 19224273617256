import { FunctionComponent } from 'react';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { ResultPageIds, Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import { CheckupContainer, MagicWandWithLabel } from '../../../components';
import { useResultPageLoading } from '../../../features/checkupResults/hooks';

const CalculatingSignalResultPage: FunctionComponent = () => {
	const pageId = ResultPageIds.CALCULATING_SIGNAL_RESULT;
	const { useResultPageContent } = useCheckupContentContext();
	const { resultPageTitle } = useResultPageContent(pageId);
	useResultPageLoading(Checkups.SIGNAL);

	return (
		<CheckupContainer>
			<MagicWandWithLabel label={resultPageTitle} />
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: ResultPageIds.CALCULATING_SIGNAL_RESULT,
	CheckupType: Checkups.SIGNAL,
})(CalculatingSignalResultPage);
