import {
	CheckupDiagnosticsPathnames,
	CheckupQuestionPathnames,
	CheckupResultKey,
	CheckupResultKeys,
	CheckupResultPathnames,
	Checkups,
	Partner,
	Partners,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow, useResults } from '../../../providers';
import { useHistory } from 'react-router-dom';
import { RESULT_PAGES } from '../constants';
import { CheckupFlowParams, CHECKUP_FLOW_CONFIGS, SupportedCheckups } from '../configs';
import {
	FlowPageNextParams,
	CheckupPath,
	CheckupPathKey,
	CheckupsPages,
	AnyPage,
	FlowPagesConfig,
} from '../types';
import { isNewCheckupExperience } from '@home-diy-toolbox/web/contentful';

const getPathFromKey = (pathKey: CheckupPathKey): CheckupPath => {
	if (pathKey in CheckupQuestionPathnames) {
		return CheckupQuestionPathnames[pathKey];
	}

	if (pathKey in CheckupDiagnosticsPathnames) {
		return CheckupDiagnosticsPathnames[pathKey];
	}

	if (pathKey in CheckupResultPathnames) {
		return CheckupResultPathnames[pathKey];
	}

	throw new Error(`Unsupported pathKey "${pathKey}" provided to getPathFromKey`);
};

const getKeyFromCurrentPath = (currentPath: string) => {
	const cleanedPath = currentPath.replace('/device-care', '');

	return (
		Object.keys(CheckupQuestionPathnames).find(
			(key) => CheckupQuestionPathnames[key] === cleanedPath
		) ||
		Object.keys(CheckupDiagnosticsPathnames).find(
			(key) => CheckupDiagnosticsPathnames[key] === cleanedPath
		) ||
		Object.keys(CheckupResultPathnames).find(
			(key) => CheckupResultPathnames[key] === cleanedPath
		)
	);
};

/** Finds the partner and merges it against the default config, overriding duplicate properties in favor of the partner config
 * If no partner config exists, returns the default config
 */
export const getConfigForPartner = <TParams extends FlowPageNextParams>(
	partner: Partner | string,
	checkup: SupportedCheckups,
	useCustomFlow: boolean
): FlowPagesConfig<TParams> => {
	const defaultConfig = CHECKUP_FLOW_CONFIGS[checkup][Partners.DEFAULT];

	if (!useCustomFlow) {
		return defaultConfig;
	}

	if (partner in CHECKUP_FLOW_CONFIGS[checkup]) {
		return { ...defaultConfig, ...CHECKUP_FLOW_CONFIGS[checkup][partner] };
	}

	return defaultConfig;
};

// Function overload to capture required vs optional params
type GoNextFunc<
	TParams extends FlowPageNextParams,
	TPage extends CheckupsPages
> = TPage extends keyof TParams ? (params: TParams[TPage]) => void : () => void;

type UseCheckupPageReturn<
	TParams extends FlowPageNextParams,
	TPage extends CheckupsPages
> = [goNext: GoNextFunc<TParams, TPage>, skipPage: () => void];

let currentPage: AnyPage = null;

const setPage = (page: AnyPage) => {
	currentPage = page;
};

export const useCheckupPageFlow = <
	TCheckup extends SupportedCheckups,
	TPage extends CheckupsPages
>(
	checkupType: TCheckup,
	checkupPage: TPage
): UseCheckupPageReturn<CheckupFlowParams[TCheckup], TPage> => {
	const history = useHistory();
	const { calculateResults } = useResults();
	const { flow } = useFlow();
	const partner = flow.sessionData.partner;

	const flowConfig = getConfigForPartner<CheckupFlowParams[TCheckup]>(
		partner,
		checkupType,
		isNewCheckupExperience(partner, checkupType)
	);
	const resultPage = RESULT_PAGES[checkupType];

	if (checkupPage !== currentPage) {
		setPage(checkupPage);
	}

	const goToResults = (pathKey: CheckupResultKey) => {
		calculateResults(Checkups.BATTERY);
		goTo(pathKey);
	};

	const goTo = (pathKey: CheckupPathKey) => {
		const path = getPathFromKey(pathKey);

		if (getKeyFromCurrentPath(history.location.pathname) === pathKey) {
			console.error(
				`Attempting to navigate to same page "${pathKey}" in useBatteryCheckup.goTo(). Ensure flow config & page caller are correct.`
			);
		}

		setPage(pathKey);

		history.push(path, {
			from: history.location.pathname,
		});
	};

	const skipPage = () => {
		if (currentPage in CheckupResultKeys) {
			console.error(
				`Attempted to skipPage() on battery checkup flow when on the result page`
			);
			return;
		}

		const nextPage = flowConfig[currentPage].skip;

		if (!nextPage) {
			throw new Error(`No value for "skip" when skipping page from "${currentPage}"`);
		}

		goTo(nextPage);
	};

	/** Go to the next flow page, whatever that might be.
	 * @param params The params goNext() is dependant on for the current page, will be automatically required based on page param
	 */
	// @ts-expect-error The types match up, but TS is unhappy, couldn't figure it out
	const goNext: GoNextFunc<CheckupFlowParams[TCheckup], TPage> = async (
		params: TPage extends keyof CheckupFlowParams[TCheckup]
			? CheckupFlowParams[TCheckup][TPage]
			: undefined
	) => {
		if (currentPage === resultPage[checkupType as SupportedCheckups]) {
			console.error(
				`Attempted to goNext() on battery checkup flow when on the result page`
			);
			return;
		}

		const pageConfig = flowConfig[checkupPage];
		let next: CheckupPathKey = null;

		if (typeof pageConfig.next === 'string') {
			next = pageConfig.next;
		} else {
			// @ts-expect-error TS doesn't seem to handle the type juggling well for this call
			next = await pageConfig.next(params);
		}

		if (next in CheckupResultKeys) {
			goToResults(next as CheckupResultKey);
		} else {
			goTo(next);
		}
	};

	return [goNext, skipPage];
};
