import { useMemo } from 'react';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import { getServiceCards } from '../ServiceCards';
import { useFlow } from '../../providers';
import { Props } from './Props';

const Title = styled(Text)`
	&& {
		margin-bottom: 1rem;
		display: inline-block;
	}
`;

const Container = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		flex-direction: row;
		& > * {
			flex: 0 0 18.75rem;
		}
	}
`;

const ScrollableContainer = styled.div`
	display: flex;
	gap: 1em;
	width: 100%;
	overflow: auto;

	& > * {
		flex: 0 0 18.75rem;
	}
`;

export const OldServices = ({ checkups, title, xScrollable }: Props) => {
	const { usePageContent } = useCheckupContentContext();
	const { answerOptions } = usePageContent(GenericPageIds.IN_STORE_HOME);
	const { flow } = useFlow();
	const { eligibility } = flow.sessionData;

	const services = useMemo(
		() =>
			checkups
				.map((checkup) => answerOptions.find((answer) => answer.fields.id === checkup))
				.filter((checkup) => eligibility[checkup.fields.id] !== false),
		[answerOptions, checkups, eligibility]
	);

	const cards = getServiceCards(services);

	return (
		<div>
			<Title weight="feather" size={4} forwardedAs="h2">
				{title}
			</Title>

			{xScrollable ? (
				<ScrollableContainer>{cards}</ScrollableContainer>
			) : (
				<Container>{cards}</Container>
			)}
		</div>
	);
};
