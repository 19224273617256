import React from 'react';
import { Document } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import RichTextOverride from './RichTextOverride';

const Container = styled.div`
	margin: 0.5rem 0;
	background: ${({ theme }) => theme.article.subtextBackgroundColor};
	padding: 1rem;
	border-radius: 0.5rem;
	&& > p {
		display: inline;
	}
`;

type Props = {
	title: string;
	content: Document;
};

export const Note: React.FunctionComponent<Props> = ({ title, content }) => {
	return (
		<Container>
			<Text as="span" weight="heavy" size={2}>
				{title}
				{title && ': '}
			</Text>
			<RichTextOverride content={content} />
		</Container>
	);
};
