import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UnitPicker } from './unit-picker';
import {
	calculateTotalDurationInHours,
	isDefaultDurationValue,
	validDurationValue,
	withDaysLimit,
	withHoursLimit,
	withMinutesLimit,
} from './utils';
import type { Duration, Unit } from './types';

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
	gap: 0.75rem;
	width: 100%;
`;

export interface DurationChangeEvent {
	value: Duration;
	totalDurationInHours: number;
	isDurationReady: boolean;
	isDefaultDuration: boolean;
}

interface DurationPickerProps {
	initialDuration?: Duration;
	onDurationChanged: (event: DurationChangeEvent) => void;
	onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
	disabled?: boolean;
}

export const DurationPicker = ({
	initialDuration = { days: undefined, hours: undefined, minutes: undefined },
	onDurationChanged,
	onKeyDown,
	disabled = false,
}: DurationPickerProps) => {
	const [{ days, hours, minutes }, setDuration] = useState<Duration>(initialDuration);

	useEffect(() => {
		onDurationChanged({
			isDurationReady: isDurationReady(initialDuration),
			value: validDurationValue(initialDuration),
			totalDurationInHours: calculateTotalDurationInHours(initialDuration),
			isDefaultDuration: isDefaultDurationValue(initialDuration),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleValueChange = (value: number | undefined, unit: Unit) => {
		setDuration((previousValues) => {
			const duration = { ...previousValues, [unit]: value };
			onDurationChanged({
				isDurationReady: isDurationReady(duration),
				value: validDurationValue(duration),
				totalDurationInHours: calculateTotalDurationInHours(duration),
				isDefaultDuration: isDefaultDurationValue(duration),
			});

			return duration;
		});
	};

	const isDurationReady = (duration: Duration) => {
		return [duration.days, duration.hours, duration.minutes].some((unit) => !!unit);
	};

	return (
		<Container>
			<UnitPicker
				value={days}
				unit="days"
				isValidValue={withDaysLimit}
				onKeyDown={onKeyDown}
				onValueChange={handleValueChange}
				disabled={disabled}
			/>
			<UnitPicker
				value={hours}
				unit="hours"
				isValidValue={withHoursLimit}
				onKeyDown={onKeyDown}
				onValueChange={handleValueChange}
				disabled={disabled}
			/>
			<UnitPicker
				value={minutes}
				unit="minutes"
				isValidValue={withMinutesLimit}
				onKeyDown={onKeyDown}
				onValueChange={handleValueChange}
				disabled={disabled}
			/>
		</Container>
	);
};
