import { ReactNode } from 'react';
import styled from 'styled-components';
import {
	Tag,
	Text,
	colors,
	getFontSize,
	getFontWeight,
} from '@soluto-private/mx-asurion-ui-react';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	display: flex;
	border: 1px solid ${colors.neutralDeep};
	border-radius: 0.5rem;
	overflow: hidden;
`;

const TagContainer = styled.div`
	margin-bottom: 0.5rem;
	display: flex;
	gap: 0.5rem;
`;

const Image = styled.img`
	flex: 0 1 50%;
	min-width: 0;
	max-width: 12.5rem;
	min-height: 13.4375rem;
	object-fit: cover;
`;

const Deductible = styled.div`
	display: flex;
	font-size: ${getFontSize(3)};
	gap: 0.25rem;
	margin-bottom: 1rem;
`;

const Content = styled.div`
	flex: 1 1 50%;
	padding: 1rem;
	display: flex;
	flex-direction: column;
`;

const Title = styled(Text)`
	&& {
		display: block;
		font-size: ${getFontSize(4)};
		font-weight: ${getFontWeight('feather')};
		margin-bottom: 0.25rem;
	}
`;

const Description = styled(Text)`
	&& {
		display: block;
		font-weight: ${getFontWeight('feather')};
		margin-bottom: 1.375rem;
	}
`;

const ButtonContainer = styled.div`
	margin-top: auto;
	display: flex;
`;

interface Props {
	title: string;
	description: string;
	deductible: string;
	tags: string[];
	imageUrl: string;
	imageAlt: string;
	button: ReactNode;
}

export const ResultCard = ({
	title,
	description,
	tags,
	imageUrl,
	imageAlt,
	button,
	deductible,
}: Props) => {
	const { t } = useTranslation();

	return (
		<Container>
			<Image src={imageUrl} alt={imageAlt} />
			<Content>
				<TagContainer>
					{tags.map((tag) => (
						<Tag key={tag} type="light" text={tag} />
					))}
				</TagContainer>
				<Title>{title}</Title>
				<Description>{description}</Description>
				{deductible ? (
					<Deductible>
						<Text weight="heavy">{deductible}</Text>
						<Text weight="feather">{t('deductible')}</Text>
					</Deductible>
				) : null}
				<ButtonContainer>{button}</ButtonContainer>
			</Content>
		</Container>
	);
};
