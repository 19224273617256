import {
	CheckupDiagnosticsPathnames,
	CheckupQuestionPathnames,
	Checkups,
	Checkup,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { environment } from '../../environments/environment';

export const servicesMap: Record<Checkup, string> = {
	[Checkups.BATTERY]: CheckupQuestionPathnames.BATTERY,
	[Checkups.SIGNAL]: CheckupDiagnosticsPathnames.SIGNAL_TURN_OFF_WIFI,
	[Checkups.SPEED]: CheckupQuestionPathnames.SPEED_ISSUE_FREQUENCY,
	[Checkups.DEVICE_SANITIZATION]: `/article/${environment.serviceArticles.default.sanitization}`,
	[Checkups.DEVICE_PERFORMANCE]: `/article/${environment.serviceArticles.default.performance}`,
	[Checkups.DEVICE_PERFORMANCE_YEAR_2]: `/article/${environment.serviceArticles.default.performanceYear2}`,
	[Checkups.DATA_RECOVERY]: `/article/${environment.serviceArticles.default.recovery}`,
	[Checkups.DEVICE_DIAGNOSTICS]: `/article/${environment.serviceArticles.default.deviceDiagnostics}`,
};
