import styled from 'styled-components';
import { Text, colors } from '@soluto-private/mx-asurion-ui-react';
import LightBulbIdea from '../../assets/lightBulbIdea.svg';
import { FunFact } from '@home-diy-toolbox/web/contentful';
import RichTextOverride from './RichTextOverride';

const Container = styled.div`
	&& {
		border-radius: 0.5rem;
		background-color: ${colors.neutralBrightest};
		padding: 1rem;
		width: auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 1rem;
		margin: 1rem 0;
	}
`;

const Disclaimer = styled(Text)`
	margin-bottom: 1.5rem;
`;

const InformationContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > p {
		margin: 0.5rem 0;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
`;

export const FunFactBlock = ({ header, content, description }: FunFact): JSX.Element => {
	return (
		<>
			<Container>
				<InformationContainer>
					<Text size={3} weight="heavy">
						{header}
					</Text>
					<RichTextOverride content={content} />
				</InformationContainer>
				<img src={LightBulbIdea} alt="Light bulb idea icon" />
			</Container>
			{!!description && (
				<Disclaimer weight="heavy" size={1}>
					{description}
				</Disclaimer>
			)}
		</>
	);
};
