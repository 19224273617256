import { Checkups, Partners } from '@home-diy-toolbox/web/common-types/refresh-base';
import {
	calculateBatteryResults,
	calculateSignalResults,
	calculateSpeedResults,
} from '../calculations';
import {
	BatteryCheckResult,
	BatteryCheckResultsModel,
	CheckupResultBase,
	SignalCheckResult,
	SignalCheckResultsModel,
} from '../types';
import {
	ATT_BATTERY_RESULT_PAGES_CONFIG,
	BATTERY_RESULT_PAGES_CONFIG,
	VERIZON_BATTERY_RESULT_PAGES_CONFIG,
	COX_BATTERY_RESULT_PAGES_CONFIG,
	USCELLULAR_BATTERY_RESULT_PAGES_CONFIG,
	CRICKET_BATTERY_RESULT_PAGES_CONFIG,
} from './batteryResultsConfig';
import { SIGNAL_RESULT_PAGES_CONFIG } from './signalResultsConfig';
import { SPEED_RESULT_PAGES_CONFIG } from './speedResultsConfig';

export const CHECKUP_CALCULATIONS = {
	[Checkups.BATTERY]: calculateBatteryResults,
	[Checkups.SIGNAL]: calculateSignalResults,
	[Checkups.SPEED]: calculateSpeedResults,
} as const;

/** The Result page configs for each of the checkup types & partners.
 * Each of these define the results structure/data that results in each result page
 */
export const RESULT_PAGES_CONFIGS = {
	[Checkups.BATTERY]: {
		[Partners.DEFAULT]: BATTERY_RESULT_PAGES_CONFIG,
		[Partners.ATT]: ATT_BATTERY_RESULT_PAGES_CONFIG,
		[Partners.VERIZON]: VERIZON_BATTERY_RESULT_PAGES_CONFIG,
		[Partners.COX]: COX_BATTERY_RESULT_PAGES_CONFIG,
		[Partners.USCELLULAR]: USCELLULAR_BATTERY_RESULT_PAGES_CONFIG,
		[Partners.CRICKET]: CRICKET_BATTERY_RESULT_PAGES_CONFIG,
	},
	[Checkups.SIGNAL]: {
		[Partners.DEFAULT]: SIGNAL_RESULT_PAGES_CONFIG,
	},
	[Checkups.SPEED]: {
		[Partners.DEFAULT]: SPEED_RESULT_PAGES_CONFIG,
	},
} as const;

// Simple rollup of the results model types
// Used to strongly type the result page configs
export type ResultsModelsByCheckup = {
	[Checkups.BATTERY]: BatteryCheckResultsModel;
	[Checkups.SIGNAL]: SignalCheckResultsModel;
	[Checkups.SPEED]: null;
};

// Simple rollup of the full result types that include the pageId
export type CheckupResults = {
	[Checkups.BATTERY]: BatteryCheckResult;
	[Checkups.SIGNAL]: SignalCheckResult;
	[Checkups.SPEED]: CheckupResultBase;
};

export type SupportedCheckupResults = keyof typeof RESULT_PAGES_CONFIGS;
