import React from 'react';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { SpeedTestProgressState } from '@home-diy-toolbox/web/network-test';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import styled from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';

type SpeedTestProgress = {
	speed: number;
	status: string;
};

type SpeedProgressProps = {
	status: SpeedTestStatus;
	progressData: SpeedTestProgress;
};

const progressStyles = {
	rotation: 1 / 2 + 1 / 5,
	strokeLinecap: 'butt',
	trailColor: '#eee',
	pathColor: colors.black,
};

const SpeedProgressCircle = styled.div`
	max-width: 450px;
	width: 350px;
	height: 190px;

	path {
		stroke-width: 3;
	}

	@media all and (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		width: 300px;
	}
`;

const SpeedTestProgressContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	width: 290px;
	margin-bottom: 68px;
	max-width: 200px;
`;

const CurrentSpeedLabel = styled.span`
	font-size: 62px;
`;

const MpbsLabel = styled.div`
	font-size: 16px;
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};
`;

const Box = styled.div`
	flex-direction: column;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -50px;
`;

const CircleNumbers = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 312px;
	font-size: 16px;
	margin-top: 40px;
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};

	@media all and (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		margin-top: 10px;
		width: 260px;
		font-size: 12px;
	}
`;

const ProgressStatus = styled.div`
	font-size: 16px;
	text-align: center;
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};
	margin-top: -14px;
	font-size: 16px;

	@media all and (max-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		font-size: 14px;
		margin-top: -16px;
	}
`;

export const SpeedProgress = ({ progressData, status }: SpeedProgressProps) => {
	const speedData = progressData.speed;
	const greaterThan50 = speedData > 50;
	const {
		app: {
			signalDialConfig: {
				fields: { testingDownloadSpeedText, testInitializingText, speedUnit },
			},
		},
	} = useCheckupContentContext();

	const getStatusString = () => {
		switch (progressData.status) {
			case SpeedTestProgressState.SCAN_INITIALIZING:
				return testInitializingText;
			case SpeedTestProgressState.TESTING_DOWNLOAD:
				return testingDownloadSpeedText;
			default:
				return;
		}
	};

	return (
		<SpeedTestProgressContainer>
			<SpeedProgressCircle>
				{speedData <= 50 ? (
					<CircularProgressbarWithChildren
						value={(speedData / 50) * 100}
						circleRatio={0.6}
						styles={buildStyles(progressStyles)}
					>
						<Box>
							<CurrentSpeedLabel>{speedData}</CurrentSpeedLabel>
							<MpbsLabel>{speedUnit}</MpbsLabel>
						</Box>
					</CircularProgressbarWithChildren>
				) : (
					<CircularProgressbarWithChildren
						value={speedData}
						circleRatio={0.6}
						styles={buildStyles(progressStyles)}
					>
						<Box>
							<CurrentSpeedLabel>{speedData}</CurrentSpeedLabel>
							<MpbsLabel>{speedUnit}</MpbsLabel>
						</Box>
					</CircularProgressbarWithChildren>
				)}
			</SpeedProgressCircle>
			<CircleNumbers>
				<div>0</div>
				{!greaterThan50 && <div>50+</div>}
				{greaterThan50 && <div>100+</div>}
			</CircleNumbers>
			{status !== SpeedTestStatus.completed && (
				<ProgressStatus>{getStatusString()}</ProgressStatus>
			)}
		</SpeedTestProgressContainer>
	);
};
