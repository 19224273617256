export enum QueryParameterKeys {
	PARTNER = 'partner',
	MAKE = 'make',
	MODEL = 'model',
	ASSET_ID = 'assetid',
	SUBSCRIBER_ID = 'subscriberid',
	CID = 'cid',
	UTM = 'utm',
	DEVICE_TYPE = 'devicetype',
	PRODUCT_ID = 'productid',
	DEVICE_OS = 'deviceos',
	MX_CLIENT = 'mxclient',
	ENROLLMENT_DATE = 'enrollmentdate',
	REDIRECT_PATH = 'redirectpath',
	REDIRECT_FROM = 'redirectfrom',
}
