import React, { FunctionComponent, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import {
	useAnalytics,
	AnalyticViewType,
	withExtras,
	AnalyticEventType,
} from '@home-diy-toolbox/web/analytics';
import {
	QuestionBlock,
	RichText,
	useCheckupPage,
} from '@home-diy-toolbox/web/contentful';
import {
	GenericPathnames,
	ComponentIds,
	GenericPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow } from '../../providers';
import {
	CheckupContainer,
	QuestionHeader,
	NextButton,
	AnswerSelectionInstructionsWrapper,
	RadioButtonWithLabelGroup2,
} from '../../components';
import { RedirectProps } from './WithOSSelection';
import { getModelFromMake } from '../../utils/device-utils';
import { SelectedOS } from '../../providers/flows/models';
import { LoadingPage } from '../LoadingPage';

type Props = RedirectProps & {
	className?: string;
};

const CheckupContainerWrapper = styled(CheckupContainer)`
	padding-top: 40px;
`;

const NextButtonWrapper = styled(NextButton)`
	margin-top: 56px;
`;

export const OSSelectionPage: FunctionComponent<Props> = ({ className }) => {
	const pageId = GenericPageIds.OS_SELECTION;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;

	const history = useHistory();
	const analytics = useAnalytics();

	const { flow, setSelectedOS: setFlowSelectedOS } = useFlow();

	const location: Location<RedirectProps> = useLocation();
	const [locationState] = useState({
		redirectPath: location.state?.redirectPath || GenericPathnames.HOME,
		checkupType: location.state?.checkupType,
	});
	const { redirectPath, checkupType } = locationState;

	const flowSession = flow.sessionData;
	const { deviceOS, deviceMake, deviceModel } = flowSession;

	const [selectedOS, setSelectedOS] = useState<SelectedOS>({
		deviceOS,
		deviceMake,
		deviceModel,
	});

	const onAnswerChange = (deviceMake: string, deviceOSArray: string[] = []) =>
		setSelectedOS({
			deviceMake,
			deviceModel: getModelFromMake(deviceMake),
			deviceOS: deviceOSArray[0],
		});

	const onNextButtonClick = () => {
		setFlowSelectedOS(selectedOS);
		history.length > 1
			? history.replace(redirectPath, {
					from: history.location.pathname,
			  })
			: history.replace(redirectPath);
	};

	useEffect(
		() => {
			analytics.dispatcher
				.extend(
					withExtras({
						PageId: pageId,
						ComponentType: AnalyticViewType.PAGE,
						CheckupType: checkupType,
					})
				)
				.dispatch(AnalyticEventType.VIEW);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const pageContent = useCheckupPage('osSelectionPage');

	if (!pageContent) {
		return <LoadingPage />;
	}

	const questionBlock = pageContent.blocks.find(
		(block) => block.entryName === 'typeOfDevice'
	) as QuestionBlock;

	return (
		<CheckupContainerWrapper className={className}>
			<QuestionHeader data-cy={`${ComponentIds.QUESTION_HEADER}`}>
				{questionBlock.question}
			</QuestionHeader>
			<AnswerSelectionInstructionsWrapper>
				<RichText document={questionBlock.questionDescription} />
			</AnswerSelectionInstructionsWrapper>
			<RadioButtonWithLabelGroup2
				answerOptions={questionBlock.answers}
				buttonIdPrefix={pageId}
				onAnswerChange={onAnswerChange}
				isAnswerChecked={(deviceMake) => deviceMake === selectedOS.deviceMake}
			/>
			<NextButtonWrapper
				onClick={onNextButtonClick}
				actionId={nextButtonId}
				data-cy="next-button"
				disabled={!selectedOS.deviceOS}
				analyticExtras={{
					SelectedOS: selectedOS.deviceOS,
					SelectedMake: selectedOS.deviceMake,
					CheckupType: checkupType,
				}}
			>
				{pageContent.ctas.next.copy}
			</NextButtonWrapper>
		</CheckupContainerWrapper>
	);
};
export default OSSelectionPage;
