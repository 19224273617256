import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { isSpaceOrEnterPress } from '@home-diy-toolbox/web/utils';
import { ReactComponent as DefaultStar } from '../../../assets/star.svg';
import { useTranslation } from 'react-i18next';

const ratings = [1, 2, 3, 4, 5];

const Fieldset = styled.fieldset`
	border: none;
	padding: 0;
	margin: 30px 0 0 0;
	display: flex;
	justify-content: center;
`;

const Star = styled(DefaultStar)<{ checked: boolean }>`
	cursor: pointer;
	height: 42px;
	width: 42px;
	margin: 3px;
	path {
		stroke: ${({ checked, theme }) =>
			checked
				? theme.feedback.starActiveStrokeColor
				: theme.feedback.starInactiveStrokeColor};
		fill: ${({ checked, theme }) =>
			checked ? theme.feedback.starActiveColor : theme.feedback.starInactiveColor};
	}
`;

export type ArticleFeedbackFormProps = {
	currentRating: number;
	disabled?: boolean;
	onChange: (number) => void;
};

const StarSelection: FunctionComponent<ArticleFeedbackFormProps> = ({
	currentRating,
	disabled,
	onChange,
}) => {
	const [hoverRating, setHoverRating] = useState<number>(0);
	const { t } = useTranslation();

	const starRatingClick = (rating: number) => {
		!disabled && onChange(rating);
		setTimeout(() => window.document.getElementById('feedbackFormHeader').focus(), 500);
	};

	return (
		<Fieldset data-pw="starRating" disabled={disabled}>
			{ratings.map((index) => {
				const checked =
					index <= (hoverRating ? hoverRating : Math.max(currentRating || 0));

				return (
					<Star
						data-pw={`star-${index}`}
						data-cy={`star_${index}`}
						key={`star_${index}`}
						tabIndex={0}
						aria-label={`${t('feedbackStarRating')} ${index}${
							currentRating === index ? ' selected' : ''
						}`}
						checked={checked}
						onClick={() => starRatingClick(index)}
						onKeyPress={(event) => isSpaceOrEnterPress(event) && starRatingClick(index)}
						onMouseEnter={() => (disabled ? null : setHoverRating(index))}
						onMouseLeave={() => (disabled ? null : setHoverRating(0))}
					/>
				);
			})}
		</Fieldset>
	);
};

export default StarSelection;
