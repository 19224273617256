import { BatteryCheckupTags } from '@home-diy-toolbox/web/common-types/refresh-base';
import { AnswerType } from '../../../../providers/flows/models';

const HAZARDOUS_DAMAGE_TAGS = [BatteryCheckupTags.HAZARDOUS_DAMAGE];

const isHazardousDamage = (phoneDamageCheckup: AnswerType | undefined): boolean => {
	if (!phoneDamageCheckup?.tags?.length) {
		return false;
	}

	return HAZARDOUS_DAMAGE_TAGS.some((tag) => phoneDamageCheckup.tags.includes(tag));
};

export { HAZARDOUS_DAMAGE_TAGS, isHazardousDamage };
