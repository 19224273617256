import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { BLOCKS } from '@contentful/rich-text-types';
import {
	Breadcrumb,
	Link,
	Text,
	Carousel,
	getFontSize,
	breakpoints,
} from '@soluto-private/mx-asurion-ui-react';
import { useFlow } from '@home-diy-toolbox/apps/device-care/flow-provider';
import { CheckupPage, QuestionBlock, RichText } from '@home-diy-toolbox/web/contentful';
import { OSCheckCard } from './OSCheckCard';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { getModelFromMake } from '../../../../utils/device-utils';
import { usePageStack } from '../hooks';

const Container = styled.div`
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const Header = styled(Text)`
	&& {
		font-size: ${getFontSize(5)};

		${breakpoints.md2} {
			font-size: ${getFontSize(6)};
		}
	}
`;

type OSCheckSectionProps = {
	pageContent: CheckupPage;
	hideOptions: boolean;
};

export const OSCheckSection = ({ pageContent, hideOptions }: OSCheckSectionProps) => {
	const stack = usePageStack('BatteryCheckupV3PageStack');
	const { flow: appFlow, setSelectedOS } = useFlow();
	const { deviceOS, deviceMake } = appFlow.sessionData;
	const history = useHistory();
	const pageId = stack.peek();

	const { ctas, heading } = pageContent;

	const questionBlock = pageContent.blocks.find(
		(block) => block.entryName === 'osCheckSection'
	) as QuestionBlock;

	return (
		<Container>
			<Breadcrumb>
				<Link onClick={() => history.push('/')}>{ctas.batteryHealth?.copy}</Link>
				<Text>{ctas.batteryCheck?.copy}</Text>
			</Breadcrumb>
			<RichText
				document={heading}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => (
							<Header forwardedAs="h1" weight="heavy">
								{children}
							</Header>
						),
					},
				}}
			/>
			{!hideOptions ? (
				<>
					<Text size={4} weight="feather">
						{questionBlock.question}
					</Text>
					{questionBlock.answers ? (
						<Carousel
							minWidth={8.5625}
							showArrowOnMobile={true}
							edgeItemsPadding={1}
							options={{
								hideScrollbar: true,
							}}
						>
							{questionBlock.answers.map(
								(
									{
										fieldTitle: answerDeviceOsLabel,
										text: answerDeviceOsValue,
										value: answerDeviceMake,
										answerImage,
									},
									i
								) => (
									<OSCheckCard
										key={i}
										deviceOsLabel={answerDeviceOsLabel}
										deviceOsValue={answerDeviceOsValue}
										deviceMake={answerDeviceMake}
										img={answerImage}
										isSelected={
											`${answerDeviceOsValue?.toLocaleLowerCase()}_${answerDeviceMake?.toLocaleLowerCase()}` ===
											`${deviceOS?.toLocaleLowerCase()}_${deviceMake?.toLocaleLowerCase()}`
										}
										onChangeDevice={setSelectedOS}
										actionId={`${
											GenericPageIds.OS_SELECTION
										}_${answerDeviceOsValue?.toLocaleLowerCase()}_${answerDeviceMake?.toLocaleLowerCase()}`}
										analyticExtras={{
											OsDeviceAnswer: `${answerDeviceOsValue?.toLocaleLowerCase()}_${answerDeviceMake?.toLocaleLowerCase()}`,
											DeviceMake: answerDeviceMake,
											DeviceModel: getModelFromMake(answerDeviceMake),
											DeviceOS: answerDeviceOsValue,
											PageId: pageId,
										}}
									/>
								)
							)}
						</Carousel>
					) : null}
				</>
			) : null}
		</Container>
	);
};
