import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { StoreHours } from '@home-diy-toolbox/web/contentful';
import { Text, Skeleton, colors, Button } from '@soluto-private/mx-asurion-ui-react';
import { Map } from '../../assets';
import { useArticleRendererContext } from '../../helpers';
import { Partners } from '@home-diy-toolbox/web/common-types';

const OpenHoursContainer = styled.div`
	&& {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
`;

const StoreOpenHours = styled(Text)`
	color: ${colors.successDeeper};
`;

const StoreClosedHours = styled(Text)`
	color: ${colors.errorDeep};
`;
const ClosingTime = styled(Text)`
	margin-left: 0.1875rem;
`;

const ButtonText = styled(Text)`
	margin-left: 0.3125rem;
`;

type Props = {
	hours: StoreHours[];
	address: string;
};

export const getOpenStatus = (hours: StoreHours[]) => {
	const today = new Date();
	const storeHours = hours[today.getDay()];
	const isStoreOpen =
		today.getHours() >= parseInt(storeHours.open) &&
		today.getHours() < parseInt(storeHours.close);

	return isStoreOpen ? (
		<>
			<StoreOpenHours size={1}>Open now</StoreOpenHours>
			{'   '}
			<ClosingTime size={1}>
				{' '}
				• Closes at {parseInt(storeHours.close) % 12 || 12}
				{parseInt(storeHours.close) >= 12 ? 'pm' : 'am'}{' '}
			</ClosingTime>
		</>
	) : (
		<StoreClosedHours size={1}>Closed</StoreClosedHours>
	);
};

export const OpenHours: FunctionComponent<Props> = ({ hours, address }) => {
	const { partner } = useArticleRendererContext();
	const directionsColor = partner === Partners.ASURION ? 'primary' : 'secondary';

	return (
		<OpenHoursContainer>
			<Text size={1} weight="feather">
				{hours ? getOpenStatus(hours) : <Skeleton height="3.5rem" />}
			</Text>
			<Button
				variant="text"
				color={directionsColor}
				onClick={() =>
					window.open(
						`https://maps.google.com/?q=${address}`,
						'_blank',
						'noopener,noreferrer'
					)
				}
			>
				<Map />
				<ButtonText color={directionsColor} size={2}>
					Get directions
				</ButtonText>{' '}
			</Button>
		</OpenHoursContainer>
	);
};
