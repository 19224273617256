export enum SpeedTestProgressState {
	TESTING_DOWNLOAD = 'TESTING_DOWNLOAD',
	SCAN_INITIALIZING = 'SCAN_INITIALIZING',
}

type SpeedTestProgressData = {
	speed: number;
	status: string;
};

export const formatSpeed = (speed: string): number => {
	let numberSpeed = Math.round(parseFloat(speed.split(' ')[0]));
	// eslint-disable-next-line no-restricted-globals
	if (isNaN(numberSpeed)) {
		numberSpeed = 0;
	}

	return numberSpeed;
};

export const getProgressData = (
	status: string,
	downloadSpeed: string
): SpeedTestProgressData => {
	if (status === 'inProgressDownload') {
		return {
			speed: formatSpeed(downloadSpeed),
			status: SpeedTestProgressState.TESTING_DOWNLOAD,
		};
	}

	return { speed: 0, status: SpeedTestProgressState.SCAN_INITIALIZING };
};
