import React from 'react';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import { ThemeType } from '@home-diy-toolbox/web/theme';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import styled, { useTheme } from 'styled-components';
import FlagIcon from '../../assets/flag-icon.svg';
import {
	SignalSpeedResult,
	SIGNAL_SPEED_RESULTS,
} from '../../features/checkupResults/constants';

type Props = {
	downloadResultLabel: string;
	speedUnit: string;
	idealSpeedLabel: string;
	signalDownloadSpeed?: number;
	signalTestResult?: SignalSpeedResult;
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const SpeedProgressCircle = styled.div`
	width: 300px;
	height: 190px;
	path {
		stroke-width: 3;
	}
`;

const progressStyles = {
	rotation: 1 / 2 + 1 / 5,
	strokeLinecap: 'butt',
	trailColor: '#eee',
	pathColor: colors.black,
};

const ResultLabel = styled.div`
	margin-top: -40px;
	line-height: 32px;
	max-width: 200px;
	text-align: center;
`;

const ResultValue = styled.div`
	font-size: 48px;
`;

const SpeedUnitLabel = styled.div`
	line-height: 28px;
	font-size: 14px;
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};
`;

const IdealSpeedLabel = styled.div`
	display: flex;
	margin-top: 16px;
	padding: 6px 10px;
	background-color: ${({ theme: { signalTest } }) =>
		signalTest.idealSignalLabelBackground};
`;

const IdealSpeedLabelIcon = styled.img`
	margin-right: 6px;
`;

const calculateProgressStrokeColor = (
	signalTestResult: SignalSpeedResult,
	theme: ThemeType
) => {
	switch (signalTestResult) {
		case SIGNAL_SPEED_RESULTS.IDEAL:
			return theme.signalTest.resultDialColorIdeal;
		case SIGNAL_SPEED_RESULTS.ALMOST_IDEAL:
			return theme.signalTest.resultDialColorAlmostIdeal;
		case SIGNAL_SPEED_RESULTS.LESS_THAN_IDEAL:
			return theme.signalTest.resultDialColorLessThanIdeal;
		case SIGNAL_SPEED_RESULTS.POOR:
			return theme.signalTest.resultDialColorPoor;
		default:
			return theme.signalTest.progressStroke;
	}
};

export const SignalTestResultDial: React.FunctionComponent<Props> = ({
	downloadResultLabel,
	signalDownloadSpeed,
	speedUnit,
	idealSpeedLabel,
	signalTestResult,
}) => {
	const theme = useTheme() as ThemeType;
	const strokeColor = calculateProgressStrokeColor(signalTestResult, theme);

	return (
		<Container>
			<SpeedProgressCircle>
				<CircularProgressbarWithChildren
					value={signalDownloadSpeed}
					circleRatio={0.6}
					styles={buildStyles({ ...progressStyles, pathColor: strokeColor })}
				>
					<ResultLabel>{downloadResultLabel}</ResultLabel>
					<ResultValue data-pw="signalDownloadSpeedResult">
						{signalDownloadSpeed}
					</ResultValue>
					<SpeedUnitLabel>{speedUnit}</SpeedUnitLabel>
				</CircularProgressbarWithChildren>
			</SpeedProgressCircle>

			<IdealSpeedLabel>
				<IdealSpeedLabelIcon src={FlagIcon} alt="Flag Icon" />
				{idealSpeedLabel}
			</IdealSpeedLabel>
		</Container>
	);
};
