import React, { FunctionComponent } from 'react';
import { useAnalytics } from 'react-shisell';
import { AnalyticEventType, withExtras } from '@home-diy-toolbox/web/analytics';
import styled from 'styled-components';
import { useArticleRendererContext } from '../../helpers';
import phone from '../../assets/phone.svg';

const Container = styled.a`
	color: ${({ theme }) => theme.article.externalLinkColor};
`;

const PhoneIcon = styled.img`
	width: 20px;
	height: 20px;
	margin-left: 2px;
`;

export type Props = {
	defaultText: string;
	defaultLink: string;
	alternateText: Array<string>;
	alternateNumber: Array<string>;
};

const getLinkFromPartner = (
	defaultText: string,
	defaultLink: string,
	alternateText: Array<string>,
	alternateNumber: Array<string>,
	partner: string
) => {
	const link = (
		alternateNumber.find((link) => link.includes(partner)) || defaultLink
	).replace(`{{${partner}}}`, '');

	const text = (
		alternateText.find((text) => text.includes(partner)) || defaultText
	).replace(`{{${partner}}}`, '');

	return { link, text };
};

export const Phone: FunctionComponent<Props> = ({
	defaultText,
	defaultLink,
	alternateText,
	alternateNumber,
}) => {
	const analytics = useAnalytics();
	const { partner } = useArticleRendererContext();

	const { link, text } = getLinkFromPartner(
		defaultText,
		defaultLink,
		alternateText,
		alternateNumber,
		partner.toLowerCase()
	);

	const onClick = () =>
		analytics &&
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'articlePhoneNumberLink',
					CtaText: text,
				})
			)
			.dispatch(AnalyticEventType.CLICK);

	return (
		<Container tabIndex={0} href={`tel:${link}`} onClick={onClick}>
			{text}
			<PhoneIcon src={phone} alt={text} />
		</Container>
	);
};
