import React, { useMemo } from 'react';
import { Client, Provider, cacheExchange, fetchExchange } from 'urql';

interface Props {
	accessToken: string;
	children: React.ReactNode;
	environment: string;
	spaceId: string;
}

export const PageContentProvider = ({
	accessToken,
	children,
	environment,
	spaceId,
}: Props) => {
	const graphQlClient = useMemo(() => {
		return new Client({
			url: `https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${environment}`,
			exchanges: [cacheExchange, fetchExchange],
			fetchOptions: {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
				},
			},
		});
	}, [spaceId, environment, accessToken]);

	return <Provider value={graphQlClient}>{children}</Provider>;
};
