import React from 'react';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';

import { CheckupService } from '@home-diy-toolbox/web/contentful';

import { useFlow } from '../../providers';
import { ServiceList } from './ServiceList';

const Title = styled(Text)`
	&& {
		margin-bottom: 1rem;
		display: inline-block;
	}
`;

interface Props
	extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	sectionTitle: string;
	services: CheckupService[];
	xScrollable?: boolean;
}

export const ServiceSection: React.FC<Props> = ({
	className,
	sectionTitle,
	services,
	xScrollable = false,
}) => {
	const {
		flow: {
			sessionData: { eligibility },
		},
	} = useFlow();

	const eligibleServices = services.filter(({ checkupType }) => {
		const isEligibilityRequired = Object.keys(eligibility).includes(checkupType);
		const isEligible = !!eligibility[checkupType];
		return isEligibilityRequired ? isEligible : true;
	});

	if (!eligibleServices.length) return null;

	return (
		<div
			className={className}
			data-cy="checkupServiceSection"
			data-pw="checkupServiceSection"
		>
			<Title weight="feather" size={4}>
				{sectionTitle}
			</Title>
			<ServiceList xScrollable={xScrollable} services={eligibleServices} />
		</div>
	);
};
