export const removeArrayFromArray = (source: string[] = [], toRemove: string[] = []) =>
	source.filter((item) => !toRemove.includes(item));

export const getArrayFromObject = (source: { [key: string]: string } = {}): string[] => {
	const keysArray = Object.keys(source);
	return keysArray.map((key) => `${key}-${source[key]}`);
};

export const getObjectFromArray = (
	source: string[] = [],
	uniqueSeparator = '-'
): { [key: string]: string } => {
	const newObject = {};
	source.forEach((item) => {
		newObject[item.split(uniqueSeparator)[0]] = item.split(uniqueSeparator)[1];
	});
	return newObject;
};
