import React, { Context, createContext, FunctionComponent, useContext } from 'react';
import {
	Checkup,
	CheckupCategoryType,
	Checkups,
	DiagnosticsCategoryType,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { FlowContextType, FlowSessionData, SelectedOS } from './models';
import { fetchFlowFromStorage } from './flowDataAccess';
import { usePersistentFlow } from './hooks';

const defaultFlow = fetchFlowFromStorage();

const defaultFlowContext: FlowContextType = {
	flow: defaultFlow,
	setSelectedOS: () => undefined,
	updateFlowSession: () => undefined,
	updateCheckupPage: () => undefined,
	getCheckupForPageId: () => undefined,
	updateDiagnostics: () => undefined,
	getDiagnosticsByTestId: () => undefined,
	getTagsForCheckup: () => undefined,
	getActiveFlowType: () => undefined,
	setCurrentQuestionIndex: () => undefined,
};

const FlowContext: Context<FlowContextType> = createContext(defaultFlowContext);
FlowContext.displayName = 'Flow';

export const useFlow = () => useContext(FlowContext);

export const FlowProvider: FunctionComponent = ({ children }) => {
	const [flow, setFlow] = usePersistentFlow(defaultFlow);

	const setSelectedOS = (selectedOS: SelectedOS) => {
		const sessionData = { ...flow.sessionData, ...selectedOS };

		setFlow({ ...flow, sessionData });
	};

	const setCurrentQuestionIndex = (currentQuestionIndex: number) => {
		const sessionData = { ...flow.sessionData, ...{ currentQuestionIndex } };
		setFlow({ ...flow, sessionData });
	};

	const updateFlowSession = (session: Partial<FlowSessionData>) => {
		const updatedFlow = flow;

		Object.entries(session).forEach(([key, value]) => {
			if (value !== undefined && value !== null) {
				updatedFlow.sessionData[key] = value;
			}
		});

		setFlow(updatedFlow);
	};

	const updateCheckupPage = (
		checkupCategory: CheckupCategoryType,
		pageId: string,
		answers: string[],
		tags: string[]
	) => {
		const updatedFlow = flow;
		const updatedQuestionsData = updatedFlow[checkupCategory];
		updatedQuestionsData.set(pageId, { answers, tags });

		updatedFlow[checkupCategory] = updatedQuestionsData;

		setFlow(updatedFlow);
	};

	const getCheckupForPageId = (checkupCategory: CheckupCategoryType, pageId: string) => {
		const checkupData = flow[checkupCategory];
		return checkupData.get(pageId);
	};

	const updateDiagnostics = (
		diagnosticsCategory: DiagnosticsCategoryType,
		testId: string,
		answer: string | number
	) => {
		const updatedFlow = flow;
		const updatedDiagnosticsData = updatedFlow[diagnosticsCategory];
		updatedDiagnosticsData.set(testId, answer);

		updatedFlow[diagnosticsCategory] = updatedDiagnosticsData;

		setFlow(updatedFlow);
	};

	const getTagsForCheckup = (checkupCategory: CheckupCategoryType) => {
		const tags = [];
		const checkupData = flow[checkupCategory];

		checkupData.forEach((checkupAnswer) => {
			if (checkupAnswer.tags?.length) {
				tags.push(...checkupAnswer.tags);
			}
		});

		return tags;
	};

	const getDiagnosticsByTestId = (
		diagnosticsCategory: DiagnosticsCategoryType,
		testId: string
	) => {
		const checkupData = flow[diagnosticsCategory];
		return checkupData.get(testId);
	};

	const getActiveFlowType = (): Checkup | DiagnosticsCategoryType => {
		if (flow.batteryCheckup?.size) return Checkups.BATTERY;
		if (flow.signalCheckup?.size) return Checkups.SIGNAL;
		if (flow.speedCheckup?.size) return Checkups.SPEED;
		if (flow.batteryDiagnostics?.size) return DiagnosticsCategoryType.BATTERY_DIAGNOSTICS;
		if (flow.signalDiagnostics?.size) return DiagnosticsCategoryType.SIGNAL_DIAGNOSTICS;

		return null;
	};

	const value = {
		flow,
		setSelectedOS,
		updateFlowSession,
		updateCheckupPage,
		getCheckupForPageId,
		updateDiagnostics,
		getDiagnosticsByTestId,
		getTagsForCheckup,
		getActiveFlowType,
		setCurrentQuestionIndex,
	};

	return <FlowContext.Provider value={value}>{children}</FlowContext.Provider>;
};
