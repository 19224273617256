import { Entry, createClient } from 'contentful';
import { getChatClient } from '../components';
import { environment } from '../../../environments/environment';
import { SimpleCopy } from '@home-diy-toolbox/web/contentful';

const contentfulClient = createClient({
	space: environment.contentful.checkup.spaceId,
	accessToken: environment.contentful.checkup.accessToken,
	environment: environment.contentful.checkup.environment,
});

interface ChatButtonType {
	chatText: string;
	buttonText: string;
}

interface DirectToChat {
	utm: string;
	messages: Entry<SimpleCopy>[];
	chatButtons: Entry<ChatButtonType>[];
}

export abstract class ChatSender {
	abstract isInitialLoad(newNode: Node): boolean;
	abstract sendVZTroubleshootMessages(partner: string): void;

	observeChatContainer(observer: MutationObserver) {
		const targetNode = document.getElementById('chat-sdk-container');
		observer.observe(targetNode, {
			childList: true,
			subtree: true,
		});
	}

	sendPromptMessage(prompt: string) {
		const observer = new MutationObserver(async (mutationList, observer) => {
			for (const mutation of mutationList) {
				const newNode = mutation?.addedNodes?.item(0);
				const initialLoad = this.isInitialLoad(newNode);
				if (!initialLoad) continue;
				getChatClient().sendMessage('CustomerMessage', prompt);
				observer.disconnect();
			}
		});

		this.observeChatContainer(observer);
	}

	async getVzTroubleshoot() {
		try {
			const entry = (
				await contentfulClient.getEntries<DirectToChat>({
					'fields.utm': 'vz_troubleshoot',
					content_type: 'directToChat',
					limit: 1,
					include: 1,
				})
			).items[0];

			return {
				messages: entry.fields.messages.map((message) => message.fields.copy),
				chatButtons: entry.fields.chatButtons.map((chatButton) => chatButton.fields),
			};
		} catch (error) {
			console.error(error);
			return null;
		}
	}
}
