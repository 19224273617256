import styled from 'styled-components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';

type InputContainerTypes = {
	width?: number | string;
};

export const InputContainer = styled.div<InputContainerTypes>`
	width: ${(props) => props.width || '100%'};

	${breakpoints.xxl} {
		max-width: 41.5rem;
	}
`;
