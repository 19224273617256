import {
	FunctionComponent,
	ReactNode,
	useState,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import {
	ArticleCard,
	ArticleGridSection as ArticleGridSectionModel,
	ArticleGridSectionFilter,
	useArticleContentContext,
} from '@home-diy-toolbox/web/contentful';
import { useMediaQuery } from '@home-diy-toolbox/web/utils';
import { SelectionChipProps, Text } from '@soluto-private/mx-asurion-ui-react';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import ArticleList from './ArticleList';
import { ArticleItem } from './ArticleItem';
import { ArticleSkeleton } from './ArticleSkeleton';
import { ManualSelectionChips } from '@soluto-private/mx-asurion-ui-react-v3';

interface DisplayChipsProps {
	filters: ArticleGridSectionFilter[];
	onChange?: (selectedChips: SelectionChipProps) => void;
}

const StyledSelectionChips = styled(ManualSelectionChips)`
	&& {
		margin: 0 -1rem;
		padding: 0 1rem;
		background-color: transparent;

		@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
			margin: 0;
			padding: 0;
		}

		@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
			flex-wrap: wrap;
		}
	}
`;

const DisplayChips: FunctionComponent<DisplayChipsProps> = ({ filters, onChange }) => {
	const location = useLocation();
	const initialChip = useMemo(
		() =>
			filters
				.find((filter, i) => (filter.url ? filter.url === location.pathname : i === 0))
				.value.join(','),
		[filters, location.pathname]
	);
	const [selectedChip, setSelectedChip] = useState<string>(initialChip);
	const [chips, setChips] = useState<SelectionChipProps[]>([]);

	useEffect(() => {
		setChips(
			filters.map((filter) => {
				const value = filter.value.join(',');
				const isSelected = selectedChip === value;

				const chip = {
					isSelected,
					label: filter.label,
					value,
					url: filter.url,
					onClick: () => {
						setSelectedChip(value);
					},
				};

				if (isSelected) {
					onChange(chip);
				}

				return chip;
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, selectedChip]);

	const theme = useContext(ThemeContext);
	const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet})`);

	return (
		<StyledSelectionChips
			size={isDesktop ? 'medium' : 'small'}
			chips={chips}
			layout="nowrap"
		/>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const HeroContainer = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 1rem;
	align-items: center;
	width: 100%;
	margin-bottom: 0.5rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		flex-direction: row;
		margin-bottom: 1.25rem;
	}
`;

const HeroContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
`;

const Headline = styled(Text)`
	display: inline-block;
	margin-bottom: 0.5rem;
`;

const FilterLabel = styled(Text)`
	text-align: start;
`;

const HeroImage = styled.img`
	height: 15rem;
	margin-left: unset;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		margin-left: auto;
	}
`;

const fadeTime = 100;
const StyledArticleList = styled(ArticleList)`
	opacity: 1;
	transition: opacity ${fadeTime}ms linear;
`;

const flattenArray = (array: string[]) => [].concat(...array);

interface ArticleGridSectionProps {
	articleGridSection: ArticleGridSectionModel;
	deviceOS?: string;
}

export const ArticleGridSection: FunctionComponent<ArticleGridSectionProps> = ({
	articleGridSection,
	deviceOS,
}) => {
	const location = useLocation();
	const { getArticleCards } = useArticleContentContext();
	const [currentChipUrl, setCurrentChipUrl] = useState(location.pathname);
	const [articlesOpacity, setArticlesOpacity] = useState(1);
	const [articles, setArticles] = useState<Array<ArticleCard>>([]);
	const [initialLoad, setInitialLoad] = useState(true);

	const updateArticles = async (selectedChip: SelectionChipProps) => {
		setCurrentChipUrl(selectedChip.url);
		const tags = flattenArray(selectedChip.value.split(','));
		const excludeTags = ['old'];

		const getArticleCardArgs = {
			tags: [articleGridSection.commonTag],
			excludeTags,
			deviceOS,
		};

		const allArticles = await getArticleCards(getArticleCardArgs);

		const articles = allArticles.filter((article) =>
			tags.every((tag) => article.tags.includes(tag))
		);

		if (initialLoad) {
			setArticles(articles);
			setInitialLoad(false);
			return;
		}

		setArticlesOpacity(0);
		setTimeout(() => {
			setArticles(articles);
			setArticlesOpacity(1);
		}, fadeTime);
	};

	const getTitle = () => {
		if (articleGridSection.title) return articleGridSection.title;
		if (deviceOS === 'ios') return articleGridSection.iOsTitle;
		if (deviceOS === 'android') return articleGridSection.androidTitle;
	};

	const addSkeleton = (articleList: ReactNode) => {
		return initialLoad ? <ArticleSkeleton /> : articleList;
	};

	if ('mainImage' in articleGridSection) {
		return (
			<Container className="hero" data-pw="articleGridSectionHero">
				<HeroContainer>
					<HeroContent>
						<Headline weight="feather" size={5}>
							{getTitle()}
						</Headline>
						<FilterLabel weight="heavy" size={2}>
							{articleGridSection.filterLabel}
						</FilterLabel>
						<DisplayChips
							filters={articleGridSection.filters}
							onChange={updateArticles}
						/>
					</HeroContent>
					<HeroImage src={articleGridSection.mainImage}></HeroImage>
				</HeroContainer>
				{addSkeleton(
					<StyledArticleList style={{ opacity: articlesOpacity }}>
						{articles.map(({ id, title, contentCard }) => (
							<ArticleItem
								id={id}
								title={title}
								subtitle={contentCard.fields.subtitle}
								imageUrl={contentCard.fields.coverImage?.fields.file?.url}
								key={id}
								originPath={currentChipUrl}
								externalLink={contentCard.fields.externalLink}
							/>
						))}
					</StyledArticleList>
				)}
			</Container>
		);
	}

	return (
		<Container data-pw="articleGridSection">
			<Headline weight="feather" size={4} data-pw="articleGridSectionTitle">
				{getTitle()}
			</Headline>
			<DisplayChips filters={articleGridSection?.filters} onChange={updateArticles} />
			{addSkeleton(
				<StyledArticleList style={{ opacity: articlesOpacity }}>
					{articles.map(({ id, title, contentCard }) => (
						<ArticleItem
							id={id}
							title={title}
							subtitle={contentCard.fields.subtitle}
							imageUrl={contentCard.fields.coverImage?.fields.file?.url}
							key={id}
							externalLink={contentCard.fields.externalLink}
						/>
					))}
				</StyledArticleList>
			)}
		</Container>
	);
};
