import { deviceCareApiClient } from '../../../../../clients';
import { BatteryChargeAnswer, UsageHabitAnswer } from '../../answers/types';
import { Asset } from '@home-diy-toolbox/shared/clients';

const getFullChargeRemainingHours = (batteryCharge: BatteryChargeAnswer) =>
	(batteryCharge.remainingHours / batteryCharge.currentCharge) * 100;

export const getAndroidBatteryResult = (
	batteryCharge: BatteryChargeAnswer,
	usageHabitAnswer: UsageHabitAnswer,
	asset: Asset
) => {
	return deviceCareApiClient.getAndroidBatteryResults({
		batteryLife: getFullChargeRemainingHours(batteryCharge),
		usageTime: {
			call: usageHabitAnswer.call,
			socialMedia: usageHabitAnswer.socialMedia,
			video: usageHabitAnswer.video,
			audio: usageHabitAnswer.audio,
			gaming: usageHabitAnswer.gaming,
		},
		asset,
	});
};
