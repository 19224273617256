import React, { FunctionComponent } from 'react';
import { withAnalytics, AnalyticsElementProps } from '@home-diy-toolbox/web/analytics';
import styled from 'styled-components';
import { isSpaceOrEnterPress } from '@home-diy-toolbox/web/utils';
import {
	RadioSelectionCard,
	RadioSelectionCardProps,
} from '@soluto-private/mx-asurion-ui-react';

type RadioButtonProps = Omit<RadioSelectionCardProps, 'children'> &
	AnalyticsElementProps & {
		name: string;
		value: string;
		label?: string;
		checked?: boolean;
		tabIndex?: number;
		'data-cy'?: string;
		containerClassName?: string;
	};

const RadioButton = withAnalytics(RadioSelectionCard);

const ParentContainer = styled.div`
	margin-top: 16px;
	.cardWidth {
		width: 100%;
	}
`;

const Label = styled.div`
	margin-right: auto;
`;

export const RadioButtonWithLabel: FunctionComponent<RadioButtonProps> = ({
	name,
	value,
	checked = false,
	label,
	actionId,
	containerClassName,
	onChange,
	analyticExtras = {},
	...others
}) => {
	const onClick = (event) => {
		onChange(event);
	};

	return (
		<ParentContainer>
			<RadioButton
				checked={checked}
				onKeyDown={(event) => isSpaceOrEnterPress(event) && onClick(event)}
				name={name}
				value={value}
				onChange={onClick}
				className="cardWidth"
				actionId={actionId}
				analyticExtras={analyticExtras}
				{...others}
			>
				<Label data-cy={`${value}`}>{label}</Label>
			</RadioButton>
		</ParentContainer>
	);
};
