import { Partners } from '@home-diy-toolbox/web/common-types';
import { useMemo } from 'react';
import { useMxContext } from './MxContextProvider';

const supportedLanguages = ['en-US', 'es-US', 'fr-CA', 'es-PR'];

const foundPartner = (partner: string) => {
	return Object.values(Partners).find((value) => value === partner);
};

// useMxContext sometimes return language undefined or not included in supported languages
export const useValidateMxContext = () => {
	const { language, client } = useMxContext();

	// validate if the returned client is in the Partners object else return Partners.DEFAULT
	const partner = useMemo(() => {
		if (client && foundPartner(client)) {
			return client;
		}
		return Partners.DEFAULT;
	}, [client]);

	const lang = useMemo(() => {
		if (!language) {
			return 'en-US';
		}

		if (supportedLanguages.includes(language)) {
			return language;
		}

		if (language.startsWith('es')) {
			return 'es-US';
		}

		if (language.startsWith('fr')) {
			return 'fr-CA';
		}

		return 'en-US';
	}, [language]);

	return {
		client: partner,
		language: lang,
	};
};
