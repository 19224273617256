import { FC, memo } from 'react';
import { BatteryCheckupLayout } from '../components';
import { DeviceProps, Pages } from '../pages/types';
import { useOnClickBack } from '../hooks';
import { BatteryResultV3Ids } from '../answers/BatteryResultV3';
import { iPhoneBatteryAnswer } from '../answers';
import ResultsPage from '../pages/ResultsPage';
import InitialPage from '../pages/InitialPage';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';

export const IphonePage: FC<DeviceProps> = memo(
	({ currentPage, answers, setAnswers, stack, tags, setTags, result, setResult }) => {
		const { dispatchEvent } = useDispatchEvent();
		const backFuncs = useOnClickBack(stack);
		const iphoneAnswers = answers.initialPage as iPhoneBatteryAnswer;
		const disabledContinue =
			!iphoneAnswers?.batteryPercentage || !iphoneAnswers?.batteryMessage;

		const continueBtnFunc: Record<Exclude<Pages, 'otherIssuesPage'>, VoidFunction> = {
			initialPage: () => {
				if (
					+iphoneAnswers.batteryPercentage < 80 ||
					iphoneAnswers.batteryMessage !== 'none'
				) {
					setResult(BatteryResultV3Ids.BATTERY_REPLACEMENT_BELOW_80_CAPACITY);
				} else {
					setResult(BatteryResultV3Ids.BATTERY_HEALTHY);
				}

				stack.push('resultPage');
			},
			resultPage: undefined,
		};

		const flow = {
			initialPage: (
				<InitialPage
					answers={answers?.initialPage}
					setAnswers={setAnswers}
					setTags={setTags}
				/>
			),
			resultPage: <ResultsPage resultId={result} tags={tags} />,
		};

		const onContinue = continueBtnFunc[currentPage]
			? () => {
					continueBtnFunc[currentPage]();
					dispatchEvent(AnalyticEventType.CLICK, {
						ActionId: 'continueClick',
						PageId: stack.peek(),
					});
			  }
			: undefined;

		return (
			<BatteryCheckupLayout
				onBack={() => {
					backFuncs[currentPage]();
					dispatchEvent(AnalyticEventType.CLICK, {
						ActionId: 'backClick',
						PageId: stack.peek(),
					});
				}}
				onContinue={onContinue}
				disabledContinue={disabledContinue}
				currPage={stack.peek()}
			>
				{flow[currentPage]}
			</BatteryCheckupLayout>
		);
	}
);
