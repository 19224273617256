import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card, Text } from '@soluto-private/mx-asurion-ui-react';
import {
	AnalyticEventType,
	useAnalytics,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import { isSpaceOrEnterPress } from '@home-diy-toolbox/web/utils';
import { AnswerOption } from '@home-diy-toolbox/web/contentful';
import { useFlow } from '../../providers';
import { Tags } from './Tags';

const Image = styled.img`
	width: 3.5em;
`;

const Title = styled(Text)`
	margin: 0.25em 0;
`;

const Container = styled(Card)`
	display: flex;
	flex-direction: column;
	cursor: pointer !important;
	@media (hover: hover) {
		&:hover {
			box-shadow: 0 0 0 1px ${({ theme }) => theme.answerOptions.hoverBorderColor} inset;
		}
	}
	&& {
		text-align: left;
	}
`;

const Grow = styled.span`
	flex-grow: 1;
`;

interface Props extends Omit<AnswerOption, 'description' | 'image'> {
	routePath: string;
	checkupType: string;
	description: string;
	image?: string;
}

// TODO: rename the component and filename to ServiceCard if the`ServiceCard`file is deleted.
export const ServiceCard2 = ({
	copy,
	description,
	image,
	routePath,
	checkupType,
	tags = [],
}: Props): JSX.Element => {
	const history = useHistory();
	const analytics = useAnalytics();
	const { t } = useTranslation();
	const { setCurrentQuestionIndex } = useFlow();

	const onclick = () => {
		setCurrentQuestionIndex(1);
		analytics.dispatcher
			.extend(withExtras({ ActionId: checkupType, CTA: copy, CheckupType: checkupType }))
			.dispatch(AnalyticEventType.CLICK);
		history.push(routePath, {
			from: history.location.pathname,
		});
	};

	return (
		<Container
			padding="1.5em"
			onClick={onclick}
			tabIndex={0}
			onKeyPress={(event) => isSpaceOrEnterPress(event) && onclick()}
			data-cy={checkupType}
			data-pw={checkupType}
		>
			{image && <Image alt={t('articleIcon')} src={image} />}
			<Title size={3} weight="heavy">
				{copy}
			</Title>
			<Text size={2}>{description}</Text>
			<Grow />
			{tags && <Tags tags={tags} />}
		</Container>
	);
};
