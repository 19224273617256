import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { invert } from 'lodash';
import { Text, getFontSize, getFontWeight } from '@soluto-private/mx-asurion-ui-react';
import { RadioGroupSlider } from '@home-diy-toolbox/web/radio-group-slider';
import type { UsageHabitAnswer, UsageHabitProps } from './types';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { Answer, RichText } from '@home-diy-toolbox/web/contentful';
import { FontSize } from '@soluto-private/mx-asurion-ui-react-v3';

const Question = styled(Text)<{ size?: FontSize; marginBottom?: number | string }>`
	&& {
		display: block;
		font-size: ${(props) => getFontSize(props.size || 6)};
		font-weight: ${getFontWeight('feather')};
		margin-bottom: ${(props) => props.marginBottom || '2.875rem'};
	}
`;

const Habit = styled(Text)`
	&& {
		margin-bottom: 0.5rem;
		font-weight: ${getFontWeight('heavy')};
	}
`;

const HabitContainer = styled.div`
	margin-bottom: 3.5625rem;
`;

const minuteMap = {
	'0_mins': 0,
	'30_mins': 30,
	'1_hr': 60,
	'1.5_hrs': 90,
	'2_plus_hrs': 120,
};

const invertedMinuteMap = invert(minuteMap);

interface UsageHabitQuestion {
	question: Document;
	questionFontSize?: FontSize;
	questionMarginBottom?: number | string;
	habits: { value: string; label: string; options: Answer[] }[];
}

export const UsageHabits = ({
	initialValue,
	onSetValue,
	content,
}: UsageHabitProps<UsageHabitAnswer, UsageHabitQuestion>) => {
	const { question, habits, questionFontSize, questionMarginBottom } = content;
	const [answers, setAnswers] = useState(initialValue);

	useEffect(() => {
		onSetValue({ value: answers, valid: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSelect = (habit: string, usage: string) => {
		setAnswers((prevVal) => {
			const newVal = {
				...prevVal,
				[habit]: minuteMap[usage],
			};
			onSetValue({ value: newVal, valid: true });
			return newVal;
		});
	};

	return (
		<div>
			<RichText
				document={question}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => (
							<Question marginBottom={questionMarginBottom} size={questionFontSize}>
								{children}
							</Question>
						),
					},
				}}
			/>
			{habits.map(({ value, label, options }) => {
				const initialValue = invertedMinuteMap[answers[value] || 0];

				return (
					<HabitContainer key={value}>
						<Habit>{label}</Habit>
						<RadioGroupSlider
							group={value}
							radioValues={options.map((option) => ({
								label: option.text,
								value: option.value,
							}))}
							initialValue={initialValue}
							onSelected={(usage) => handleSelect(value, usage as string)}
						/>
					</HabitContainer>
				);
			})}
		</div>
	);
};
