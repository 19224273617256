import * as React from 'react';

export const Map = () => (
	<svg width={14} height={15} xmlns="http://www.w3.org/2000/svg">
		<path
			d="m7.274 6.694.619-.619.857.857.857-.857.619.619-.858.857.858.858-.619.618-.857-.857-.857.857-.62-.618.858-.858-.857-.857ZM4.083 7.114a.73.73 0 1 0 0-1.459.73.73 0 0 0 0 1.459ZM6.562 8.134a.73.73 0 1 1-1.458 0 .73.73 0 0 1 1.458 0Z"
			style={{ fill: 'currentcolor' }}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.343 1.816a.582.582 0 0 1 .907.485v8.167a.585.585 0 0 1-.26.486l-3.5 2.333a.585.585 0 0 1-.688-.03L5.218 11.19l-3.145 2.097a.584.584 0 0 1-.906-.486V4.634c0-.194.097-.376.26-.485l3.5-2.333a.583.583 0 0 1 .687.03l2.584 2.067 3.145-2.097ZM8.198 12.079l2.885-1.923V3.39L8.49 5.12a.583.583 0 0 1-.688-.03L5.218 3.023 2.333 4.947v6.764l2.593-1.73a.585.585 0 0 1 .689.031l2.583 2.067Z"
			style={{ fill: 'currentColor' }}
		/>
	</svg>
);
