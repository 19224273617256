import React, { FunctionComponent, useState } from 'react';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { RichText, useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import {
	Checkups,
	CheckupCategoryType,
	CheckupPageIds,
	ComponentIds,
	DeviceOS,
	CheckupPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow } from '../../../../providers';
import {
	CheckupQuestionsProgress,
	CheckupContainer,
	QuestionContainer,
	QuestionHeader,
	NextButton,
	ImageContainer,
	RadioButtonWithLabel,
	AnswerSelectionInstructionsWrapper,
	PreviousButton,
} from '../../../../components';
import { useCheckupPageFlow } from '../../../../features/checkupFlows';
import { useHistory } from 'react-router-dom';

const NextButtonWrapper = styled(NextButton)`
	margin-top: 56px;
`;

const PhoneChargerPage: FunctionComponent = () => {
	const pageId = CheckupPageIds.PHONE_CHARGER;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;

	const history = useHistory();
	const [goNext] = useCheckupPageFlow(Checkups.BATTERY, CheckupPages.PHONE_CHARGER);
	const { usePageContent } = useCheckupContentContext();
	const { flow, updateCheckupPage, getCheckupForPageId, setCurrentQuestionIndex } =
		useFlow();

	const { answers: latestAnswers, tags: latestTags } = getCheckupForPageId(
		CheckupCategoryType.BATTERY_CHECKUP,
		pageId
	) || {
		answers: [],
		tags: [],
	};

	const { deviceOS, partner } = flow.sessionData;

	const [selectedAnswer, setSelectedAnswer] = useState<string[]>(latestAnswers);
	const [selectedTags, setSelectedTags] = useState<string[]>(latestTags);

	const onAnswerChange = (answerSelected: string, tags: string[] = []) => {
		setSelectedAnswer([answerSelected]);
		setSelectedTags(tags);
	};

	const isNextButtonDisabled = () => selectedAnswer.length === 0;

	const onNextButtonClick = () => {
		updateCheckupPage(
			CheckupCategoryType.BATTERY_CHECKUP,
			pageId,
			selectedAnswer,
			selectedTags
		);

		setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex + 1);

		goNext({ deviceOS: deviceOS as DeviceOS });
	};

	const onBackButtonClick = () => {
		setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex - 1);
		history.goBack();
	};

	const {
		questionTitleCopy,
		instructionsCopy,
		questionImage,
		answerOptions,
		nextButtonCopy,
	} = usePageContent(pageId, partner, Checkups.BATTERY);

	const renderAnswerOptions = () =>
		answerOptions.map(({ fields: { id: answerId, copy, tags } }) => {
			const buttonId = `${pageId}_${answerId}`;
			return (
				<RadioButtonWithLabel
					value={answerId}
					label={copy}
					name={`${pageId}_answerOptions`}
					key={buttonId}
					checked={selectedAnswer.includes(answerId)}
					onChange={() => onAnswerChange(answerId, tags)}
				/>
			);
		});

	return (
		<CheckupContainer>
			<CheckupQuestionsProgress
				currQuestion={flow.sessionData.currentQuestionIndex}
				checkupType={Checkups.BATTERY}
			/>
			<QuestionContainer>
				<QuestionHeader>{questionTitleCopy}</QuestionHeader>
				<AnswerSelectionInstructionsWrapper>
					<RichText document={instructionsCopy} />
				</AnswerSelectionInstructionsWrapper>
				<ImageContainer>
					<img src={questionImage.fields.file.url} alt="" />
				</ImageContainer>
			</QuestionContainer>
			{renderAnswerOptions()}
			<NextButtonWrapper
				onClick={onNextButtonClick}
				actionId={nextButtonId}
				data-cy="next-button"
				disabled={isNextButtonDisabled()}
				analyticExtras={{
					SelectedAnswers: selectedAnswer,
					CheckupType: Checkups.BATTERY,
				}}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
			<PreviousButton onClick={onBackButtonClick} />
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: CheckupPageIds.PHONE_CHARGER,
	CheckupType: Checkups.BATTERY,
})(PhoneChargerPage);
