import { GradientPage } from '@home-diy-toolbox/web/theme';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import React from 'react';
import styled from 'styled-components';
import { ActionButtons } from '.';
import { usePositionOffsets } from '../pages/hooks/usePositionOffsets';

const Container = styled(GradientPage)`
	&&& {
		display: grid;
		width: 100%;
		max-width: unset;
		padding: 0;
		height: 100%;
		grid-template-rows: 1fr auto;
		grid-template-columns: minmax(0, 1fr);
		grid-template-areas:
			'content'
			'buttons';

		${breakpoints.md2} {
			grid-template-columns: 70% 30%;
			grid-template-areas:
				'content sidebar'
				'buttons sidebar';
		}

		${breakpoints.lg} {
			grid-template-columns: 60% 40%;
		}
	}
`;

const Content = styled.div`
	grid-area: content;
	padding: 1.5rem 1rem;

	${breakpoints.md} {
		padding: 1.5rem;
	}

	${breakpoints.md2} {
		padding: 3rem 1.5rem;
	}

	${breakpoints.xl} {
		padding: 3rem;
	}
`;

const ExpertContactCardContainer = styled.div`
	margin-top: 2.25rem;

	${breakpoints.md2} {
		display: none;
	}
`;

const Buttons = styled.div`
	z-index: 1;
	position: sticky;
	bottom: 0;
	grid-area: buttons;
`;

const Sidebar = styled.div`
	display: none;
	position: sticky;
	bottom: 0;
	align-self: start;
	grid-area: sidebar;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		display: block;
	}
`;

export const CheckupPageLayout = ({
	children,
	bannerComponent,
	cardComponent = null,
	backButtonConfig,
	continueButtonConfig,
}) => {
	const { subnavHeight, heightDiff } = usePositionOffsets();

	const heightDiffOffset = `-${heightDiff}px`;
	const subnavHeightOffset = `${subnavHeight}px`;
	const height = `calc(100vh - ${subnavHeightOffset})`;

	return (
		<Container
			className="widecontainer"
			style={{ minHeight: height, marginTop: heightDiffOffset }}
		>
			<Content>
				{children}
				<ExpertContactCardContainer>{cardComponent}</ExpertContactCardContainer>
			</Content>
			<Sidebar style={{ height, top: subnavHeightOffset }}>{bannerComponent}</Sidebar>
			<Buttons>
				<ActionButtons
					backButtonConfig={backButtonConfig}
					continueButtonConfig={continueButtonConfig}
				/>
			</Buttons>
		</Container>
	);
};
