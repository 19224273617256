import { QuestionBlock, useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { PageBanner } from '../components/PageBanner';
import { PageCard } from '../components/PageCard';
import { Issues } from '../questions/Issues';
import { QuestionIndex } from '../components/QuestionIndex';
import { CheckupPageLayout } from '../components/CheckupPageLayout';
import { MainIssuesAnswer, OtherIssuesAnswer } from '../answers/types';
import { ButtonConfig, Index, OnSetValueFn } from './types';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

interface BaseProps {
	index: Index;
	onSetValue: OnSetValueFn;
	continueButtonConfig: ButtonConfig;
	backButtonConfig: ButtonConfig;
}

type MainIssueProps = BaseProps & {
	issue: 'mainIssue';
	initialValue: MainIssuesAnswer;
};

type OtherIssuesProps = BaseProps & {
	issue: 'otherIssues';
	initialValue: OtherIssuesAnswer;
};

type Props = MainIssueProps | OtherIssuesProps;

export const IssuePage = ({
	index,
	issue,
	initialValue,
	onSetValue,
	backButtonConfig,
	continueButtonConfig,
}: Props) => {
	const pageContent = useCheckupPage(issue);

	if (!pageContent) return <PageLoader />;

	const questionBlock = pageContent.blocks.find(
		(block) => block.entryName === issue
	) as QuestionBlock;

	return (
		<CheckupPageLayout
			bannerComponent={<PageBanner banner={pageContent?.banners[0]} />}
			cardComponent={<PageCard banner={pageContent?.banners[0]} />}
			backButtonConfig={{
				customLabel: pageContent?.ctas.back.copy,
				...backButtonConfig,
			}}
			continueButtonConfig={{
				customLabel: pageContent?.ctas.continue.copy,
				...continueButtonConfig,
			}}
		>
			<QuestionIndex currentIndex={index.currentIndex} total={index.total} />
			<Issues
				initialValue={initialValue}
				content={{
					question: pageContent.heading,
					instruction: questionBlock.question,
					options: questionBlock.answers,
				}}
				onSetValue={onSetValue}
			/>
		</CheckupPageLayout>
	);
};
