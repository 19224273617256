/// This would replace ContentfulAnwswerGroups once all pages would adopt to the new contentful model
import { Answer } from '@home-diy-toolbox/web/contentful';
import React, { FunctionComponent } from 'react';
import { RadioButtonWithLabel } from '../RadioButtonWithLabel';

export type RadioButtonWithLabelGroupProps2 = {
	answerOptions: Answer[];
	isAnswerChecked: (answerId: string) => boolean;
	onAnswerChange: (answerId: string, tags: string[]) => void;
	buttonIdPrefix: string;
};

export const RadioButtonWithLabelGroup2: FunctionComponent<
	RadioButtonWithLabelGroupProps2
> = ({ answerOptions, isAnswerChecked, onAnswerChange, buttonIdPrefix }) => (
	<>
		{answerOptions.map(({ entryName: answerId, text, tags }) => {
			const buttonId = `${buttonIdPrefix}_${answerId}`;
			return (
				<RadioButtonWithLabel
					value={answerId}
					label={text}
					name={`${buttonIdPrefix}_answerOptions`}
					key={buttonId}
					data-cy={buttonId}
					checked={isAnswerChecked(answerId)}
					onChange={() => onAnswerChange(answerId, tags)}
				/>
			);
		})}
	</>
);
