import { useEffect, useMemo } from 'react';
import { useStackState } from '../../../../../utils';

export function usePageStack<T>(
	intitialStack: T[],
	sessionKey: string,
	resetSession: boolean
) {
	const sessionStack = useMemo(() => sessionStorage.getItem(sessionKey), [sessionKey]);
	const stack = useStackState<T>(
		!sessionStack || resetSession ? intitialStack : JSON.parse(sessionStack)
	);

	useEffect(() => {
		sessionStorage.setItem(sessionKey, JSON.stringify(stack.stack));
	}, [stack.stack, sessionKey]);

	return stack;
}
