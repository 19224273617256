import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Article } from '@home-diy-toolbox/web/contentful';
import { ArticlePathnames } from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
	useAnalytics,
	AnalyticEventType,
	AnalyticViewType,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import {
	fontFamily,
	ButtonSelectionCard,
	Button,
	ButtonProps,
	Text,
} from '@soluto-private/mx-asurion-ui-react';
import { useFlow } from '../../providers';

const HeaderCopy = styled(Text)`
	&& {
		margin-bottom: 2rem;
		display: inline-block;
	}
`;

const ArticleItemContainer = styled(ButtonSelectionCard)`
	&& {
		align-items: center;
		box-shadow: none;
		width: 100%;
		height: 3.5rem;
		max-width: ${({ theme }) => theme.appMaxWidth};
	}
`;

const ArticleItemDivider = styled.hr`
	border: 1px solid ${({ theme }) => theme.dividerBackgroundColor};
	width: 100%;
	margin: 1.5rem 0;
`;

const ArticleIcon = styled.img`
	min-width: 1.875rem;
	width: 3.4375rem;
	min-height: 2rem;
`;

const CopyContainer = styled.div`
	margin-left: 0.9375rem;
	width: 75%;
	text-align: left;
	flex: 1;

	@media screen and (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
		margin-left: 1.5625rem;
	}
`;

const ArticleTitle = styled.h1`
	font-family: ${fontFamily};
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const ArticleDescription = styled.p`
	font-family: ${fontFamily};
	margin: 0;
	font-size: 1rem;
	line-height: 1.5rem;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const ExpandButton = styled(Button)`
	min-width: 100%;
	max-width: ${({ theme }) => theme.appMaxWidth};
`;

export type ArticleSuggestionItemProps = {
	id: string;
	title: string;
	description: string;
	iconUrl: string;
	iconAlt: string;
	checkupType?: string;
};

export const ArticleItem: FunctionComponent<ArticleSuggestionItemProps> = ({
	id,
	title,
	description,
	iconUrl,
	checkupType,
}) => {
	const history = useHistory();
	const analytics = useAnalytics();
	const {
		setCurrentQuestionIndex,
		flow: {
			sessionData: { currentQuestionIndex },
		},
	} = useFlow();

	const onClick = (id: string) => {
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'articleSuggestionItem',
					ArticleId: id,
					ArticleTitle: title,
					ComponentType: AnalyticViewType.ARTICLE_SUGGESTION_ITEM,
					CheckupType: checkupType,
				})
			)
			.dispatch(AnalyticEventType.CLICK);

		setCurrentQuestionIndex(currentQuestionIndex + 1);
		history.push(ArticlePathnames.ARTICLE.replace(':articleId', id), {
			from: history.location.pathname,
		});
	};

	// ToDo - add default Icon if icon is not configured for any article
	return (
		<ArticleItemContainer
			data-cy="suggested-articles"
			data-pw="sugesstedArticleItem"
			onClick={() => onClick(id)}
		>
			{iconUrl && <ArticleIcon src={iconUrl} aria-hidden="true" />}
			<CopyContainer>
				<ArticleTitle>{title}</ArticleTitle>
				<ArticleDescription>{description}</ArticleDescription>
			</CopyContainer>
		</ArticleItemContainer>
	);
};

type ArticleSuggestionProps = {
	headerCopy: string;
	articleSuggestions?: Article[];
	checkupType?: string;
	buttonVariant?: ButtonProps['variant'];
};

const getArticleInfoForAnalytics = (
	articleSuggestions: Article[] = [],
	limit = 3
): {
	ArticlesSuggested: string[];
	ArticleIdsShown: string[];
	ArticleTitlesShown: string[];
} => {
	const ArticlesSuggested = [];
	const ArticleIdsShown = [];
	const ArticleTitlesShown = [];
	if (articleSuggestions && articleSuggestions.length > 0) {
		articleSuggestions.forEach((articleSuggestion, index) => {
			ArticlesSuggested.push(articleSuggestion.id);
			if (index < limit) {
				ArticleIdsShown.push(articleSuggestion.id);
				ArticleTitlesShown.push(articleSuggestion.title);
			}
		});
	}
	return { ArticlesSuggested, ArticleIdsShown, ArticleTitlesShown };
};

export const ArticleSuggestionsSection: FunctionComponent<ArticleSuggestionProps> = ({
	headerCopy,
	articleSuggestions,
	checkupType,
	buttonVariant = 'default',
}) => {
	const analytics = useAnalytics();
	const { t } = useTranslation();
	const [limit, setLimit] = useState(3);
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		articleSuggestions &&
			articleSuggestions.length > 0 &&
			analytics.dispatcher
				.extend(
					withExtras({
						ComponentType: AnalyticViewType.ARTICLE_SUGGESTION_SECTION,
						CheckupType: checkupType,
						...getArticleInfoForAnalytics(articleSuggestions, 3),
					})
				)
				.dispatch(AnalyticEventType.VIEW);
	}, [analytics, articleSuggestions, checkupType]);

	const onExpand = () => {
		setLimit(6);
		setExpanded(true);
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'articleSuggestionExpandButton',
					CheckupType: checkupType,
					...getArticleInfoForAnalytics(articleSuggestions, 6),
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	};

	return (
		<div data-pw="suggestedArticles">
			<HeaderCopy size={4} weight="feather" forwardedAs="h2">
				{headerCopy}
			</HeaderCopy>
			{articleSuggestions &&
				articleSuggestions
					.slice(0, limit)
					.map(({ id: articleId, title, contentCard }, i) => {
						const iconUrl = contentCard?.fields?.backgroundImage?.fields?.file?.url;
						const iconAlt = contentCard?.fields?.backgroundImage?.fields?.description;
						const description = contentCard?.fields?.subtitle;

						return (
							<React.Fragment key={articleId}>
								<ArticleItem
									id={articleId}
									title={title}
									description={description}
									iconUrl={iconUrl}
									iconAlt={iconAlt}
									data-cy={`articleSuggestion_${i + 1}`}
								/>
								{i < articleSuggestions.length - 1 && <ArticleItemDivider />}
							</React.Fragment>
						);
					})}
			{!expanded && (
				<ExpandButton
					color="secondary"
					onClick={onExpand}
					variant={buttonVariant}
					data-cy="articleSuggestionExpandButton"
					ariaLabel="Show more tips"
				>
					{t('showMoreTips')}
				</ExpandButton>
			)}
		</div>
	);
};
