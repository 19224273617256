import { v4 as uuid } from 'uuid';

export const getUserBrowserId = () => {
	let userBrowserId = window.localStorage.getItem('UserBrowserId');

	if (!userBrowserId) {
		userBrowserId = uuid();
		window.localStorage.setItem('UserBrowserId', userBrowserId);
	}

	return userBrowserId;
};
