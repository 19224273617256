import { useEffect } from 'react';
import {
	ArticleLoader,
	FeedbackComponentTypes,
	FeedbackTypes,
} from '../../../../components';
import { CheckupPage, FeedbackForm, SimpleCopy2 } from '@home-diy-toolbox/web/contentful';

import { FeedbackChoicesTypes } from '@home-diy-toolbox/web/contentful';

type ArticleComponentProps = {
	onFirstRender: () => void;
	articleId: string;
	pageContent: CheckupPage;
};

export const ArticleComponent = ({
	onFirstRender,
	articleId,
	pageContent,
}: ArticleComponentProps) => {
	const feedbackSectionTitle = pageContent.blocks.find(
		(block) => block.entryName === 'articlePositive'
	) as SimpleCopy2;

	const articlePositive = pageContent.blocks.find(
		(block) => block.entryName === 'articlePositive'
	) as FeedbackForm;

	const articleNegative = pageContent.blocks.find(
		(block) => block.entryName === 'articleNegative'
	) as FeedbackForm;

	useEffect(() => {
		onFirstRender();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ArticleLoader
			articleId={articleId}
			feedbackSectionProps={{
				variant: FeedbackComponentTypes.STARS,
				type: FeedbackTypes.ARTICLE,
				feedbackTitle: feedbackSectionTitle.simpleCopy,
				positiveForm: {
					...articlePositive,
					formId: articlePositive?.entryName,
					feedbackChoices: articlePositive?.feedbackChoices.map((choice) => ({
						id: choice.entryName,
						label: choice.copy,
					})),
					submitButtonCopy: articlePositive?.submitCta?.copy,
					feedbackChoicesType: FeedbackChoicesTypes.NEW,
				},
				negativeForm: {
					...articleNegative,
					formId: articleNegative?.entryName,
					feedbackChoices: articleNegative?.feedbackChoices.map((choice) => ({
						id: choice.entryName,
						label: choice.copy,
					})),
					submitButtonCopy: articleNegative?.submitCta?.copy,
					feedbackChoicesType: FeedbackChoicesTypes.NEW,
				},
			}}
		/>
	);
};
