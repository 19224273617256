import React from 'react';
import { Banner } from '@home-diy-toolbox/web/contentful';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { GenericPageIds } from '@home-diy-toolbox/web/common-types/refresh-base';
import { ExpertContactCard } from './ExpertContactCard';
import { getChatClient } from '../../../../features/chat/components';

export const PageCard = ({ banner }: { banner: Banner }) => {
	const { dispatchEvent } = useDispatchEvent();

	if (!banner || banner.type !== 'expert') return null;

	return (
		<ExpertContactCard
			title={banner.title}
			description={banner.description}
			schedule1={null}
			schedule2={null}
			callButton={
				banner.mainCta
					? {
							icon: 'Phone',
							text: banner.mainCta.copy,
							href: `tel:${banner.mainCta.telephoneNumber}`,
							onClick: () => {
								dispatchEvent(AnalyticEventType.CLICK, {
									ActionId: 'expertContactBanner_callButton',
									From: GenericPageIds.BATTERY_CHECKUP,
								});
							},
					  }
					: null
			}
			chatButton={
				banner.secondaryCta
					? {
							icon: 'ChatBubbleCircle',
							text: banner.secondaryCta.copy,
							onClick: () => {
								getChatClient().openMessagingOverlay();
								dispatchEvent(AnalyticEventType.CLICK, {
									ActionId: 'expertContactBanner_chatButton',
									From: GenericPageIds.BATTERY_CHECKUP,
								});
							},
					  }
					: null
			}
			imageUrl={banner.cardImage.asset.url}
			imageAlt={banner.cardImage.alternativeText}
		/>
	);
};
