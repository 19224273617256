import { createContext, useContext, useEffect, useState } from 'react';
import { environment } from '../../environments/environment';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';
import { getUserBrowserId } from '@home-diy-toolbox/web/utils';
import { deviceCareApiClient } from '../../clients';
import { FEATURE_LIST } from './flags';
import { overrideFeature } from './overrideFeature';

type Dictionary = {
	[key: string]: unknown;
};

type FeatureFlagContextType = {
	isLoading: boolean;
	features: Dictionary;
};

const FeatureFlagContext = createContext<FeatureFlagContextType>(null);

export const useFeatureFlags = () => useContext(FeatureFlagContext);

type ProviderProps = {
	children: React.ReactNode;
};

export const FeatureFlagsProvider = ({ children }: ProviderProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [features, setFeatures] = useState<Dictionary>({});
	const { client: partner } = useValidateMxContext();

	const { evidentlyProject } = environment;
	const evaluationContext = {
		partner,
	};
	const payload = {
		entityId: getUserBrowserId(),
		evaluationContext,
	};

	useEffect(() => {
		if (!partner) return;

		Promise.all(
			FEATURE_LIST.map((feature) =>
				deviceCareApiClient
					.getFeatureValue(evidentlyProject, feature, payload)
					.then((value) => ({ [feature]: value }))
			)
		)
			.then((values) => {
				setFeatures(
					values.reduce(
						(previousValue, currentValue) => ({
							...previousValue,
							...currentValue,
						}),
						{}
					)
				);
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				throw err;
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partner]);

	return (
		<FeatureFlagContext.Provider
			value={{
				isLoading,
				features: overrideFeature(features),
			}}
		>
			{children}
		</FeatureFlagContext.Provider>
	);
};
