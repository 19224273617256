import { DeviceModel, DeviceOS } from '@home-diy-toolbox/web/common-types/refresh-base';
import { SelectedOS } from '../providers';

interface DefaultOs {
	isDefaultOs: (os: string, vendor: string) => boolean;
	selectedOS: SelectedOS;
}
export const defaultOsList: DefaultOs[] = [
	{
		isDefaultOs(os) {
			if (!os) return false;
			return os.includes('ios');
		},
		selectedOS: {
			deviceOS: DeviceOS.IOS,
			deviceMake: 'Apple',
			deviceModel: DeviceModel.IPHONE_GENERIC,
		},
	},
	{
		isDefaultOs(os, vendor) {
			if (!os || !vendor) return false;
			return os.includes('android') && vendor.includes('samsung');
		},
		selectedOS: {
			deviceOS: DeviceOS.ANDROID,
			deviceMake: 'Samsung',
			deviceModel: DeviceModel.SAMSUNG_GENERIC,
		},
	},
	{
		isDefaultOs(os, vendor) {
			if (!os) return false;
			return os.includes('android') && !vendor?.includes('samsung');
		},
		selectedOS: {
			deviceOS: DeviceOS.ANDROID,
			deviceMake: 'Android Other',
			deviceModel: DeviceModel.ANDROID_GENERIC,
		},
	},
];
