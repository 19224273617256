import { FC, memo, useCallback, useMemo } from 'react';
import { Answer, QuestionBlock, useCheckupPage } from '@home-diy-toolbox/web/contentful';
import {
	AndroidOthersBatteryAnswer,
	InitialPageAnswers,
	UserInputsType,
} from '../answers/types';
import { StartClaimCard } from '../components/StartClaimCard';
import { BatteryQuestionBlock } from '../components/BatteryQuestionBlock';
import styled from 'styled-components';
import { DeviceProps } from './types';
import { Spinner } from '../components/Spinner';

const AndroidOthersContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

type Props = {
	answers: AndroidOthersBatteryAnswer;
	setAnswers: (newAnswers: InitialPageAnswers) => void;
	setTags: DeviceProps['setTags'];
};

export const AndroidOthersBatteryCheckupV3: FC<Props> = memo(
	({ answers, setAnswers, setTags }) => {
		const pageContent = useCheckupPage('androidOthersBatteryFlow');
		const blocks = pageContent?.blocks;

		const onChange = useCallback(
			(entryName: string, answer: UserInputsType, tags: string[]) => {
				setAnswers({ ...answers, [entryName]: answer });

				setTags((oldTags) => new Set([...Array.from(oldTags), ...tags]));
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[answers]
		);

		const blocksComponent = useMemo(() => {
			const blocksComponent = blocks?.map((block, blockIndex) => {
				const blockComponents = {
					QuestionBlock: (questionBlock: QuestionBlock) => {
						// disable `input.text` if on entryName=`chargeLast` if checkbox is selected (don't have this info)
						const checkDisabled = (answerOption: Answer) => {
							if (answerOption.type === 'checkbox') return false;

							const userInput = answers?.[block.entryName] ?? null;
							if (!userInput) return false;
							// disabled the text field if checkbox=`don't have this information` is checked
							const checkbox = questionBlock.answers.find((b) => b.type === 'checkbox');
							return userInput === checkbox.value;
						};

						return (
							<BatteryQuestionBlock
								key={block.entryName}
								userInput={answers?.[block.entryName] ?? null}
								onChange={(answer, newTags) => onChange(block.entryName, answer, newTags)}
								typename={questionBlock.typename}
								entryName={questionBlock.entryName}
								question={questionBlock.question}
								questionDescription={questionBlock.questionDescription}
								answers={questionBlock.answers}
								disabledOption={
									questionBlock.entryName === 'chargeLast' ? checkDisabled : false
								}
							/>
						);
					},
					ResultCard: () => <StartClaimCard key={blockIndex} />,
				};

				if (blockComponents[block.typename]) {
					return blockComponents[block.typename](block);
				}

				return null;
			});

			return blocksComponent;
		}, [answers, blocks, onChange]);

		if (!pageContent) return <Spinner />;

		return <AndroidOthersContainer>{blocksComponent}</AndroidOthersContainer>;
	}
);
