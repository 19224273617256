import { FunctionComponent } from 'react';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { useCheckupPage } from '@home-diy-toolbox/web/contentful';
import { ResultPageIds, Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { CheckupContainer, MagicWandWithLabel } from '../../../components';
import { useResultPageLoading } from '../../../features/checkupResults/hooks';
import { PageLoader } from '@soluto-private/mx-asurion-ui-react';

const CalculatingSpeedResultPage: FunctionComponent = () => {
	const pageId = ResultPageIds.CALCULATING_SPEED_RESULT;
	const pageContent = useCheckupPage(pageId);
	useResultPageLoading(Checkups.SPEED);

	if (!pageContent) return <PageLoader />;

	return (
		<CheckupContainer>
			<MagicWandWithLabel label={pageContent.heading} />
		</CheckupContainer>
	);
};
export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: ResultPageIds.CALCULATING_SPEED_RESULT,
	CheckupType: Checkups.SPEED,
})(CalculatingSpeedResultPage);
