import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BatteryFilterPathNames } from '@home-diy-toolbox/web/common-types/refresh-base';

export const useResetSession = () => {
	const history = useHistory();
	const from = history.location.state?.['from'];

	const clearHistoryState = () => history.replace(history.location.pathname);

	const reset = useMemo(() => {
		// this will prevent the session from resetting when user goes back
		clearHistoryState();

		const fromHomepage = from === '/';
		const fromBatteryLandingPage = Object.values(BatteryFilterPathNames).includes(from);

		return fromHomepage || fromBatteryLandingPage;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [from]);

	return reset;
};
