import { useLocation } from 'react-router-dom';
import { remoteExpertChat, simplrChatAI, SUPPORTED_PATHNAME_FOR_SIMPLR_LIST } from '.';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';
import { checkREChatSdkReady } from './checkREChatSdkReady';
import { useEffect } from 'react';
import { useFeatureFlags } from '../../../providers/featureFlag';

// If the client is `att` and in the home page (`/`)
// Hide the Remote Expert SDK
// Then show the Simplr AI Chat
export const useHideChatSdk = () => {
	const location = useLocation();
	const isChatSdkReady = checkREChatSdkReady();
	const { client } = useValidateMxContext();
	const {
		isLoading: isFeatureFlagsLoading,
		features: { simplrAI2024 },
	} = useFeatureFlags();

	useEffect(() => {
		if (isFeatureFlagsLoading) {
			return;
		}

		// No need to show simplr fab anymore if client is not supported
		if (!simplrAI2024) {
			simplrChatAI.hideMessaging();
			remoteExpertChat.showMessaging();
			return;
		}

		// homepage
		if (SUPPORTED_PATHNAME_FOR_SIMPLR_LIST.includes(location.pathname)) {
			simplrChatAI.showMessaging();
			remoteExpertChat.hideMessaging();
		} else {
			simplrChatAI.hideMessaging();
			remoteExpertChat.showMessaging();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		simplrChatAI.isLoaded,
		client,
		isChatSdkReady,
		location.pathname,
		isFeatureFlagsLoading,
		simplrAI2024,
	]);
};
