import { useQuery } from 'urql';
import { useManageMxClient } from '../useManageMxClient';
import { servicePageQuery } from './queries/servicePage';
import { transformGraphqlEntry } from './utils';
import { useMemo } from 'react';
import { ServicePage } from './models';
import { useValidateMxContext } from '../useValidateMxContext';

export const useServicePage = (serviceId: string): ServicePage | null => {
	const partner = useManageMxClient();
	const { language } = useValidateMxContext();

	const [response] = useQuery({
		query: servicePageQuery,
		variables: {
			serviceId,
			partnerTag: `partner_${partner}`,
			locale: language,
		},
	});

	const data = useMemo(() => {
		if (!response.data) return null;
		return transformGraphqlEntry(response.data.servicePageCollection.items[0]);
	}, [response.data]);

	return data;
};
