import { Partner, Partners } from '@home-diy-toolbox/web/common-types';
import { useMemo } from 'react';
import { useValidateMxContext } from './useValidateMxContext';

const DEVICE_CARE_MXCLIENT = 'device-care_mxclient' as const;

const foundPartner = (partner: string) => {
	return Object.values(Partners).find((value) => value === partner);
};

export const useManageMxClient = (): Partner | null => {
	const { client: mxClient } = useValidateMxContext();

	const client = useMemo(() => {
		if (mxClient) {
			sessionStorage.setItem(DEVICE_CARE_MXCLIENT, mxClient);
			return mxClient;
		} else {
			const clientSessionStorage = sessionStorage.getItem(DEVICE_CARE_MXCLIENT);

			if (!clientSessionStorage) {
				return null;
			}

			if (foundPartner(clientSessionStorage)) {
				return clientSessionStorage;
			}

			return null;
		}
	}, [mxClient]);

	return client as Partner | null;
};
