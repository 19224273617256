import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Breadcrumb, Link, Text, breakpoints } from '@soluto-private/mx-asurion-ui-react';

const BreadcrumbContainer = styled.div`
	width: 100%;
	position: relative;
	margin-bottom: 2.25rem;

	${breakpoints.lg} {
		max-width: 75rem;
	}

	${breakpoints.xxl} {
		max-width: 90rem;
	}
`;

interface Step {
	path?: string;
	label: string;
}

interface BreadcrumbProps {
	steps?: Step[];
}

export const HeaderBreadcrumbs: React.FC<BreadcrumbProps> = ({ steps }) => {
	const { t } = useTranslation();
	const history = useHistory();
	if (!steps || steps?.length < 1) return null;
	return (
		<BreadcrumbContainer className="widecontainer">
			<Breadcrumb>
				{steps.map((step) =>
					step.path ? (
						<Link
							key={step.label}
							onClick={() => {
								history.push(step.path);
							}}
						>
							{t(step.label)}
						</Link>
					) : (
						<Text key={step.label}>{t(step.label)}</Text>
					)
				)}
			</Breadcrumb>
		</BreadcrumbContainer>
	);
};
