import React, { useState, useMemo, useEffect } from 'react';
import type { RadioValue, Value } from '../types';

const isDescendant = (parent: HTMLElement, child: HTMLElement): boolean => {
	let node = child.parentNode;
	while (node != null) {
		if (node === parent) {
			return true;
		}
		node = node.parentNode;
	}
	return false;
};

interface ExtendedValue extends RadioValue {
	index: number;
	id: string;
}

export interface GroupStateAction {
	checkedValue?: Value;
	checkedValueIndex: number;
	focusedIndex: number;
	isAnyChecked: boolean;
	onClick: React.MouseEventHandler<HTMLLIElement>;
	onKeyDown: React.KeyboardEventHandler<HTMLLIElement>;
	resetFocus: React.FocusEventHandler<HTMLElement>;
}

const uncheckedIndex = -1;
const firstValueIndex = 0;

export const useGroupState = (
	values: ExtendedValue[],
	onSelected: (value: Value) => void,
	initialValue?: number | string
): GroupStateAction => {
	const initialIndex = useMemo(() => {
		const initialCheckedIndex = values.findIndex(({ value }) => value === initialValue);
		return typeof initialCheckedIndex !== undefined
			? initialCheckedIndex
			: uncheckedIndex;
	}, [initialValue, values]);
	const lastValueIndex = values.length - 1;
	const [checkedValue, setCheckedValue] = useState<typeof initialValue>(initialValue);
	const [checked, setChecked] = useState<number>(initialIndex);
	const isAnyChecked = checked !== uncheckedIndex;
	const [focused, setFocused] = useState<number>(
		isAnyChecked ? initialIndex : firstValueIndex
	);

	useEffect(() => setChecked(initialIndex), [initialIndex]);
	useEffect(
		() => setFocused(isAnyChecked ? initialIndex : firstValueIndex),
		[isAnyChecked, initialIndex]
	);

	const onClick = ({
		currentTarget: {
			dataset: { value },
			value: index,
		},
	}: React.MouseEvent<HTMLLIElement>) => {
		setChecked(index);
		setCheckedValue(value);
		onSelected(value);
	};

	const onKeyDown = ({
		key,
		currentTarget: {
			dataset: { value },
			value: index,
		},
	}: React.KeyboardEvent<HTMLLIElement>) => {
		switch (key) {
			case ' ':
			case 'Enter':
				setChecked(index);
				setCheckedValue(value);
				onSelected(value);
				break;

			case 'Up':
			case 'ArrowUp':
			case 'Left':
			case 'ArrowLeft':
				// Set previous as selected
				if (index === firstValueIndex) {
					setFocused(lastValueIndex);
				} else {
					setFocused(index - 1);
				}
				break;

			case 'Down':
			case 'ArrowDown':
			case 'Right':
			case 'ArrowRight':
				// Set next as selected
				if (index === lastValueIndex) {
					setFocused(firstValueIndex);
				} else {
					setFocused(index + 1);
				}
				break;

			default:
				break;
		}
	};

	const resetFocus = (event: React.FocusEvent<HTMLUListElement, HTMLElement>) => {
		const { currentTarget, relatedTarget } = event;
		if (relatedTarget && isDescendant(currentTarget, relatedTarget)) {
			return;
		}
		setFocused(isAnyChecked ? checked : firstValueIndex);
	};

	return {
		checkedValue,
		checkedValueIndex: checked,
		focusedIndex: focused,
		isAnyChecked,
		onClick,
		onKeyDown,
		resetFocus,
	};
};

export const useCheckIsFocus = () => {
	const [isFocus, setIsFocus] = useState<boolean>(false);

	useEffect(() => {
		const eventListener = (e: globalThis.KeyboardEvent) => {
			if (e.key !== 'Tab') {
				e.preventDefault();
			}
		};

		if (isFocus) {
			window.addEventListener('keydown', eventListener);
		} else {
			window.removeEventListener('keydown', eventListener);
		}
		return () => {
			window.removeEventListener('keydown', eventListener);
		};
	}, [isFocus]);

	return {
		setIsFocus,
	};
};
