export const FLOW_VERSION = 1;
export const FLOW_VERSION_KEY = 'flow_version';

export const FLOW_DIAGNOSTIC_KEYS = {
	Battery: 'flow_diagnostics_battery',
	Signal: 'flow_diagnostics_signal',
} as const;

export const FLOW_CHECKUP_KEYS = {
	Battery: 'flow_checkup_battery',
	Signal: 'flow_checkup_signal',
	Speed: 'flow_checkup_speed',
} as const;
