export const articlePageQuery = `
query ArticlePage($articleId: String!, $partnerTag: String, $locale: String) {
    articlePageCollection(
        limit: 1,
        where: {
            id: $articleId,
            contentfulMetadata: {tags: {id_contains_all: [$partnerTag]}}
        },
        locale: $locale
    ) {
        items {
            id
            relatedGuidesTitle
            relatedGuidesLink
            serviceListTitle
            serviceListCollection {
                items {
                    id
                    checkupType
                    title
                    description
                    cover {
                        title
                        description
                        url
                    }
                    action
                    location
                    tags
                }
            }
            feedbackSectionTitle
            positiveFeedbackForm {
                entryName
                header
                subheader
                commentsPlaceholder
                completeMessageCopy
                submitCta {
                    copy
                }
                feedbackChoicesCollection {
                    items {
                        entryName
                        copy
                    }
                }
            }
            negativeFeedbackForm {
                entryName
                header
                subheader
                commentsPlaceholder
                completeMessageCopy
                submitCta {
                    copy
                }
                feedbackChoicesCollection {
                    items {
                        entryName
                        copy
                    }
                }
            }
            batteryPageTitle {
                entryName
                copy
            }
            signalPageTitle {
                entryName
                copy
            }
            speedPageTitle {
                entryName
                copy
            }
        }
    }
}
`;
