import React, { Fragment } from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import { MARKS, BLOCKS, INLINES } from '@contentful/rich-text-types';
import styled from 'styled-components';
import { Text } from '@soluto-private/mx-asurion-ui-react';

const StyledImg = styled.img`
	display: block;
	height: auto;
	margin: 0.5rem 0px;
	width: 100%;
	max-width: 17.8125rem;
	border-radius: 0.5rem;
	filter: drop-shadow(0px 0.25rem 0.25rem rgba(0, 0, 0, 0.1));
`;

const Paragraph = styled(Text)`
	&& {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
`;

const Heading1 = styled(Text)`
	&& {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
	}
`;

const Heading2 = styled(Text)`
	&& {
		margin-top: 0.875rem;
		margin-bottom: 0.875rem;
	}
`;

export const defaultRenderOptions: Options = {
	renderText: (text) => {
		const sentences = text.split('\n');
		return (
			<>
				{sentences[0]}
				{sentences.slice(1).map((sentenceItem, index) => (
					<Fragment key={`sentenceFragment${index}`}>
						<br />
						{sentenceItem}
					</Fragment>
				))}
			</>
		);
	},
	renderMark: {
		[MARKS.BOLD]: (text) => <strong>{text}</strong>,
		[MARKS.ITALIC]: (text) => <em>{text}</em>,
	},
	renderNode: {
		[BLOCKS.EMBEDDED_ASSET]: ({
			data: {
				target: { fields },
			},
		}) => <StyledImg loading="lazy" alt={fields.title} src={fields.file.url} />,
		[INLINES.HYPERLINK]: ({ data: { uri } }, children) => {
			return (
				<a href={uri} target="_blank" rel="noopener noreferrer">
					{children}
				</a>
			);
		},
		[BLOCKS.PARAGRAPH]: (_, children) => (
			<Paragraph forwardedAs="p" size={2}>
				{children}
			</Paragraph>
		),
		[BLOCKS.HEADING_1]: (_, children) => (
			<Heading1 forwardedAs="h1" data-cy="header-text" weight="feather" size={4}>
				{children}
			</Heading1>
		),
		[BLOCKS.HEADING_2]: (_, children) => (
			<Heading2 forwardedAs="h2" size={3} weight="heavy" data-cy="subheading-text-2">
				{children}
			</Heading2>
		),
	},
};
