import { Checkup } from '@home-diy-toolbox/web/common-types/refresh-base';

export enum CheckupServiceLocation {
	IN_STORE = 'in_store',
	REMOTE = 'remote',
}

export type CheckupService = {
	id: string;
	checkupType: Checkup;
	title: string;
	description: string;
	cover: string;
	action: string;
	location: CheckupServiceLocation[];
};
