import styled from 'styled-components';
import { invert } from 'lodash';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import {
	Checkups,
	GenericPageIds,
	SpeedFilterPathnames,
	SpeedPageIds,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import {
	FeedbackChoicesTypes,
	HeroArticleGridSection,
	useManageMxClient,
	useServicePage,
	FeedbackForm as FeedbackFormTypes,
} from '@home-diy-toolbox/web/contentful';
import { useTranslation } from 'react-i18next';
import { Step, useBreadcrumb, useFlow } from '../../../providers';
import { useHeader } from '../../../features/header';
import { ExpertContactCard } from '../../../components/ExpertContactCard';
import {
	FeedbackForm,
	FeedbackComponentTypes,
	FeedbackTypes,
} from '../../../components/FeedbackForm';
import { ResultSectionDivider } from '../../../components/atoms';
import { useEffect, useMemo } from 'react';
import { GradientPage } from '@home-diy-toolbox/web/theme';
import { ArticleGridSection } from '../../../components/ArticleGridSection';
import { LoadingPage } from '../../LoadingPage';
import { ServiceSection2 } from '../../../components/CheckupServiceSection/ServiceSection2';

const Container = styled(GradientPage)`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	padding: 0 1rem;
	gap: 2.25rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
		padding: 0 1.5rem;
	}

	${breakpoints.lg} {
		max-width: 75rem;
		padding: 0;
		gap: 4.125rem;
	}

	${breakpoints.xxl} {
		padding: 0 1rem;
		max-width: 90rem;
	}

	& .sectionDivider {
		margin: 1rem 0;
	}
`;

const formatFeedback = (feedback: FeedbackFormTypes) => ({
	commentsPlaceholder: feedback.commentsPlaceholder,
	completeMessageCopy: feedback.completeMessageCopy,
	feedbackChoices: feedback.feedbackChoices.map((choice) => ({
		id: choice.entryName,
		label: choice.copy,
	})),
	feedbackChoicesType: FeedbackChoicesTypes.NEW,
	formId: feedback.entryName,
	header: feedback.header,
	subheader: feedback.subheader,
	submitButtonCopy: feedback.submitCta.copy,
});

export const SpeedLandingPage = (): JSX.Element => {
	const partner = useManageMxClient();
	const speedPage = useServicePage(`${partner}_speedArticle`);
	const { t } = useTranslation();

	const { setBreadcrumbSteps, resetBreadcrumbSteps } = useBreadcrumb();
	const { updateHeader } = useHeader();
	const {
		flow: { flowId, sessionData },
	} = useFlow();

	useEffect(() => {
		updateHeader(
			{
				title: t(speedPage?.pageTitle),
			},
			'/'
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [speedPage?.pageTitle, t]);

	useEffect(() => {
		if (speedPage?.pageTitle) {
			const breadcrumbSteps: Step[] = [
				{
					path: '/',
					label: 'Device Care',
				},
				{
					label: speedPage?.pageTitle,
				},
			];
			setBreadcrumbSteps(breadcrumbSteps);
		}
		return () => {
			resetBreadcrumbSteps();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [speedPage?.pageTitle]);

	const gridSection = useMemo(() => {
		if (speedPage) {
			const data: HeroArticleGridSection = {
				id: speedPage.id,
				title: speedPage.pageTitle,
				commonTag: speedPage.commonTag,
				filters: speedPage.filters.map((filter) => ({
					label: filter.label,
					value: filter.values,
					url: filter.url,
				})),
				filterLabel: speedPage.breadcrumbSubtitle,
				mainImage: speedPage.heroImage.url,
			};
			return data;
		}
		return null;
	}, [speedPage]);

	if (!speedPage) {
		return <LoadingPage />;
	}

	return (
		<Container className="widecontainer">
			<ArticleGridSection
				deviceOS={sessionData.deviceOS}
				articleGridSection={gridSection}
			/>
			<ExpertContactCard from={GenericPageIds.SPEED_PAGE} />
			<div>
				<ResultSectionDivider className="sectionDivider" />
				<FeedbackForm
					variant={FeedbackComponentTypes.STARS}
					type={FeedbackTypes.SESSION}
					feedbackTitle={speedPage.feedbackSectionTitle}
					positiveForm={formatFeedback(speedPage.positiveFeedbackForm)}
					negativeForm={formatFeedback(speedPage.negativeFeedbackForm)}
					flowId={flowId}
					analyticsExtras={{ CheckupType: Checkups.SPEED }}
				/>
				<ResultSectionDivider className="sectionDivider" />
			</div>
			<ServiceSection2
				sectionTitle={speedPage.serviceListTitle}
				services={speedPage.serviceList}
			/>
		</Container>
	);
};

const pathname = window.location.pathname;
const subpath = pathname.match(/\/device-care(\/.+)/);
const filter = subpath && subpath.length > 1 ? subpath[1] : '/speed-popular';
const speedPathMap = invert(SpeedFilterPathnames);
const page = speedPathMap[filter];

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: SpeedPageIds[page],
	CheckupType: Checkups.SPEED,
})(SpeedLandingPage);
