export enum ActionTypes {
	EXTERNAL_LINK = 'externalLink',
	INTENAL_LINK = 'internalLink',
	PHONE_NUMBER = 'phoneNumber',
	OPEN_CHAT = 'openChat',
}

export interface Action {
	id: string;
	action: ActionTypes;
	url?: string;
}
