import React from 'react';
import {
	Button,
	Link,
	colors,
	getFontWeight,
	Text,
} from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const Container = styled.div`
	display: grid;
	box-sizing: border-box;
	height: 100%;
	overflow-y: auto;
	background-color: ${colors.neutralBrightest};
	grid-template-rows: auto 1fr;
	gap: 2rem;
`;

const Details = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	flex-direction: column;
	padding: 8vh 3rem 0 3rem;
	margin: 0 auto;
	max-width: 24.1875rem;
`;

const StyledText = styled(Text)`
	&& {
		display: block;
		color: ${colors.neutralDeepest};
	}
`;

const Title = styled(StyledText)`
	&& {
		display: block;
		margin-bottom: 0.5rem;
	}
`;

const Subtitle = styled(StyledText)`
	&& {
		display: block;
		margin-bottom: 0.5rem;
	}
`;

const Schedule = styled(StyledText)`
	&& {
		display: block;
		font-weight: ${getFontWeight('heavy')};
		margin-bottom: 2.125rem;
	}
`;

const CallButton = styled(Link)`
	&& {
		margin-bottom: 0.5rem;
	}
`;

const Model = styled.div`
	display: flex;
	align-items: flex-end;
	align-self: end;
	max-width: 36.25rem;
	margin: 0 auto;
	margin-top: auto;

	& > img {
		width: 100%;
	}
`;

interface ButtonConfig {
	text: string;
	onClick: () => void;
}

interface CallButtonConfig extends ButtonConfig {
	href: string;
}

type ChatButtonConfig = ButtonConfig;

interface Props {
	title: string;
	subtitle: string;
	schedule?: string;
	callButton?: CallButtonConfig;
	chatButton?: ChatButtonConfig;
	imageUrl: string;
	imageAlt: string;
}

export const ExpertContactBanner = ({
	title,
	subtitle,
	schedule = '',
	callButton,
	chatButton,
	imageUrl,
	imageAlt,
}: Props) => {
	return (
		<Container>
			<Details>
				<Title size={5} weight="feather">
					{title}
				</Title>
				<Subtitle size={3} weight="feather">
					{subtitle}
				</Subtitle>
				<Schedule>{schedule}</Schedule>
				{callButton ? (
					<CallButton
						variant="button"
						href={callButton.href}
						btnVariantProps={{ btnSize: 'medium', btnVariant: 'default' }}
						onClick={() => callButton.onClick()}
					>
						{callButton.text}
					</CallButton>
				) : null}
				{chatButton ? (
					<Button
						color="secondary"
						variant="outline"
						onClick={() => chatButton.onClick()}
					>
						{chatButton.text}
					</Button>
				) : null}
			</Details>
			<Model>
				<img src={imageUrl} alt={imageAlt} />
			</Model>
		</Container>
	);
};
