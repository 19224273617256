import styled from 'styled-components';

export const Table = styled.table`
	width: 100%;
	border-spacing: 0.5rem;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableCell = styled.td`
	& > *,
	& > * > * {
		margin: unset;
		width: unset;
	}
`;
