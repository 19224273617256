import { DeviceOS } from '@home-diy-toolbox/web/common-types/refresh-base';
import { DeviceModel } from '@home-diy-toolbox/web/common-types/refresh-base';

export const getOSFromMake = (deviceMake) =>
	deviceMake === 'Apple' ? DeviceOS.IOS : DeviceOS.ANDROID;

export const getModelFromMake = (deviceMake) =>
	deviceMake === 'Apple'
		? DeviceModel.IPHONE_GENERIC
		: deviceMake === 'Samsung'
		? DeviceModel.SAMSUNG_GENERIC
		: DeviceModel.ANDROID_GENERIC;
