import { HttpResponse } from '@home-diy-toolbox/shared/clients';

export class FetchResponse<T> implements HttpResponse<T> {
	constructor(private readonly response: Response) {}

	public get body(): T {
		return this.response.json() as unknown as T;
	}

	public get headers(): HeadersInit {
		return this.response.headers;
	}

	public get status(): number {
		return this.response.status;
	}
}
