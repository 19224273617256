import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { StoreConfig } from '@home-diy-toolbox/web/contentful';
import { StoreCard } from './StoreCard';
import { Text } from '@soluto-private/mx-asurion-ui-react';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const StateContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const StoresContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5rem; // Still asking designer about gap size change.
`;

const HeaderText = styled(Text)`
	margin-bottom: 1rem;
	display: inline-block;
`;

type Props = {
	stores: StoreConfig[];
};

export const StoreList: FunctionComponent<Props> = ({ stores }) => {
	const uniqueStates = [...new Set(stores.map((store) => store.state))].sort();

	const organizedStores = uniqueStates.map((state) =>
		stores.filter((store) => store.state === state)
	);

	return (
		organizedStores && (
			<Container>
				{organizedStores?.map((stores) => (
					<StateContainer key={stores[0].state}>
						<HeaderText size={4} weight="feather">
							{stores[0]?.state}
						</HeaderText>
						<StoresContainer>
							{stores.map((store) => (
								<StoreCard key={store?.id} store={store} />
							))}
						</StoresContainer>
					</StateContainer>
				))}
			</Container>
		)
	);
};
