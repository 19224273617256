import { useQuery } from 'urql';
import { contactExpertCardQuery } from './queries';
import { ContactExpertCard } from './models';
import { useEffect, useMemo } from 'react';
import { useManageMxClient } from '../useManageMxClient';

const transformEntry = (entry) => {
	if (typeof entry !== 'object' || !entry) return entry;

	if (Array.isArray(entry)) {
		return entry.map((x) => transformEntry(x));
	}

	const holder = {};
	for (const key of Object.keys(entry)) {
		const value = entry[key];

		const isCollection = value?.items && key.endsWith('Collection');
		if (isCollection) {
			holder[key.replace('Collection', '')] = transformEntry(value.items);
			continue;
		}
		const isRichText = value?.json && value.json.content && value.json.nodeType;
		if (isRichText) {
			holder[key] = value.json;
			continue;
		}

		holder[key.replace('__', '')] = transformEntry(value);
	}

	return holder;
};

export const useContactExpertCard = (
	contactExpertCardEntryName: string
): ContactExpertCard => {
	const partner = useManageMxClient();

	const [response, reexecuteQuery] = useQuery({
		query: contactExpertCardQuery,
		variables: {
			contactExpertCard: contactExpertCardEntryName,
			partnerTag: `partner_${partner}`,
		},
		pause: true,
	});

	const data = useMemo(() => {
		if (!response.data) return null;
		return transformEntry(response.data.contactExpertCardCollection.items[0]);
	}, [response.data]);

	useEffect(() => {
		if (!partner) return;
		if (response.fetching) return;
		if (data) return;

		reexecuteQuery({ requestPolicy: 'network-only' });
	}, [partner, reexecuteQuery, data, response.fetching]);

	return data;
};
