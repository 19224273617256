import { HttpClient } from './types';

export type BaseHttpApiClientConfig = {
	domain: string;
	httpClient: HttpClient;
	commonHeaders?: HeadersInit;
};

export abstract class BaseHttpApiClient {
	private readonly httpClient: HttpClient;

	protected readonly domain: string;

	protected readonly commonHeaders: HeadersInit;

	constructor(config: BaseHttpApiClientConfig) {
		if (!config) {
			throw new Error('Must provide the client configuration.');
		}

		const { domain, httpClient, commonHeaders } = config;

		if (!domain) {
			throw new Error('Must provide an API domain.');
		}
		if (!httpClient) {
			throw new Error('Must provide a HTTP Client.');
		}

		this.domain = domain;
		this.httpClient = httpClient;
		this.commonHeaders = commonHeaders;
	}

	protected get headers(): HeadersInit {
		return this.commonHeaders;
	}

	protected async get<R>(endpoint: string, headers?: HeadersInit) {
		return this.httpClient.get<R>(endpoint, { ...this.headers, ...headers });
	}

	protected async put<P, R>(endpoint: string, payload: P, headers?: HeadersInit) {
		return this.httpClient.put<P, R>(endpoint, payload, {
			...this.headers,
			...headers,
		});
	}

	protected async post<P, R>(endpoint: string, payload: P, headers?: HeadersInit) {
		return this.httpClient.post<P, R>(endpoint, payload, {
			...this.headers,
			...headers,
		});
	}
}
