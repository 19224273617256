import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
	breakpoints,
	getFontSize,
	getFontWeight,
	Text,
	Link,
} from '@soluto-private/mx-asurion-ui-react';
import {
	BatteryFilterPathNames,
	BatteryPages,
	CheckupCategoryType,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { ArticleCard, useArticleContentContext } from '@home-diy-toolbox/web/contentful';
import { useFlow } from '../../../../providers';
import { ArticleItem } from '../../../../components';

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		'title title'
		'articles articles'
		'link link';
	row-gap: 1.25rem;

	${breakpoints.md} {
		grid-template-areas:
			'title link'
			'articles articles';
	}
`;

const Articles = styled.div`
	display: flex;
	overflow-x: auto;
	gap: 1rem;
	padding: 0 1rem;
	margin: 0 -1rem;
	grid-area: articles;

	${breakpoints.md} {
		padding: 0 1.5rem;
		margin: 0 -1.5rem;
	}

	${breakpoints.md2} {
		padding: 0;
		margin: 0;
	}

	& > * {
		flex: 0 0 14.9375rem;
	}
`;

const Title = styled(Text)`
	&& {
		display: block;
		font-size: ${getFontSize(3)};
		font-weight: ${getFontWeight('heavy')};
		grid-area: title;
	}
`;

const GuidesLink = styled(Text)`
	&& {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		grid-area: link;
	}
`;

interface Props {
	relatedGuidesTitle: string;
	relatedGuidesLink: string;
	answerTags: Set<string>;
}
export const RelatedGuides = ({
	relatedGuidesLink,
	relatedGuidesTitle,
	answerTags,
}: Props) => {
	const {
		flow: { sessionData },
	} = useFlow();
	const { deviceOS } = sessionData;
	const history = useHistory();
	const [articleCards, setArticleCards] = useState<ArticleCard[]>([]);
	const { getTopArticles, getTags } = useArticleContentContext();
	useEffect(() => {
		const requiredTags = getTags([], CheckupCategoryType.BATTERY_CHECKUP);
		const optionalTags = Array.from(answerTags);
		const excludedTags = ['old'];

		getTopArticles(requiredTags, optionalTags, deviceOS, excludedTags).then(
			(articleCards) => {
				setArticleCards(articleCards);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceOS]);

	return (
		<Container>
			<Title forwardedAs="h2">{relatedGuidesTitle}</Title>
			<GuidesLink>
				<Link
					tabIndex={0}
					data-cy="allRelatedGuidesLink"
					color="secondary"
					iconSide="right"
					iconSrc="ArrowRight"
					onClick={() => {
						history.push(BatteryFilterPathNames[BatteryPages.ALL], {
							from: history.location.pathname,
						});
					}}
				>
					{relatedGuidesLink}
				</Link>
			</GuidesLink>
			<Articles>
				{articleCards.map(({ id, title, contentCard }) => (
					<ArticleItem
						key={id}
						id={id}
						title={title}
						subtitle={contentCard.fields.subtitle}
						imageUrl={contentCard.fields.coverImage?.fields.file?.url}
						externalLink={contentCard.fields.externalLink}
					/>
				))}
			</Articles>
		</Container>
	);
};
