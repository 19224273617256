import { useQuery } from 'urql';
import { useManageMxClient } from '../useManageMxClient';
import { articlePageQuery } from './queries/articlePage';
import { transformGraphqlEntry } from './utils';
import { useState, useEffect } from 'react';
import { NewArticlePage } from './models';
import { useValidateMxContext } from '../useValidateMxContext';

export const useArticlePage = (articleId: string): NewArticlePage | null => {
	const partner = useManageMxClient();
	const { language } = useValidateMxContext();

	const [response] = useQuery({
		query: articlePageQuery,
		variables: {
			articleId,
			partnerTag: `partner_${partner}`,
			locale: language,
		},
	});

	const [data, setData] = useState<NewArticlePage | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		if (response.fetching) {
			setLoading(true);
			setError(false);
		} else if (response.error) {
			setLoading(false);
			setError(true);
		} else if (response.data) {
			const transformedData = transformGraphqlEntry(
				response.data.articlePageCollection.items[0]
			);
			setData(transformedData);
			setLoading(false);
			setError(false);
		}
	}, [response]);

	if (loading || error) {
		return null;
	}

	return data;
};
