import {
	expandChatOverlay,
	hideChatBubble,
	initialize,
	showChatBubble,
	attachSDKToContainer,
	injectChatScriptByVersion,
	showMessage,
} from '@soluto-private/remote-expert-sdk';
import { EventEmitter } from 'events';
import { Analytics } from 'react-shisell';
import { AnalyticEventType, withExtras } from '@home-diy-toolbox/web/analytics';
import { environment } from '../../../environments/environment';
import { ChatSender, RemoteChatSender } from '../senders';
import {
	Channel,
	Language,
	MessagingOverlayStateChangedEventArgs,
	MessagingTopic,
	Partner,
	SessionChangedEventArgs,
} from '@soluto-private/remote-expert-types';
import { ChatInterface } from './types';

const getValidLanguage = (lang: string): Language => {
	const language = lang.toUpperCase() as Language;
	if (Object.values(Language).includes(language)) {
		return language;
	}
	return Language.EN_US;
};

type RemoteExpertChatInitConfig = {
	partner: string;
	language: string;
	token?: string;
	messagingTopic?: string;
};

export type RemoteExpertChatInitType = [
	config: RemoteExpertChatInitConfig,
	analytics: Analytics,
	onLoadCompleteCallback?: () => void
];

class RemoteExpertChat implements ChatInterface<RemoteExpertChatInitType> {
	private async _init(
		{ partner, language, token, messagingTopic }: RemoteExpertChatInitConfig,
		eventsHandler: (eventEmitter: EventEmitter) => void,
		onLoadCompleteCallback: () => void
	): Promise<void> {
		await new Promise<void>((resolve, reject) => {
			injectChatScriptByVersion(
				{
					id: 'remote-expert-sdk',
					version: 'v1',
					environment: environment.production ? 'production' : 'development',
				},
				async () => {
					try {
						await initialize({
							partner: partner as Partner,
							messagingTopic:
								(messagingTopic as MessagingTopic) ?? MessagingTopic.GENERAL_CONVERSATION,
							appName: 'Device Care',
							language: getValidLanguage(language),
							channel: Channel.CHAT,
							registerToEvents: eventsHandler,
							environment: environment.production ? 'production' : 'development',
							token,
						});

						resolve();
						onLoadCompleteCallback?.();
					} catch (e) {
						console.log(`Error initializing Twilio Chat SDK: ${e as string}`);
						reject(e);
					}
				}
			).catch((e) => reject(e));
		});
	}

	async init(
		config: RemoteExpertChatInitConfig,
		analytics: Analytics,
		onLoadCompleteCallback?: () => void
	): Promise<void> {
		if (typeof config.partner === 'undefined') {
			return;
		}
		const eventsHandler = (eventEmitter: EventEmitter) => {
			eventEmitter.on('FABIO_CLICKED', (args: MessagingOverlayStateChangedEventArgs) => {
				analytics.dispatcher
					.extend(
						withExtras({
							ActionId: `FABIO_CLICKED_${args.isExpanded ? 'open' : 'close'}`,
						})
					)
					.dispatch(AnalyticEventType.CLICK);
			});
			eventEmitter.on('SESSION_CHANGED', (args: SessionChangedEventArgs) => {
				analytics.dispatcher
					.extend(
						withExtras({
							SessionChanged: args.action,
							SessionId: args.sessionId,
							ExpertId: args.expertId,
							Expertise: args.expertise,
							Pool: args.pool,
						})
					)
					.dispatch(AnalyticEventType.AE_SESSION_CHANGED);
			});
		};
		await this._init(config, eventsHandler, onLoadCompleteCallback);
	}

	async showMessaging(): Promise<void> {
		try {
			await showChatBubble();
		} catch (e) {
			console.log(`Twilio SDK ERROR: ${e as string}`);
		}
	}

	async hideMessaging(): Promise<void> {
		try {
			await hideChatBubble();
		} catch (e) {
			console.log(`Twilio SDK ERROR: ${e as string}`);
		}
	}

	openMessagingOverlay(message?: string): void {
		try {
			expandChatOverlay({ message });
		} catch (e) {
			console.log(`Twilio SDK ERROR: ${e as string}`);
		}
	}

	sendHiddenMessage(message: string): void {
		showMessage(message, true);
	}
	async sendMessage(type: string, message: string): Promise<void> {
		if (type === 'CustomerMessage') {
			showMessage(message, true);
		}
	}

	async attachToContainer(containerId: string) {
		try {
			attachSDKToContainer(containerId);
		} catch (e) {
			console.log(`Twilio SDK ERROR: ${e as string}`);
		}
	}

	createSender(): ChatSender {
		return new RemoteChatSender();
	}
}

export const remoteExpertChat = new RemoteExpertChat();
