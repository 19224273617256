import { Partner, Partners } from '@home-diy-toolbox/web/common-types';
import { Checkup, Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';

export const isNewCheckupExperience = (partner: Partner, checkup: Checkup) => {
	const readyPartners = {
		[Checkups.BATTERY]: {
			[Partners.ATT]: true,
			[Partners.VERIZON]: true,
			[Partners.COX]: true,
			[Partners.USCELLULAR]: true,
			[Partners.CRICKET]: true,
		},
	};

	return readyPartners[checkup]?.[partner] || false;
};
