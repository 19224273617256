import React, { ReactElement, useEffect, useState } from 'react';
import { ButtonProps } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { isSpaceOrEnterPress } from '@home-diy-toolbox/web/utils';

const Container = styled.div`
	flex: 1 1;
	display: flex;
`;

export interface QuizFlowOption {
	/** The option value that identifies each question option from the other. Not the same as the displayed text */
	value: string | number;

	/** The actual text on display for the option */
	text: string;
}

export interface QuizFlowOptionProps {
	config: QuizFlowOption;
	selected?: boolean;
	button: ReactElement<ButtonProps & { 'data-testid'?: string }>;
	/** Callback when the selected option changes. */
	onSelect?: (event: React.MouseEvent<HTMLElement>, value: unknown) => void;
	'data-testid'?: string;
}

export const QuizFlowOption = ({
	config: { value, text },
	selected,
	onSelect,
	button,
	...rest
}: QuizFlowOptionProps) => {
	const [variant, setVariant] = useState<'default' | 'outline'>('outline');

	useEffect(() => {
		setVariant(selected ? 'default' : 'outline');
	}, [selected]);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		if (isSpaceOrEnterPress(event)) {
			handleSelect(event);
		}
	};

	const handleSelect = (event) => {
		onSelect?.(event, value);
	};

	return (
		<Container className="quiz-flow-button">
			{React.cloneElement(
				button,
				{
					onClick: handleSelect,
					onChange: handleSelect,
					onKeyPress: handleKeyPress,
					variant: variant,
					value: value,
					...rest,
				},
				text
			)}
		</Container>
	);
};
