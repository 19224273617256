import React, { FunctionComponent, useState } from 'react';
import {
	useAnalytics,
	AnalyticEventType,
	withExtras,
} from '@home-diy-toolbox/web/analytics';
import { useCheckupContentContext, RichText } from '@home-diy-toolbox/web/contentful';
import {
	ComponentIds,
	Checkups,
	DiagnosticsPageIds,
	DiagnosticsCategoryType,
	DiagnosticsPages,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import styled from 'styled-components';
import { useFlow } from '../../../../providers';
import {
	NextButton,
	RadioButtonWithLabelGroup,
	PreviousButton,
} from '../../../../components';
import QuestionIcon from '../../../../assets/question.svg';
import { useTranslation } from 'react-i18next';
import { Modal, Text } from '@soluto-private/mx-asurion-ui-react';
import { useCheckupPageFlow } from '../../../../features/checkupFlows';
import { useHistory } from 'react-router-dom';

const NextButtonWrapper = styled(NextButton)`
	margin-top: 40px;
`;
const InfoBox = styled.div`
	display: flex;
	flex-direction: row;
`;

const QuestionPrompt = styled(Text)`
	margin-top: 16px;
`;

const Spacer = styled.div`
	margin: 8px 0;
`;

const Icon = styled.img`
	margin-left: 8px;
	border: none;
	background: none;
	cursor: pointer;
`;

const RichTextStyleContainer = styled.div`
	& > img {
		width: 90%;
		margin-top: 10px;
	}

	& > p {
		font-size: 20px;
		line-height: 30px;
		font-weight: bold;
		margin-bottom: 0;

		@media screen and (min-width: ${({ theme: { breakpoints } }) => breakpoints.mobile}) {
			font-size: 24px;
			line-height: 32px;
		}
	}
`;
type Props = {
	isNextEnabled: boolean;
	onNextClicked: () => void;
};

export const BatteryMessagesQuestion: FunctionComponent<Props> = ({
	isNextEnabled,
	onNextClicked,
}) => {
	const pageId = DiagnosticsPageIds.IOS_BATTERY_MESSAGES;
	const nextButtonId = `${pageId}_${ComponentIds.NEXT_BUTTON}`;
	const history = useHistory();

	const analytics = useAnalytics();
	const [goNext] = useCheckupPageFlow(
		Checkups.BATTERY,
		DiagnosticsPages.IOS_BATTERY_MESSAGES
	);
	const { useDiagnosticsContent } = useCheckupContentContext();
	const {
		updateDiagnostics,
		getDiagnosticsByTestId,
		flow: {
			sessionData: { deviceOS, deviceMake, currentQuestionIndex, partner },
		},
		flow,
		setCurrentQuestionIndex,
	} = useFlow();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const latestAnswers =
		(getDiagnosticsByTestId(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId
		) as string) || '';

	const [selectedAnswer, setSelectedAnswer] = useState<string>(latestAnswers);

	const onAnswerChange = (answerSelected: string) => {
		setSelectedAnswer(answerSelected);
	};

	const onNextButtonClick = () => {
		onNextClicked();
		updateDiagnostics(
			DiagnosticsCategoryType.BATTERY_DIAGNOSTICS,
			pageId,
			selectedAnswer
		);

		setCurrentQuestionIndex(currentQuestionIndex + 1);

		goNext({ flow });
	};

	const onBackButtonClick = () => {
		setCurrentQuestionIndex(currentQuestionIndex - 1);
		history.goBack();
	};

	const onModalOpen = () => {
		setIsModalOpen(true);
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'batteryMessageInstructionsModalOpen',
					CheckupType: Checkups.BATTERY,
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	};

	const onModalExit = () => {
		setIsModalOpen(false);
		analytics.dispatcher
			.extend(
				withExtras({
					ActionId: 'batteryMessageInstructionsModalClosed',
					CheckupType: Checkups.BATTERY,
				})
			)
			.dispatch(AnalyticEventType.CLICK);
	};

	const { questionTitleCopy, answerOptions, nextButtonCopy, modalTitle, modalContent } =
		useDiagnosticsContent(pageId, deviceOS, deviceMake, partner, Checkups.BATTERY);

	const { t } = useTranslation();

	return (
		<>
			<InfoBox>
				<QuestionPrompt size={4} weight="feather">
					{questionTitleCopy}
					<Icon
						onClick={onModalOpen}
						src={QuestionIcon}
						alt={t('questionMarkIconName')}
					/>
					{isModalOpen && (
						<Modal isOpen title={modalTitle} onCloseClick={onModalExit}>
							<RichTextStyleContainer>
								<RichText document={modalContent} />
							</RichTextStyleContainer>
						</Modal>
					)}
				</QuestionPrompt>
			</InfoBox>
			<Spacer />
			<RadioButtonWithLabelGroup
				answerOptions={answerOptions}
				onAnswerChange={onAnswerChange}
				isAnswerChecked={(answerId) => selectedAnswer.includes(answerId)}
				buttonIdPrefix={pageId}
			/>
			<NextButtonWrapper
				onClick={onNextButtonClick}
				actionId={nextButtonId}
				data-cy="next-button"
				disabled={selectedAnswer.length === 0 || !isNextEnabled}
				analyticExtras={{
					SelectedAnswers: selectedAnswer,
					CheckupType: Checkups.BATTERY,
				}}
			>
				{nextButtonCopy}
			</NextButtonWrapper>
			<PreviousButton onClick={onBackButtonClick} />
		</>
	);
};
