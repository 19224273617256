import React from 'react';
import { Document } from '@contentful/rich-text-types';
import { RichText } from '@home-diy-toolbox/web/contentful';
import styled from 'styled-components';
import CheckIcon from '../../assets/check-circle.svg';

type Props = {
	resultSummary?: Document;
};

const Container = styled.div`
	ul {
		list-style: none;
		padding-left: 0px;
	}
	li {
		background: url(${CheckIcon}) no-repeat left center;
		margin: 10px 0;
		padding-left: 32px;
		line-height: 24px;
	}
`;

export const SignalSummaryPositives: React.FunctionComponent<Props> = ({
	resultSummary,
}) => (
	<Container>
		<RichText document={resultSummary} />
	</Container>
);
