import { useMemo } from 'react';
import { useLayoutContentContext } from '@home-diy-toolbox/web/contentful';
import { ServiceSection } from '..';
import { Props } from './Props';

export const NewServices = ({ checkups, title, xScrollable }: Props) => {
	const { checkupServices } = useLayoutContentContext();

	const services = useMemo(
		() =>
			checkups
				.map((checkup) =>
					checkupServices.find((service) => service.checkupType === checkup)
				)
				.filter((checkup) => !!checkup),
		[checkups, checkupServices]
	);

	return (
		<ServiceSection xScrollable={xScrollable} sectionTitle={title} services={services} />
	);
};
