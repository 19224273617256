import { DependencyList, ElementType, useEffect } from 'react';
import {
	useAnalytics,
	withAnalyticOnView as originalWithAnalyticOnView,
} from 'react-shisell';
import { withExtras } from 'shisell/extenders';
import { AnalyticExtras, AnalyticEventType, AnalyticViewType } from '../types';

export const useViewAnalytics = (
	actionId: AnalyticViewType,
	analyticsExtras?: AnalyticExtras,
	deps?: DependencyList
) => {
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.dispatcher
			.extend(withExtras({ ActionId: actionId, ...analyticsExtras }))
			.dispatch(AnalyticEventType.VIEW);
	}, deps || []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const withAnalyticOnView = (
	ComponentType: AnalyticViewType,
	analyticsExtras?: AnalyticExtras
) => {
	const pageAnalyticsExtras = () => withExtras({ ComponentType, ...analyticsExtras });

	return function <T extends Record<string, unknown>>(Component: ElementType<T>) {
		return originalWithAnalyticOnView<T>({
			analyticName: AnalyticEventType.VIEW,
			extendAnalytics: () => pageAnalyticsExtras(),
		})(Component);
	};
};
