import React from 'react';
import styled from 'styled-components';
import { Text, Card, Button, colors } from '@soluto-private/mx-asurion-ui-react';

const StyledCard = styled(Card)`
	&& {
		display: flex;
		flex-direction: column;
		padding: 0;
		position: relative;
		background-color: transparent;

		&:active {
			background-color: transparent;
		}
	}
`;

const CardText = styled(Text)`
	text-align: left;
	margin: 0;
`;

const CardBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	gap: 1rem;
	padding: 1rem;
`;

const ImageContainer = styled.div`
	&& {
		height: 10.625rem;
		display: flex;
		overflow: hidden;
		justify-content: center;
		border-radius: 8px 8px 0 0;
		position: relative;
		z-index: -1;
		background-color: ${colors.white};
	}

	&& > img {
		height: 100%;
	}
`;

export interface Props {
	title: string;
	imageSrc: string;
	subtitle: string;
	buttonText: string;
	// eslint-disable-next-line @typescript-eslint/ban-types
	onClick: Function;
}

export const ServiceCard = ({
	title,
	imageSrc,
	subtitle,
	buttonText,
	onClick,
}: Props): JSX.Element => {
	const stopPropOnClick = (event) => {
		event.stopPropagation();
		onClick();
	};

	return (
		<StyledCard
			key={title}
			type="call-to-action"
			onClick={(event) => stopPropOnClick(event)}
		>
			<ImageContainer>
				<img src={imageSrc} alt="" />
			</ImageContainer>
			<CardBody>
				<div>
					<CardText
						weight="feather"
						size={4}
						forwardedAs="h1"
						style={{ marginBottom: '0.25rem' }}
					>
						{title}
					</CardText>
					<CardText
						weight="feather"
						size={1}
						forwardedAs="p"
						color={colors.neutralDeepest}
					>
						{subtitle}
					</CardText>
				</div>
				<div>
					<Button
						color="secondary"
						onClick={(event) => stopPropOnClick(event)}
						size="small"
						variant="outline"
						style={{ position: 'relative' }}
						data-cy="serviceCardButton"
					>
						{buttonText}
					</Button>
				</div>
			</CardBody>
		</StyledCard>
	);
};
