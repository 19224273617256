const units = ['days', 'hours', 'minutes'] as const;
export type Unit = typeof units[number];

export type Duration = Record<Unit, number | undefined>;
type DefaultDuration = Record<Unit, 0>;

export const defaultDuration: DefaultDuration = {
	days: null,
	hours: null,
	minutes: null,
} as const;
