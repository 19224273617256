import { Partner } from '@home-diy-toolbox/web/common-types';
import { environment } from '../environments/environment';
import { useMemo } from 'react';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';

export const serviceArticles = (partner: Partner) => {
	return {
		...environment.serviceArticles.default,
		...(environment.serviceArticles[partner] ?? {}),
	};
};

export const useServiceArticles = () => {
	const { client: partner } = useValidateMxContext();
	return useMemo(() => serviceArticles(partner as Partner), [partner]);
};
