import { HttpClient } from '@home-diy-toolbox/shared/clients';
import { FetchResponse } from './FetchResponse';

export type Logger = {
	error: (message: string) => void;
};

export class Fetch implements HttpClient {
	constructor(
		private readonly logger: Logger = console,
		private readonly commonHeaders?: HeadersInit
	) {}

	async get<R>(endpoint: string, headers?: HeadersInit) {
		return await this.getResponse<undefined, R>('GET', endpoint, headers);
	}

	async put<P, R>(endpoint: string, payload: P, headers?: HeadersInit) {
		return this.getResponse<P, R>('PUT', endpoint, headers, payload);
	}

	async post<P, R>(endpoint: string, payload: P, headers?: HeadersInit) {
		return this.getResponse<P, R>('POST', endpoint, headers, payload);
	}

	private async getResponse<P, R>(
		method: string,
		endpoint: string,
		headers?: HeadersInit,
		payload?: P
	) {
		try {
			headers = {
				'Content-Type': 'application/json',
				...this.commonHeaders,
				...headers,
			};

			const requestInit = {
				method,
				headers,
			};

			if (payload) {
				requestInit['body'] =
					headers['Content-Type'] === 'application/json'
						? JSON.stringify(payload)
						: payload;
			}

			const response = await fetch(endpoint, requestInit);
			return new FetchResponse<R>(response);
		} catch (err) {
			this.logger.error(err);
			throw err;
		}
	}
}
