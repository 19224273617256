import React from 'react';
import { getCookieValue } from '@home-diy-toolbox/web/utils';
import { NewServices } from './NewServices';
import { OldServices } from './OldServices';
import { Props } from './Props';
import { useFeatureFlags } from '../../providers/featureFlag';

export const OtherServices = (props: Props) => {
	const {
		features: { redesign2022 },
	} = useFeatureFlags();
	const isNewDesign = redesign2022 || getCookieValue('redesign_2022');

	return isNewDesign ? <NewServices {...props} /> : <OldServices {...props} />;
};
