import React from 'react';
import styled from 'styled-components';
import {
	Button,
	Link,
	IconSrc,
	colors,
	Text,
	getFontWeight,
} from '@soluto-private/mx-asurion-ui-react';

const Container = styled.div`
	display: grid;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	border: 0.0625rem solid ${colors.neutralBright};
	border-radius: 0.5rem;
	row-gap: 2rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		height: 17.1875rem;
		padding: 0 3rem 0 3rem;
		grid-template-columns: 1fr auto;
		grid-template-rows: minmax(0, 1fr);
	}
`;

const ContactInfo = styled.div`
	display: inline-flex;
	flex-direction: column;
	padding: 1.5rem 1rem 0 1rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		padding: 0;
		margin: 2rem 0 2.6875rem 0;
	}
`;

const StyledText = styled(Text)`
	&& {
		display: block;
		color: ${colors.neutralDeepest};
	}
`;

const Title = styled(StyledText)`
	&& {
		margin-bottom: 1rem;
		line-height: 1.15;
	}
`;

const Description = styled(StyledText)`
	&& {
		margin-bottom: 1rem;
		line-height: 1.3;
		text-align: inherit;

		@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
			margin-bottom: 1rem;
		}
	}
`;

const Schedule = styled(StyledText)`
	&& {
		margin-bottom: 1rem;
		font-weight: ${getFontWeight('heavy')};
	}
`;

const Buttons = styled.div`
	row-gap: 0.75rem;
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		flex-direction: row;
		gap: 1rem;
	}
`;

const Models = styled.img`
	justify-self: center;
	max-height: 11.875rem;
	max-width: 100%;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		max-height: 100%;
		justify-self: end;
		align-self: end;
	}
`;

interface ButtonConfig {
	icon: IconSrc;
	text: string;
	onClick?: () => void;
}

interface CallButtonConfig extends ButtonConfig {
	href: string;
}

type ChatButtonConfig = ButtonConfig;

interface Props {
	title: string;
	description: string;
	schedule1: string;
	schedule2: string;
	callButton: CallButtonConfig;
	chatButton: ChatButtonConfig;
	imageUrl: string;
	imageAlt: string;
}

export const ExpertContactCard = ({
	title,
	description,
	schedule1,
	schedule2,
	callButton,
	chatButton,
	imageUrl,
	imageAlt,
}: Props) => {
	return (
		<Container data-cy="expertContactCard">
			<ContactInfo>
				<Title weight="feather" size={5}>
					{title}
				</Title>
				<Description weight="feather">{description}</Description>
				<Schedule>
					{schedule1} <br /> {schedule2}
				</Schedule>
				<Buttons>
					{callButton ? (
						<Link
							variant="button"
							btnVariantProps={{ btnVariant: 'default', btnSize: 'small' }}
							iconSrc={callButton.icon}
							href={callButton.href}
							onClick={callButton.onClick}
							data-testid="call-btn"
						>
							{callButton.text}
						</Link>
					) : null}
					{chatButton ? (
						<Button
							iconSrc={chatButton.icon}
							onClick={chatButton.onClick}
							color="secondary"
							size="small"
							variant="outline"
							data-testid="chat-btn"
						>
							{chatButton.text}
						</Button>
					) : null}
				</Buttons>
			</ContactInfo>
			<Models src={imageUrl} alt={imageAlt} />
		</Container>
	);
};
