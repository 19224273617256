import { Action, ActionTypes } from '@home-diy-toolbox/web/contentful';
import { linkEnhancer } from '../../../utils';
import { getChatClient } from '../../../features/chat/components';

type Act = (action: Action) => void;

export const getAct = (action: Action) => {
	if (action.action !== ActionTypes.OPEN_CHAT) {
		return () => urlAct(action);
	}

	return () => chatAct(action);
};

export const openUrl = (url: string) => {
	window.open(linkEnhancer(url), '_blank', 'noreferrer,noopener');
};

const chatAct: Act = () => {
	getChatClient().openMessagingOverlay();
};

const urlAct: Act = (action: Action) => {
	openUrl(action.url);
};
