import React from 'react';
import styled from 'styled-components';
import { colors } from '@soluto-private/mx-asurion-ui-react';

const Container = styled.div`
	display: flex;
	height: 100%;
	overflow-y: auto;
	background-color: ${colors.neutralBrightest};
	justify-content: center;
	align-items: center;
`;

const headerHeight = '72px';
const Image = styled.img`
	max-width: 60%;
	position: relative;
	top: -${headerHeight};
`;

interface Props {
	imageUrl: string;
	imageAlt: string;
}

export const ImageBanner = ({ imageUrl, imageAlt }: Props) => {
	return (
		<Container>
			<Image src={imageUrl} alt={imageAlt} />
		</Container>
	);
};
