export const contactExpertCardQuery = `query ContactExpertCard($contactExpertCard: String!, $partnerTag: String!) {
    contactExpertCardCollection (
        limit: 1
        where: {entryName: $contactExpertCard, contentfulMetadata: {tags: {id_contains_all: [$partnerTag]}}}
    ) {
        items{
            entryName
            coverImage {
                asset {
                    url
                }
                alternativeText
            }
            mainHeading
            cardHeading {
                json,
            }
            cardDescription
            popupTitle
            cardCtAsCollection {
                items
                {
                    entryName
                    copy
                    url
                    telephoneNumber
                    action
                }
            }
            openCardButtonsCollection {
                items {
                    entryName
                    copy
                }
            }
        }
    } 
}`;
