import React, { ComponentType } from 'react';
import { useAnalytics } from 'react-shisell';
import { withExtras } from 'shisell/extenders';
import { AnalyticExtras, AnalyticEventType } from '../../types';

const hasAnalyticsExtras = (analyticExtras?: AnalyticExtras) => {
	if (!analyticExtras) return false;

	// Fastest way to check for an empty object
	// First iteration triggers return, indicating that there was a obj key
	for (const i in analyticExtras) return true;

	return false;
};

type WithAnalyticsProps<
	TComponent extends React.HTMLAttributes<THtmlElement>,
	THtmlElement
> = TComponent & AnalyticsElementProps;

export interface AnalyticsElementProps {
	actionId?: string;
	analyticExtras?: AnalyticExtras;
}

/** Will augment the provided component with analytics capabilities.
 * Adds two props: 'actionId' and 'analyticExtras'
 *
 * @param Component
 * 	Component to augment with analytics
 * @returns
 * 	The provided component, augmented with analytics
 */
export const withAnalytics = <
	TComponent extends React.HTMLAttributes<THtmlElement>,
	THtmlElement
>(
	Component: ComponentType<TComponent>
) => {
	return ({
		actionId,
		analyticExtras,
		onClick,
		children,
		...props
	}: WithAnalyticsProps<TComponent, THtmlElement>) => {
		const { dispatcher } = useAnalytics();
		const enhancedDispatcher = dispatcher.extend(
			withExtras({ ActionId: actionId, ...analyticExtras })
		);

		if (!actionId && hasAnalyticsExtras(analyticExtras)) {
			console.error(
				'withAnalytics was provided with analyticExtras but no actionId. No analytics will be emitted.'
			);
		}

		const handleClick = (e: React.MouseEvent<THtmlElement>) => {
			onClick && onClick(e);

			if (actionId) {
				enhancedDispatcher.dispatch(AnalyticEventType.CLICK);
			}
		};

		return (
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore Ignoring because https://github.com/microsoft/TypeScript/issues/35858#issuecomment-573909154
			<Component {...props} onClick={handleClick}>
				{children}
			</Component>
		);
	};
};
