import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	#fabio-button, button[title="ChatBubbleToggle"], #re-sdk-root-container{ 
			display: none;
	}
`;

export const ChatButtonHider = () => {
	return <GlobalStyle />;
};
