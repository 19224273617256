import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import DownloadArrow from '../../../../../assets/download-arrow.svg';
import { useTranslation } from 'react-i18next';

const DownloadUploadSpeedsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	justify-content: space-evenly;
	min-height: 90px;
	width: 300px;
	flex-wrap: wrap;
`;

const DownloadSection = styled.div``;

const Heading = styled.div`
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};
	text-align: left;
	white-space: nowrap;
	font-size: 14px;
`;
//todo: fix weird height changes here
const SpeedNotAvailable = styled.div`
	display: flex;
	margin-top: 5px;
	margin-left: 12px;
	margin-right: 4px;
	font-size: 32px;
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};
`;

const SpeedAvailable = styled.div`
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};
	font-size: 32px;
	line-height: 16px;
	text-align: center;
	margin-top: 5px;
	margin-left: 6px;
`;

const ArrowIcon = styled.img<{ started: boolean }>`
	opacity: ${({ started }) => (started ? '1' : '.2')};
`;

const SpeedResult = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 12px;
	justify-content: center;
	align-items: center;
`;

const Metric = styled.div`
	margin-top: 10px;
	margin-left: 6px;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: ${({ theme: { signalTest } }) => signalTest.fontColorMain};
`;

type DownloadUploadSpeedsProps = {
	status: string;
	downloadSpeed: number;
};

const DownloadUploadSpeeds = ({ status, downloadSpeed }: DownloadUploadSpeedsProps) => {
	const { t } = useTranslation();
	const [hasDownloadStarted, setHasDownloadStarted] = useState(false);
	const {
		app: {
			signalDialConfig: {
				fields: { downloadSpeedLabel, speedUnit },
			},
		},
	} = useCheckupContentContext();
	useEffect(() => {
		if (status === SpeedTestStatus.inProgressDownload) {
			setHasDownloadStarted(true);
		}
	}, [status]);

	const downloadCompleted =
		(hasDownloadStarted && status !== SpeedTestStatus.inProgressDownload) ||
		status === SpeedTestStatus.completed;

	return (
		<DownloadUploadSpeedsContainer>
			<DownloadSection>
				<Heading>{downloadSpeedLabel}</Heading>
				<SpeedResult>
					<ArrowIcon
						started={hasDownloadStarted}
						src={DownloadArrow}
						alt="download arrow"
					/>
					{!downloadCompleted ? (
						<SpeedNotAvailable aria-label={t('downloadSpeedTestInProgress')}>
							-
						</SpeedNotAvailable>
					) : (
						<SpeedAvailable
							aria-label={`${t('downloadSpeedIs')} ${downloadSpeed} ${t('mps')}`}
						>
							{downloadSpeed}
						</SpeedAvailable>
					)}
					<Metric>{speedUnit}</Metric>
				</SpeedResult>
			</DownloadSection>
		</DownloadUploadSpeedsContainer>
	);
};

export default DownloadUploadSpeeds;
