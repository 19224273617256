import { ArticleCard, useArticleContentContext } from '@home-diy-toolbox/web/contentful';
import { ArticleItem } from '../../../../components';
import { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
	BatteryFilterPathNames,
	BatteryPages,
	CheckupCategoryType,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { useFlow } from '@home-diy-toolbox/apps/device-care/flow-provider';
import { Text, breakpoints, Link } from '@soluto-private/mx-asurion-ui-react';
import { useHistory } from 'react-router-dom';
import {
	AnalyticEventType,
	useDispatchEvent,
	withAnalytics,
} from '@home-diy-toolbox/web/analytics';
import { checkIsMobile } from '../utils';
import { DynamicGrid } from '@soluto-private/mx-asurion-ui-react';
import { BatteryResultV3Ids } from '../answers/BatteryResultV3';

const Articles = styled.div`
	margin: 1rem 0;
	display: grid;
	gap: 1rem;
	grid-template-columns: 1fr;
	grid-template-areas:
		'title'
		'articles'
		'link';
`;

const Article = styled.div`
	grid-area: articles;
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;

	${breakpoints.md} {
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			'title title'
			'articles articles'
			'link link';
	}
`;

const ShowMoreLink = styled.div`
	grid-area: link;
	text-align: center;

	${breakpoints.lg} {
		margin: 1rem 0;
	}
`;

const TipsHeader = styled(Text)`
	grid-area: title;
`;

const ShowMore = withAnalytics(Link);

interface Props {
	relatedGuidesTitle: string;
	relatedGuidesLink: string;
	answerTags: Set<string>;
	resultId: BatteryResultV3Ids;
}

export const RelatedArticles: FC<Props> = ({
	relatedGuidesTitle,
	relatedGuidesLink,
	answerTags,
	resultId,
}) => {
	const {
		flow: { sessionData },
	} = useFlow();
	const { deviceOS } = sessionData;
	const [articleCards, setArticleCards] = useState<ArticleCard[]>([]);
	const { getTopArticles, getTags } = useArticleContentContext();
	const history = useHistory();
	const isMobile = checkIsMobile();
	const { dispatchEvent } = useDispatchEvent();

	useEffect(() => {
		const requiredTags = getTags([], CheckupCategoryType.BATTERY_CHECKUP);
		requiredTags.push('v3');
		const optionalTags = Array.from(answerTags);
		const excludedTags = ['old'];

		getTopArticles(requiredTags, optionalTags, deviceOS, excludedTags).then(
			(articleCards) => {
				setArticleCards(articleCards);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceOS]);

	// dont' show the title and links if there's no article
	if (!articleCards.length) {
		return null;
	}

	const getArticleContainer = (children: ReactNode) => {
		if (isMobile) {
			return (
				<DynamicGrid
					gapSize={2}
					minWidth={14.938}
					scroll={{
						hideScrollbar: false,
						scrollHint: 0.5,
						scrollSnapAlign: 'start',
					}}
				>
					{children}
				</DynamicGrid>
			);
		}

		return <Article>{children}</Article>;
	};

	return (
		<Articles>
			<TipsHeader weight="heavy" size={4}>
				{relatedGuidesTitle}
			</TipsHeader>
			{getArticleContainer(
				articleCards.map(({ id, title, contentCard }) => (
					<ArticleItem
						key={id}
						id={id}
						title={title}
						subtitle={contentCard.fields.subtitle}
						imageUrl={contentCard.fields.coverImage?.fields.file?.url}
						externalLink={contentCard.fields.externalLink}
						onClick={() => {
							dispatchEvent(AnalyticEventType.CLICK, {
								ActionId: 'articleCard',
								ArticleCardId: id,
								BatteryCheckupResult: resultId,
								PageId: 'resultPage',
							});
						}}
					/>
				))
			)}
			<ShowMoreLink>
				<ShowMore
					tabIndex={0}
					data-cy="allRelatedGuidesLink"
					color="secondary"
					onClick={() => {
						history.push(BatteryFilterPathNames[BatteryPages.ALL], {
							from: history.location.pathname,
						});
					}}
					actionId="showMoreClick"
					analyticExtras={{
						PageId: 'resultPage',
						BatteryCheckupResult: resultId,
					}}
				>
					{relatedGuidesLink}
				</ShowMore>
			</ShowMoreLink>
		</Articles>
	);
};
