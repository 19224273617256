import { BaseHttpApiClient } from './BaseHttpApiClient';

export interface AvailableSlots {
	slots: number;
	start: string;
	end: string;
	appointment_at_utc: number;
}

export interface NextAvailableStoreSlot {
	date: string;
	at: AvailableSlots[];
}

export interface Store {
	id: number;
	name: string;
	key: string;
	distance: number;
	time_zone: string;
	open_date: string;
	address_1: string;
	address_2: string;
	hours: {
		human: [{ days: string; hours: string }];
		all: [{ open: string; close: string }];
	};
	city: string;
	state: string;
	postal_code: string;
	country_code: string;
	phone: string;
	email: string;
	latitude: string;
	longitude: string;
	next_available_appointment?: NextAvailableStoreSlot | [];
}

export interface StoresResponse {
	error: boolean;
	distance_km: boolean;
	stores: Store[];
}
export class UbifApiClient extends BaseHttpApiClient {
	public async getStoresByZipCode(zipCode: string) {
		return this.get<StoresResponse>(
			`${
				this.domain
			}/locate-stores?address=US,${zipCode}&max_distance=60&distance_km=false&num_stores=6&start_date=${
				new Date().toISOString().split('T')[0]
			}`
		);
	}
}
