export const checkREChatSdkReady = () => {
	try {
		// `__CHAT_SDK` is the key where the Remote Chat SDK pointed
		if (window.__CHAT_SDK) {
			return true;
		}
		return false;
	} catch (error) {
		return false;
	}
};
