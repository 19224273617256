import React, { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import { NumericFormat } from 'react-number-format';
import type { Unit } from './types';

const TEST_ID_PREFIX = 'DurationPicker';

const Input = styled(NumericFormat)`
	height: 48px;
	border: 1px solid #bfbfbf;
	border-radius: 4px;
	font-size: 16px;
	line-height: 20px;
	text-align: left;
	padding-left: 14px;
	box-sizing: border-box;

	&:focus-visible {
		outline-color: #000000;
	}

	&:disabled {
		background-color: ${colors.disabledBackground};
		color: ${colors.disabledText};
		border-color: ${colors.disabledText};
	}
`;

interface UnitPickerProps {
	value: number | undefined;
	unit: Unit;
	isValidValue?: (value: number | undefined) => boolean;
	onValueChange: (value: number | undefined, key: Unit) => void;
}

export const UnitPicker = ({
	value,
	unit,
	isValidValue,
	onKeyDown,
	onValueChange,
	onBlur,
	disabled,
}: UnitPickerProps & InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Input
			placeholder={t(unit)}
			allowNegative={false}
			allowLeadingZeros={false}
			decimalScale={0}
			isAllowed={({ floatValue }) => !isValidValue || isValidValue(floatValue)}
			value={value}
			onValueChange={({ floatValue }) => onValueChange(floatValue, unit)}
			onKeyDown={onKeyDown}
			data-cy={`${TEST_ID_PREFIX}_${unit}`}
			disabled={disabled}
			onBlur={(e) => {
				onBlur?.(e);
			}}
		/>
	);
};
