import { BaseHttpApiClient } from '../BaseHttpApiClient';
import {
	SurveyPayload,
	CheckupDiagnosticResultsPayload,
	StartFlowRequest,
	StartFlowPayload,
	StartFlowResponse,
	GetSubscriptionsRequest,
	GetSubscriptionsResponse,
	AndroidBatteryResults,
	AndroidBatteryResultsPayload,
	FeatureRequestPayload,
} from './types';
import { VariableValue } from '@aws-sdk/client-evidently';

export class DeviceCareApiClient extends BaseHttpApiClient {
	public async startFlow({
		partner,
		userBrowserId,
		subscriberId,
		assetId,
	}: StartFlowRequest): Promise<StartFlowResponse | void> {
		const payload: StartFlowPayload = { partner, subscriberId };

		const startFlowPathPrefix = subscriberId
			? assetId
				? `assets/${assetId}/`
				: `segmented/`
			: '';
		try {
			const response = await this.post<StartFlowPayload, StartFlowResponse>(
				`${this.domain}/user/${userBrowserId}/${startFlowPathPrefix}flow/start`,
				payload
			);
			return response.status === 200 ? response.body : null;
		} catch (err) {
			console.error(err);
			// TODO: pass error to analytics provider
			return;
		}
	}

	public async getSubscriptions({
		partner,
		asurionId,
	}: GetSubscriptionsRequest): Promise<GetSubscriptionsResponse> {
		try {
			const response = await this.get<GetSubscriptionsResponse>(
				`${this.domain}/mobility-subscriptions/${asurionId}?partner=${partner}`
			);

			if (response.status !== 200) {
				throw new Error(`Request failed, status: ${response.status}`);
			}
			return response.body;
		} catch (err) {
			console.error(err);
		}
	}

	public async saveSurvey(payload: SurveyPayload) {
		return this.post<SurveyPayload, void>(`${this.domain}/survey`, payload);
	}

	public async saveCheckupDiagnosticResults(payload: CheckupDiagnosticResultsPayload) {
		return this.post<CheckupDiagnosticResultsPayload, void>(
			`${this.domain}/test-result`,
			payload
		);
	}

	public async getAndroidBatteryResults(
		payload: AndroidBatteryResultsPayload
	): Promise<AndroidBatteryResults> {
		try {
			const response = await this.post<
				AndroidBatteryResultsPayload,
				AndroidBatteryResults
			>(`${this.domain}/battery/android`, payload);

			if (response.status !== 200) {
				throw new Error(`Request failed, status: ${response.status}`);
			}
			return response.body;
		} catch (err) {
			console.error(err);
			return { isHealthy: false, estimatedMaxCapacity: 0 };
		}
	}

	public async getFeatureValue<T>(
		project: string,
		feature: string,
		payload: FeatureRequestPayload
	): Promise<T> {
		try {
			const response = await this.post<FeatureRequestPayload, VariableValue>(
				`${this.domain}/features/${project}/${feature}`,
				payload
			);
			return Object.values(await response.body)[0];
		} catch (err) {
			console.error(err);
			throw err;
		}
	}
}
