import styled, { ThemeContext } from 'styled-components';
import {
	Banner,
	FeedbackChoicesTypes,
	FeedbackForm,
	ResultCard as ResultCardType,
	RichText,
	Button,
	BatteryResultIds,
	useContactExpertCard,
} from '@home-diy-toolbox/web/contentful';
import {
	Text,
	getFontSize,
	getFontWeight,
	Link,
} from '@soluto-private/mx-asurion-ui-react';
import { Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import {
	FeedbackComponentTypes,
	FeedbackTypes,
	FeedbackForm as FeedbackFormComponent,
	ResultSectionDivider,
	OtherServices,
} from '../../../../components';
import { useFlow } from '../../../../providers';
import { ResultCard } from './ResultCard';
import { ActionButton } from '../../../../components/atoms/ActionButton';
import { RelatedGuides } from './RelatedGuides';
import { AnalyticEventType, useDispatchEvent } from '@home-diy-toolbox/web/analytics';
import { PageCard } from '../components/PageCard';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from '@home-diy-toolbox/web/utils';
import { ContactExpertsCard } from './ContactExpertsCard';
import { ContactExpertsModal } from '../../../../components/ContactExpertCard/ContactExpertsModal';
import { ContactExpertsDrawer } from '../../../../components/ContactExpertCard/ContactExpertsDrawer';
import { getChatClient } from '../../../../features/chat/components';
import { ResultButtonRenderer } from '../../../../components/atoms/ActionButton/ResultButtonRenderer';

const Result = styled(Text)`
	&& {
		display: block;
		font-size: ${getFontSize(6)};
		font-weight: ${getFontWeight('feather')};
		margin: 0;
		margin-bottom: 0.5rem;
	}
`;

const Description = styled(Text)`
	&& {
		display: block;
		font-size: ${getFontSize(4)};
		font-weight: ${getFontWeight('feather')};
		margin-bottom: 2.25rem;
	}
`;

const DescriptionLink = styled(Link)`
	&& {
		font-size: ${getFontSize(4)};
		font-weight: ${getFontWeight('feather')};
	}
`;

const ResultCardContainer = styled.div`
	margin-bottom: 2.25rem;
`;

const RelatedGuidesContainer = styled.div`
	margin-bottom: 3.25rem;
`;

const CardContainer = styled.div`
	display: block;
	margin-bottom: 3.25rem;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.desktop}) {
		display: none;
	}
`;

const FeedbackContainer = styled.div`
	margin-bottom: 3.25rem;
`;

interface Props {
	resultId: BatteryResultIds;
	resultTitle: Document;
	resultDescription: Document;
	resultCard: ResultCardType;
	pageCard: Banner;
	feedbackTitle: string;
	checkupPositive: FeedbackForm;
	checkupNegative: FeedbackForm;
	otherServicesTitle: string;
	relatedGuidesTitle: string;
	relatedGuidesLink: string;
	answerTags: Set<string>;
}

const buttonRenderer = new ResultButtonRenderer();

export const ResultContent = ({
	resultId,
	resultTitle,
	resultDescription,
	resultCard,
	relatedGuidesLink,
	relatedGuidesTitle,
	feedbackTitle,
	checkupPositive,
	checkupNegative,
	pageCard,
	otherServicesTitle,
	answerTags,
}: Props) => {
	const {
		flow: { flowId, sessionData },
	} = useFlow();
	const { deductibles } = sessionData;
	const { dispatchEvent } = useDispatchEvent();
	const [drawerIsOpen, setDrawerIsOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const theme = useContext(ThemeContext);
	const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop})`);

	const contactExpertCardData = useContactExpertCard('talkToAnExpert');

	const checkButtonAction = (button: Button) => {
		if (button.action === 'openChat') {
			getChatClient().openMessagingOverlay();
		} else if (button.action === 'phoneNumber') {
			window.location.href = 'tel:' + button.telephoneNumber;
		}
	};

	useEffect(() => {
		if (contactExpertCardData && resultId === BatteryResultIds.GOOD_BATTERY) {
			dispatchEvent(AnalyticEventType.DEBUG, {
				Result: resultId,
				CheckupType: Checkups.BATTERY,
				ContactExpertCard: contactExpertCardData,
			});
		}
	}, [contactExpertCardData, dispatchEvent, resultId]);

	return (
		<>
			<RichText
				document={resultTitle}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => <Result as="h1">{children}</Result>,
					},
				}}
			/>
			<RichText
				document={resultDescription}
				renderOptionsOverride={{
					renderNode: {
						[BLOCKS.PARAGRAPH]: (_, children) => <Description>{children}</Description>,
						[INLINES.HYPERLINK]: ({ data }, children) => (
							<DescriptionLink href={data.uri}>{children}</DescriptionLink>
						),
					},
				}}
			/>

			{resultCard ? (
				<ResultCardContainer>
					<ResultCard
						title={resultCard.cardHeading}
						description={resultCard.cardDescription}
						tags={[resultCard.badgeName]}
						imageUrl={resultCard.coverImage.asset.url}
						imageAlt={resultCard.coverImage.alternativeText}
						deductible={deductibles?.[resultCard.deductible]}
						button={
							<ActionButton
								button={resultCard.cardCta}
								buttonRenderer={buttonRenderer}
								onClick={() => {
									dispatchEvent(AnalyticEventType.CLICK, {
										ActionId: 'batteryResultsCtaButtonLink',
									});
								}}
							/>
						}
					/>
				</ResultCardContainer>
			) : null}

			{resultId === BatteryResultIds.GOOD_BATTERY && contactExpertCardData ? (
				<ContactExpertsCard
					mainHeading={contactExpertCardData.mainHeading}
					cardHeading={contactExpertCardData.cardHeading}
					cardSubheading={contactExpertCardData.cardDescription}
					imageUrl={contactExpertCardData.coverImage.asset.url}
					imageAlt={contactExpertCardData.coverImage.asset.url}
					isDesktop={isDesktop}
					onClickBanner={() => {
						if (contactExpertCardData.cardCtAs.length > 1) {
							isDesktop ? setModalIsOpen(true) : setDrawerIsOpen(true);
						} else {
							checkButtonAction(contactExpertCardData.cardCtAs[0]);
						}

						dispatchEvent(AnalyticEventType.CLICK, {
							ActionId: 'batteryResultsLiveExpertCard',
						});
					}}
				/>
			) : null}

			<RelatedGuidesContainer>
				<RelatedGuides
					relatedGuidesLink={relatedGuidesLink}
					relatedGuidesTitle={relatedGuidesTitle}
					answerTags={answerTags}
				/>
			</RelatedGuidesContainer>
			<CardContainer>
				<PageCard banner={pageCard} />
			</CardContainer>
			<FeedbackContainer>
				<ResultSectionDivider />
				<FeedbackFormComponent
					variant={FeedbackComponentTypes.STARS}
					type={FeedbackTypes.SESSION}
					feedbackTitle={feedbackTitle}
					positiveForm={{
						commentsPlaceholder: checkupPositive.commentsPlaceholder,
						completeMessageCopy: checkupPositive.completeMessageCopy,
						feedbackChoices: checkupPositive.feedbackChoices.map((choice) => ({
							id: choice.entryName,
							label: choice.copy,
						})),
						feedbackChoicesType: FeedbackChoicesTypes.NEW,
						formId: checkupPositive.entryName,
						header: checkupPositive.header,
						subheader: checkupPositive.subheader,
						submitButtonCopy: checkupPositive.submitCta.copy,
					}}
					negativeForm={{
						commentsPlaceholder: checkupNegative.commentsPlaceholder,
						completeMessageCopy: checkupNegative.completeMessageCopy,
						feedbackChoices: checkupNegative.feedbackChoices.map((choice) => ({
							id: choice.entryName,
							label: choice.copy,
						})),
						feedbackChoicesType: FeedbackChoicesTypes.NEW,
						formId: checkupNegative.entryName,
						header: checkupNegative.header,
						subheader: checkupNegative.subheader,
						submitButtonCopy: checkupNegative.submitCta.copy,
					}}
					flowId={flowId}
					analyticsExtras={{ CheckupType: Checkups.BATTERY }}
				/>
				<ResultSectionDivider />
			</FeedbackContainer>
			<OtherServices
				xScrollable={true}
				title={otherServicesTitle}
				checkups={[
					Checkups.SIGNAL,
					Checkups.SPEED,
					Checkups.DEVICE_PERFORMANCE,
					Checkups.DEVICE_PERFORMANCE_YEAR_2,
				]}
			/>

			{resultId === BatteryResultIds.GOOD_BATTERY && contactExpertCardData ? (
				<>
					<ContactExpertsModal
						isOpen={modalIsOpen}
						title={contactExpertCardData.popupTitle}
						cardCtas={contactExpertCardData.cardCtAs}
						onCloseClick={() => {
							setModalIsOpen(false);
						}}
					/>

					<ContactExpertsDrawer
						title={contactExpertCardData.popupTitle}
						cardCtas={contactExpertCardData.cardCtAs}
						isOpen={drawerIsOpen}
						onOpenUpdate={() => {
							setDrawerIsOpen(false);
						}}
					/>
				</>
			) : null}
		</>
	);
};
