export const AppName = 'device-care';

export enum DeviceOS {
	IOS = 'ios',
	ANDROID = 'android',
}

export enum DeviceModel {
	IPHONE_GENERIC = 'Generic iPhone',
	ANDROID_GENERIC = 'Generic Android',
	SAMSUNG_GENERIC = 'Generic Samsung',
}
