import React from 'react';
import styled from 'styled-components';
import {
	ImageStyles,
	StyledImage as StyledImageType,
} from '@home-diy-toolbox/web/contentful';

const CoverImage = styled.img`
	&& {
		width: 100%;
		height: auto;
		display: block;
	}
`;

type Props = Pick<StyledImageType, 'image' | 'style'>;

const StyledImage = ({ image, style }: Props) => {
	switch (style) {
		case ImageStyles.COVER_FIT:
			return <CoverImage src={image.fields.file.url} alt={image.fields.description} />;
		default: {
			const exhaustiveCheck: never = style;
			throw new Error(`${exhaustiveCheck} StyledImage variant is unknown!`);
		}
	}
};

export default StyledImage;
