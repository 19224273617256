import { FunctionComponent, Suspense, useEffect, useState } from 'react';
import {
	useCheckupContentContext,
	useLayoutContentContext,
} from '@home-diy-toolbox/web/contentful';
import { environment } from '../environments/environment';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '../routes';
import {
	QueryParameterKeys,
	Checkups,
	Partner,
	Partners,
} from '@home-diy-toolbox/web/common-types/refresh-base';
import { initializeWixiSdk } from '@home-diy-toolbox/web/network-test';
import styled from 'styled-components';
import { LoadingPage } from '../pages';
import { useFlow, useBreadcrumb } from '../providers';
import { asurionIdApiClient, deviceCareApiClient } from '../clients';
import { getOSFromMake, getModelFromMake } from '../utils/device-utils';
import {
	getUserBrowserId,
	useScrollToTop,
	getAllQueryParams,
	clearAllQueryParams,
	getCookieValue,
} from '@home-diy-toolbox/web/utils';
import { FlowSessionData } from '../providers/flows/models';
import { useTranslation } from 'react-i18next';
import { useHeader } from '../features/header';
import { HeaderBreadcrumbs } from '../components/HeaderBreadcrumbs';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { defaultOsList } from './defaultOsList';
import {
	getUser,
	getAuthenticationState,
	AuthenticationState,
	User,
} from '@soluto-private/mx-app-authentication';
import { GetSubscriptionsResponse, Asset } from '@home-diy-toolbox/shared/clients';
import { useFeatureFlags } from '../providers/featureFlag';
import {
	AnalyticEventType,
	useAnalytics,
	useDispatchEvent,
	UserBrowser,
} from '@home-diy-toolbox/web/analytics';
import {
	simplrChatAI,
	useHideChatSdk,
	remoteExpertChat,
	setIsSimplrAI2024Supported,
	SUPPORTED_PATHNAME_FOR_SIMPLR_LIST,
} from '../features/chat/components';

const StyledApp = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 24px auto 94px auto;

	min-width: ${({ theme }) => theme.minWidth};
	> div:not(.widecontainer) {
		max-width: ${({ theme }) => theme.appMaxWidth};
	}

	& > .widecontainer {
		max-width: 75rem;
		padding: 0 1rem;
		box-sizing: border-box;

		@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.tablet}) {
			padding: 0 1.5rem;
		}

		${breakpoints.lg} {
			padding: 0 1rem;
		}

		${breakpoints.xxl} {
			max-width: 90rem;
		}
	}
`;

type AppProps = {
	partner: string;
	locale: string; // or language
};

const fetchStartFlow = async (sessionData) => {
	let updatedSessionData = { ...sessionData };
	const { partner, subscriberId, assetId } = sessionData;
	const user = await deviceCareApiClient.startFlow({
		partner,
		userBrowserId: getUserBrowserId(),
		subscriberId,
		assetId,
	});

	if (user) {
		const { asset, eligibility, deductibles } = user;
		if (subscriberId && asset) {
			updatedSessionData = {
				...updatedSessionData,
				deviceOS: asset?.os?.toLowerCase(),
				deviceMake: asset.make,
				deviceModel: asset.model,
				purchaseDate: asset.purchaseDate,
				deviceType: asset.type,
				eligibility,
				deductibles,
			};
			if (asset.make && (!asset.os || asset.os.toLowerCase() === 'unknown')) {
				updatedSessionData.deviceOS = getOSFromMake(asset.make);
			}
			if (asset.make && !asset.model) {
				updatedSessionData.deviceModel = getModelFromMake(asset.make);
			}
		}
	}

	if (!updatedSessionData.deviceOS) {
		const userBrowser = UserBrowser.get();
		const userOS = defaultOsList.find((value) => {
			return value.isDefaultOs(
				userBrowser?.Os?.toLowerCase(),
				userBrowser?.Vendor?.toLowerCase()
			);
		});

		if (userOS) {
			const { selectedOS } = userOS;
			const { deviceMake, deviceModel } = updatedSessionData;
			updatedSessionData = {
				...updatedSessionData,
				deviceOS: selectedOS.deviceOS,
				deviceMake: deviceMake ? deviceMake : selectedOS.deviceMake,
				deviceModel: deviceModel ? deviceModel : selectedOS.deviceModel,
			};
		}
	}

	return updatedSessionData;
};

const useAsurionIdAccessToken = () => {
	const [accessToken, setAccessToken] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const { dispatchEvent } = useDispatchEvent();

	useEffect(() => {
		(async () => {
			setLoading(true);
			const uri = new URL(window.location.href);
			const authCode = uri.searchParams.get('code');
			const EXCLUDED_SEARCH_PARAMS = ['code', 'state', 'scope'];

			EXCLUDED_SEARCH_PARAMS.forEach((param) => {
				uri.searchParams.delete(param);
			});

			if (authCode) {
				dispatchEvent(AnalyticEventType.DEBUG, {
					Location: uri.toString(),
					ActionId: 'InitiateRedeemAccessToken',
				});

				const redeemAcessTokenRes = await asurionIdApiClient.redeemAcessToken(
					authCode,
					environment.asurionId.clientId,
					uri.toString() // e.g.: https://my.asurion.com/device-care?mxclient=att
				);

				if (redeemAcessTokenRes?.access_token) {
					setAccessToken(redeemAcessTokenRes.access_token);

					dispatchEvent(AnalyticEventType.DEBUG, {
						Location: uri.toString(),
						ActionId: 'RedeemAccessToken',
					});
				}
			}
			setLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { accessToken, loading };
};

export const App: FunctionComponent<AppProps> = ({ partner, locale }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [chatLoading, setChatLoading] = useState<boolean>(false);
	const {
		isLoading: isFeatureFlagsLoading,
		features: { redesign2022, batteryCheckupRedesign2024, simplrAI2024 },
	} = useFeatureFlags();

	const { app } = useCheckupContentContext();
	const partnerApp = useLayoutContentContext();

	const history = useHistory();
	const { updateFlowSession, setCurrentQuestionIndex, flow } = useFlow();
	const analytics = useAnalytics();
	const { t } = useTranslation();
	const { currentSteps } = useBreadcrumb();
	const { updateHeader, initHeader } = useHeader();
	const isRedesign2022 = redesign2022 || getCookieValue('redesign_2022');
	const location = useLocation();
	const { accessToken, loading: isAccessTokenLoading } = useAsurionIdAccessToken();
	const { dispatchEvent } = useDispatchEvent();

	useEffect(() => {
		Object.values(Checkups).forEach((checkup) => {
			const newHeader = `${checkup}Header`;
			if (history.location.pathname.includes(`/${checkup}`)) {
				updateHeader(
					{
						title: t(newHeader),
					},
					undefined,
					() => setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex - 1)
				);
			} else {
				updateHeader(undefined, undefined, () =>
					setCurrentQuestionIndex(flow.sessionData.currentQuestionIndex - 1)
				);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		t,
		history.location.pathname,
		setCurrentQuestionIndex,
		flow.sessionData.currentQuestionIndex,
	]);

	useEffect(() => {
		initHeader(); // Redundant call to avoid race condition in mx-header
		initializeWixiSdk(environment.wixiPartner[partner]);
		initializeAppInfo();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useHideChatSdk();

	useEffect(() => {
		if (!isFeatureFlagsLoading) {
			setIsSimplrAI2024Supported(simplrAI2024 as boolean);
		}
	}, [simplrAI2024, isFeatureFlagsLoading]);

	useEffect(() => {
		if (
			!simplrChatAI.isLoaded &&
			!isFeatureFlagsLoading &&
			simplrAI2024 &&
			SUPPORTED_PATHNAME_FOR_SIMPLR_LIST.includes(location.pathname) &&
			flow.sessionData.deviceOS
		) {
			simplrChatAI.init(partner, analytics);
		}
	}, [
		partner,
		location.pathname,
		flow.sessionData.deviceOS,
		analytics,
		simplrAI2024,
		isFeatureFlagsLoading,
	]);

	useEffect(() => {
		if (app?.unauthChatSdk && !isFeatureFlagsLoading && !isAccessTokenLoading) {
			const showChat = async () => {
				setChatLoading(true);
				try {
					const timeChatInitiated = Date.now();

					const chatConfig = {
						partner,
						language: locale,
						messagingTopic:
							batteryCheckupRedesign2024 && partner === Partners.USCELLULAR
								? 'DEVICE-CARE' // for uscellular only
								: 'GENERAL-CONVERSATION',
					};

					if (accessToken) {
						chatConfig['token'] = accessToken;
					}

					await remoteExpertChat.init(chatConfig, analytics, () => {
						setChatLoading(false);

						dispatchEvent(AnalyticEventType.DEBUG, {
							ChatActionId: 'chatClientInit',
							TimeTook: Date.now() - timeChatInitiated,
						});
					});
				} catch (error) {
					console.warn('Failed to initialize SDK: ', error);
				}
				setChatLoading(false);
			};

			showChat();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		analytics,
		app?.unauthChatSdk,
		partner,
		batteryCheckupRedesign2024,
		isFeatureFlagsLoading,
		accessToken,
		isAccessTokenLoading,
	]);

	const initializeAppInfo = async () => {
		const queryParams = getAllQueryParams();

		const {
			[QueryParameterKeys.CID]: cid,
			[QueryParameterKeys.DEVICE_TYPE]: deviceType,
			[QueryParameterKeys.MAKE]: deviceMake,
			[QueryParameterKeys.MODEL]: deviceModel,
			[QueryParameterKeys.DEVICE_OS]: deviceOS,
			[QueryParameterKeys.ASSET_ID]: assetId,
			[QueryParameterKeys.PRODUCT_ID]: productId,
			[QueryParameterKeys.REDIRECT_PATH]: redirectpath,
			[QueryParameterKeys.REDIRECT_FROM]: redirectfrom,
			[QueryParameterKeys.UTM]: utm,
		} = queryParams as Record<string, string>;
		let { [QueryParameterKeys.SUBSCRIBER_ID]: subscriberId } = queryParams as Record<
			string,
			string
		>;

		if (!subscriberId) {
			// check for different subscriberId param names
			Object.keys(queryParams).forEach((paramName) => {
				if (
					['subid', 'subscriberid', 'subscriber', 'planid'].includes(
						paramName.toLowerCase()
					) &&
					queryParams[paramName]
				) {
					subscriberId = queryParams[paramName] as string;
				}
			});
		}

		const sessionData: Partial<FlowSessionData> = {
			cid: redirectfrom ? `${redirectfrom}_${cid}` : cid,
			utm: utm || '',
			partner: partner as Partner,
			deviceMake,
			deviceModel,
			deviceOS,
			assetId,
			subscriberId,
			productId,
			redirectpath,
			deviceType,
			deviceSelection: {
				...flow.sessionData.deviceSelection,
			},
		};

		if (deviceMake && (!deviceOS || deviceOS.toLowerCase() === 'unknown')) {
			sessionData.deviceOS = getOSFromMake(deviceMake);
		}

		if (deviceMake && !deviceModel) {
			sessionData.deviceModel = getModelFromMake(deviceMake);
		}

		if (
			flow.sessionData.deviceSelection?.assetsFetched &&
			flow.sessionData.deviceSelection?.selectionRequired &&
			assetId
		) {
			sessionData.deviceSelection.selectionDone = true;
		}

		if (
			!sessionData.deviceOS &&
			!assetId &&
			getAuthenticationState() === AuthenticationState.LoggedIn &&
			!flow.sessionData.deviceSelection?.assetsFetched
		) {
			const user: User = await getUser();
			const asurionId = user.profile.asurion_id;

			const userSubscriptions: GetSubscriptionsResponse =
				await deviceCareApiClient.getSubscriptions({
					partner,
					asurionId,
				});
			const uniqueSubIds = new Set<string>();
			const uniqueAssets: Asset[] = Object.values(
				userSubscriptions.subscriptions.reduce((acc, { subscriptionNumber, assets }) => {
					uniqueSubIds.add(subscriptionNumber);
					assets.forEach((asset) => (acc[asset.id] = asset));
					return acc;
				}, {})
			);

			sessionData.deviceSelection.assetsFetched = true;
			if (uniqueAssets.length > 1) {
				sessionData.deviceSelection.selectionRequired = true;
			} else if (uniqueAssets.length === 1) {
				sessionData.deviceSelection.selectionRequired = false;
				const [subId] = uniqueSubIds;
				sessionData.subscriberId = subId;
				sessionData.assetId = uniqueAssets[0].id;
				const os =
					uniqueAssets[0].os ||
					(uniqueAssets[0].make && getOSFromMake(uniqueAssets[0].make));
				sessionData.deviceOS = os || null;

				const model =
					uniqueAssets[0].model ||
					(uniqueAssets[0].make && getModelFromMake(uniqueAssets[0].make));
				sessionData.deviceModel = model;
			}
		}

		const fetchedStartFlowData = await fetchStartFlow(sessionData);
		updateFlowSession(fetchedStartFlowData);

		if (redirectpath) history.replace(redirectpath);

		setLoading(false);

		clearAllQueryParams(history);
	};

	useScrollToTop();

	// TODO: separate the chat route from app.tsx
	const onChatPage = location.pathname.endsWith('/chat');

	if (
		!app ||
		!partnerApp ||
		loading ||
		isRedesign2022 === null ||
		isFeatureFlagsLoading ||
		(onChatPage && chatLoading)
	) {
		return <LoadingPage />;
	}

	return (
		<Suspense fallback={<LoadingPage />}>
			<StyledApp id={'mainContent'} tabIndex={-1}>
				<HeaderBreadcrumbs steps={currentSteps} />
				<Routes isRedesign2022={isRedesign2022} />
			</StyledApp>
		</Suspense>
	);
};
export default App;
