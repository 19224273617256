import { FunctionComponent } from 'react';
import { AnalyticViewType, withAnalyticOnView } from '@home-diy-toolbox/web/analytics';
import { useCheckupContentContext } from '@home-diy-toolbox/web/contentful';
import { ResultPageIds, Checkups } from '@home-diy-toolbox/web/common-types/refresh-base';
import { CheckupContainer, MagicWandWithLabel } from '../../../components';
import { useResultPageLoading } from '../../../features/checkupResults/hooks';

const CalculatingBatteryResultPage: FunctionComponent = () => {
	const pageId = ResultPageIds.CALCULATING_BATTERY_RESULT;
	const { useResultPageContent } = useCheckupContentContext();
	const { resultPageTitle } = useResultPageContent(pageId);
	useResultPageLoading(Checkups.BATTERY);

	return (
		<CheckupContainer>
			<MagicWandWithLabel label={resultPageTitle} />
		</CheckupContainer>
	);
};

export default withAnalyticOnView(AnalyticViewType.PAGE, {
	PageId: ResultPageIds.CALCULATING_BATTERY_RESULT,
	CheckupType: Checkups.BATTERY,
})(CalculatingBatteryResultPage);
