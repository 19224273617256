import { FunctionComponent, useEffect, useState } from 'react';
import { StoreConfig, StoreHours } from '@home-diy-toolbox/web/contentful';
import styled from 'styled-components';
import { StoreCard as AsurionStoreCard } from '@soluto-private/mx-asurion-ui-react';
import { OpenHours } from './OpenHours';
import { useArticleRendererContext } from '../../helpers';

type Props = {
	store: StoreConfig;
};

const StyledAsurionStoreCard = styled(AsurionStoreCard)`
	&& > div {
		display: block;
		& > * > * {
			display: block;
		}
	}
`;

export const StoreCard: FunctionComponent<Props> = ({ store }) => {
	const { ubifApiClient } = useArticleRendererContext();
	const [hours, setHours] = useState<StoreHours[]>();
	const [phoneNumber, setPhoneNumber] = useState<string>();

	useEffect(() => {
		const getData = async () => {
			const zipCode = store.address.split(' ').pop();
			const resp = await ubifApiClient.getStoresByZipCode(zipCode);
			const body = await resp.body;
			const storeInfo = body.stores.find((s) => s.id === store.id);
			setHours(storeInfo.hours.all);
			setPhoneNumber(storeInfo.phone);
		};
		getData();
	}, [store.address, store.id, ubifApiClient]);

	const prettyPrintPhoneNumber = (phoneNumber?: string) => {
		return phoneNumber
			? phoneNumber.slice(0, 3) +
					'-' +
					phoneNumber.slice(3, 6) +
					'-' +
					phoneNumber.slice(6)
			: undefined;
	};
	return (
		<StyledAsurionStoreCard
			address={store.address}
			openHours={<OpenHours hours={hours} address={store.address} />}
			phoneNumber={prettyPrintPhoneNumber(phoneNumber)}
			showIcons
			storeName={store.name}
			storeNameSize={4}
		/>
	);
};
