import React, { Context, createContext, useContext } from 'react';
import { ArticleRendererContext } from './types';

const ArticleContext: Context<ArticleRendererContext> = createContext(undefined);

export const useArticleRendererContext = () => useContext(ArticleContext);

type Props = {
	children: React.ReactNode;
	context: ArticleRendererContext;
};

export const ArticleRendererProvider = ({ children, context }: Props): JSX.Element => (
	<ArticleContext.Provider value={context}>{children}</ArticleContext.Provider>
);
