import { Route, Switch, Redirect } from 'react-router-dom';
import { Checkups, Partners } from '@home-diy-toolbox/web/common-types/refresh-base';
import { CheckupRoutes } from './CheckupRoutes';
import { DiagnosticsRoutes } from './DiagnosticsRoutes';
import { ResultRoutes } from './ResultRoutes';
import {
	ExpertChatPage,
	OSSelectionPage,
	withOSSelection,
	NewHomePage,
	HomePage,
} from '../pages';
import { ArticleRoutes } from './ArticleRoutes';
import { SignalLandingPage, SpeedLandingPage } from '../pages/LandingPages';
import { BatteryLandingPage } from '../pages/LandingPages/BatteryLanding';
import { useValidateMxContext } from '@home-diy-toolbox/web/contentful';

const NewHomePageWithOS = withOSSelection(NewHomePage);
const SignalLandingPageWithOS = withOSSelection(SignalLandingPage);
const SpeedLandingPageWithOS = withOSSelection(SpeedLandingPage);

export const Routes = ({ isRedesign2022 }) => {
	const { client: partner } = useValidateMxContext();

	return (
		<Switch>
			<Route path={'/os-selection'} component={OSSelectionPage} />
			<Route
				path={'/checkups'}
				render={({ match: { url } }) => <CheckupRoutes baseRoute={url} />}
			/>
			<Route
				path={'/diagnostics'}
				render={({ match: { url } }) => <DiagnosticsRoutes baseRoute={url} />}
			/>
			<Route
				path={'/result'}
				render={({ match: { url } }) => <ResultRoutes baseRoute={url} />}
			/>
			<Route
				path={'/article'}
				render={({ match: { url } }) => (
					<ArticleRoutes
						baseRoute={url}
						isNewDesign={isRedesign2022} // ArticleRoutes.isNewDesign if redesign2022
					/>
				)}
			/>
			<Route path={'/chat'} component={ExpertChatPage} />
			{/* TODO: remove once battery landing page for att is ready for deployment*/}
			{partner !== Partners.ATT ? (
				<Route
					path={[
						'/battery-popular',
						'/battery-better-charging',
						'/battery-healthier-battery',
						'/battery-conserving-battery',
						'/battery-all',
					]}
					render={({ match: { url } }) => (
						<BatteryLandingPage baseRoute={url} checkupType={Checkups.BATTERY} />
					)}
				/>
			) : null}
			{isRedesign2022 ? (
				[
					<Route exact path={'/'} component={NewHomePageWithOS} key="newHomePage" />,
					<Route
						path={[
							'/signal-popular',
							'/signal-call-quality',
							'/signal-text-messages',
							'/signal-downloading-content',
							'/signal-all',
						]}
						render={({ match: { url } }) => (
							<SignalLandingPageWithOS baseRoute={url} checkupType={Checkups.SIGNAL} />
						)}
						key="newSignalPage"
					/>,
					<Route
						path={[
							'/speed-popular',
							'/speed-app-performance',
							'/speed-loading-content',
							'/speed-optimization',
							'/speed-all',
						]}
						render={({ match: { url } }) => (
							<SpeedLandingPageWithOS baseRoute={url} checkupType={Checkups.SPEED} />
						)}
						key="newSpeedPage"
					/>,
				]
			) : (
				<Route exact path={'/'} key="oldHomePage" component={HomePage} />
			)}
			<Route path="*" render={() => <Redirect to={'/'} />} />
		</Switch>
	);
};
